<template>
  <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800  w-52">
    <div class="fixed flex flex-col top-0 left-0 bg-white h-full border-r w-52">
      <div class="flex items-center justify-center h-14 border-b ">
        <div @click="$refs.image.click()" class="cursor-pointer">
          <div v-if="userStore._user_avatar == null" class="border-2 w-8 h-8 border-black rounded-full mr-2">
            <font-awesome-icon icon="fa-solid fa-user-astronaut" />
          </div>
          <img v-if="userStore._user_avatar != null" class="w-8 h-8 rounded-full object-cover mr-4" :src="avatar"
            alt="">
          <input type="file" @change="onFileChange" ref="image" style="display: none"
            class="p-1 focus:outline-none focus:shadow-outline  text-neutral-500 hover:text-neutral-700">
        </div>
        <div>
          {{ name }}
        </div>
      </div>
      <div class="overflow-y-auto overflow-x-hidden flex-grow">
        <ul class="flex flex-col py-4 space-y-1">
          <li class="px-5">
            <div class="flex flex-row items-center h-8">
              <div class="text-sm font-light tracking-wide text-gray-500">Menu</div>
            </div>
          </li>
          <li>
            <div @click="goRoute('/dashboardAdm')"
              class="relative flex flex-row items-center cursor-pointer h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
              <span class="inline-flex justify-center items-center ml-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
                </svg>


              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Dashboard</span>
            </div>
          </li>
          <li>
            <div @click="goRoute('/company')"
              class="relative flex flex-row cursor-pointer items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
              <span class="inline-flex justify-center items-center ml-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                </svg>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Company</span>
            </div>
          </li>

          <li class="px-5">
            <div class="flex flex-row items-center h-8">
              <div class="text-sm font-light tracking-wide text-gray-500">Cadastros</div>
            </div>
          </li>
          <li>
            <div @click="goRoute('/plan')"
              class="relative flex flex-row items-center cursor-pointer h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
              <span class="inline-flex justify-center items-center ml-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                </svg>


              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Planos</span>
            </div>
          </li>
          <div @click="goRoute('/')"
              class="relative flex flex-row items-center h-11 cursor-pointer focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1">
                  </path>
                </svg>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Logout</span>
            </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { userDefineStore } from '@/store/UserStore'
  const userStore = userDefineStore()
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
const file = ref(null);
const avatar = ref(`http://localhost:8000${userStore._user_avatar}`);
const formData = ref(null);
const router = useRouter()
const name = userStore._user_name

const onFileChange = (event) => {
  file.value = event.target.files[0];
  formData.value = new FormData();
  formData.value.append('file', file.value);
  const payload = {
    user_id: userStore._user_id,
    avatar: file.value,
    company_id: userStore._company_id
  }
  const url = process.env.VUE_APP_API_URL + "avatar";
  const config = {
    headers: {
      Authorization: `Bearer ${userStore._api_token}`,
      'Content-Type': 'multipart/form-data'
    }
  };
  axios.post(url, payload, config).catch(function () {

  }).then((response) => {
    userStore.setAvatar(response.data.avatar)
    avatar.value = `http://localhost:8000${userStore._user_avatar}`;
  })
};

const goRoute = (route) => {
  const redirectPath = route || '/';
  router.push(redirectPath);
}
// eslint-disable-next-line

</script>
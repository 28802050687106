
<template>
    <div class="fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-end items-start animated fadeIn faster"
        style="background: rgba(0,0,0,.7);">
        <div class="border border-indigo-500 shadow-lg  modal-container bg-white w-1/3 h-1/3 
             rounded shadow-lg  overflow-y-auto" :class="distanceTop">
            <div class="modal-content py-4 text-center px-4 z-49">
                <div class="flex justify-between items-center pb-1">
                    <p class="text-2xl font-bold"></p>
                    <div @click="sendClose" class="modal-close cursor-pointer z-50">
                        <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                            viewBox="0 0 18 18">
                            <path
                                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                            </path>
                        </svg>
                    </div>
                </div>
               
                <span class="text-lg font-semibold">{{title}}</span>

                <!--Body-->
                <div class=" flex flex-col md:h-max-1/2 bg-white z-60">
                    <slot>

                    </slot>
                </div>
            </div>

            <!--Footer-->
            <div class="flex flex-row items-center  pt-2">
            </div>
        </div>
    </div>
</template>
<script setup>
// eslint-disable-next-line 
const emit = defineEmits(['refresh']);
// eslint-disable-next-line
const props = defineProps(['title', 'distanceTop'])
const sendClose = () => {
    emit('refresh');
}

</script>
<style scoped>
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1.5;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1.5;
    }

    to {
        opacity: 0;
    }
}
</style>

<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
       
           <SidebarMenu></SidebarMenu>
           <div class="flex flex-col w-full">
           <NavBar :section="'Calendário'" ></NavBar>
           <div class="flex justify-start bg-white">

               <span class="text-xs bg-indigo-300 rounded-md cursor-pointer text-white p-1 w-28" @click="newCalendar">  Nova Agenda </span>
           </div>
           <div class="flex flex-row bg-white justify-center">

               <div
               class="flex justify-center item-center pt-2   text-base font-sm h-8 mr-2 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
               @click="lessWeek()">
               
               <font-awesome-icon icon="fa-solid fa-angle-left" />
            </div>
            <span class="text-lg text-blue-500">{{ week }}</span>
            <div
            class="flex justify-center item-center pt-2   text-base font-sm h-8 ml-2 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
            @click="moreWeek()">
            
            <font-awesome-icon icon="fa-solid fa-angle-right" />
         </div>
        </div>
           

           
           <div class="grid grid-cols-9 bg-white" v-if="load">
            <div class="grid grid-rows-25 col-span-1">
               
                   <span class="text-sm"> </span><br>
                   <div class="grid  text-xs ml-2 content-end text-gray-500"  v-for="(hora,index) in horas" :key="index">
                       <div class="grid justify-items-start content-start border-t border-t-transparent border-t-indigo-100 h-14 w-full ">{{ hora }}</div>
                   </div>
               </div>
             
              
               <div>
                   <span class="text-sm">
                       SEG
                      {{ calendar[0].day }}
                   </span>
                   <div  v-for="(agenda,index0) in calendar[0].agendas" :key="index0" class="flex flex-col">
                    <div  class="border border-indigo-100 h-14   ">                   
                            <CardCalendar :schedule="agenda" @openCalendar="openCalendar(agenda)" ></CardCalendar>                                                                  
                       </div>
                   </div>
               </div>
               <div>
                   <span class="text-sm">
                       TER
                       {{ calendar[1].day }}
                   </span>
                  
                   <div  v-for="(agenda,index0) in calendar[1].agendas" :key="index0" class="flex flex-col">
                    <div  class="border border-indigo-100 h-14   ">                   
                            <CardCalendar :schedule="agenda" @openCalendar="openCalendar(agenda)" ></CardCalendar>                                                                  
                       </div>
                   </div>
               </div>
               <div>
                   <span class="text-sm">
                       QUA
                       {{ calendar[2].day }}
                   </span>
                   <div  v-for="(agenda,index0) in calendar[2].agendas" :key="index0" class="flex flex-col">
                    <div  class="border border-indigo-100 h-14   ">                   
                            <CardCalendar :schedule="agenda" @openCalendar="openCalendar(agenda)" ></CardCalendar>                                                                  
                       </div>
                   </div>
               </div>
               <div>
                   <span class="text-sm">
                       QUI
                       {{ calendar[3].day }}
                   </span>
                   <div  v-for="(agenda,index0) in calendar[3].agendas" :key="index0" class="flex flex-col">
                    <div  class="border border-indigo-100 h-14   ">                   
                            <CardCalendar :schedule="agenda" @openCalendar="openCalendar(agenda)" ></CardCalendar>                                                                  
                       </div>
                   </div>
               </div>
               <div>
                   <span class="text-sm">
                       SEX
                       {{ calendar[4].day }}
                   </span>
                   <div  v-for="(agenda,index0) in calendar[4].agendas" :key="index0" class="flex flex-col">
                    <div  class="border border-indigo-100 h-14   ">                   
                            <CardCalendar :schedule="agenda" @openCalendar="openCalendar(agenda)" ></CardCalendar>                                                                  
                       </div>
                   </div>
               </div>
               <div>
                   <span class="text-sm">
                       SAB
                       {{ calendar[5].day }}
                   </span>
                   <div  v-for="(agenda,index0) in calendar[5].agendas" :key="index0" class="flex flex-col">
                    <div  class="border border-indigo-100 h-14   ">                   
                            <CardCalendar :schedule="agenda" @openCalendar="openCalendar(agenda)" ></CardCalendar>                                                                  
                       </div>
                   </div>
               </div>
               <div>
                   <span class="text-sm">
                       DOM
                       {{ calendar[6].day }}
                   </span>
                   <div  v-for="(agenda,index0) in calendar[6].agendas" :key="index0" class="flex flex-col">
                    <div  class="border border-indigo-100 h-14   ">                   
                            <CardCalendar :schedule="agenda" @openCalendar="openCalendar(agenda)" ></CardCalendar>                                                                  
                       </div>
                   </div>
               </div>
              
           </div>
          
           </div>
           <ModalItem v-if="showModalSchedule" @refresh="closeModal">
                <FormSchedule  @close="closeModal" ></FormSchedule>
            </ModalItem>
           <ModalItem v-if="showModalUpdate" @refresh="closeModal">
            <FormSchedule  :update="updateSchedule" :date="updateSchedule.data" :schedule="updateSchedule.hora" @close="closeModal"></FormSchedule>
            </ModalItem>
   </div>
</template>

<script setup>
import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import CardCalendar from '@/components/CardCalendar'
import FormSchedule from '@/components/FormSchedule.vue'
import ModalItem from '@/components/ModalItem.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { ref, onMounted } from 'vue';
const userStore = userDefineStore()
const load = ref(false)
const numberWeek = ref(0)
const updateSchedule = ref()
const week = ref()
const calendar = ref([])
const horas = ref(['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00',
'13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'])
const showModalSchedule = ref(false)
const showModalUpdate = ref(false)
const closeModal = () => {
  showModalSchedule.value = false;
  showModalUpdate.value = false;
  startCalendar()
}
onMounted(()=>{
   startCalendar()
  })
  const moreWeek = ()=>{
    numberWeek.value ++
    startCalendar()
  }
  const lessWeek = ()=>{
    numberWeek.value --
    startCalendar()
  }
  const newCalendar = ()=>{
    
    showModalSchedule.value = true;
    startCalendar()   
  }
  const openCalendar = (item)=>{
console.log(item)
        
      updateSchedule.value = item[0]
    showModalUpdate.value = true
  }
const startCalendar = ()=>{
    const url = process.env.VUE_APP_API_URL + `startCalendar/${userStore._user_id}/${numberWeek.value}`
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () { }).then(function (result) {
        
        
        calendar.value = result.data.calendar
     console.log(result.data)
        week.value = result.data.info
        load.value = true
    })
    
}
</script>
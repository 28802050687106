<template>
  <div class="fls flex-col items-start">
   
      <div class="flex flex-row justify-center mt-4 items-baseline">
  
            <div class="flex flex-row ml-2 mr-2 items-baseline content-end ">
                <p class=" text-gray-700 font-semibold font-sans  mr-1">Cliente: </p>
                <select v-model="client_id" @change="getOpportunity"
                    class="mt-1 w-60 mb-2 rounded-md border border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option class="min-w-40 mb-2" v-for="(item, index) in listClients" :value="item.id" :key="index">
                        {{ item.name }}</option>
                </select>
            </div>
            <div class="flex flex-row ml-2 mr-2 items-baseline content-end ">
                <p class=" text-gray-700 font-semibold font-sans  mr-1">Questionário: </p>
                <select v-model="questionary_id" @change="onChange"
                    class="mt-1 w-60 mb-2 rounded-md border border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option class="min-w-40 mb-2" v-for="(item, index) in listQuestionaries" :value="item.id" :key="index">
                        {{ item.title }}</option>
                </select>
            </div>
          
            <div class="flex flex-row items-center ml-4 ">
                <ButtonLify @action-click="getOpportunity" >
                    <font-awesome-icon icon="fa-solid fa-search" class="text-white" />
                </ButtonLify>
            </div>
        </div>
  
    <div class="flex flex-row justify-center items-center mt-4" v-if="opportunityQualify">
       <span class="mr-4 text-indigo-500">
           Oportunidade número: {{opportunitySelected.number}}
        </span>
        <span class="mr-4 text-indigo-500">
           Criada em: {{formateDate(opportunitySelected.created_at)}}
        </span>
    </div>
    <div class="flex flex-col  items-center mt-4" v-if="opportunityQualify">
                <div v-for="(item, index) in listQuestions" :key="index" class="flex flex-col text-lg  items-start ">

                    <div v-if="index == control">
                        <FormResponse @sendQuestion="saveResponse" @back="back" :index="index"
                            :qualify="responseQualified[control]" :question="item">
                        </FormResponse>
                    </div>

                </div>

                <div v-if="calculate" class="flex flex-col   items-center justify-center">
                    <span class="flex justify-center text-2xl text-indigo-700 font-sans">{{ menssage }}.</span>
                   
                    <div class="flex justify-center item-center pt-2 mt-2  text-base font-sm h-8 ml-8 w-48 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                        @click="saveQualification">
                        <span class="flex justify-center item-center">

                            Encerrar qualificação
                        </span>
                        <span class="flex justify-center item-center ml-1">

                            <font-awesome-icon icon="fa-solid fa-save" />
                        </span>
                    </div>
                </div>
            </div>
  </div>
</template>

<script setup>

import FormResponse from '@/components/FormResponse.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { inject, onMounted, ref } from 'vue'

  import ButtonLify from '@/components/default/ButtonLify.vue'
const userStore = userDefineStore()
// eslint-disable-next-line
const $swal = inject('$swal');
const totalQuestions = ref();
const listQuestions = ref([]);
const responseQualified = ref([]);
const opportunitySelected = ref([])
const payloadSave = ref([])
const listClients = ref([])
const listQuestionaries = ref([])
const client_id = ref(false)
const questionary_id = ref(false)
const product_id = ref(false)
const chat = ref('')
const opportunityQualify = ref(false)

const control = ref(0)
const calculate = ref(false)
const menssage = ref();
//const router = useRouter()
onMounted(() => {
    getClients();
    getQuestionaries()
    
})
const updateOpportunity= (qualification_id) => {
    opportunitySelected.value.qualification_id = qualification_id
    const url = process.env.VUE_APP_API_URL + "opportunity/" + opportunitySelected.value.id
    
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, opportunitySelected.value, config).catch(function () {

        //router.push('/');
    }).then(() => {
        getChats()
    })
}
const getProduct = ()=>{
    const url = process.env.VUE_APP_API_URL + "product/"+product_id.value 
   
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config)
}
const getOpportunity = () => {
   
    const url = process.env.VUE_APP_API_URL + "opportunitySearch" 
    const payload = {
    company_id: userStore._company_id,
  
    client_id: client_id.value
}
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url,payload, config).then((response) => {
        if(Object.keys(response.data.opportunity).length == 0){
            $swal.fire(
            'Nenhuma oportunidade para este cliente',
            'Verifique se existem oportunidades em andamento para este cliente',
            'info'
            )
        }
        else if(Object.keys(response.data.opportunity).length == 1){
            product_id.value = response.data.opportunity[0].product_id
            opportunitySelected.value= response.data.opportunity[0];
            opportunityQualify.value = true
            getProduct()
        }
    })
}
const getChats = () => {
    const config = {
        headers: {
            Authorization: `Bearer ${userStore._api_token}`,
        }
    }
    const url2 = process.env.VUE_APP_API_URL + "searchChat/" + opportunitySelected.value.id 
    axios.get(url2, config).catch(function () {
    }).then((response) => {
  
        chat.value = response.data[0]
        saveComment(chat.value.id)
    })
}

const saveComment = (chat) => {
    let payload = {
        description: "Oportunidade qualificada por " +userStore._user_name,
        status: 'active' ,
        chat_id: chat,
        user_id: userStore._user_id,
        company_id:   userStore._company_id,
    }

    const url = process.env.VUE_APP_API_URL + "comment"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        $swal.fire(
        'Oportunidade Qualificada! 🖖',
        'Boas Vendas!!',
        'success'
    )
    })
}

const getClients = () => {

const url = process.env.VUE_APP_API_URL + "clients/" + userStore._company_id
const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
};
axios.get(url, config).catch(function () {
}).then(function (responseClients) {

    listClients.value = responseClients.data
})
}

const back = () => {
    control.value--;
}
const saveResponse = (event) => {
    responseQualified.value[control.value] = event
    control.value++;
    if (control.value == totalQuestions.value) {
        let totalPoints = responseQualified.value.reduce((total, num) => total + num.points, 0);
        let totalDefault = responseQualified.value.reduce((total, num) => total + parseInt(num.value), 0);
        let totalPercente = (totalPoints / totalDefault) * 100
        let status = 0
        if (totalPercente >= 75) {
            status = 3
            menssage.value = "Seu cliente atingiu " + totalPercente + "%."
        }
        else if ((totalPercente < 75) && (totalPercente >= 50)) {
            menssage.value = "Seu cliente atingiu " + totalPercente + "%."
            status = 2
        }
        else {
            menssage.value = "Seu cliente atingiu " + totalPercente + "%."
            status = 1
        }
        calculate.value = true
        payloadSave.value = {
            status: 'qualificated',
            points: totalPoints,
            temperature: status,
            answers: responseQualified.value,
            prospect_id: null,
            client_id: client_id.value,
            lead_id: null,
            statusProspect: 'send',
            user_id: userStore._user_id,
            company_id: userStore._company_id,
            product_id: '1'
        }

    }
}
const getQuestionaries = ()=>{
    const url = process.env.VUE_APP_API_URL + "typeQuestionaries/" + userStore._company_id + '/Oportunidade'
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
       
        listQuestionaries.value = response.data
    })
}
// eslint-disable-next-line
const onChange = (event) => {
    if((client_id.value == '')||(questionary_id.value == '')){
        $swal.fire(
        'Ops!',
        'Você precisa declarar todos os campos!',
        'warning'
    )
    }
    else{
    const url = process.env.VUE_APP_API_URL + "questionsQuestionary/" + userStore._company_id + '/' + event.target.value
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
        totalQuestions.value = Object.keys(response.data).length
        if(totalQuestions.value == 0){
        $swal.fire(
        'Ops!',
        'Você não possui nenhuma pergunta para este questionário, verifique as perguntas no item do menu Questionários.',
        'info'
    )   
    }
    else{
        listQuestions.value = response.data
    }
    })
}
}
const saveQualification = () => {
    payloadSave.value.msg = "Oportunidade qualificada por " +userStore._user_name

    const url = process.env.VUE_APP_API_URL + "qualification"

    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payloadSave.value, config).catch(function () {

    }).then((response) => {

        updateOpportunity(response.data.id);
    })
}

const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}

</script>

<style scoped>
    
</style>
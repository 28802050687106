<template>
    <div>
        <span class="text-xl text-lify_blue">Relatório de vendas </span>
        <div class="flex flex-row p-2 w-full">
            <div class="flex flex-col w-44">
                <label class="flex text-base font-medium text-gray-900 ">Data início</label>
                <input type="date" v-model="dateStart"
                class="flex  bg-gray-50 border h-9 border-gray-300 text-gray-900
                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">

            </div>
            <div class="flex flex-col w-44 ml-4">
                <label class="flex text-base font-medium text-gray-900">Data fim</label>
                <input type="date" v-model="dateEnd"
                class="flex  bg-gray-50 border h-9 border-gray-300 text-gray-900
                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
            </div>
        <div  class="flex ml-3   mt-6">
          <ButtonLify @action-click="getInfos">
            <font-awesome-icon icon="fa-solid fa-search" />
          </ButtonLify>
         
        </div>
    </div>
    <div class="flex flex-row" v-if="loadGrafico">
        <div class="flex flex-col">
            <CardDashboardResume class="mt-2" :real="true" :title="'Vendas com sucesso'" :value="cardSalesSuccess.value" :event="'salesSuccess'" @sendEvent="getSalesSuccess"></CardDashboardResume>
            <CardDashboardResume class="mt-2" :real="true" :title="'Vendas  não concretizadas'" :value="cardSalesFailure.value" :event="'salesFailure'" @sendEvent="getSalesFailure"></CardDashboardResume>
        </div>
        <DonutChartComponent :data="dataDonut"  :label="labelDonut" :key="keystamp" :colors="['#9ef01a','#EF233C']"></DonutChartComponent>
    </div>
    <TableOpportunities v-if="openSales" :listOpportunities="listOpportunities" :key="keystampTable"></TableOpportunities>
</div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import axios from 'axios'
import { userDefineStore } from '@/store/UserStore'
import CardDashboardResume from './CardDashboardResume.vue';
import DonutChartComponent from './DonutChartComponent.vue';
import TableOpportunities from  '@/components/dashboard/TableOpportunities.vue'
import ButtonLify from '../default/ButtonLify.vue';
const userStore = userDefineStore()
const cardSalesFailure =ref(0)
const cardSalesSuccess =ref(0)
const dateStart = ref()
const dateEnd = ref()
const listOpportunities = ref()

const keystamp = ref(0)
const keystampTable = ref(0)
const openSales = ref(false)
const loadGrafico = ref(false)
const labelDonut =ref([])
const dataDonut =ref([])
const salesSuccess =ref([])
//const salesFailure =ref([])


onMounted(() => {
    iniDates()
    getSales()
    valueSalesSuccess()
    valueSalesFailure()
})
const getSalesSuccess = ()=>{
    const url = process.env.VUE_APP_API_URL + "reportSalesSuccess/" + userStore._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
        listOpportunities.value = response.data
        openSales.value = true
        keystampTable.value = new Date().getTime();
        console.log(salesSuccess.value)
       
})
}
const getSalesFailure = ()=>{
    const url = process.env.VUE_APP_API_URL + "reportSalesFailure/" + userStore._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
        listOpportunities.value = response.data
        keystampTable.value = new Date().getTime();
        openSales.value = true
        console.log(listOpportunities.value)
       
})
}
const getSales = ()=>{
    const url = process.env.VUE_APP_API_URL + "reportSalesAll/" + userStore._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
        listOpportunities.value = response.data
       
})
}
const getInfos = ()=>{
    getSales()
    getSalesSuccess()
    getSalesFailure()
    valueSalesSuccess()
    valueSalesFailure()
}
const iniDates = ()=>{
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    dateStart.value = now.getFullYear()+"-"+(month)+"-01";
    dateEnd.value = now.getFullYear()+"-"+(month)+"-"+(day) ;
   }
  


const valueSalesSuccess = () => {
  
  const url = process.env.VUE_APP_API_URL + "salesSuccess/" + userStore._company_id + '/' + dateStart.value + '/' + dateEnd.value
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    
  axios.get(url, config).then((response) => {

    cardSalesSuccess.value = response.data
  });
}
const valueSalesFailure = () => {
   
   const url = process.env.VUE_APP_API_URL + "salesFailure/" + userStore._company_id + '/' + dateStart.value + '/' + dateEnd.value
     const config = {
         headers: { Authorization: `Bearer ${userStore._api_token}` }
     };
     
   axios.get(url, config).then((response) => {
     cardSalesFailure.value = response.data
     console.log(cardSalesFailure.value)

    inicializaGrafico()
   });
 }
 const inicializaGrafico = ()=>{
          labelDonut.value = ['Vendas concretizadas: '+cardSalesSuccess.value.qtd,  'Vendas não concretizadas: '+cardSalesFailure.value.qtd ]
          dataDonut.value = [cardSalesSuccess.value.qtd,  cardSalesFailure.value.qtd  ]
          loadGrafico.value = true

    keystamp.value = new Date().getTime();
   
  }
</script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHotjar, faVuejs, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import {
    faAddressBook,faAddressCard,faAngleDown, faAngleLeft, faAngleRight, faArrowRotateLeft,
    faBell,faBox,faBriefcase,faBuilding, faBuildingUser,
    faCalendarDays, faChartPie, faCheck,faCircleInfo,faCircleLeft, faCircleRight,faClock,faComment,
    faDiagramProject,faDollarSign,
    faEdit, faEnvelope,
    faFile,faFileArrowDown,faFileInvoiceDollar,faFilter,faFire,faFolderClosed,faFolderOpen,
    faGear,
    faHome, faHomeUser,faHandshake,
    faIcicles,faIdCard,faIdCardClip,faImage,
    faList, faListCheck,
    faLocationDot, faLock,
    faMinus,
    faMugHot,
    faPaperPlane, faPaperclip, faPen, faPhone, faPlus, faPowerOff, faPrint,
    faQuestion,
    faRectangleXmark,faRotateLeft,
    faSave,faShareNodes,faScrewdriverWrench,faSearch,faSort,faSquareCaretDown, faSquareCaretUp,faStar,
    faTrash, faTable,
    faUser,faUserAstronaut,faUserGear, faUserPlus,faUserTie,faUsers, faX
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createPinia } from 'pinia'
import 'sweetalert2/dist/sweetalert2.min.css'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'
import { createApp } from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from "vue3-apexcharts"
import App from './App.vue'
import './assets/index.css'
import router from './router'


library.add(faAddressBook,
    faAddressCard,
    faAngleDown, faAngleLeft, faAngleRight, faArrowRotateLeft,
    faBell, faBriefcase,faCircleInfo,
    faBuilding, faBuildingUser,faBox,
    faCalendarDays, faChartPie, faCheck, faCircleLeft, faCircleRight, faComment,faClock,
    faDollarSign, faDiagramProject,
    faEdit, faEnvelope,
    faFile, faFileInvoiceDollar, faFolderOpen, faFileArrowDown, faFolderClosed,faFire, faFilter,
    faGear,
    faHome, faHomeUser,faHotjar,faHandshake,
    faIcicles,faIdCard,faIdCardClip, faImage,
    faLocationDot, faLock, faList, faListCheck,
    faMinus,faMugHot,
    faPaperPlane, faPaperclip, faPen, faPhone, faPlus, faPowerOff,faPrint,
    faQuestion,
    faRectangleXmark, faRotateLeft,
    faSave, faSearch, faStar,faSquareCaretDown, faShareNodes,faSquareCaretUp, faSort,faScrewdriverWrench,
    faTrash, faTable,
    faUser, faUserTie, faUserAstronaut, faUsers,faUserGear, faUserPlus,
    faWhatsapp,
    faVuejs, faX)
    const pinia = createPinia()
    const app = createApp(App)
app.use(pinia).use(router).use(VueSweetalert2).use(VueApexCharts).use(VCalendar, {}).component('font-awesome-icon', FontAwesomeIcon).mount('#app')

import Echo from 'laravel-echo'
import { chatDefineStore } from './store/ChatStore'
import { userDefineStore } from './store/UserStore'
const user = userDefineStore()
const chat = chatDefineStore()

window.Pusher = require('pusher-js')
   window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    authEndpoint: process.env.VUE_APP_API_URL + 'broadcasting/auth/1',
    encrypted: true,
    forceTLS: true,
    cluster: "mt1",
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws','wss'],
    auth: {
        headers: {
            authorization: 'Bearer' + user._api_token,
        }
    }
})
window.Echo.channel('MyChannel')
.listen('MessageWSEvent', (e) => {
  console.log('WS COECTADO')
  chat.receivedNotification(e)
})

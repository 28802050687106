<template>
  <div>
    <div class="flex flex-col w-full" v-if="load">
        
            <div class="flex flex-row items-start">
                <div  class="flex flex-col">
                    <div v-if="client" class="flex flex-row">
                       <span class="ml-2 text-md  ">Tipo: Cliente </span>
                        <span class="ml-4 text-md  ">Nome: {{ client.name }} </span>
                        <span class="ml-4 text-md  ">e-mail: {{ client.email }}</span>
                        <span class="ml-4 text-md  ">Fone: {{ client.phone }}</span>

                    </div>
                    <div v-if="lead" class="flex flex-row items-start p-2">
                        <span class="ml-4 text-md  ">Tipo: Lead </span>
                        <span class="ml-4 text-md  ">Nome: {{ lead.name }} </span>
                        <span class="ml-4 text-md  ">e-mail: {{ lead.email }}</span>
                        <span class="ml-4 text-md  ">Fone: {{ lead.phone }}</span>

                    </div>
                    <div class="flex flex-row">
                        <span class="flex flex-row items-start ml-6 text-md">Produto de interesse: {{ product.product }}</span>
                        <span class="flex flex-row items-start ml-2 text-md">Pontos de Qualificação: {{ qualification.points }}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-row items-center items-baseline ">
                <span class="flex justify-center items-center ml-6 mt-2">Questionário</span>
                <button v-if="!openQuestion" 
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                    @click="openQuestion = !openQuestion">
                    <font-awesome-icon icon="fa-solid fa-angle-left" />
                </button>
                <button v-if="openQuestion" aria-label="Edit user"
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                    @click="openQuestion = !openQuestion">
                    <font-awesome-icon icon="fa-solid fa-angle-down" />
                </button>
            </div>
            <div v-for="(element, index) in questions" class="mt-2 ml-8 mr-4" :key="index">
                <LineAnswers v-if="openQuestion" :element="element"></LineAnswers>
            </div>
        </div>
  </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  import LineAnswers from './LineAnswers.vue'
  const user = userDefineStore()
const load = ref(false)
const opportunity = ref(false)
const product = ref(false)
const lead = ref(false)
const client = ref(false)
const qualification = ref(false)
const openQuestion = ref(false)
const questions = ref(false)
// eslint-disable-next-line
const props = defineProps(['opportunity'])
onMounted(() => {
    getOpportunty();
})
// eslint-disable-next-line 
const getOpportunty = () => {

        opportunity.value = props.opportunity
        if(props.opportunity.client_id){
            getClient(props.opportunity.client_id)
        }
        if(props.opportunity.lead_id){
            getLead(props.opportunity.lead_id)
        }
        getProduct(props.opportunity.product_id)
        getQualification(props.opportunity.id)
       
    
}

const getProduct = (id) => {
   const url = process.env.VUE_APP_API_URL + "product/" + id
       const config = {
           headers: { Authorization: `Bearer ${user._api_token}` }
       };
       axios.get(url, config).then((response) => {
           product.value = response.data
       })
   }
const getClient = (id) => {
   const url = process.env.VUE_APP_API_URL + "client/" + id
       const config = {
           headers: { Authorization: `Bearer ${user._api_token}` }
       };
       axios.get(url, config).then((response) => {
           client.value = response.data
       })
   }
const getLead = (id) => {
   const url = process.env.VUE_APP_API_URL + "lead/" + id
       const config = {
           headers: { Authorization: `Bearer ${user._api_token}` }
       };
       axios.get(url, config).then((response) => {
           lead.value = response.data
       })
   }
const getQualification = (id) => {
   const url = process.env.VUE_APP_API_URL + "qualification/" + id
       const config = {
           headers: { Authorization: `Bearer ${user._api_token}` }
       };
       axios.get(url, config).then((response) => {
           qualification.value = response.data
           questions.value = JSON.parse(qualification.value.answers)
           load.value = true;
       })
   }
</script>

<style scoped>
    
</style>
<template>
    <div class="p-2 mb-3 w-60  flex flex-col border-solid border-2 border-re-600 bg-blue-50 rounded-lg hover:shadow-lg "
        :class="color">
        <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
            element.type
        }}</p>
        <div class="flex flex-col justify-between  ">

            <div class="flex items-center justify-between">
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
                    element.name_opportunity
                }}</p>
                <font-awesome-icon v-if="element.qualification_temperature == 1" icon="fa-solid fa-icicles" class="text-blue-300" />
                <font-awesome-icon v-if="element.qualification_temperature == 2" icon="fa-solid fa-mug-hot" class="text-yellow-300" />
                <font-awesome-icon v-if="element.qualification_temperature == 3" icon="fa-brands fa-hotjar" class="text-red-500" />
            </div>


          
        </div>
        <p class="flex items-start text-gray-700 font-semibold font-sans ">{{ formatDate(element.created_at) }}</p>
        <button @click="finish" class="bg-lify_blue rounded-md border border-lify_blue_hover outline-lify_blue_hover
        outline outline-offset-2 outline-1 m-2 hover:bg-lify_blue_hover text-white">
            Finalizar Venda
        </button>
    </div>
</template>

<script setup>
import { inject } from 'vue'

const $swal = inject('$swal');
// eslint-disable-next-line
const props = defineProps({ element: Object, color: String })
// eslint-disable-next-line
const emit = defineEmits(['sendQualify', 'openModal', 'openModalSchedule','encerrar']);
// eslint-disable-next-line
const sendQualify = (item) => {
    emit('sendQualify', item);
}


function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}
const finish = ()=>{
    $swal.fire({
  title: 'Finalizar Venda',
  text: 'Escolha uma opção para o encerramento da venda:',
  showCancelButton: true,
  confirmButtonText: 'Sucesso',
  cancelButtonText: 'Insucesso',
  icon:'info',
}).then((result) => {
    if (result.isConfirmed) {
        openComment('success')
  }
  else{
    openComment('failure')
  }
    })
}
const openComment = (status)=>{
    $swal.fire({
            title: 'Para encerrar a venda precisamos inserir um comentário',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (post) => {
              return post
  },
 
}).then((result) => {
    if (result.isConfirmed) {
        console.log(result)
        emit('encerrar',{element:props.element,list:5, comment: result.value, status: status})

    }
})
}

</script>

<style scoped></style>
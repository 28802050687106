<template>
    <div>
      <div class="flex flex-col">
        <div class="flex  flex-col items-center mt-4">
            <span class="mb-2 text-base font-medium text-gray-900 ">
                Data
            </span>
            <input type="datetime-local" v-model="date"
            class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
        </div> 
        <div class="flex flex-col items-center mt-4">
            <span class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                Descrição
            </span>
            <textarea type="text" v-model="description"
                class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"></textarea>
        </div>
           <div class="flex  flex-col items-center mt-4">

               <ButtonLify v-if="!update" @action-click="saveReports">
                   <font-awesome-icon icon="fa-solid fa-save" />
                </ButtonLify>
                <ButtonLify v-if="update" @action-click="updateReports">
                    <font-awesome-icon icon="fa-solid fa-save" />
                </ButtonLify>
            </div>      
                  
    </div>
</div>
</template>
  
  <script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref, inject } from 'vue'
  import ButtonLify from './default/ButtonLify.vue';
  const userStore = userDefineStore()

  // eslint-disable-next-line
  const emit = defineEmits(['saved'])
  // eslint-disable-next-line
  const props = defineProps(['opportunity','client', 'lead', 'prospect', 'title','update','report'])
  const $swal = inject('$swal');
  const description = ref('')
  const opportunity = ref(null)
  const client = ref(null)
  const lead = ref(null)
  const prospect = ref(null)
  const date = ref('')
  const url = ref(process.env.VUE_APP_API_URL)
  const file = ref('')
  const formData = ref(null);
  onMounted(() => {
    
    if(props.opportunity){
        opportunity.value = props.opportunity.opportunity_id    
        url.value +=   "reportsOpportunity/"+ props.opportunity.opportunity_id
       
    }
    if(props.client){
        client.value = props.client.id
        url.value +=   "reportsClient/"+ props.client.id
       
    }
    if(props.lead){
        lead.value = props.lead.id
        url.value +=   "reportsLead/"+ props.lead.id
        
    }
    if(props.prospect){
        prospect.value = props.prospect.id
        url.value +=   "reportsProspect/"+ props.prospect.id

    }
    if(props.update){
        date.value = props.report.date
        description.value = props.report.description
        
    }
      
  })
  const updateReports = ()=>{
      const url = process.env.VUE_APP_API_URL + "reportVisit/" + props.report.id
      let payload = { 'document': null, 'description': description.value,'date': date.value }
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      axios.put(url, payload, config).then(() => {
          date.value = ''
          description.value = ''
          $swal.fire(
            'Ok',
            'Update efetuado com sucesso!',
            'success'
        )
          emit('saved')
      })
  }
 
  const saveReports = ()=>{
    if((date.value == '')|| (description.value == '')){
        $swal.fire(
            'Ops!',
            'Você precisa inserir a data e a descrição para cadastrar o relatório de visitas.',
            'warning'
        )
    }
    else{
      formData.value = new FormData();
      formData.value.append('file', file.value);
      const url = process.env.VUE_APP_API_URL + "reportVisit"
      const config = {
          headers: {
              Authorization: `Bearer ${userStore._api_token}`,
              'Content-Type': 'multipart/form-data'
          }
      };
      let dataAgenda = date.value.split('T')
      dataAgenda = dataAgenda[0]+' '+dataAgenda[1]
      const payload = {
          description: description.value,
          date: dataAgenda,
          document: file.value,
          user_id: userStore._user_id,
          opportunity_id: opportunity.value,
          client_id: client.value,
          lead_id: lead.value,
          prospect_id: prospect.value,
          company_id: userStore._company_id,
      }

      axios.post(url, payload, config).then(() => {
        date.value = ''
        description.value = ''
        emit('saved')
      })
    }
  }

  
  </script>
  
  <style scoped>
      
  </style>
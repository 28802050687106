<template>
  <div class="flex flex-row  h-screen w-screen bg-neutral-100">
            <div>
                <SidebarMenu></SidebarMenu>
            </div>
            <div class="flex flex-col w-full">
                <NavBar :section="'Notificações'" :head="[]" class="pb-2"></NavBar>
                <div class="flex flex-row justify-start">
                    <div >
                        <CardDates @send-dates="receivedDates" ></CardDates>
                        <BuscaUser v-if="userStore._user_type == 'Gerente'" @send-user="receivedUser" :title="'Usuário'" class="ml-4 mt-2"></BuscaUser>
                        <div >

                        </div>
                    </div>
                    <DonutChartComponent  :data="data" :label="labels" :title="'Resumo de notificações'" :key="keystamp"></DonutChartComponent>
                </div>
                <div class="flex  flex-row  items-start pl-2" v-for="(notification, index) in listNotification" :key="index">
                    <CardNotification :element="notification"/>
                </div>
            </div>

    
  </div>
</template>

<script setup>
import CardNotification from '@/components/CardNotification.vue'
import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import { userDefineStore } from '@/store/UserStore'
import { chatDefineStore } from '@/store/ChatStore'
import CardDates from '@/components/default/CardDates.vue'
import BuscaUser from '@/components/BuscaUser.vue'
import axios from 'axios'
import DonutChartComponent from '@/components/dashboard/DonutChartComponent.vue'
import { onMounted, ref } from 'vue'
const userStore = userDefineStore() 
const chatStore = chatDefineStore()
const listNotification = ref()
const dateStart = ref()
const dateEnd = ref()
const user = ref()

const keystamp = ref(0)
const labels = ref([])
const data = ref([])
onMounted(() => {
   chatStore.resetNotification()
   if(userStore._user_type == 'Gerente'){
       getAllNotifications();
   }
   else{
    user.value = userStore._user_id
    getNotifications()
   }
})


const receivedUser = (userReceived)=>{
console.log(userReceived)
user.value = userReceived.id
if(dateStart.value){
    getNotifications()
}

}
const receivedDates = (dates)=>{
    console.log(dates)
    dateStart.value = dates.dateStart
    dateEnd.value = dates.dateEnd
    if(user.value){
        getNotifications()
    }
}
const initializeGraph = ()=>{
  
// Usando reduce para acumular tipos únicos e suas quantidades
let resultado = listNotification.value.reduce((acc, obj) => {
    if (!acc[obj.type]) {
        acc[obj.type] = 0;
    }
    acc[obj.type]++;
    return acc;
}, {});

let tiposUnicos = Object.keys(resultado);
let quantidades = Object.values(resultado);
labels.value = tiposUnicos
    data.value = quantidades

    keystamp.value = new Date().getTime();
}
const getNotifications = () => {
    if(user.value){
    const config = {
        headers: {
            Authorization: `Bearer ${userStore._api_token}`,
        }
    }
     const  url = `${process.env.VUE_APP_API_URL}notificationsUserDate/${userStore._company_id}/${user.value}/${dateStart.value}/${dateEnd.value}`
    axios.get(url, config).catch(function () {
    }).then((response) => {
        console.log(response.data)
        listNotification.value = response.data
        initializeGraph()
    })
}
else{
    getAllNotifications()
}
}
const getAllNotifications = () => {
    const config = {
        headers: {
            Authorization: `Bearer ${userStore._api_token}`,
        }
    }
     const  url = `${process.env.VUE_APP_API_URL}notificationsDate/${userStore._company_id}/${dateStart.value}/${dateEnd.value}`
    axios.get(url, config).catch(function () {
    }).then((response) => {
        console.log(response.data)
        listNotification.value = response.data
        initializeGraph()
    })
}

</script>

<style scoped>
    
</style>
<template>
  <div class="flex flex-row  h-screen w-screen bg-neutral-100">
    <div>

      <SidebarMenu></SidebarMenu>
    </div>

    <div class="flex flex-col w-full">
      <NavBar :section="'Qualificação de Leads'" :head="['Novo Prospect']" @navItem="navItem"></NavBar>
      <div class="flex flex-row justify-between overflow-hidden h-screen w-auto overflow-hidden  bg-gray-100 ">


        <div class="flex  ml-4  flex-col  items-center">
          <p class="mb-2 text-gray-700 font-semibold font-sans tracking-wide">Não Qualificado</p>
          <div class="w-full max-w-lg h-5/6 overflow-y-auto  overflow-x-hidden  text-center">
            <div class="" v-for="(item, index) in listProspects" :key="index">
              <card :element="item" @sendQualify="receivedQualify" @deleteProspect="deleteProspect"></card>
            </div>
            <div v-if="listProspects == ''">
              <CardEmpty :msg="'Nenhum lead em qualificação'"></CardEmpty>
            </div>
          </div>
        </div>
        <div class="flex ml-4 flex-col  items-center">
          <p class="mb-2 text-gray-700 font-semibold font-sans tracking-wide"> Em Qualificação</p>
          <div class="w-full max-w-lg h-5/6 overflow-y-auto  overflow-x-hidden  text-center">
            <div class="" v-for="(item, index) in listProspectsUser" :key="index">
              <CardMyQualifying :element="item" @sendQualify="receivedQualify"></CardMyQualifying>
            </div>
            <div v-if="listProspectsUser == ''">
              <CardEmpty :msg="'Nenhum lead em qualificação'"></CardEmpty>
            </div>
          </div>
        </div>
        <div class="flex mr-4   flex-col  items-center">
          
          <div class="flex flex-row items-center">
            <p class="mb-2 text-gray-700 font-semibold font-sans tracking-wide"> Qualificado</p>
            <ButtonLify @action-click="openDate = !openDate" class="ml-2">

              <font-awesome-icon icon="fa-solid fa-calendar-days" class="text-sm"/>
            </ButtonLify>
            </div>
         
          <div class="flex flex-col justify-start mt-4 items-center w-full  h-5/6 overflow-y-auto  overflow-x-hidden  text-center">
            <div class="" v-for="(item, index) in listQualifications" :key="index">
              <CardQualificated :element="item" @sendQualify="receivedQualify"
              @deleteQualification="deleteQualification"
               @openModalCalendar="openModalCalendar">
              </CardQualificated>
            </div>
            <div v-if="listQualifications == ''">
              <CardEmpty :msg="'Nenhum lead qualificado'"></CardEmpty>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <ModalItemSm v-if="modal" @refresh="close">
    <FormProspect @close="close"></FormProspect>
  </ModalItemSm>
  <ModalItemSmRightTop v-if="openDate" @refresh="closeDate" :distance-top="'mt-20 mr-28'" :title="'Selecione o período da consulta'">
    <div class="flex flex-col" v-show="openDate">
        <div class="flex flex-col justify-center items-center">
          <label class="block text-base font-medium text-gray-900 ">Data início</label>
          <input type="date" v-model="dateStart"
          class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
        </div>
        <div class="flex flex-col justify-center items-center mt-4">
          <label class="block  text-base font-medium text-gray-900">Data fim</label>
          <input type="date" v-model="dateEnd"
          class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
        </div>
        <div  class="flex justify-center items-center mt-4">
          <ButtonLify @action-click="closeDate">

            <font-awesome-icon icon="fa-solid fa-search" />
          </ButtonLify>
              
            </div>
          </div>
    </ModalItemSmRightTop>
  <ModalItemSm v-if="modalCsv" @refresh="close">
    <button raised class="bg-blue-500" @click="onPickFile">Upload CSV</button>
                            <input 
                                type="file" 
                               
                                ref="fileInput" 
                                class="form-control" 
                                @change="loadCSV">
  </ModalItemSm>
  <ModalItemSm v-if="modalCalendar" @refresh="closeSchedule">
    <FormSchedule @close="close" :opportunity_id="1"></FormSchedule>
  </ModalItemSm>
</template>

<script setup>
import CardEmpty from '@/components/CardEmpty.vue'
import CardMyQualifying from '@/components/CardMyQualifying.vue'
import CardQualificated from '@/components/CardQualificated.vue'
import card from '@/components/CardQualify.vue'
import FormProspect from '@/components/FormProspect.vue'
import FormSchedule from '@/components/FormSchedule.vue'
import ModalItemSm from '@/components/ModalItemSm.vue'
import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import ModalItemSmRightTop from '@/components/ModalItemSmRightTop.vue'
import ButtonLify from '@/components/default/ButtonLify.vue'
import { inject, onMounted, ref } from 'vue'
const userStore = userDefineStore()
const $swal = inject('$swal');
const modal = ref(false)
const modalCsv = ref(false)
const openDate = ref(false)
const modalCalendar = ref(false)
const qualifying = ref(false)
const dateStart = ref()
const dateEnd = ref()
const listProspects = ref('');
const listProspectsUser = ref('');
const listQualifications = ref('');
const prospectQualify = ref()
const close = () => {
  modal.value = false
  getProspects()
  getProspectsUser()
  getQualifications();
}
// eslint-disable-next-line
const navItem = (item) => {
  if(item == 'Novo Prospect'){
    modal.value = true
    modalCsv.value = false
  }
  else{
    modal.value = false
    modalCsv.value = true
  }
}
onMounted(() => {
  iniDates()
  getProspects()
  getProspectsUser()
  getQualifications();
})
const openModalCalendar = () => {
  modalCalendar.value = true
}
const closeDate = ()=>{
  openDate.value = false
  getQualifications()
}

const loadCSV = (ev)=> {
       const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = e => console.log( e.target.result);
      reader.readAsText(file);
     
        }

const deleteProspect = (id)=>{

  const url = process.env.VUE_APP_API_URL + "prospect/" + id;
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  axios.delete(url, config).then(() => {
    getProspects()
  })
}
const deleteLead = (id)=>{
  const url = process.env.VUE_APP_API_URL + "lead/" + id;
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  axios.delete(url, config).then(() => {
    getProspects()
    getProspectsUser()
    getQualifications();
  })
}
const deleteQualification = (item)=>{
  const url = process.env.VUE_APP_API_URL + "qualification/" + item.id_qualification;
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  axios.delete(url, config).then(() => {

    const urlProspect = process.env.VUE_APP_API_URL + "prospect/" + item.id_prospect;
    axios.get(urlProspect, config).then((response) => {
      let updateProspect = response.data
      updateProspect.qualificated = false
      updateProspect.status = 'active'
      axios.put(process.env.VUE_APP_API_URL + "prospect/" + item.id_prospect ,updateProspect, config)
      .then(() => {
        deleteLead(item.lead_id)
      getProspects()
      getProspectsUser()
      getQualifications();
      $swal.fire(
            'Ok',
            'Qualificação efetuada com sucesso, seu Lad voltou a fila de prospect. Você pode deleta-lo na fila de não qualificado.',
            'sucess'
        )
    })
    });

});
}
const iniDates = ()=>{
  
  var now = new Date();
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() + 1)).slice(-2);

dateStart.value = now.getFullYear()+"-"+(month)+"-01" ;
dateEnd.value = now.getFullYear()+"-"+(month)+"-"+(day) ;
  }
const getProspects = async () => {

  const url = process.env.VUE_APP_API_URL + "prospects/" + userStore._company_id;
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  await axios.get(url, config).then((response) => {

    listProspects.value = response.data
  })
}
const getProspectsUser = async () => {
  const url = process.env.VUE_APP_API_URL + "userProspects/" + userStore._user_id;
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  await axios.get(url, config).then((response) => {
    listProspectsUser.value = response.data
  })
}
const getQualifications = () => {
  const url = process.env.VUE_APP_API_URL + "qualificationsDate/" + userStore._company_id + '/'+ dateStart.value+ '/'+ dateEnd.value;
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listQualifications.value = response.data
  })
}
const receivedQualify = (item) => {
  prospectQualify.value = item
  qualifying.value = true
}
const closeSchedule = () => {
  modalCalendar.value = false
}

</script>

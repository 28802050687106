<template>
    <div class="grid col-span-4 row-span-1 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-2 mb-4">
        <div class="flex flex-row justify-between">
          <span class=" ml-4">Informações de contatos</span>
          <!--<span @click="newContact">
            <font-awesome-icon class="text-white mr-4"
              icon="fa-solid fa-user-plus" />
          </span> -->
        </div>
      </div>
      <div class="grid col-span-4 h-8 grid-cols-12  content-start  gap-1">
        <span class="grid justify-items-start  col-span-3  text-sm">Nome</span>
        <span class="grid justify-items-start  col-span-4  text-sm">e-mail</span>
        <span class="grid justify-items-start  col-span-3  text-sm">Telefone</span>
        <span class="grid justify-items-start  col-span-2  text-sm">Ação</span>
      </div>
      <div class="grid col-span-4 row-span-4  overflow-y-auto overflow-x-hidden " >
        <div class="grid grid-cols-12 bg-white justify-items-start  hover:bg-slate-200"
          v-for="(line, index) in listContacts" :key="index">
          <div class=" col-span-3 text-gray-400 font-sm  break-all">
              <font-awesome-icon v-if="line.type == 'Principal'" class="text-yellow-500"
                icon="fa-solid fa-star" />
              <span class=" text-sm ml-2">{{ line.name   }}</span>
          </div>
          <div class=" col-span-4 text-gray-400 font-sm  break-all">
            <span class=" text-sm ml-2">{{ line.email   }}</span>
          </div>
          <div class=" col-span-3 text-gray-400 font-sm  break-all">
            <span class=" text-sm ml-2">{{ line.phone   }}</span>
          </div>
          
          <div class="col-span-2 font-sm break-all">
            <button class="p-1 focus:outline-none focus:shadow-outline text-green-500 hover:text-green-600"
                    @click="sendWhatsapp(line)">
                    <font-awesome-icon icon="fa-brands fa-whatsapp" />
                </button>
                <a class="p-1 focus:outline-none focus:shadow-outline text-neutral-500 hover:text-green-600"
                    :href="`mailto:${line.email}`">
                    <font-awesome-icon icon="fa-solid fa-envelope" />
                </a>
                <a :href="`tel:${line.phone}`"
                    class="p-1 focus:outline-none focus:shadow-outline text-neutral-500 hover:text-green-600">
                    <font-awesome-icon icon="fa-solid fa-phone" />
                </a>
          </div>
        </div>
      </div>
  
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  const userStore = userDefineStore()

// eslint-disable-next-line
const emit = defineEmits(['saved', 'delete', 'openModal']);
// eslint-disable-next-line
const props = defineProps(['client_id'])


const listContacts = ref([])
const listar = ref(false)

onMounted(() => {
    getContacts();
})
const sendWhatsapp = (item) => {
    const text = 'Este é um teste de mensagem custonizada'
    const phone = '55' + item.phone.replace(/[^0-9]/g, '')
    const url = 'https://wa.me/' + phone + '?text=' + text;
    window.open(url)
}
const getContacts = () => {
    const url = process.env.VUE_APP_API_URL + "contacts/" + props.client_id

    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () { }).then(function (responseContacts) {
        listContacts.value = responseContacts.data
        listar.value = true;
    })
}
</script>

<style scoped></style>
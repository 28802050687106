<template>
    <div>
        <div class="flex flex-row items-start ml-4 mt-4 justify-start ">
        <div @click="$refs.image.click()" class="cursor-pointer">          
          <img v-if="company.logo" class="w-48 h-48 rounded-md" 
              :src="returnLogo(company.logo)" >
              <span v-else> <font-awesome-icon icon="fa-solid fa-image" class="w-48 h-48 rounded-md" /></span>
              
            <input type="file" @change="onFileChange" ref="image" style="display: none"
            class="p-1 focus:outline-none focus:shadow-outline  text-neutral-500 hover:text-neutral-700">
        </div>
        <div class=" ml-10">
            <div class="flex flex-row">
                <span class="text-lg">Empresa:</span>  
                <span class="text-lg ml-2">{{ company.name }}</span>  
            </div>
            <div class="flex flex-row">
                <span class="text-lg">CNPJ:</span>  
                <span class="text-lg ml-2">{{ company.cnpj }}</span>  
            </div>
            <div class="flex flex-row">
                <span class="text-lg">Endereço:</span>  
                <span class="text-lg ml-2">{{ company.logradouro }},</span>  
                <span class="text-lg ml-2"> {{ company.numero }}</span>  
                <span v-if="company.complemento" class="text-lg ml-2">/ {{ company.complemento }}</span>  
                <span class="text-lg ml-2"> {{ company.bairro }}-</span>  
                <span class="text-lg ml-2"> {{ company.localidade }}/</span>  
                <span class="text-lg "> {{ company.uf }}</span>  
            </div>
        </div>
      </div>
      <div>

        <div class="flex flex-row items-center ml-8 mt-8 mr-8" :key="keystamp">
          <div @click="$refs.image.click()" class="cursor-pointer">          
          <span v-if="!avatar" class="w-10 h-10 rounded-full object-cover mr-4" :src="avatar" alt="">
            <font-awesome-icon icon="fa-solid fa-image" />
          </span>
          <img v-if="avatar" class="w-10 h-10 rounded-full object-cover mr-4" :src="avatar" alt="">
          <input type="file" @change="onFileChangeAvatar" ref="image" style="display: none"
            class="p-1 focus:outline-none focus:shadow-outline  text-neutral-500 hover:text-neutral-700">
        </div>
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Nome</label>
                    <input type="text" v-model="name"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        email</label>
                    <input type="text" v-model="email"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Alterar senha</label>
                    <input type="password" v-model="password"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex flex-col items-center ml-8 mt-4">
                  
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Repetir senha</label>
                    <input type="password" v-model="repitPassword"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                        <span v-if="diferents" class="text-red-500"> A senha não confere</span>
                        <span v-if="equals" class="text-green-500"> Ok</span>
                        
                      </div>
                

                
                <div 
                    class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="updateUser">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
            </div>
      </div>
    </div>
</template>

<script setup>
import { onMounted, ref,inject, watch } from 'vue';
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
const $swal = inject('$swal');
const logo =ref(false)
const userStore = userDefineStore()
const company = ref(false)
const file =ref(false)
const fileAvatar =ref(false)
const formData =ref(false)
const formDataAvatar =ref(false)
const diferents =ref(false)
const equals =ref(false)
const email = ref('')
const avatar = ref()
const name = ref('')
const password = ref('')
const repitPassword = ref()
const keystamp = ref(0)
// eslint-disable-next-line
const emit = defineEmits(['reloadAvatar'])

onMounted(()=>{
 getComapny()
  name.value = userStore._user_name
  email.value = userStore._user_email
  if(userStore._user_avatar != null)
  avatar.value = `${process.env.VUE_APP_BASE_URL}${userStore._user_avatar}`


})

const returnLogo = (logo)=>{
    return `${process.env.VUE_APP_BASE_URL}${logo}`
}
const getComapny = () => {
    const url = process.env.VUE_APP_API_URL + "company/" + userStore._company_id
    const config = {
      headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        company.value = response.data
    })
  }
  
const onFileChange = (event) => {
  if(userStore._user_type == 'Gerente'){
    file.value = event.target.files[0];
    formData.value = new FormData();
    formData.value.append('file', file.value);
    const payload = {
      user_id: userStore._user_id,
      logo: file.value,
      company_id: userStore._company_id
    }
    const url = process.env.VUE_APP_API_URL + "logoCompany";
    const config = {
      headers: {
        Authorization: `Bearer ${userStore._api_token}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    axios.post(url, payload, config).then((response) => {
      logo.value = `http://localhost:8000${response.data.logo}`;
    })
  }
  else{
    $swal.fire(
        'Ops',
        'Você não possui permissão para alterar o logo',
        'info'
    )
  }
};
const updateUser = () => {
    const url = process.env.VUE_APP_API_URL + "user/" + userStore._user_id
    let payload = {}
    if(password.value != ''){
       payload = { 'name': name.value, 'email': email.value, 'password': password.value, company_id: userStore._company_id }
      }
      else{
      payload = { 'name': name.value, 'email': email.value,  company_id: userStore._company_id }

    }
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
      $swal.fire(
        'Ok',
        'Informações alteradas',
        'info'
    )
    })
}
const onFileChangeAvatar = (event) => {
  fileAvatar.value = event.target.files[0];
  formDataAvatar.value = new FormData();
  formDataAvatar.value.append('file', fileAvatar.value);
  const payload = {
    user_id: userStore._user_id,
    avatar: fileAvatar.value,
    company_id: userStore._company_id
  }
  const url = process.env.VUE_APP_API_URL + "avatar";
  const config = {
    headers: {
      Authorization: `Bearer ${userStore._api_token}`,
      'Content-Type': 'multipart/form-data'
    }
  };
  axios.post(url, payload, config).catch(function (erro) {
    console.log(erro)
  }).then((response) => {
    userStore.setAvatar(response.data.avatar)
    avatar.value = `http://localhost:8000${userStore._user_avatar}`;
    keystamp.value = new Date().getTime();
    emit( 'reloadAvatar')
    $swal.fire(
        'Ok',
        'Seu avatar foi alterado',
        'success'
    )
  })
}
watch(
    () => password.value,
    () => {
      console.log(` ${password.value}`)
  if(password.value != repitPassword.value){
        diferents.value =true
      }
      else{
         diferents.value =false
         equals.value = true
       }
    },
   
)
watch(
  () => repitPassword.value,
    () => {
      console.log(` ${repitPassword.value}`)
  if(password.value != repitPassword.value){
        diferents.value =true
      }
      else{
         diferents.value =false
         equals.value = true
       }
    }
)

</script>
<template>
    <div v-if="load">
      {{ title }}
        <VueApexCharts width="400" type="donut" :options="options" :series="series"></VueApexCharts>
    </div>
</template>

<script setup>
import VueApexCharts from "vue3-apexcharts";
import { onMounted, ref  } from 'vue'
// eslint-disable-next-line
const props = defineProps(['label','data','colors','title'])
const options = ref({})
const series = ref([])
const load = ref(false)
const colorsValue = ref(['#207EF7','#06B6D4','#0aefff', '#0aff99', '#a1ff0a', '#be0aff', '#580aff',   '#deff0a', '#ffd300', '#dc0073', '#ff0000', '#ff00cc', '#0d41e1'])

onMounted(()=>{
  if(props.colors){
    colorsValue.value = props.colors
  }
    inicializaChart()
})
const inicializaChart = ()=>{

    options.value = {
      chart: {
              type: 'donut',
            },
            labels: props.label,
            colors: colorsValue.value,
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
            },
            legend: {
              show: true,
              position: "right",
              horizontalAlign: 'left', 
    },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                
              }
            }]
        
      }
     series.value = props.data
     load.value = true
}
</script>
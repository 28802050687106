<template>
     <div class="flex flex-col overflow-auto">
        <div class=" h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
        <div class="flex flex-row justify-between">
          <span class=" ml-4">Oportunidades do cliente</span>
          
        </div>

      </div>  
        <div class="flex flex-col ml-4 mt-4 mr-4">
            <div class="grid grid-cols-12 bg-white justify-items-center w-auto mr-4">
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Data Abertura</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Cliente</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Produto</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Valor</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Situação</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Ação</div>
            </div>
            <div class="grid grid-cols-12 bg-white justify-items-center w-auto mr-4 hover:bg-slate-500 hover:text-white" 
             v-for="(line, index) in listOpportunities" :key="index">
                <div class=" col-span-2  font-light  text-xs  px-2 py-2  ">{{ formateDate(line.created_at) }}</div>
                <div class=" col-span-2  font-light  text-xs  px-2 py-2 ">{{ line.name_opportunity }}</div>
                <div class=" col-span-2  font-light  text-xs  px-2 py-2 ">{{ line.product }}</div>
                <div class=" col-span-2  font-light  text-xs  px-2 py-2 ">{{ formatarReal(line.value_opportunity) }}</div>
                <div class=" col-span-2  font-light  text-xs  px-2 py-2 ">{{ line.status }}</div>
                <div class="mt-2 p-2 w-10 text-sm font-sm h-8 ml-2 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                @click="selectOpportunity(line)">

                    <font-awesome-icon icon="fa-solid fa-check" />
                </div>
            </div>
        </div>

    </div>
</template>
<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  const userStore = userDefineStore()

import { ref, onMounted } from 'vue'

const listOpportunities = ref([])

// eslint-disable-next-line
const props = defineProps(['client'])
// eslint-disable-next-line
const emit = defineEmits(['opportunitySelected']);
onMounted(()=>{
    getOpportunities()
})

const getOpportunities = ()=>{
    const url = process.env.VUE_APP_API_URL + `clientOpportunities/${props.client.id}`
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      
    axios.get(url, config).then((response) => {

      listOpportunities.value = response.data

    });

}
const selectOpportunity =(item)=>{
    emit('opportunitySelected', item);
   
}

const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleDateString('pt-BR', options)
}
const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
</script>
import axios from 'axios'
import { defineStore } from 'pinia'

export const userDefineStore = defineStore('user',{
    //state
    state(){
        return{
            _api_token: '',
        _user_name: '',
        _user_email: '',
        _user_avatar: '',
        _user_id: '',
        _user_type: '',
        _user_subscribe: '',
        _company_id: '',
        }
    },
    actions:{
        login( payload) {
            const url = process.env.VUE_APP_API_URL + "login"
            return new Promise((resolve, reject) => {
                axios.post(url, payload).then((loginResponse) => {
                    this._api_token = loginResponse.data.access_token
                    this._user_name = loginResponse.data.user.name
                    this._user_email = loginResponse.data.user.email
                    this._user_id = loginResponse.data.user.id
                    this._user_avatar = loginResponse.data.user.avatar
                    this._user_type = loginResponse.data.user.type
                    this._company_id = loginResponse.data.user.company_id
                    localStorage.setItem("token", this._api_token);
                    localStorage.setItem("user_id", this._user_id);
                
                    resolve()
                }).catch(() => {
                    reject()
                })
            });
        },
        setAvatar( img) {
            this._user_avatar = img
                },
       
        useSubscribe() {
    
            const url = process.env.VUE_APP_API_URL + "subscripeStatus/" + this._user_id
            return new Promise((resolve, reject) => {
                const config = {
                    headers: { Authorization: `Bearer ${this._api_token}` }
                };
                axios.get(url, config).then((response) => {
                    this._user_subscribe = response
                    resolve()
                }).catch(() => {
                    reject()
                })
            });
        },
        useMe() {

            const url = process.env.VUE_APP_API_URL + "user/" + localStorage.getItem("user_id")
            return new Promise((resolve, reject) => {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
                };
                axios.get(url, config).then((result) => {
                
                    this._api_token = localStorage.getItem("token")
                   
                        this._user_name = result.data.name
                        this._user_email = result.data.email
                        this._user_id = result.data.id
                        this._user_avatar = result.data.avatar
                        this._user_type = result.data.type
                        this._company_id = result.data.company_id
                  
                 
                    resolve()
                   
                }).catch(() => {
                    reject()
                })
            });
        },
        isAdmin() { 
            const url = process.env.VUE_APP_API_URL + "user/" + this._user_id
            return new Promise((resolve, reject) => {
                const config = {
                    headers: { Authorization: `Bearer ${this._api_token}` }
                };
                axios.get(url, config).then((response) => {
                    
                    if (response.data.company_id == 1) {
                       
                        
                        resolve()
                    }
                    else {
                        
                        reject()
                    }
                }).catch(() => {
                    reject()
                })
            });
        }
    },
    getters:{
        getToken(){
            return this._api_token
        }
    }

    
})
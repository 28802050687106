<template>
  <div class="flex flex-col">
            
                <label class="block mt-4 text-sm font-medium text-gray-900 ">
                    Título do Questionário</label>
                    <input type="text" v-model="title"
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                
                <label class="block mt-4 text-sm font-medium text-gray-900 ">
                Descrição do Questionário</label>
            <input type="text" v-model="description"
                class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
       
                    <label class="block mt-4 text-sm font-medium text-gray-900 ">
                    Tipo de Questionário</label>
                    <select v-model="type" 
                            class="mt-1 rounded-md border border-gray-300 bg-white text-black w-full py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <option value="Produto">Produto</option>
                            <option value="Lead">Lead</option>
                            <option value="Cliente">Cliente</option>
                            <option value="Oportunidade">Oportunidade</option>
                        </select>
           
                <ButtonLify v-if="!update" @action-click="saveQuestionary" class="mt-4" >
                    <font-awesome-icon icon="fa-solid fa-save" />
                </ButtonLify>
                <ButtonLify v-if="update" @action-click="updateQuestionary" >
                    <font-awesome-icon icon="fa-solid fa-save" />
                </ButtonLify>                                
            
  </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { inject, ref } from 'vue'
  import ButtonLify from './default/ButtonLify.vue';
  const user = userDefineStore()
// eslint-disable-next-line
const emit = defineEmits(['saved', 'delete']);
const $swal = inject('$swal');
const title = ref('')
const description = ref('')
const update = ref(false)
const type = ref('')
const saveQuestionary = ()=>{
    if((title.value !='')&&(description.value != '')&&(type.value != '')){
    const url = process.env.VUE_APP_API_URL + "questionary"
    const payload = {
        title: title.value,
        description: description.value,
        type: type.value,
        user_id: user._user_id,
        company_id: user._company_id
    }
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.post(url, payload, config).catch(function () {

    }).then((response) => {
         title.value = '';
        type.value = '';
        description.value = '';
        $swal.fire(
            'Perfeito!',
            'Questionário cadastrado, para inserir as perguntas acessa o menu Perguntas.',
            'success'
        )
        emit('saved',response.data)
    })
}
else{
    $swal.fire(
            'Ops!',
            'Você precisa inserir otodos os campos para salvar o questionário.',
            'error'
        )
}
}
</script>

<style scoped>
    
</style>
<template>
    <div class="pt-2 pl-2 pr-2 mb-3 w-200  flex flex-col border-solid border-2 border-re-600 bg-white rounded-lg hover:shadow-lg ">
        <div class="flex flex-row  mb-2 justify-between items-baseline">
            <div class="flex flex-col  items-start">
                <p class="ml-2 text-gray-700 font-semibold font-sans text-start">
                    {{element.message}}
                </p>
               
            </div>
            <font-awesome-icon v-if="!openDetail" @click="openDetail = !openDetail" icon="fa-solid fa-angle-left cursor-pointer" class="text-gray-500 ml-2 cursor-pointer " />
            <font-awesome-icon v-if="openDetail" @click="openDetail = !openDetail" icon="fa-solid fa-angle-down cursor-pointer" class="text-gray-500 ml-2 cursor-pointer " />
        </div>
        <div v-if="openDetail">

        <hr class="flex w-full mt-2 items-center">
        <p class="flex items-start ml-2 mb-2 text-gray-700 font-semibold font-sans">
        </p>
        <div class="flex flex-col justify-between mb-4">
            <div class="flex items-center justify-between">
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">
                    Tipo de Notificação: {{element.type}}
                </p>
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">Data da notificação: {{
                    formatDate(element.created_at)
                }}</p>
                
                <div>

                    
                    <font-awesome-icon icon="fa-solid fa-folder-open" class="text-blue-500 mr-1 cursor-pointer " @click="openItem"/>
                       
                    </div>
                </div>
            </div>
        </div>
        <ModalItem v-if="openTask" @refresh="closeModal" :title="element.type">    
            <CardTask :task="task" @sendRefresh="getTasks()"></CardTask>                    
        </ModalItem>
        <ModalItem v-if="openOpportunity" @refresh="closeModal" :title="element.type">  
            <TimelineOpportunities :opportunity="opportunity"></TimelineOpportunities>                      
        </ModalItem>
        <ModalItem v-if="openClient" @refresh="closeModal" :title="element.type">    
            <CardInfoClients class="grid col-span-4 " :client="client" ></CardInfoClients>                    
        </ModalItem>
    </div>
</template>

<script setup>
import {  ref } from 'vue'
import ModalItem from './ModalItem.vue';
import TimelineOpportunities from './TimelineOpportunities.vue';
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import CardTask from './CardTask.vue';
import CardInfoClients from './CardInfoClients.vue';
const userStore = userDefineStore()
// eslint-disable-next-line
const props = defineProps({ element: Object, color: String })
const openDetail = ref(true)
// eslint-disable-next-line
const emit = defineEmits(['sendQualify', 'openModal']);
const openTask = ref(false)
const openClient = ref(false)
const openOpportunity = ref(false)
const opportunity = ref()
const task = ref()
const client = ref()
const closeModal = ()=>{
    openOpportunity.value = false
    openClient.value = false
    openTask.value = false

}
const openItem = () => {
    const config = {
    headers: {
        Authorization: `Bearer ${userStore._api_token}`,
    }
}
    if(props.element.type == 'Oportunidade'){
     const   url = process.env.VUE_APP_API_URL + "opportunity/" + props.element.opportunity_id
    
    axios.get(url, config).catch(function () {
    }).then((response) => {
        opportunity.value = response.data[0]
        openOpportunity.value = true
    })
    }
    else if(props.element.type == 'Cliente'){
        const   url = process.env.VUE_APP_API_URL + "client/" + props.element.client_id
    
    axios.get(url, config).catch(function () {
    }).then((response) => {
        task.value = response.data
        
        openTask.value = true
    })
        openClient.value = true
    }
    else if(props.element.type == 'Tarefa'){
        const   url = process.env.VUE_APP_API_URL + "tasks/" + props.element.task_id
    
    axios.get(url, config).catch(function () {
    }).then((response) => {
        task.value = response.data
        
        openTask.value = true
    })
    }
}
function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}

</script>

<style scoped></style>
<template>
    <div class="grid grid-cols-10  gap-2">
      <div class="grid col-span-7">
        <div class="flex flex-row h-10 p-2">
          <label class="block mb-1 text-base font-medium text-gray-900 ">Data início</label>
          <input type="date" v-model="dateStart"
          class="ml-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
          <label class="block ml-4 mb-1 text-base font-medium text-gray-900">Data fim</label>
          <input type="date" v-model="dateEnd"
          class="ml-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
          <div  class="flex justify-center items-center ml-2">
            <ButtonLify @action-click="getSales">

              <font-awesome-icon icon="fa-solid fa-search" />
            </ButtonLify>
            
          </div>
        </div>
        <div class="grid grid-cols-10 p-2">
         
          <div class="grid col-span-3 w-full">
          <CardDashboardResume class="mt-2" :real="true" :title="'Vendas em andamento'" :value="onSales" :event="'onSales'" @sendEvent="receivedEvent"></CardDashboardResume>
          <CardDashboardResume class="mt-2" :real="true" :title="'Vendas com sucesso'" :value="cardSalesSuccess" :event="'salesSuccess'" @sendEvent="receivedEvent"></CardDashboardResume>
          <CardDashboardResume class="mt-2" :real="true" :title="'Vendas  não concretizadas'" :value="cardSalesFailure.value" :event="'salesFailure'" @sendEvent="receivedEvent"></CardDashboardResume>
          <CardDashboardResume class="mt-2" :real="false" :title="'Qualificações de Leads'" :value="cardLead" :event="'reviewLeads'" @sendEvent="receivedEvent"></CardDashboardResume>
          <CardDashboardResume class="mt-2" :real="false" :title="'Avaliações de Cliente'" :value="cardAvaClient" :event="'reviewClients'" @sendEvent="receivedEvent"></CardDashboardResume>
          <CardDashboardResume class="mt-2" :real="false" :title="'Avaliações de Produtos'" :value="cardAvaProducts" :event="'reviewProducts'" @sendEvent="receivedEvent"></CardDashboardResume>
          
        
          </div>
          <div v-if="loadGrafico" class="grid col-span-7">
            <LineChart :series="series" :real="true" :key="keystamp" :title="'Vendas com Sucesso'" :labels="['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV','DEZ']"></LineChart>       
            <DonutChartComponent :data="dataDonut"  :label="labelDonut" :key="keystamp"></DonutChartComponent>
          </div>
        </div>
      </div>
      <div  class="grid col-span-3 p-2 pt-2 h-128 max-h-full overflow-y-auto overflow-x-hidden">
        <div  class="" >
          <CardUserTasks></CardUserTasks>
          </div>
      </div>
      <ModalItem @refresh="close" v-if="shoeModalPortifolio">  
        <ListClients :list_client="listClientsPortifolio"></ListClients>
      </ModalItem>
    </div>
    <TableReportOpportunities v-if="openOnSales" class="ml-4 mr-4" :listOpportunities="listOpportunities" :key="keystamp"/>
    <TableReportReviews v-if="openReview" :type="type" class="ml-4 mr-4" :listReviews="listReviews" :key="keystamp"/>
    
  </template>
  
  <script setup>
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  import CardDashboardResume from './CardDashboardResume.vue'
  import LineChart from './LineChart.vue'
  import DonutChartComponent from './DonutChartComponent.vue'
  import CardUserTasks from '../CardUserTasks.vue'
  import ModalItem from '../ModalItem.vue'
  import ListClients from '../ListClients.vue'
  import TableReportOpportunities from '@/components/TableReportOpportunities.vue'
  import TableReportReviews from '@/components/TableReportReviews.vue'
  import { userDefineStore } from '@/store/UserStore'
  import ButtonLify from '../default/ButtonLify.vue'

  const user = userDefineStore()
  const shoeModalPortifolio = ref(false)
  const openOnSales = ref(false)
  const openReview = ref(false)
  const dateStart = ref(false)
  const loadGrafico = ref(false)
  const keystamp = ref(0)
  const listPhases = ref([])
  const listReviews = ref([])
  const load = ref(false)
  const cardSalesFailure =ref(0)
  const cardClientsPortifolio =ref(0)
  const cardSalesSuccess =ref(0)
  const onSales =ref(0)
  const cardLead =ref(0)
  const cardAvaClient =ref(0)
  const cardAvaProducts =ref(0)
  const labelDonut =ref([])
  const listClientsPortifolio =ref([])
  const dataDonut =ref([])
  const series = ref([])
  const type = ref()
  
  
  const dateEnd = ref('')
  const listOpportunities = ref([])
  
  onMounted(()=>{
    getPhases()
    iniDates()
    onSalesValue()
    salesSuccess()
    salesFailure()
    clientPortifolio()
    getOpportunties()
    inicializaLeads()
    reviewClients()
    reviewProducts()
    inicializaGrafico()
  })
  const inicializaGrafico = ()=>{
    const url = process.env.VUE_APP_API_URL + "salesSuccessMounth/" + user._company_id 
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
  
      series.value = 
          [{
            name: "Concretizadas",
            data: response.data,
            
          },
          
        ]
          labelDonut.value = ['Qualificação de Leads',  'Avaliação de Clientes', 'Avaliação de Produtos']
          dataDonut.value = [cardLead.value,  cardAvaClient.value, cardAvaProducts.value]
        loadGrafico.value = true
    });
  }
  const inicializaLeads = ()=>{
    const url = process.env.VUE_APP_API_URL + "leadsQualifications/" + user._company_id +"/"+ dateStart.value +"/"+ dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {

            cardLead.value = Object.keys(response.data).length
    });
  }
  
  const reviewProducts = ()=>{
    const url = process.env.VUE_APP_API_URL + "reviewProducts/" + user._company_id +"/"+ dateStart.value +"/"+ dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {

      cardAvaProducts.value = Object.keys(response.data).length
    });
  }
  const reviewClients = ()=>{
    const url = process.env.VUE_APP_API_URL + "reviewClients/" + user._company_id +"/"+ dateStart.value +"/"+ dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {

      cardAvaClient.value = Object.keys(response.data).length
    });
  }
  const iniDates = ()=>{
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    dateStart.value = now.getFullYear()+"-"+(month)+"-01";
    dateEnd.value = now.getFullYear()+"-"+(month)+"-"+(day) ;
   }
   // eslint-disable-next-line
  const getSales = ()=>{
    keystamp.value = new Date().getTime();
    getOpportunties()
    onSalesValue()
    salesSuccess()
    salesFailure()
    if(openOnSales.value){
      receivedEvent('onSales')
    }
  }
  const getOpportunties = () => {
   
    const url = process.env.VUE_APP_API_URL + "cardDashboard/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
      listOpportunities.value = response.data
      
      load.value = true
     // atualizaFunil();
    });
  }
  const receivedEvent = (item)=>{

    if(item == 'openModalPortifolio'){
      shoeModalPortifolio.value = true
    }
    if (item == 'onSales') {
      const url = process.env.VUE_APP_API_URL + "reportOnAllSalesUser/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value+ '/'+ user._user_id 
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
        listOpportunities.value = response.data
        openOnSales.value = true
        openReview.value = false
})
    }
    if (item == 'salesSuccess') {
      const url = process.env.VUE_APP_API_URL + "reportSalesSuccessUser/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value+ '/'+ user._user_id 
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
        listOpportunities.value = response.data
        openOnSales.value = true
        openReview.value = false
})
    }
    if (item == 'salesFailure') {
      const url = process.env.VUE_APP_API_URL + "reportSalesFailureUser/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value+ '/'+ user._user_id 
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
        listOpportunities.value = response.data
        openOnSales.value = true
         openReview.value = false
})
    }
    if(item == 'reviewLeads'){
    const url = process.env.VUE_APP_API_URL + "reportReviewLeadsUser/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value + '/'+ user._user_id 
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
      listReviews.value = response.data
        type.value='lead'
        openReview.value = true
        openOnSales.value = false
})
}
if(item == 'reviewClients'){
    const url = process.env.VUE_APP_API_URL + "reportReviewClientsUser/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value + '/'+ user._user_id 
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
      listReviews.value = response.data
        type.value='client'
        openReview.value = true
        openOnSales.value = false
})
}
  }
  const close = ()=>{
    shoeModalPortifolio.value = false
  }
  const salesSuccess = () => {
  
    const url = process.env.VUE_APP_API_URL + "salesSuccess/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
      cardSalesSuccess.value = response.data.value
    });
  }
  const onSalesValue = () => {

    const url = process.env.VUE_APP_API_URL + "onSales/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
   
      onSales.value = response.data
    });
  }
  const clientPortifolio = () => {
   
    const url = process.env.VUE_APP_API_URL + `clientPortifolio/${user._company_id }/${user._user_id}`
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
      
      cardClientsPortifolio.value = Object.keys(response.data).length
      listClientsPortifolio.value = response.data
    });
  }
  const salesFailure = () => {
   
    const url = process.env.VUE_APP_API_URL + "salesFailure/" + user._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${user._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
    
      cardSalesFailure.value = response.data
    });
  }
  
  const getPhases = () => {
    let url = process.env.VUE_APP_API_URL + "phasesFunnels/" + user._company_id
    
    const config = {
      headers: { Authorization: `Bearer ${user._api_token}` }
    };
  
    axios.get(url, config).then((response) => {
     
      listPhases.value = response.data
  
    });
  }
  
  </script>
  
  <style scoped>
      
  </style>
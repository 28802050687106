<template>
  <div  class="flex flex-col w-full items-center justify-center  rounded-md bg-cover bg-gradient-to-r " :class="color">
    <div class="flex flex-row p-2">
      <div class="text-white text-base ">{{ etapa }}</div>
      <div class="text-white text-base ml-4">{{ qtd }}</div>
      <div class="text-white text-base ml-4">{{ formatarReal(value) }}</div>
    </div>

  </div>
</template>
  
<script setup>
//import axios from 'axios'
//import {  ref, onMounted } from 'vue'
// eslint-disable-next-line
const props = defineProps([ 'color', 'etapa', 'qtd','value' ])


const formatarReal = (valor)=>{

 return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
</script>
  
<style scoped></style>
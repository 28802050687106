<template>
 
  <div class="grid grid-cols-6 content-start h-full w-full p-2">
    <div class="grid col-span-4">
      <div class="grid col-span-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
        <div class="flex flex-row justify-between">
          <span class=" ml-4">Informações do cliente</span>
          <span v-if="client" @click="editClient" class="cursor-pointer">
            <font-awesome-icon class="text-white mr-4"
              icon="fa-solid fa-pen" />
          </span> 
         
        </div>
      </div>
      <CardInfoClients class="grid col-span-4 " @save="updateClient" :client="client" :update="update" :key="keystamp"></CardInfoClients>
      <div class="grid col-span-4 row-span-1 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-2 mb-4">
        <div class="flex flex-row justify-between">
          <span class=" ml-4">Informações de contatos</span>
          <span @click="newContact" class="cursor-pointer">
            <font-awesome-icon class="text-white mr-4"
              icon="fa-solid fa-user-plus" />
          </span> 
        </div>
      </div>
      <div class="grid col-span-4 h-8 grid-cols-12  content-start  gap-1">
        <span class="grid justify-items-start  col-span-3  text-sm">Nome</span>
        <span class="grid justify-items-start  col-span-4  text-sm">e-mail</span>
        <span class="grid justify-items-start  col-span-3  text-sm">Telefone</span>
        <span class="grid justify-items-start  col-span-2  text-sm">Ação</span>
      </div>
      <div class="grid col-span-4 row-span-4  pt-2 h-64  max-h-64 overflow-y-auto overflow-x-hidden " v-if="loadContacts" >
        <div class="grid grid-cols-12 bg-white justify-items-start  hover:bg-slate-200"
          v-for="(line, index) in listContacts" :key="index">
          <div class=" col-span-3 text-gray-400 font-sm  break-all">
              <font-awesome-icon v-if="line.type == 'Principal'" class="text-yellow-500"
                icon="fa-solid fa-star" />
              <span class=" text-sm ml-2">{{ line.name   }}</span>
          </div>
          <div class=" col-span-4 text-gray-400 font-sm  break-all">
            <span class=" text-sm ml-2">{{ line.email   }}</span>
          </div>
          <div class=" col-span-3 text-gray-400 font-sm  break-all">
            <span class=" text-sm ml-2">{{ line.phone   }}</span>
          </div>
          
          <div class="col-span-2 font-sm break-all">
            <button class="text-indigo-500 ml-1 hover:text-indigo-200" @click="editContact(line)"> <font-awesome-icon
              icon="fa-solid fa-edit" /></button>
            <button class="text-green-500 ml-1 hover:text-green-200" @click="contact(line)"> <font-awesome-icon
              icon="fa-solid fa-id-card" /></button>
            <button class="text-red-400 ml-1 hover:text-red-200" @click="deleteContact(line)"> <font-awesome-icon
              icon="fa-solid fa-trash" /></button>
          </div>
        </div>
      </div>
    </div>
           
    <div v-if="true" class="grid col-span-2 ">
        <div class="flex flex-col p-2">

          <div v-if="loadContacts" class="" >
            <CardManageClient class="" :clientInfo="client"  ></CardManageClient>
          </div>
          <div v-if="loadContacts" class="pt-2 h-96 max-h-80 overflow-y-auto overflow-x-hidden" >
            <CardTasks class="" :clientInfo="client" @sendOpen="sendOpen" ></CardTasks>
          </div>
        </div>
    </div>
        
</div>
  <ModalItem v-if="openTask" @refresh="close">
      <InfoTask :task="taskSelected"></InfoTask>
  </ModalItem>
 
  <ModalItem v-if="modal" @refresh="close" :title="'Novo contato'" >
    <NewContact @close="close" @saved="saveContact" @updated="updateContact" :client_id="client.id" :updateContact="contactUpdate" :showCpf="false"></NewContact>
  </ModalItem>
  
<ModalItem v-if="show_contact" @refresh="closeShowClient">
  <span class=" text-base ml-2">Nome: {{ contactShow.name   }}</span>
  <span class=" text-base ml-2">e-mail: {{ contactShow.email   }}</span>
  <span class=" text-base ml-2">Telefone: {{ contactShow.phone   }}</span>
  <span class=" text-base ml-2">Cargo: {{ contactShow.office   }}</span>
  <span class=" text-base ml-2">Tipo de Contato: {{ contactShow.type   }}</span>
  <span class=" text-base ml-2">Data de nascimento: {{ contactShow.birth   }}</span>
</ModalItem>

    </template>
<script setup>
import {ref, onMounted, inject} from 'vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import NewContact from './NewContact.vue';
import ModalItem from './ModalItem.vue';
import CardTasks from './CardTasks.vue';
import CardManageClient from './CardManageClient.vue'
import CardInfoClients from './CardInfoClients.vue';
import InfoTask from './InfoTask.vue';
const user = userDefineStore()
// eslint-disable-next-line
const emit = defineEmits(['refresh']);
// eslint-disable-next-line
const props = defineProps(['section', 'head', 'client'])
const $swal = inject('$swal');
const listContacts = ref()
const loadContacts = ref(false)
const update = ref(false)
const openTask = ref(false)
const modal = ref(false)
const taskSelected = ref()
const show_contact = ref(false)
const contactShow = ref(false)
const keystamp= ref(false)


const contactUpdate = ref(false)
onMounted(()=>{
  
    if(props.client?.id){

        getContact()
    }
})
const editClient = ()=>{
  update.value = !update.value 
  keystamp.value = new Date().getTime();
}
const closeShowClient = ()=>{
  contactShow.value = false
  show_contact.value = false
  emit('refresh');
}
const close = () => {
  modal.value = false
  contactUpdate.value = false
  openTask.value = false
  keystamp.value = new Date().getTime();
  getContact()
 
}

const sendOpen = (task)=>{

  taskSelected.value = task
  openTask.value = true
}
const newContact = ()=>{
  modal.value = true
}

const contact = (item)=>{
  contactShow.value = item
  show_contact.value = true
}

const getContact = () => {
  const url = process.env.VUE_APP_API_URL + "contacts/" + props.client.id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listContacts.value = response.data
    loadContacts.value = true
  })
}

const saveContact = (payload) => {
  
    const url = process.env.VUE_APP_API_URL + "contact"
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        getContact()
      modal.value=false

        $swal.fire(
        '🤝',
        'Contato criado com sucesso',
        'success'
    )
    })
}
const updateContact = (payload) => {
  
    const url = process.env.VUE_APP_API_URL + "contact/" + payload.id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
        getContact()
      modal.value=false
        $swal.fire(
        '🤝',
        'Contato modificado com sucesso',
        'success'
    )
    contactUpdate.value = false
    modal.value=false
    })
}
const updateClient = (payload) => {
  
    const url = process.env.VUE_APP_API_URL + "client/" + payload.id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
        keystamp.value = new Date().getTime();
      modal.value=false
        $swal.fire(
        '🤝',
        'Cliente modificado com sucesso',
        'success'
    )
    contactUpdate.value = false
    modal.value=false
    })
}
const editContact = (item)=>{
  contactUpdate.value = item
  modal.value=true
}
const deleteContact = (item) => {

  const url = process.env.VUE_APP_API_URL + "contact/" + item.id

  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.delete(url, config).catch(function () {

  }).then(() => {
    getContact()
    $swal.fire(
      'Ok',
      'Contato Deletado com sucesso',
      'success'
    )
  })
}
</script>
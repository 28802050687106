import {defineStore} from 'pinia';

export const opportunityDefineStore = defineStore('opportunity',{
    //state
    state(){
        return{ opportunity_id: '' }
    },
    actions:{
        setInfo( opportunity) {                         
                console.log(opportunity)
                localStorage.setItem("opportunity_id", opportunity.opportunity_id  );          
                localStorage.setItem("name_opportunity", opportunity.name_opportunity  );          
                localStorage.setItem("phase_name", opportunity.phase_name  );          
                localStorage.setItem("product", opportunity.product  );          
                localStorage.setItem("value_opportunity", opportunity.value_opportunity  );          
                localStorage.setItem("seller_name", opportunity.seller_name  );          
        },
        resetInfo() {
                this.opportunity_id =null
                this.name_opportunity = null
                this.phase_name = null
                this.product = null
                this.value_opportunity = null
                this.seller_name = null
        },
    },
    getters:{
        getOpportunityInfo(){
            if(this.opportunity_id == ''){
                this.opportunity_id = localStorage.getItem("opportunity_id")
                this.name_opportunity = localStorage.getItem("name_opportunity");          
                this.phase_name = localStorage.getItem("phase_name");          
                this.product = localStorage.getItem("product");          
                this.value_opportunity = localStorage.getItem("value_opportunity");          
                this.seller_name = localStorage.getItem("seller_name");      
            }
            const payloadInfo = {
                opportunity_id: this.opportunity_id,
                name_opportunity : this.name_opportunity,
                phase_name : this.phase_name,
                product : this.product,
                value_opportunity: this.value_opportunity,
                seller_name:this.seller_name,
            }
            return payloadInfo
        }
    }
})
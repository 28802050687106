<template>
    <div
        class="p-2 mb-3  flex flex-col border-solid border-2  border-neutral-50 bg-white rounded-lg hover:shadow-lg ">
        <div class="flex flex-row justify-between  ">

            <div class="flex items-center">
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
                    question.question
                }}</p>
            </div>

        </div>
        <div class="flex flex-row">
            <input v-model="resposta" type="text-area"
                class="bg-gray-50 mt-2 border w-200 border-gray-300 text-gray-900  
                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2 ">
        </div>
        <div class="flex flex-row items-center mt-2 mr-2">
            <label for="quantity">Pontos (máximo {{ question.value }}):</label>
            <input
                class="bg-gray-50 ml-2 border border-gray-300 text-gray-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2 "
                type="number" v-model="points" name="quantity" :min="0" :max="question.value">
        </div>
        <div class="flex flex-row">
            <ButtonLify @action-click="backTo"  v-if="index != 0" >            
                <font-awesome-icon icon="fa-solid fa-circle-left" />
            </ButtonLify>
            <ButtonLify @action-click="saveResponse"   class="ml-2">            
                <font-awesome-icon icon="fa-solid fa-circle-right" />
            </ButtonLify>
        </div>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import ButtonLify from './default/ButtonLify.vue';
// eslint-disable-next-line
const props = defineProps(['question', 'qualify', 'index'])

const points = ref(0)
// eslint-disable-next-line
const emit = defineEmits(['sendQuestion', 'back']);
const saveResponse = () => {
    let question = props.question
    question.answer = resposta.value
    question.points = points.value
    emit('sendQuestion', question);
}
const backTo = () => {
    emit('back');
}
const resposta = ref()
onMounted(() => {
    // eslint-disable-next-line
    resposta.value = props.qualify?.answer ? props.qualify.answer : '';
    points.value = props.qualify?.points ? props.qualify.points : 0;

})

</script>

<style scoped></style>
<template>
    <div class="flex flex-col overflow-auto">
      <div class=" h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
        <div class="flex flex-row justify-between">
          <span class=" ml-4">Oportunidades do cliente</span>
          <span @click="newOpportunity">
            <font-awesome-icon class="text-white mr-4 cursor-pointer"
               icon="fa-solid fa-plus" />
          </span> 
        </div>
      </div>  
        <div class="flex flex-col ml-4 mt-4 mr-4">
          <div class="grid grid-cols-6 mt-2 ml-4 mr-4">
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Cliente</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Produto</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Data</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Vendedor</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">R$</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Abrir</span>
        </div>
        <div class="grid grid-rows-1 mt-2  border-lify_blue hover:bg-slate-200" v-for="(opportunity, index) in opportunities" :key="index">
                <div class="grid grid-cols-6 mt-2 border-indigo-300">

                    <span class="grid col-span-1 text-base   text-black">{{ opportunity.name_opportunity }}</span>
                    <span class="grid col-span-1 text-base   text-black">{{ opportunity.product }}</span>
                    <span class="grid col-span-1 text-base   text-black">{{ formateDate(opportunity.updated_at) }}</span>
                    <span class="grid col-span-1 text-base   text-black">{{ opportunity.name_seller }}</span>
                    <span class="grid col-span-1 text-base   text-black">{{ formatarReal(opportunity.value_opportunity) }}</span>
                    <span class="grid col-span-1 text-base  text-black">
                    <ButtonLify :icon="true" v-show="!openOpportunity" @action-click="openOpportunities(opportunity)" class="grid col-span-1 justify-items-center justify-self-center" >
                        <font-awesome-icon icon="fa-solid fa-folder-open" class="flex items-center text-center" />
                    </ButtonLify>
                    <ButtonLify :icon="true" v-show="openOpportunity" @action-click="closeOpportunities()" class="grid col-span-1 justify-items-center justify-self-center" >
                        <font-awesome-icon icon="fa-solid fa-folder-closed" class="flex items-center text-center" />
                    </ButtonLify>
                </span>
                    
                </div>
            </div>
        </div>
        <div v-if="openOpportunity" > 
        <ModalItem @refresh="closeOpportunities">             
       <CardOpportunity :opportunity="opportunity"></CardOpportunity>
    </ModalItem>  
    </div>   
    </div>
</template>
<script setup>
import ModalItem from '@/components/ModalItem.vue';
import CardOpportunity from '@/components/CardOpportunity.vue';
import ButtonLify from '@/components/default/ButtonLify.vue';
import {ref} from 'vue'

const openOpportunity = ref(false)
const opportunity = ref('')
// eslint-disable-next-line
const props = defineProps(['opportunities'])
  // eslint-disable-next-line
  const emit = defineEmits(['new']);
  const newOpportunity = ()=>{
    emit('new');
  }
  const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleDateString('pt-BR', options)
}
const openOpportunities = (element)=>{
  opportunity.value = element
  openOpportunity.value = true
}
const closeOpportunities = ()=>{
  openOpportunity.value = false
}
</script>
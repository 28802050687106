<template>
  <div>
    <div  class="flex flex-col ml-2 mr-2">
        <div class="flex items-start p-2 text-xl font-sm h-8 text-indigo-500 rounded-md">
          Avaliação
        </div>
        <div>
            <div class="flex flex-col ml-4 mr-4 mt-4">
                <div class="grid grid-cols-12 bg-white justify-items-center w-full">
                    <div class="bg-white col-span-3 text-gray-400 font-sm rounded-tl px-4 py-2">Cliente</div>
                    <div class="bg-white col-span-3 text-gray-400 font-sm  px-4 py-2">Produto</div>
                    <div class="bg-white col-span-3 text-gray-400 font-sm  px-4 py-2">Avaliado por</div>
                    <div class="bg-white col-span-1 text-gray-400 font-sm  px-4 py-2">Data da avaliação</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tr px-4 py-2">Ação</div>
                </div>
                <div v-for="(line, index) in listReviews" :key="index"
                    class="grid grid-cols-12 bg-white py-3 px-3 hover:bg-neutral-50 justify-items-center">
                    <div v-if="line.client_name" class="col-span-3">{{ line.client_name}}</div>
                    <div v-else class="col-span-3">Somente avaliação de produto</div>
                    <div class="col-span-3">{{ line.product }}</div>
                    <div class="col-span-3">{{ line.user_name}}</div>
                    <div class="col-span-1">{{ formateDate(line.created_at) }}</div>
                    <div class="col-span-2">
                        <button class="text-indigo-500" @click="openReview(line)"> <font-awesome-icon
                                icon="fa-solid fa-folder-open" /></button>
                        <button class="text-red-400 ml-4" @click="deleteReview(line.id)"> <font-awesome-icon
                                icon="fa-solid fa-trash" /></button>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
    <div v-if="openQuestions" > 
        <ModalItem @refresh="closeReview">             
            <div v-for="(element, index) in questions" class="mt-2  mr-4" :key="index">
                <LineAnswers  :element="element"></LineAnswers>
            </div> 
        </ModalItem>          
    </div>
  </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  import ModalItem from './ModalItem.vue';
  import LineAnswers from './LineAnswers.vue';
  const userStore = userDefineStore()
const listReviews = ref([])
const questions = ref([])
const openQuestions = ref(false)
// eslint-disable-next-line
const emit = defineEmits(['openReview']);
onMounted(()=>{
    getReviews()
})
const getReviews = ()=>{
    const url = process.env.VUE_APP_API_URL + "productReviews/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
   
        listReviews.value = response.data
    })
}
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const deleteReview = (id)=>{
    const url = process.env.VUE_APP_API_URL + "review/" + id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.delete(url,  config)
    .then(() => {getReviews()});
}
const openReview = (item)=>{
    console.log(item)
    questions.value = JSON.parse(item.answers)
    openQuestions.value = true
emit('openReview',item)
}
const closeReview = ()=>{
    
    questions.value = ''
    openQuestions.value =false
}
</script>

<style scoped>
    
</style>
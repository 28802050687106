<template>
  <div class="flex flex-col">
  <div v-if="!update" class="grid grid-cols-3 text-black">
        <span class="grid justify-items-start  col-span-1 ml-2 text-sm mt-2">Nome:</span>
        <span class="grid justify-items-start  col-span-2 mr-2 text-md mt-2 font-semibold">{{ client.name }}</span>
        <span v-if="client.cnpj != null" class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">CNPJ</span>
        <span v-if="client.cnpj != null" class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.cnpj }}</span>
        <span v-if="client.cpf != null" class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">CPF</span>
        <span v-if="client.cpf != null" class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.cpf }}</span>
        <input v-if="update  && client.cpf" class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm " v-model="cpf" type="text">
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">Segmento</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.segment }}</span>
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">CEP</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.cep }}</span>
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">Logradouro</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.logradouro }}</span>
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">Número</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.numero }}</span>
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">Complemento</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.complemento }}</span>
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">Bairro</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.bairro }}</span>
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">Localidade</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.localidade }}</span>
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">UF</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm ">{{ client.uf }}</span>
        <span class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">Executivo responsável:</span>
        <span class="grid justify-items-start mt-2 col-span-2 mr-2 text-md font-semibold">{{ client.owner_name }}</span>
       </div>
  <div v-if="update" class="grid grid-cols-3 text-black">
        <span class="grid justify-items-start  col-span-1 ml-2 mt-2 text-sm">Nome:</span>
        <input v-if="client.cnpj" class="grid justify-items-start  col-span-2 mt-2 mr-2 text-sm " v-model="name" >
        <span v-if="client.cnpj != null" class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">CNPJ</span>
        <input v-if="client.cnpj" class="grid justify-items-start  col-span-2 mt-2 mr-2 text-sm " v-model="cnpj" type="text">
        <span v-if="client.cpf != null" class="grid justify-items-start mt-2 col-span-1 ml-2 text-sm">CPF</span>
        <input v-if="update  && client.cpf" class="grid justify-items-start mt-2 col-span-2 mr-2 text-sm " v-model="cpf" type="text">
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">Segmento</span>
        <div class="grid justify-items-start  mt-2 col-span-2 mr-2 text-sm ">
              <select v-model="client_segmentation_id" 
                    class="mt-1 rounded-md border w-60 border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                <option v-for="(item, index) in listSegments" :value="item.id" :key="index">
                                    {{ item.segment }}
                                </option>
            </select>
        </div>
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">CEP</span>
        <input class="grid justify-items-start mt-2  col-span-2 mr-2 text-sm " v-model="cep" type="text">
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">Logradouro</span>
        <input class="grid justify-items-start mt-2  col-span-2 mr-2 text-sm " v-model="logradouro" type="text">
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">Número</span>
        <input class="grid justify-items-start mt-2  col-span-2 mr-2 text-sm " v-model="numero" type="text">
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">Complemento</span>
        <input class="grid justify-items-start mt-2  col-span-2 mr-2 text-sm " v-model="complemento" type="text">
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">Bairro</span>
        <input class="grid justify-items-start mt-2  col-span-2 mr-2 text-sm " v-model="bairro" type="text">
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">Localidade</span>
        <input class="grid justify-items-start mt-2  col-span-2 mr-2 text-sm " v-model="localidade" type="text">
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">UF</span>
        <input class="grid justify-items-start mt-2  col-span-2 mr-2 text-sm " v-model="uf" type="text">
        <span class="grid justify-items-start  mt-2 col-span-1 ml-2 text-sm">Executivo responsável:</span>
        <div v-if="alterar" class="grid justify-items-start mt-2  col-span-2 mr-2 text-sm ">            
            <BuscaUser @sendUser="receivedUser" :title="''"></BuscaUser>                    
        </div>
            <div v-if="!alterar" class="grid justify-items-start mt-2 col-span-2 mr-2">
              <div class="flex justify-between">
                <span class=" text-md font-semibold">{{ client.owner_name }}</span>
                <font-awesome-icon class="text-black ml-4" @click="alterar = !alterar"
                icon="fa-solid fa-pen" />
              </div>
            </div>
      </div>
      <div>
        <ButtonLify v-if="update" :icon="true" @action-click="saveClient">
          <font-awesome-icon class="text-white mr-4"
            icon="fa-solid fa-save" />
        </ButtonLify>
      </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import BuscaUser from './BuscaUser.vue';
import ButtonLify from './default/ButtonLify.vue';
import axios from 'axios'
  import { userDefineStore } from '@/store/UserStore'
const user = userDefineStore()
// eslint-disable-next-line
const props = defineProps(['client','update'])
// eslint-disable-next-line
const emit = defineEmits(['save'])
const listSegments = ref([])
const name = ref('')
const alterar = ref(false)
const cnpj = ref('')
const cpf = ref('')
const segment = ref('')
const cep = ref('')
const logradouro = ref('')
const numero = ref('')
const complemento = ref('')
const client_segmentation_id = ref('')
const bairro = ref('')
const localidade = ref('')
const uf = ref('')
const owner_name = ref('')
const owner_id = ref('')

onMounted(() => {
  console.log(props.client)
  if(props.update){
name.value = props.client.name 
cnpj.value = props.client.cnpj 
cpf.value = props.client.cpf 
segment.value = props.client.segment 
cep.value = props.client.cep 
logradouro.value = props.client.logradouro 
numero.value = props.client.numero 
complemento.value = props.client.complemento 
bairro.value = props.client.bairro 
localidade.value = props.client.localidade 
uf.value = props.client.uf 
owner_name.value = props.client.owner_name 
owner_id.value = props.client.owner
client_segmentation_id.value = props.client.client_segmentation_id
getSegments()
  }
})
const receivedUser = (userReceived)=>{
owner_id.value = userReceived.id

}
const getSegments = () => {
    const url = process.env.VUE_APP_API_URL + "clientSegments/" + user._company_id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listSegments.value = response.data
    })
}
const saveClient = ()=>{
  
let payload = {
  id : props.client.id,
 status : props.client.status,
  name : name.value ,
  cnpj : cnpj.value ,
  cpf : cpf.value,
  segment : segment.value,
  client_segmentation_id: client_segmentation_id.value,
  cep : cep.value ,
  logradouro : logradouro.value,
  numero : numero.value ,
  complemento : complemento.value ,
  bairro : bairro.value ,
  localidade : localidade.value ,
  uf: uf.value ,
  categorie_id: props.client.categorie_id,
  user_id : user._user_id,
  owner: owner_id.value 

}
emit('save',payload)
}
</script>
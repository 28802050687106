<template>
    <div class="p-2 mb-3 w-72 mr-4 flex flex-col border-solid border-2 border-sky-600
                                     bg-sky-300 rounded-lg hover:shadow-lg " :class="color">
        <div class="flex flex-col justify-between  ">

            <div class="flex items-center">
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
                    element.name
                }}</p>
            </div>

            <div class="flex">

                <button v-if="element.status == 'active'" @click="openQualify(element)"
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600">


                    <font-awesome-icon icon="fa-solid fa-edit" />
                </button>
                <button v-if="element.status == 'active'" aria-label="Delete user"
                    class="p-1 focus:outline-none focus:shadow-outline text-red-500 hover:text-red-600"
                    @click="onDelete(element)">
                    <font-awesome-icon icon="fa-solid fa-trash" />
                </button>
                <button v-if="element.status == 'qualifying'" @click="viewUser"
                    class="p-1 focus:outline-none focus:shadow-outline text-yellow-500 hover:text-yellow-600">


                    <font-awesome-icon icon="fa-solid fa-lock" />
                </button>
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{ formatDate(element.created_at) }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { inject } from 'vue'
  import { useRouter } from 'vue-router'
  const user = userDefineStore()
const $swal = inject('$swal');
const router = useRouter();
// eslint-disable-next-line
const props = defineProps({ element: Object, color: String })


// eslint-disable-next-line
const emit = defineEmits(['sendQualify','deleteProspect']);
/*const sendQualify = (item) => {
    emit('sendQualify', item);
}
*/
const onDelete = (item) => {

   emit('deleteProspect', item.id)
}
const openQualify = (element) => {
    const url = process.env.VUE_APP_API_URL + "prospect/" + element.id;
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).then((response) => {
        if (response.data.status == 'active') {
            updateProspect(element)
            router.push({ name: 'qualificating', params: { prospect_id: element.id } });
        }
        else {
            $swal.fire(
                'Prospect em qualificação',
                'Este prospect entrou em qualificação por outro usuário',
                'info'
            )
        }

    })
}
const viewUser = () => {
    const url = process.env.VUE_APP_API_URL + "user/" + props.element.user_id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).then(function (response) {
        $swal.fire(
            'Prospect em qualificação',
            'Este prospect entrou em qualificação pelo usuário ' + response.data.name,
            'info'
        )
    })

}
const updateProspect = (element) => {
    const url = process.env.VUE_APP_API_URL + "prospect/" + element.id
    element.status = 'qualifying'
    element.user_id = user._user_id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.put(url, element, config).catch(function () {
    }).then(() => {
    })
}
function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}
</script>

<style scoped></style>
<template>
 
    <div class="grid grid-cols-6 content-start h-full w-full p-2">
      <div class="grid col-span-4">
        <div class="grid col-span-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
          <div class="flex flex-row justify-between">
            <span class=" ml-4">Informações da empresa</span>
            <span @click="newClient">
              <font-awesome-icon class="text-white mr-4 cursor-pointer"
                 icon="fa-solid fa-plus" />
            </span> 
          </div>
        </div>
   
        <CardInfoCompany class="grid col-span-4 " :company="company" ></CardInfoCompany>
        <div class="grid col-span-4 row-span-1 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-2 mb-4">
          <div class="flex flex-row justify-between">
            <span class=" ml-4">Usuários</span>
            <span @click="newUser" class="cursor-pointer">
              <font-awesome-icon class="text-white mr-4"
                icon="fa-solid fa-user-plus" />
            </span> 
          </div>
        </div>
        <div class="grid col-span-4 h-8 grid-cols-12  content-start  gap-1">
          <span class="grid justify-items-start  col-span-3  text-sm">Nome</span>
          <span class="grid justify-items-start  col-span-4  text-sm">e-mail</span>
          <span class="grid justify-items-start  col-span-3  text-sm">Tipo</span>
          <span class="grid justify-items-start  col-span-2  text-sm">Ação</span>
        </div>
        <div class="grid col-span-4 row-span-4  pt-2 h-64  max-h-64 overflow-y-auto overflow-x-hidden " v-if="loadUsers" >
          <div class="grid grid-cols-12 bg-white justify-items-start  hover:bg-slate-200"
            v-for="(line, index) in listUsers" :key="index">
            <div class=" col-span-3 text-gray-400 font-sm  break-all">
                <font-awesome-icon v-if="line.type == 'manager'" class="text-yellow-500"
                  icon="fa-solid fa-star" />
                <span class=" text-sm ml-2">{{ line.name   }}</span>
            </div>
            <div class=" col-span-4 text-gray-400 font-sm  break-all">
              <span class=" text-sm ml-2">{{ line.email   }}</span>
            </div>
            <div class=" col-span-3 text-gray-400 font-sm  break-all">
              <span class=" text-sm ml-2">{{ line.type   }}</span>
            </div>
            
            <div class="col-span-2 font-sm break-all">
              <button class="text-indigo-500 ml-1 hover:text-indigo-200" @click="editUsers(line)"> <font-awesome-icon
                icon="fa-solid fa-edit" /></button>
              <button class="text-green-500 ml-1 hover:text-green-200" @click="user(line)"> <font-awesome-icon
                icon="fa-solid fa-id-card" /></button>
              <button class="text-red-400 ml-1 hover:text-red-200" @click="deleteUser(line)"> <font-awesome-icon
                icon="fa-solid fa-trash" /></button>
            </div>
          </div>
        </div>
      </div>
             
      <div v-if="true" class="grid col-span-2 ">
          <div class="flex flex-col p-2">
  
            <div v-if="loadUsers" class="" >
              <div class="grid col-span-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                <span class="ml-4 ">Gerenciamento</span>
              </div>
              <div class="text-indigo-500 ml-1 hover:text-indigo-200">
            <div 
              class="relative flex flex-row cursor-pointer items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
              <span class="inline-flex justify-center items-center ml-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate"> Chamados</span>
            </div>
        </div>
            </div>
            
          </div>
      </div>
          
  </div>
    
    <ModalItem v-if="modal" @refresh="close">
      <FormNewUser @close="close" @saved="saveUser" @updated="updateUser" :company_id="company.id" :updateUser="userUpdate" :showCpf="false"></FormNewUser>
    </ModalItem>
    <ModalItem v-if="modalNewCompany" @refresh="closeNewCompany">
      <FormCompany @close="closeNewCompany" @refresh="closeNewCompany"></FormCompany>
    </ModalItem>
  <ModalItem v-if="show_user" @refresh="closeShowClient">
    <span class=" text-base ml-2">Nome: {{ userShow.name   }}</span>
    <span class=" text-base ml-2">e-mail: {{ userShow.email   }}</span>
    <span class=" text-base ml-2">Tipo: {{ userShow.type   }}</span>
 
  </ModalItem>
  
      </template>
  <script setup>
  import {ref,  inject, onMounted} from 'vue'
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import FormCompany from '@/components/admin/FormCompany.vue';
  import ModalItem from '@/components/ModalItem.vue';
  import CardInfoCompany from '@/components/admin/CardInfoCompany.vue'
  import FormNewUser from '@/components/FormNewUser.vue';
  const userStore = userDefineStore()
  // eslint-disable-next-line
  const emit = defineEmits(['refresh']);
  // eslint-disable-next-line
  const props = defineProps(['section', 'head', 'company'])
  const $swal = inject('$swal');
  const listUsers = ref()
  const loadUsers = ref(false)
  const openTask = ref(false)
  const modal = ref(false)
  const show_user = ref(false)
  const userShow = ref(false)
  const modalNewCompany= ref(false)
  const keystamp= ref(false)
  
  
  const userUpdate = ref(false)

  onMounted(()=>{
  
  if(props.company?.id){

      getUser()
  }
})

  const closeShowClient = ()=>{
    userShow.value = false
    show_user.value = false
    emit('refresh');
  }
  const close = () => {
    modal.value = false
    userUpdate.value = false
    openTask.value = false
    keystamp.value = new Date().getTime();
    getUser()
   
  }
  const closeNewCompany = () => {
    
    modalNewCompany.value = false
    keystamp.value = new Date().getTime();
    emit('refresh');
   
  }

  const newUser = ()=>{
    modal.value = true
  }
  const newClient = ()=>{
    modalNewCompany.value = true
  }
  const user = (item)=>{
    userShow.value = item
    show_user.value = true
  }
  
  const getUser = () => {
    const url = process.env.VUE_APP_API_URL + "users/" + props.company.id
    const config = {
      headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
      listUsers.value = response.data
      loadUsers.value = true
    })
  }
  
  const saveUser = (payload) => {
    
      const url = process.env.VUE_APP_API_URL + "register"
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      axios.post(url, payload, config).then(() => {
          getUser()
        modal.value=false
  
          $swal.fire(
          '🤝',
          'Contato criado com sucesso',
          'success'
      )
      })
  }
  const updateUser = (payload) => {
    
      const url = process.env.VUE_APP_API_URL + "user/" + payload.id
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      axios.put(url, payload, config).then(() => {
          getUser()
        modal.value=false
          $swal.fire(
          '🤝',
          'Contato modificado com sucesso',
          'success'
      )
      userUpdate.value = false
      modal.value=false
      })
  }
  const editUsers = (item)=>{
    userUpdate.value = item
    modal.value=true
  }
  const deleteUser = (item) => {
  
    const url = process.env.VUE_APP_API_URL + "user/" + item.id
  
    const config = {
      headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.delete(url, config).catch(function () {
  
    }).then(() => {
      getUser()
      $swal.fire(
        'Ok',
        'Contato Deletado com sucesso',
        'success'
      )
    })
  }
  </script>
<template>
    <div class="p-2 mb-3 w-64  flex flex-col border-solid border-2 border-re-600
                                                                                                                                                 bg-white rounded-lg hover:shadow-lg "
        :class="color">
        <div class="flex flex-col justify-between  ">

            <div class="flex items-center justify-between">
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
                    element.name
                }}</p>
                <font-awesome-icon v-if="element.status_qualification == 1" icon="fa-solid fa-icicles"
                    class="text-blue-300" />
                <font-awesome-icon v-if="element.status_qualification == 2" icon="fa-solid fa-mug-hot"
                    class="text-yellow-300" />
                <font-awesome-icon v-if="element.status_qualification == 3" icon="fa-brands fa-hotjar"
                    class="text-red-500" />
            </div>


            <div class="flex">

                <button aria-label="Edit user"
                    class="p-1 focus:outline-none focus:shadow-outline text-green-500 hover:text-green-600"
                    @click="sendQualify(element)">
                    <font-awesome-icon icon="fa-brands fa-whatsapp" />
                </button>
                <a class="p-1 focus:outline-none focus:shadow-outline text-neutral-500 hover:text-green-600"
                    :href="`mailto:${element.email}`">
                    <font-awesome-icon icon="fa-solid fa-envelope" />
                </a>
                <a :href="`tel:${element.phone}`"
                    class="p-1 focus:outline-none focus:shadow-outline text-neutral-500 hover:text-green-600">
                    <font-awesome-icon icon="fa-solid fa-phone" />
                </a>

                <button class="p-1 focus:outline-none focus:shadow-outline text-neutral-500 hover:text-green-600"
                    @click="openCalendar(element)">
                    <font-awesome-icon icon="fa-solid fa-calendar-days" />
                </button>
                <RouterLink :to="{ name: 'lead', params: { qualifited_id: element.id_qualification } }"
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600">


                    <font-awesome-icon icon="fa-solid fa-edit" />
                </RouterLink>
                <button aria-label="Delete user"
                    class="p-1 focus:outline-none focus:shadow-outline text-red-500 hover:text-red-600"
                    @click="onDelete(element)">
                    <font-awesome-icon icon="fa-solid fa-trash" />
                </button>
            </div>
            <p class="flex items-start ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
                formatDate(element.updated_at) }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { RouterLink } from 'vue-router'
// eslint-disable-next-line
const props = defineProps({ element: Object, color: String })

// eslint-disable-next-line
const emit = defineEmits(['sendQualify', 'openModalCalendar', 'deleteQualification']);
const sendQualify = (item) => {
    emit('sendQualify', item);
}
const openCalendar = (item) => {
    emit('openModalCalendar', item);
}

const onDelete = (item) => {
    emit('deleteQualification', item);
}
function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}
</script>

<style scoped></style>
<template>
    <div>
        <span class="text-xl text-lify_blue">Relatório de vendas por produtos</span>
        <div class="flex flex-row p-2 w-full">
            <div class="flex flex-col w-44">
                <label class="flex text-base font-medium text-gray-900 ">Data início</label>
                <input type="date" v-model="dateStart"
                class="flex  bg-gray-50 border h-9 border-gray-300 text-gray-900
                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">

            </div>
            <div class="flex flex-col w-44 ml-4">
                <label class="flex text-base font-medium text-gray-900">Data fim</label>
                <input type="date" v-model="dateEnd"
                class="flex  bg-gray-50 border h-9 border-gray-300 text-gray-900
                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
            </div>
        <BuscaProdutos class="flex ml-4" @sendData="receivedProduto" :title="true"></BuscaProdutos>
        
    </div>
    <div>
        <div class="grid grid-cols-6 mt-6 mr-4 ml-4">
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Cliente</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Produto</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Data</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Vendedor</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">R$</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Abrir</span>
        </div>
        <div class="grid grid-rows-1 mt-2  border-indigo-300 hover:bg-slate-200 mr-4 ml-4" v-for="(opportunity, index) in listOpportunities" :key="index">
                <div class="grid grid-cols-6 mt-2 border-indigo-300">

            <span class="grid col-span-1 text-base text-black">{{ opportunity.name_opportunity }}</span>
            <span class="grid col-span-1 text-base text-black">{{ opportunity.product }}</span>
            <span class="grid col-span-1 text-base text-black">{{ formateDate(opportunity.updated_at) }}</span>
            <span class="grid col-span-1 text-base text-black">{{ opportunity.name_seller }}</span>
            <span class="grid col-span-1 text-base text-black">{{ formatarReal(opportunity.value_opportunity) }}</span>
            <span class="grid col-span-1 text-base  text-black">
                    <ButtonLify v-show="!openOpportunity" @action-click="openOpportunities(opportunity)" class="grid col-span-1 justify-items-center justify-self-center" >
                        <font-awesome-icon icon="fa-solid fa-folder-open" class="flex items-center text-center" />
                    </ButtonLify>
                    <ButtonLify v-show="openOpportunity" @action-click="closeOpportunities()" class="grid col-span-1 justify-items-center justify-self-center" >
                        <font-awesome-icon icon="fa-solid fa-folder-closed" class="flex items-center text-center" />
                    </ButtonLify>
                </span>
        </div>
        </div>
        <div v-if="openOpportunity" > 
        <ModalItem @refresh="closeOpportunities">             
       <CardOpportunity :opportunity="opportunity"></CardOpportunity>
    </ModalItem>          
    </div>
    </div>
</div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
  import axios from 'axios'
  import { userDefineStore } from '@/store/UserStore'
  import ModalItem from '../ModalItem.vue';
  import BuscaProdutos from '../BuscaProdutos.vue'
  import CardOpportunity from '../CardOpportunity.vue';
  import ButtonLify from '../default/ButtonLify.vue';
  const userStore = userDefineStore()
const dateStart = ref()
const product = ref('')
const product_id = ref('')
const dateEnd = ref()
const listOpportunities = ref()
const opportunity = ref()
const openOpportunity = ref(false)
onMounted(() => {
    iniDates()
    getSalesAll()
})
const getSales = ()=>{
    const url = process.env.VUE_APP_API_URL + "reportProductsSold/" + userStore._company_id + '/' + dateStart.value + '/' + dateEnd.value+ '/' + product.value.id
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
        listOpportunities.value = response.data
})
}
const getSalesAll = ()=>{
    const url = process.env.VUE_APP_API_URL + "reportProductsSoldAll/" + userStore._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
        listOpportunities.value = response.data
})
}
const iniDates = ()=>{
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    dateStart.value = now.getFullYear()+"-"+(month)+"-01";
    dateEnd.value = now.getFullYear()+"-"+(month)+"-"+(day) ;
   }
   const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const openOpportunities = (item)=>{

    opportunity.value = item
openOpportunity.value =true
}
const closeOpportunities = ()=>{
    
    opportunity.value = ''
    openOpportunity.value =false
}
const receivedProduto = (item)=>{
 
   product.value = item
   product_id.value = item.id
   getSales()
}
const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
</script>
<template>
    <div class="mt-4 sm:mt-0 w-full">
        <div class="md:grid md:grid-cols-1 md:gap-6">
            <div class="mt-4 md:col-span-2 md:mt-0">
                <form>
                    <div class="overflow-hidden shadow sm:rounded-md">
                        <div class="bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-6 gap-6">
                                <div class="col-span-6">
                                    <label class="block text-sm font-medium text-gray-700">Insira uma nova
                                        pergunta</label>
                                    <input type="text" v-model="question" autocomplete="street-address"
                                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm px-6
                                                                                                                                        border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 
                                                                                                                                        h-8 sm:text-sm">
                                </div>
                               
                                <div class="col-span-6 sm:col-span-2">
                                    <label for="country" class="block text-sm font-medium text-gray-700">Valor</label>
                                    <input type="number" min="0" max="10" v-model="value" autocomplete="country-name"
                                        class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">

                                </div>
                                <div v-if="!update" class="col-span-6 sm:col-span-2 mt-6">
                                    <ButtonLify @actionClick="saveQuestions" :title="'Salvar'"></ButtonLify>
                                   
                                </div>
                                <div v-if="update" class="col-span-6 sm:col-span-2 mt-6">
                                    <ButtonLify @actionClick="updateQuestion" :title="'Update'"></ButtonLify>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import { onMounted, ref } from 'vue'
  import ButtonLify from './default/ButtonLify.vue';
  const user = userDefineStore()
// eslint-disable-next-line
const props = defineProps(['questionary_id', 'itemEdit'])
// eslint-disable-next-line
const emit = defineEmits(['sendQuestion', 'updateQuestion']);
const saveQuestions = () => {
    emit('sendQuestion', { question: question.value, value: value.value, questionary_id: props.questionary_id, company_id: user._company_id });
    question.value = ''
    value.value = ''
}
const updateQuestion = () => {
    emit('updateQuestion', { id: props.itemEdit.id, question: question.value, order: props.itemEdit.order, value: value.value, questionary_id: props.questionary_id, company_id: user._company_id });
    question.value = ''
    value.value = ''
}
onMounted(() => {
    props.itemEdit?.question ? update.value = true : update.value = false
    question.value = props.itemEdit?.question
    value.value = props.itemEdit?.value
    
}
)
const question = ref()
const update = ref()
const value = ref()



</script>
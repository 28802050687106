<template>
    <div class=" ml-4 mr-4" action="#">
                <div v-if="showOptions" class="flex flex-row items-start p-2">
                    <input type="radio" id="one" value="client" v-model="search">
                    <label class="block  text-base font-medium text-gray-900 dark:text-white" for="one">Cliente</label>
                    <input type="radio" class="ml-4" id="two" value="opportunity" v-model="search">
                    <label class="block  text-base font-medium text-gray-900 dark:text-white" for="two">Oportunidade</label>
                    <br>
                </div>
                <div class="flex flex-row ml-2 mr-2" v-if="cardOpen" >
                <span class="mr-2">
                    {{ opportunity.name_opportunity }}
                </span>
                <span class="mr-2">
                    {{ opportunity.phone_opportunity }}
                </span>
                <span class="mr-2">
                    {{ opportunity.product }}
                </span>
                </div>
        
        <div v-if="!showOptions" class="flex flex-row items-center ml-2 mt-2 italic text-sm">*Dados anexados ao agendamento</div>
        <BuscaClientes v-if="showClient" @sendClient="receivedClient"></BuscaClientes>
        <SearchOpportunity v-if="showOpportunity" @sendOpportunity="receivedOpportunity"></SearchOpportunity>
        <div class="flex flex-col">

        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Titulo</label>
            <input type="text" v-model="title"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
        </div>
        
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Descrição</label>
            <input type="text" v-model="description" class="bg-gray-50 border border-gray-300 text-gray-900 
                                                 text-sm rounded-lg focus:ring-blue-500 
                                                focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 
                                    dark:placeholder-gray-400 dark:text-white">
        </div>
        <div class="flex flex-col  mb-2 content-end ">
                <p class=" text-gray-700 font-semibold font-sans  mr-1">Data </p>
            <input  type="text"
      onfocus="(this.type='date')"
      onblur="(this.type='text')" v-model="date" class="bg-gray-50 border border-gray-300 text-gray-900 
                                                    text-sm rounded-lg focus:ring-blue-500 
                                                    focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 
                                                    dark:placeholder-gray-400 dark:text-white">
        </div>
        <div  class="flex flex-col  mb-2 content-end ">
                <p class=" text-gray-700 font-semibold font-sans  mr-1">Hora </p>
            <input  type="text"
      onfocus="(this.type='time')"
      onblur="(this.type='text')" v-model="schedule" class="bg-gray-50 border border-gray-300 text-gray-900 
                                                    text-sm rounded-lg focus:ring-blue-500 
                                                    focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 
                                                    dark:placeholder-gray-400 dark:text-white">
        </div>
        <div class="flex justify-between mt-2">
            <div class="flex flex-row items-baseline  mb-2 content-end ">
                <p class=" text-gray-700 font-semibold font-sans  mr-1">Tipo </p>
                <select v-model="type"
                    class="mt-1 rounded-md border border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option value="Ligação">Ligação</option>
                    <option value="Reunião">Reunião</option>
                    <option value="Visita">Visita</option>
                    <option value="Apresentação">Apresentação</option>
                </select>
            </div>
            
            <button v-if="!updated" class="w-auto ml-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 
                                    focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm 
                                    px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 
                                    dark:focus:ring-blue-800" @click="saveSchedule">
                Cadastrar
            </button>
            <button v-if="updated" class="w-auto ml-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 
                                    focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm 
                                    px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 
                                    dark:focus:ring-blue-800" @click="updateSchedule">
                Atualizar
            </button>

        </div>
        </div>





    </div>
</template>
<script setup>
import SearchOpportunity from '@/components/SearchOpportunity.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { onBeforeUnmount, onMounted, ref, watch, inject } from 'vue'
import BuscaClientes from './BuscaClientes.vue'
const user = userDefineStore()
// eslint-disable-next-line
const props = defineProps(['date','hour', 'opportunity_id','update', 'client_id', 'schedule'])
// eslint-disable-next-line
const emit = defineEmits(['close']);
const $swal = inject('$swal');
const description = ref('')
const search = ref()
const opportunity_id =ref(null)
const client_id =ref(null)
const type = ref('')
const cardOpen = ref(false)
const date = ref('')
const schedule = ref()
const scheduleDate = ref()
const showClient = ref(false)
const dateTime = ref(false)
const updated = ref(false)
const showDate = ref(true)
const showOpportunity = ref(false)
const showOptions = ref(true)
const title = ref('')
const client = ref(null)
const opportunity = ref(null)
onMounted(() => {
    console.log(props.update)
    console.log(props.date)

    showProps()
    if(props.schedule === undefined){
        dateTime.value = true
    }


})
onBeforeUnmount(() => {
    description.value = ''
    type.value = ''
    client.value = ''
    opportunity.value = ''
    title.value = ''
    updated.value = false
})
const showProps = () => {

    if ((props.client_id != undefined) || (props.opportunity_id != undefined))  {
        showOptions.value = false
        props.opportunity_id != undefined ? opportunity_id.value = props.opportunity_id : opportunity_id.value = false
        props.client_id != undefined ? client_id.value = props.client_id : client_id.value = false
    }
    else {
        showOptions.value = true
    }
    

    if (props.date == undefined) {
        showDate.value = false
    }
    else {
        let str = props.date.split('-')
        scheduleDate.value = str[2] + '-' + str[1] + '-' + str[0]
        date.value = true
    }
    if (props.hour != undefined) {
        schedule.value = props.hour
    }
    
    if (props.update != null) {
        description.value = props.update.description ? props.update.description : null
        type.value = props.update.type ? props.update.type : null
        date.value = props.update.data ? props.update.data : null
        let str = props.date.split('-')
        date.value  = str[2] + '-' + str[1] + '-' + str[0]
        schedule.value = props.update.hora ? props.update.hora : null

        client.value = props.update.client ? props.update.client : null
        opportunity.value = props.update.opportunity ? props.update.opportunity : null
        title.value = props.update.title ? props.update.title : null
        updated.value = true
    }
}
watch(
    () => search.value,
    () => {
        if (search.value == 'client') {
            showClient.value = true
            cardOpen.value =false
            showOpportunity.value = false
        }
        if (search.value == 'opportunity') {
            showOpportunity.value = true
            showClient.value = false
        }
    }
)
const receivedClient = (item) => {
    client.value = item.id
}
const receivedOpportunity = (item) => {
    opportunity.value = item
    cardOpen.value = true
    opportunity_id.value = item.id
}


const saveSchedule = () => {
    let dateFinal = '';
    let hourFinal = '';
   
    if(!showDate.value){
        dateFinal =  date.value  
        hourFinal = schedule.value;
    }
    else{

    dateFinal =  props.schedule  
        hourFinal = schedule.value;
    }
    if((schedule.value =='' )||(description.value =='' )||(type.value =='' )||(title.value =='' )||(dateFinal.includes('undefine'))||(hourFinal.includes('undefine'))){
        $swal.fire(
            'Ops!',
            'Você precisa preencher todos os dados do agendamento.',
            'warning'
        )
    }
    else{
    
    let payload = {
        'description': description.value,
        'type': type.value,
        'data': dateFinal,
        'hora': hourFinal,
        'client_id': client.value,
        'opportunity_id': opportunity_id.value,
        'title': title.value,
        'company_id': user._company_id,
        'user_id': user._user_id
    }
    const url = process.env.VUE_APP_API_URL + "calendar"
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        if(opportunity.value != null){
            saveComment(title.value)
        }
        description.value = ''
        schedule.value = ''
        type.value = ''
        title.value = ''
        emit('close');
        $swal.fire(
            '👍!',
            'Agendamento efetuado!',
            'success'
        )
    })
}

}
const saveComment = (comment) => {

    let url = process.env.VUE_APP_API_URL + "searchChat/" + opportunity.value.opportunity_id 
  
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {

    
  
    let payload = {
        description: 'Agendamento via calendário: '+comment,
        status: 'active' ,
        chat_id: response.data[0].id,
        user_id: user._user_id,
        company_id:   user._company_id,
    }
    const url2 = process.env.VUE_APP_API_URL + "comment"
    
    axios.post(url2, payload, config).then(() => {
        
    })
});
}
const updateSchedule = () => {
    let str = scheduleDate.value.split('-')
    scheduleDate.value = str[2] + '-' + str[1] + '-' + str[0]
console.log(props.update)
    let payload = {
        'id': props.update.id,
        'description': description.value,
        'type': type.value,
        'data': scheduleDate.value,
        'hora':  schedule.value,
        'client_id': client.value,
        'opportunity_id': opportunity.value,
        'title': title.value,
        'company_id': user._company_id,
        'user_id': user._user_id
    }
    const url = process.env.VUE_APP_API_URL + "calendar/" + props.update.id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
        description.value = ''
        schedule.value = ''
        date.value = ''
        type.value = ''
        title.value = ''
        emit('close');
    })
}

</script>
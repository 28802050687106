<template>
    <div>
        <div class="grid grid-cols-2 gap 2 mb-4 mt-4">
                
            <div class="flex flex-col items-start ml-2">
                <div class="flex flex-row">
                    <span class="text-red-500">* </span>
                    <label class="text-sm ml-2 font-normal text-gray-900">
                        Nome</label>
                    </div>
                <input type="text" v-model="name"
                class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>
            <div class="flex flex-col items-start ml-2">
                <div class="flex flex-row">
                    <span class="text-red-500">* </span>
                <label class="text-sm ml-2 font-normal text-gray-900">
                    e-mail</label>
                    </div>
                <input type="text" v-model="email"
                class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>
            </div>
            <div class="flex flex-row items-center mt-2">
            <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                        Fone</label>
                    <input type="text" v-model="phone" @input="maskPhone"
                    class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                    <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                        Cargo</label>
                    <input type="text" v-model="office"
                    class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
             
                    <div class="flex flex-col items-start ml-2">
                        <label class="text-sm ml-2 font-normal text-gray-900">
                            Data Nascimento</label>
                            <input type="date" v-model="birth"
                            class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        </div>
                    <div class="flex flex-col items-start ml-2">
                        <div class="flex flex-row">
                            <span class="text-red-500">* </span>
                        <label class="text-sm ml-2 font-normal text-gray-900">
                            Tipo</label>
                            </div>
                            <select v-model="type" 
                    class="ml-2 rounded-md border w-60 border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                <option value="Principal">Principal</option>
                                <option value="Secundário">Secundário</option>
            </select>
                        </div>
                
           
            <div class="flex flex-row items-center ml-2">
                <ButtonLify :icon="true" v-if="!update" @actionClick="saveContact">
                    <font-awesome-icon icon="fa-solid fa-save" />
                </ButtonLify>
                <ButtonLify :icon="true" v-if="update" @actionClick="updateContact">
                    <font-awesome-icon icon="fa-solid fa-save" />
                </ButtonLify>
                <ButtonLify :icon="true" v-if="update" @actionClick="closed">
                    <font-awesome-icon icon="fa-solid fa-rectangle-xmark" />
                </ButtonLify>
               
            </div>
        </div>
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import {  onMounted, ref, watch, inject } from 'vue'
  import ButtonLify from './default/ButtonLify.vue';
  const userStore = userDefineStore()
  const $swal = inject('$swal');
// eslint-disable-next-line
const props = defineProps(['client_id', 'showCpf', 'updateContact'])

// eslint-disable-next-line
const emit = defineEmits(['saved', 'updated','close']);
const email = ref('')
const phone = ref('')
const office = ref('')

const type = ref('')

const cpf = ref(null)
const update = ref(false)
const birth = ref('')
const name = ref('')
onMounted(() => {
    if (props.updateContact) {
   
        update.value = true
        email.value = props.updateContact.email
        phone.value = props.updateContact.phone
        office.value = props.updateContact.office
        type.value = props.updateContact.type
        name.value = props.updateContact.name
        
        birth.value = props.updateContact.birth
    }

})

watch(
    () => props.updateContact,
    () => {

        email.value = props.updateContact.email
        phone.value = props.updateContact.phone
        office.value = props.updateContact.office
        type.value = props.updateContact.type
        cpf.value = props.updateContact.cpf
        birth.value = props.updateContact.birth
        name.value = props.updateContact.name
       
    }
)

const maskPhone = () => {
    var x = phone.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    phone.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}
const updateContact = () => {
    const payload = { 
        id: props.updateContact.id, 
        name: name.value, 
        birth: birth.value, 
        type: type.value,
        email: email.value,  
        office: office.value, phone: phone.value, 
        client_id: props.client_id, 
        user_id: userStore._user_id,
        company_id: userStore._company_id }
   
    emit('updated', payload);
    name.value = '';
    email.value = '';
    type.value = '';
    office.value = '';
    phone.value = '';
    birth.value = '';
    
}
const closed = ()=>{
    emit('close');
}
const saveContact = () => {
    if((name.value =="")||(email.value =="")||(type.value =="")){
        $swal.fire(
        'Atenção',
        'Você precisa cadastrar nome, e-mail e tipo de contato para obter sucesso.',
        'warning'
    )
    }
    else{
        const payload = { 
            name: name.value,  
            email: email.value,
            type: type.value,
            office: office.value, 
            birth: birth.value, 
            phone: phone.value, 
            client_id: props.client_id, 
            company_id: userStore._company_id,
            user_id: userStore._user_id }
        emit('saved', payload);
        name.value = '';
        email.value = '';
        office.value = '';
        phone.value = '';
        birth.value = '';
        type.value = ''
    }
}
</script>

<style scoped></style>
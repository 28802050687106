<template>
    <div class="p-2 mb-3 w-72  flex flex-col border-solid border-2 border-sky-600
     rounded-lg hover:shadow-lg  " >
        <div class="flex flex-col justify-between  " >

            <div class="flex flex-row justify-between">
                <span @click="openTask" class="ml-2 text-gray-700 text-sm font-sans tracking-wide hover:cursor-pointer hover:text-blue-700">
                    {{task.opportunity_name
                    }}
                </span>
            </div>
            <div class="flex flex-row justify-between">
                <p class="ml-2 text-gray-700 text-sm font-sans tracking-wide">
                   Tarefa: {{ task.title }}
                </p>
            </div>
            <div class="flex flex-row justify-between">
                <p class="ml-2 text-gray-700 text-sm font-sans tracking-wide">
                   Tipo: {{ task.descriptionTask }}
                </p>
            </div>
            <div class="flex flex-row justify-between">
                <p class="ml-2 text-gray-700 text-sm font-sans tracking-wide">
                   Vencimento {{ formateDate(task.data) }}
                </p>
                <div v-if="openComent"  @click="openComent = !openComent"> <font-awesome-icon class="text-blue-500 cursor-pointer ml-2" icon="fa-solid fa-circle-info " /></div>
                <div v-if="!openComent" @click="openComent = !openComent"> <font-awesome-icon class="text-blue-500 cursor-pointer" icon="fa-solid fa-circle-info" /></div>
            </div>
            

            <ModalItemSm @refresh="close" v-if="openComent"> 
                <div class="flex flex-col justify-start items-start text-start">
                    <div class="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
                        <span class="ml-4">Informações da Tarefa</span>
                    </div>
                    <div class="flex flex-row items-start">
                        <span class="text-base font-normal text-gray-700">                            
                            Tafera: 
                        </span>
                        <span class="text-base font-normal ml-2 text-gray-900">                            
                           {{task.title}}
                        </span>
                    </div>
                   
                    <div class="flex flex-row items-start mt-2">
                        <span class="text-base font-normal text-gray-700">                            
                            Descrição: 
                        </span>
                        <span class="text-base font-normal ml-2 text-gray-900">                            
                           {{task.description}}
                        </span>
                    </div>
                    <div class="flex flex-row items-start mt-2">
                        <span class="text-base font-normal text-gray-700">                            
                            Data Abertura: 
                        </span>
                        <span class="text-base font-normal ml-2 text-gray-900">                            
                            {{formateDate2(task.created_at)}}
                        </span>
                    </div>
                   

                    <div class="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
                        <span class="ml-4">Comentários</span>
                    </div>
                    <div v-for="(commentario, index) in task.coments" :key="index" class="flex items-start justify-start">
                        <span  class="flex ml-6 italic items-start text-sm text-blue-600">
                            {{ commentario.name }} : {{ commentario.description }}
                        </span>
                    </div>
                    <div  class="flex flex-col items-start mr-4">
                        <label class=" mb-1 ml-2 text-sm font-medium text-gray-900 ">
                            Novo comentário
                        </label>
                        <textarea type="text" v-model="comment"
                            class="ml-4 mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                        </textarea>
                    </div>
                    <div  class="flex justify-end mt-2">
                        <button @click="saveComment(task.id)" class="flex items-center justify-center w-6 h-6
                         bg-indigo-500 text-indigo-50 rounded-md cursor-pointer">
                         <font-awesome-icon icon="fa-solid fa-save" />
                        </button>
                    </div>
                    <div class="content-end  items-end w-full  bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
                        <hr>
                    </div>
                    <button @click="closeTask" class="flex items-center justify-center p-2
                     bg-blue-500 text-indigo-50 rounded-md cursor-pointer">
                    Encerrar Tarefa
                    </button>
                </div>
            </ModalItemSm>
        </div>
    </div>
</template>

<script setup>
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { inject, ref } from 'vue'
import ModalItemSm from './ModalItemSm.vue'
const user = userDefineStore()
const $swal = inject('$swal');
// eslint-disable-next-line
const props = defineProps(['task'])
const comment = ref()
const openComent = ref(false)
// eslint-disable-next-line
const emit = defineEmits(['sendOpen','sendRefresh']);
const close = ()=>{
    openComent.value = !openComent.value 
}
const formateDate = (data) => {
    
    let dataLocal = data.split('-')
    dataLocal = dataLocal[2]+'/'+dataLocal[1]+'/'+dataLocal[0]
   
    return dataLocal
}
const formateDate2 = (data) => {
    
    let dataLocal = data.split('T')
    dataLocal = dataLocal[0].split('-')
    dataLocal = dataLocal[2]+'/'+dataLocal[1]+'/'+dataLocal[0]
    return dataLocal
}
const openTask = ()=>{
    emit('sendOpen', props.task);
}


const saveComment = (tasks_id) => {
    let payload = {
        description: comment.value,
        status: 'active' ,
        chat_id: null,
        tasks_id: tasks_id,
        user_id: user._user_id,
        company_id:   user._company_id,
    }
    const url = process.env.VUE_APP_API_URL + "comment"
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        comment.value = "";
        emit('sendRefresh');
    })
}

const closeTask = ()=>{
    $swal.fire({
  title: 'Finalizar Tarefa',
  text: 'Escolha uma opção para o encerramento da venda:',
  showCancelButton: true,
  confirmButtonText: 'Sucesso',
  cancelButtonText: 'Insucesso',
  icon:'info',
}).then((result) => {
    if (result.isConfirmed) {
        let payload = {
        result: 'success',
        
    }
    const url = process.env.VUE_APP_API_URL + "closeTask/"+ props.task.id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
        emit('sendRefresh');
        $swal.fire(
        'Ok!',
        'Proposta finalizada com sucesso.',
        'success'
    )
    })
       
  }
  else{
    let payload = {
        result: 'unsuccess',
        
    }
    const url = process.env.VUE_APP_API_URL + "closeTask/"+ props.task.id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
        emit('sendRefresh');
        $swal.fire(
        'Ok!',
        'Proposta finalizada com insucesso.',
        'error'
    )
    })
    
  }
    })

}

</script>

<style scoped>

</style>
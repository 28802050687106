<template>
    <div>
        <div class="flex flex-row  h-screen w-screen bg-neutral-100">
            <div>
                <SidebarMenu></SidebarMenu>
            </div>
            <div class="flex flex-col w-full">
                <NavBar :section="'Oportunidades'" :menuItem="menuItem" :head="['Em andamento','Nova','Qualificar Oportunidade', 'Busca' ]" @navItem="navItem"></NavBar>

                <div v-if="newOportunity">
                    <FormNewOportunityClient></FormNewOportunityClient>
                </div>
               
                <div  v-if="numeroOportunidades != 0">
                <div v-if="listUserOpportunities">
                    <div class="grid col-span-4 m-4  h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                        <span class="ml-4 ">Suas oportunidades em andamento</span>
                    </div>
                    <div class="grid grid-cols-2" >
                        <div v-for="(opportunity, index) in listUserOpportunities" :key="index">
                            <OpportunityInProgress  class="flex justify-start" :opportunity="opportunity"></OpportunityInProgress>
                        </div>
                    </div>
                </div>
               
                    <div v-if="listOpportunitiesMarked">

                        <div class="grid col-span-4  m-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                            <span class="ml-4 ">Oportunidades em andamento compartilhadas com você</span>
                        </div>
                        <div class="grid grid-cols-2">
                            <div v-for="(opportunity, index) in listOpportunitiesMarked" :key="index">
                                <OpportunityMarkedInProgress  class="flex justify-start" :opportunity="opportunity"></OpportunityMarkedInProgress>
                            </div>
                        </div>
                    </div>
                    <div v-if="userStore._user_type == 'Gerente'">
                    <div class="grid col-span-4 m-4  h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                        <span class="ml-4 ">Todas as oportunidades em andamento</span>
                    </div>
                    <div v-if="numeroOportunidades == 0">
                    Não existe nenhuma oportunidades  em andamento.
                    </div>
                    <div class="grid grid-cols-2">
                        <div v-for="(opportunity, index) in listOpportunities" :key="index">
                            <OpportunityInProgress  class="flex justify-start" :opportunity="opportunity"></OpportunityInProgress>
                        </div>
                    </div>
                </div>
             </div>
                <div v-if="qualify">
                    <QuestionarioComponent />
                </div>
                <div v-if="searchOpportunity">
                    <BuscaDataUser @sendData="receivedData"/>
                    <UserOpportunity v-if="loadData" :user="user" :dateStart="dateStart" :dateEnd="dateEnd" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

//import TimelineOpportunities from '@/components/TimelineOpportunities.vue'
import OpportunityInProgress from '@/components/OpportunityInProgress.vue'
import FormNewOportunityClient from '@/components/FormNewOportunityClient.vue'
import NavBar from '@/components/NavBar.vue'
import BuscaDataUser from '@/components/BuscaDataUser.vue'
import QuestionarioComponent from '@/components/QuestionarioComponent.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import UserOpportunity from '@/components/UserOpportunity.vue'
import { userDefineStore } from '@/store/UserStore'
import OpportunityMarkedInProgress from '@/components/OpportunityMarkedInProgress.vue'
import axios from 'axios'
import { ref,onMounted } from 'vue'
const userStore = userDefineStore()

const listOpportunity = ref(true)
const listOpportunities = ref([])
const listUserOpportunities = ref([])
const listOpportunitiesMarked = ref([])
const numeroOportunidades = ref(0)
const numeroUserOportunidades = ref(0)
const numeroOportunidadesMaarked = ref(0)
const qualify = ref(false)
const newOportunity = ref(false)
const searchOpportunity = ref(false)
const user = ref(false)
const dateStart = ref(false)
const dateEnd = ref(false)
const loadData = ref(false)
const menuItem = ref('Em andamento')

onMounted(() => {
    if (userStore._user_type == 'Gerente') {
        getOpportunities();
    }
    getUserOpportunities()
    getMarkedOpportunities()
})

const getOpportunities = () => {
    const config = {
        headers: {
            Authorization: `Bearer ${userStore._api_token}`,
        }
    }
    let url = process.env.VUE_APP_API_URL + "opportunities/" + userStore._company_id
        axios.get(url, config).catch(function () {
    }).then((response) => { 
        numeroOportunidades.value = Object.keys(response.data).length
        listOpportunities.value = response.data
       
    })
}
const getUserOpportunities = () => {
    const config = {
        headers: {
            Authorization: `Bearer ${userStore._api_token}`,
        }
    }
    let url = process.env.VUE_APP_API_URL + "userOpportunities/" + userStore._user_id
        axios.get(url, config).catch(function () {
    }).then((response) => {
        numeroUserOportunidades.value = Object.keys(response.data).length
        listUserOpportunities.value = response.data        
    })    
}
const getMarkedOpportunities = () => {
    const config = {
        headers: {
            Authorization: `Bearer ${userStore._api_token}`,
        }
    }
    
      let  url = process.env.VUE_APP_API_URL + "markedsOpportunities/" + userStore._user_id
    
    axios.get(url, config).catch(function () {
    }).then((response) => {
        numeroOportunidadesMaarked.value = Object.keys(response.data).length
        listOpportunitiesMarked.value = response.data
        if((numeroOportunidades.value == 0)&&(numeroUserOportunidades.value == 0)&&(numeroOportunidadesMaarked.value ==0)){
            navItem('Nova')
        }
    })
}
const receivedData = (data) =>{
    user.value = data.user
    dateStart.value = data.dateStart
    dateEnd.value = data.dateEnd
    loadData.value = true
}
const navItem = (item) => {
    if (item == 'Nova') {
        newOportunity.value = true
        qualify.value = false
        listOpportunity.value = false
        searchOpportunity.value = false
        menuItem.value = 'Nova'
    }
    else if (item == 'Em andamento') {
        newOportunity.value = false
        qualify.value = false
        listOpportunity.value = true
        searchOpportunity.value = false
         menuItem.value = 'Em andamento'
    }
    else if (item == 'Busca') {
        newOportunity.value = false
        qualify.value = false
        listOpportunity.value = false
        searchOpportunity.value = true
         menuItem.value = 'Busca'
    }
    else {
        newOportunity.value = false
        qualify.value = true
        listOpportunity.value = false
        searchOpportunity.value = false
         menuItem.value = 'Qualificar Oportunidade'
    }
}
</script>

<style scoped></style>
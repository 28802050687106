
<template>
  <div id="app">

    <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-md w-full space-y-8">
        <div>
          <img class="mx-auto h-56 w-auto" src="@/assets/adm.jpg">


        </div>


        <form class="mt-8 space-y-6">

          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">Email address</label>
              <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email address">
            </div>
            <div v-if="show">
              <label for="password" class="sr-only">Password</label>
              <input v-model="password" id="password" name="password" type="password" autocomplete="current-password"
                required
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password">
            </div>
          </div>

          <div class="flex items-center justify-between">


            <div v-if="show" class="text-sm">
              <a @click="show = !show" class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">
                Esqueceu sua senha? </a>
            </div>
            <div v-if="!show" class="text-sm">
              <a @click="show = !show" class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">
                Retornoar ao login </a>
            </div>
          </div>

          <div>
            <button v-if="show" @click.stop.prevent="login()" class="group relative w-full 
                                              flex justify-center py-2 px-4 border border-transparent 
                                              text-sm font-medium rounded-md text-black bg-indigo-100 
                                              hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2
                                               focus:ring-indigo-500">
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: solid/lock-closed -->
                <svg class="h-5 w-5 text-black group-hover:text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd" />
                </svg>
              </span>
              Entrar
            </button>
            <button v-if="!show" @click.stop.prevent="resetPassword()"
              class="group relative w-full 
                                              flex justify-center py-2 px-4 border border-transparent 
                                              text-sm font-medium rounded-md text-black bg-blue-500 
                                              hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: solid/lock-closed -->
                <svg class="h-5 w-5 text-black group-hover:text-blue-400" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd" />
                </svg>
              </span>
              reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import { inject, ref } from 'vue';
  import { useRouter } from 'vue-router';
  
  const userStore = userDefineStore()
const email = ref(null)
const password = ref(null)
const router = useRouter()
const show = ref(true)
const $swal = inject('$swal');
const login = () => {
  const payload = {
    email: email.value,
    password: password.value
  }
  userStore.login(payload).then(() => {
 
    router.push({ name: 'dashboardAdm' })
  }).catch(() => {
    $swal.fire(
      'Ops!',
      'email ou password inválido, verifique ou resete sua senha',
      'error'
    )
  })
}
const resetPassword = () => {
  $swal.fire(
    'Ops!',
    'Desculpe mas este é somente um teste, ainda não implementamos o reset de senha',
    'warning'
  )
}

</script>
<style scoped></style>

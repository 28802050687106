<template>
    <div class="mt-4 mr-4 ml-4">
        <div class="flex items-start gap-2.5">
            <img v-if="avatar" class="w-10 h-10 rounded-full" :src="avatar" >
            <span v-else> <font-awesome-icon icon="fa-solid fa-user" class="w-10 h-10 rounded-full" /></span>
                <div class="flex flex-col w-full max-w-[420px] min-h-[120px]  leading-1.5 p-4 border-blue-200 bg-blue-100 rounded-e-xl rounded-es-xl dark:bg-blue-700">
                    <div class="flex items-center space-x-2 rtl:space-x-reverse">
                       <span class="text-sm font-semibold text-blue-900 ">{{ coment.name }}</span>
                       <span class="text-sm font-normal text-blue-500 ">{{ formateDate(coment.created_at) }}</span>
                    </div>
                    <p class="text-sm font-normal py-2.5 text-blue-900 ">{{ coment.description }}</p>
                </div>
        </div>
    </div>
</template>
<script setup>

import { onMounted, ref } from 'vue'
import { userDefineStore } from '@/store/UserStore'
  const user = userDefineStore()
// eslint-disable-next-line
const props = defineProps(['coment'])
const avatar = ref(false);

onMounted(()=>{
  if(user._user_avatar != null)
  avatar.value = `${process.env.VUE_APP_BASE_URL}${props.coment.avatar}`

})


const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" };
   
    return dataLocal.toLocaleTimeString('pt-BR', options)
    
}
</script>
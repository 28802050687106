<template>
     <div>
        <div class="grid grid-cols-6 mt-6">
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Cliente</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Produto</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Data</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Vendedor</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">R$</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Abrir</span>
        </div>
        <div class="grid grid-cols-6 p-2" v-for="(opportunity, index) in listOpportunities" :key="index">
            <span class="grid col-span-1 text-base  text-black">{{ opportunity.name_opportunity }}</span>
            <span class="grid col-span-1 text-base  text-black">{{ opportunity.product }}</span>
            <span class="grid col-span-1 text-base  text-black">{{ formateDate(opportunity.updated_at) }}</span>
            <span class="grid col-span-1 text-base  text-black">{{ opportunity.name_seller }}</span>
            <span class="grid col-span-1 text-base  text-black">{{ formatarReal(opportunity.value_opportunity) }}</span>
            <span class="grid col-span-1 text-base  text-black">
                <ButtonLify :icon="true" v-if="!openOpportunity"  @action-click="openOpportunities(opportunity)" class="grid col-span-1 justify-items-center justify-self-center">
                    <font-awesome-icon icon="fa-solid fa-folder-open"  class="flex items-center text-center"/>
                </ButtonLify>
                <ButtonLify  :icon="true" v-if="openOpportunity" @action-click="closeOpportunities">
                    <font-awesome-icon icon="fa-solid fa-folder-closed"  class="flex items-center text-center"/>
                </ButtonLify>
            </span>
        </div>
        <div v-if="openOpportunity" > 
        <ModalItem @refresh="closeOpportunities">             
            <CardOpportunity :opportunity="opportunity"></CardOpportunity>
        </ModalItem>          
    </div>
    </div>
    </template>
    <script setup>
    import ModalItem from '@/components/ModalItem.vue'
    import CardOpportunity from '@/components/CardOpportunity.vue'
    import ButtonLify from './default/ButtonLify.vue';
    import {ref}  from 'vue'
// eslint-disable-next-line
const props = defineProps(['listOpportunities'])
const opportunity = ref()
const openOpportunity = ref(false)
const closeOpportunities = ()=>{
    
    opportunity.value = ''
    openOpportunity.value =false
}
const openOpportunities = (item)=>{

opportunity.value = item
openOpportunity.value =true
}
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
    </script>
<template>
    <div class="flex flex-row items-center mt-4">
              <img v-if="user.avatar" class="w-8 h-8 rounded-full" 
              :src="returnAvatar(user.avatar)" >
              <span v-else> <font-awesome-icon icon="fa-solid fa-user" class="w-8 h-8 rounded-full" /></span>
              <span class="text-lg ml-4">{{user.name}}</span>
            </div>
</template>
<script setup>
// eslint-disable-next-line
const props = defineProps(['user'])
const returnAvatar = (avatar)=>{
    return `${process.env.VUE_APP_BASE_URL}${avatar}`
}
</script>
<template>
    <div v-if="loadTasks" class="flex flex-col border border-blue-500 text-start rounded-md p-2 h-full">
        <div class="grid col-span-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                <span class="ml-4 ">Tarefas</span>
        </div>
        <div class="flex flex-row p-2 justify-between">
            <button @click="controlButton('Hoje')"      :class="{ 'bg-blue-500': btnHoje, 'bg-cyan-500': !btnHoje }" class="text-sm cursor-pointer  text-white p-2 rounded-md">Hoje</button>
            <button @click="controlButton('Vencidas')"  :class="{ 'bg-blue-500': btnVencida, 'bg-cyan-500': !btnVencida }" class="text-sm cursor-pointer  text-white p-2 rounded-md">Vencidas</button>
            <button @click="controlButton('Futuras')"   :class="{ 'bg-blue-500': btnfuturo, 'bg-cyan-500': !btnfuturo }" class="text-sm cursor-pointer  text-white p-2 rounded-md">Futuras</button>

        </div>
        <div class="grid col-span-4 mt-2 mb-2 h-6 bg-gradient-to-r from-gray-100 to-gray-300 text-start text-black rounded-md ">
                <span class="ml-4 ">Minhas Tarefas</span>
        </div>
        <div v-for="(task, keystamp) in listMyTasks" :key="keystamp" class="overflow-y-auto overflow-x-hidden ">
            <CardTask :task="task" :color="'bg-blue-500'" @sendRefresh="refresh" @sendOpen="sendOpen"></CardTask>
        </div>
        <div class="grid col-span-4 mt-2 mb-2 h-6 bg-gradient-to-r from-gray-100 to-gray-300 text-start text-black rounded-md ">
                <span class="ml-4 ">Tarefas em conjunto</span>
        </div>
        
        <div v-for="(task, index) in listInvolvedsTasks" :key="index" class="overflow-y-auto overflow-x-hidden ">
            <CardTask :task="task" :color="'bg-blue-500'" @sendOpen="sendOpen"></CardTask>
        </div>
    </div>
</template>
<script setup>
import {ref, onMounted} from 'vue'

import CardTask from './CardTask.vue';
import axios from 'axios'
import { userDefineStore } from '@/store/UserStore'
  const user = userDefineStore()
//const $swal = inject('$swal');
// eslint-disable-next-line
const emit = defineEmits(['sendOpen']);
const listMyTasks = ref()
const listInvolvedsTasks = ref()
const loadTasks = ref()
const btnHoje = ref(true)
const btnfuturo = ref(false)
const btnVencida = ref(false)
const keystamp = ref(0)
const sendOpen = (task)=>{
    emit('sendOpen', task);
}
const controlButton = (btn)=>{
  if(btn == 'Hoje'){
    btnHoje.value = true
    btnfuturo.value = false
    btnVencida.value = false
    tasksNow()
  }
  else if(btn == 'Vencidas'){
    btnHoje.value = false
    btnfuturo.value = false
    btnVencida.value = true
    tasksDue()
  }
  else{
    btnHoje.value = false
    btnfuturo.value = true
    btnVencida.value = false
    tasksFuture()
  }
}
const refresh = ()=>{
    if(btnHoje.value){
      tasksNow()
    }
    else if(btnfuturo.value){
      tasksFuture()
    }
    else if(btnVencida.value){
      tasksDue()
    }
}
onMounted(()=>{
  tasksNow()
    
})
const tasksNow = ()=>{
  const url = process.env.VUE_APP_API_URL + "userTasksNow/" + user._user_id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listMyTasks.value=[]
    listMyTasks.value = response.data.myTasks
    listInvolvedsTasks.value = response.data.involvedsTasks
    loadTasks.value = true
    keystamp.value = new Date().getTime();
  })
}
const tasksDue = ()=>{
  const url = process.env.VUE_APP_API_URL + "userTasksDue/" + user._user_id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listMyTasks.value=[]
    listMyTasks.value = response.data.myTasks
    listInvolvedsTasks.value = response.data.involvedsTasks
    loadTasks.value = true
    keystamp.value = new Date().getTime();
  })
}
const tasksFuture = ()=>{
  const url = process.env.VUE_APP_API_URL + "userTasksFuture/" + user._user_id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listMyTasks.value=[]
    listMyTasks.value = response.data.myTasks
    listInvolvedsTasks.value = response.data.involvedsTasks
    loadTasks.value = true
    keystamp.value = new Date().getTime();
  })
}

</script>
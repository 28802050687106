<template>
   <div class="bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
        <span class="ml-4">Cadastro de Clientes</span>
    </div>
    <div class="grid grid-cols-2">
        
      <div class="flex flex-row items-center  mt-2">
        <input type="radio" id="one" value="pf" v-model="person">
        <label class="block  text-base font-medium text-gray-900 dark:text-white ml-2" for="one">Pessoa Física</label>
        <input type="radio" class="ml-4" id="two" value="pj" v-model="person">
        <label class="block  text-base font-medium text-gray-900 dark:text-white ml-2" for="two">Pessoa Jurídica</label>
        <br>
      </div>

      <div v-if="pf || pj" class="flex flex-row  mt-2">
            <p class=" text-gray-700 font-semibold font-sans  mr-1">Categoria: </p>
            <select v-model="categorie" @change="onChange"
                    class="mt-1 rounded-md border w-60 border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                <option v-for="(item, index) in categories" :value="item.id" :key="index">
                                    {{ item.type }}
                                </option>
            </select>
        </div>
      </div>
      <div v-if="person != '' && categorie" class="flex flex-row  mt-4">
        
        <FormNewClient  @saved="saveClient" :client_id="null" :showCpf="showCpf"/>
      </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { inject, onMounted, ref, watch } from 'vue'
  const user = userDefineStore()

import FormNewClient from './FormNewClient.vue'
const $swal = inject('$swal');
// eslint-disable-next-line
const emit = defineEmits(['close']);
//const $swal = inject('$swal'); 38.972.079/0001-95

const categories = ref([])
const person = ref('')
const pf = ref(false)
const showCpf = ref(false)
const pj = ref(false)
const categorie = ref(false)


onMounted(()=>{
  getCategories()
})

watch(
  () => person.value,
  () => {
    if (person.value == 'pf') {
      pf.value = true
      pj.value = false
      showCpf.value = true
    }
    if (person.value == 'pj') {
      pj.value = true
      pf.value = false
      showCpf.value=false
    }
  }
)



const getCategories = () => {
    const url = process.env.VUE_APP_API_URL + "categories/" + user._company_id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
        categories.value = response.data
    })
}

const saveContact = (payload) => {
  const url = process.env.VUE_APP_API_URL + "contact"
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.post(url, payload, config).then(()=>{
    emit('close',  payload.client_id);
    $swal.fire(
      'Ok',
      'Novo cliente criado com sucesso!',
      'success'
    )
  })
}

const saveClient = (payload) => {

  
  payload.categorie_id = categorie.value
  const url = process.env.VUE_APP_API_URL + "client"
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.post(url, payload, config).catch(function () {
  }).then((response) => {
    payload.client_id = response.data.client.id
    if(!showCpf.value)
    {
      payload.name = payload.name_contact
    }
    payload.type = 'Principal'
    saveContact(payload)
  })
}
</script>

<style scoped></style>
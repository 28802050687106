<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>

            <SidebarMenu></SidebarMenu>
        </div>
        <div class="flex flex-col" v-if="load">
            <div class="flex flex-row">
                <div class="flex flex-col items-start ml-4">

                    <div class="flex flex-row items-center">
                        <div class="flex flex-col">
                            <div
                                class="flex flex-col justify-start mt-4 items-start bg-white  text-indigo-700 border-solid
                                                                                                                          border-2  border-neutral-50
                                                                                                                         bg-white rounded-lg shadow-lg p-4 ">
                                <span class="ml-4 text-md  ">Oportunidade: {{ opportunity.number }} </span>
                                <span class="ml-4 text-md  ">Nome: {{ lead.name }} </span>
                                <span class="ml-4 text-md  ">Data entrada: {{ formatDate(lead.created_at) }}
                                </span>
                                <span class="ml-4 text-md  ">e-mail: {{ lead.email }}</span>
                                <span class="ml-4 text-md  ">Fone: {{ lead.phone }}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="flex flex-row  w-128 items-center justify-between">

                        <span class="flex justify-center items-center ml-4 mt-2">Qualificação</span>
                        <button v-if="!openQuestion" aria-label="Edit user"
                            class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                            @click="openQuestion = !openQuestion">
                            <font-awesome-icon icon="fa-solid fa-angle-left" />
                        </button>
                        <button v-if="openQuestion" aria-label="Edit user"
                            class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                            @click="openQuestion = !openQuestion">
                            <font-awesome-icon icon="fa-solid fa-angle-down" />
                        </button>
                    </div>
                    <div v-if="openQuestion"
                        class="flex flex-col 
                                                                                                                                                                                                                         overflow-x-hidden 
                                                                                                                                                                                                                         items-start mt-1 ml-4 border-solid border-2 border-neutral-50 
                                                                                                                                                                                                                         h-44 bg-white rounded-lg shadow-lg ">


                        <div v-for="(element, index) in questions" :key="index">

                            <LineAnswers :element="element"></LineAnswers>
                        </div>
                    </div>
                    <div
                        class="flex flex-col justify-start p-4 ml-4 mt-4 items-start bg-indigo-100  text-indigo-700 border-solid">
                        <span class="ml-4 text-md  ">Produto: {{ product.product }}</span>
                        <span class="ml-4 text-md  ">Descrição: {{ product.description }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import LineAnswers from '@/components/LineAnswers.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
const userStore = userDefineStore()
const route = useRoute()
//const router = useRouter()
const opportunity = ref({});
const questions = ref({});
const lead = ref({});
const chat = ref({});
const product = ref({});
const openQuestion = ref(false);
const load = ref(false);

onMounted(() => {
    getOportunity();
})

const getOportunity = () => {
    let url = process.env.VUE_APP_API_URL + "opportunity/" + route.params.opportunity_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        opportunity.value = response.data
        url = process.env.VUE_APP_API_URL + "lead/" + opportunity.value.lead_id
        axios.get(url, config).catch(function () { }).then(function (responselead) {
            lead.value = responselead.data
        })
        url = process.env.VUE_APP_API_URL + "product/" + opportunity.value.product_id
        axios.get(url, config).catch(function () { }).then(function (responseProduct) {
            product.value = responseProduct.data
        })

        url = process.env.VUE_APP_API_URL + "qualification/" + opportunity.value.qualification_id
        axios.get(url, config).catch(function () { }).then(function (responseQualification) {
            questions.value = JSON.parse(responseQualification.data.answers)
            load.value = true
        })
        url = process.env.VUE_APP_API_URL + "chatOpportunity/" + opportunity.value.opportunity_id
        axios.get(url, config).catch(function () { }).then(function (responseChat) {
            chat.value = responseChat.data
            load.value = true
        })

    })
}
function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}
</script>

<style scoped></style>
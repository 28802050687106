<template>
    <div class="flex p-2 border border-lify_blue rounded-md flex-col  max-h-20
    bg-gradient-to-r from-gray-50 to-gray-200 ">
        <div class="flex flex-row justify-between">
            <span class="text-sm">{{ title }}</span>
            <ButtonLify @action-click="emitEvent">
                <font-awesome-icon icon="fa-solid fa-table text-sm"  />
            </ButtonLify>
            
        </div>
         <span v-if="real" class="text-lg">{{ formatarReal(value) }}</span>
         <span v-if="!real" class="text-lg">{{ value }}</span>
    </div>
</template>
<script setup>
import ButtonLify from '../default/ButtonLify.vue';
//import { onMounted, ref  } from 'vue'
// eslint-disable-next-line
const props = defineProps(['title','value','event','real'])
// eslint-disable-next-line
const emit = defineEmits(['sendEvent']);

const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
const emitEvent = ()=>{
    emit('sendEvent',props.event)
}
</script>
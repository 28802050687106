<template>
    <div class="flex flex-row  h-screen w-screen bg-white">
        <div>
            <SidebarMenu></SidebarMenu>
        </div>
        <div class="flex flex-col w-full">
            <NavBar :section="'Proposta Comercial'"
             :client="true" 
             :head="[ ]" 
              @sendClient="receivedClient" @refresh="refresh" :key="keystamp"></NavBar>  
              <div id="element-to-convert">       
            <div class="mt-2 p-2 w-10 text-sm font-sm h-8 ml-2 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                @click="exportToPDF">
                <font-awesome-icon icon="fa-solid fa-print" />
            </div>
              
                <div class="flex flex-col items-center  justify-start ">
                  
                  <img v-if="infoCompany.logo" class="w-48 h-48 rounded-md object-cover  cursor-pointer" :src="logo" alt="">
                 
                <div>
                    <div class="flex flex-row justify-center">
                        <span class="text-lg">Empresa:</span>  
                        <span class="text-lg ml-2">{{ infoCompany.name }}</span>  
                    </div>
                    <div class="flex flex-row justify-center">
                        <span class="text-lg">CNPJ:</span>  
                        <span class="text-lg ml-2">{{ infoCompany.cnpj }}</span>  
                    </div>
                    <div class="flex flex-row justify-center">
                        <span class="text-lg">Endereço:</span>  
                        <span class="text-lg ml-2">{{ infoCompany.logradouro }},</span>  
                        <span class="text-lg ml-2"> {{ infoCompany.numero }}</span>  
                        <span v-if="infoCompany.complemento" class="text-lg ml-2">/ {{ infoCompany.complemento }}</span>  
                        <span class="text-lg ml-2"> {{ infoCompany.bairro }}-</span>  
                        <span class="text-lg ml-2"> {{ infoCompany.localidade }}/</span>  
                        <span class="text-lg "> {{ infoCompany.uf }}</span>  
                    </div>
                </div>
      
    </div>
            <div v-if="loadClient" class="flex flex-col p-4">
                <CardInfoClients :client="client"></CardInfoClients>
                <ModalItemVue v-if="openModalOpportunity">
                    <ListUserOpportunity @opportunitySelected="opportunitySelected" :client="client"></ListUserOpportunity>
                </ModalItemVue>
            </div>
            <div v-if="openOpportunity">
                <div class="flex flex-col justify-start p-4">
                    <span class="flex items-start text-lg font-semibold">Oportunidade n°: {{ opportunity.number }}</span>
                    <span class="flex items-start ">Produto: {{ opportunity.product }}</span>
                    <span class="flex items-start ">Descrição do produto: {{ opportunity.product_description }}</span>
                    <span class="flex items-start ">{{ formatarReal(opportunity.value_opportunity) }}</span>
                </div>
                <div v-if="!closeDescriptionProduct" class="flex flex-col items-start p-4">
                    <span class="flex items-start text-lg font-semibold" >Descrição</span> 
                    <p class="flex items-start mt-3 text-sm leading-6 text-gray-600 text-start">
                        {{ descriptionProduct }}
                    </p>
                </div>
                <div v-if="closeDescriptionProduct" class="flex flex-col">                
                    <span class="mt-3 text-sm leading-6 text-gray-600">Descreva seu produto ou serviço</span>
                    <div class="flex flex-row items-start justify-start w-full">                
                        <div class="mt-2">
                            <textarea id="about" v-model="descriptionProduct" name="about" rows="3" class="block w-262 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                        </div>
                        <div @click="closeDescriptionProduct = !closeDescriptionProduct" class="modal-close cursor-pointer z-50">
                            <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                viewBox="0 0 18 18">
                                <path
                                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="flex items-start p-4">
                    <span class="flex items-start text-lg font-semibold" >Fotos</span> 
                    <div class="flex flex-row items-start">
                        <div class="flex flex-row justify-between" v-for="(url, index) in listUrl" :key="index">
                            <img class="w-80 h-44 "  :src="url" />
                        </div>
                    </div>
                </div>
                <div v-if="imageSelect" class="flex flex-row items-start justify-start w-full">
                    <label for="dropzone-file" class="flex flex-col items-center justify-center w-80 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">proposrção 16:9 SVG, PNG, JPG</p>
                        </div>
                        <input id="dropzone-file" type="file" class="hidden" @change="onFileChange"/>
                    </label>
                    <div class="flex  items-start pb-1">
                    <p class="text-2xl font-bold"></p>
                    <div @click="imageSelect = !imageSelect" class="modal-close cursor-pointer z-50">
                        <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                            viewBox="0 0 18 18">
                            <path
                                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                            </path>
                        </svg>
                    </div>
                </div>
                </div> 
                <div v-if="!closeDescription" class="flex flex-col items-start p-4">
                    <span class="flex items-start text-lg font-semibold" >Observações</span> 
                    <p class="flex items-start mt-3 text-sm leading-6 text-gray-600 text-start">{{ description }}</p>
                </div>
                <div v-if="closeDescription" class="flex flex-col">                
                    <span class="mt-3 text-sm leading-6 text-gray-600">Descreva Informações importantes de sua proposta</span>
                    <div class="flex flex-row items-start justify-start w-full">                
                        <div class="mt-2">
                            <textarea id="about" v-model="description" name="about" rows="3" class="block w-262 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                            focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                        </div>
                        <div @click="closeDescription = !closeDescription" class="modal-close cursor-pointer z-50">
                            <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                viewBox="0 0 18 18">
                                <path
                                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import NavBar from '@/components/NavBar.vue'
  import SidebarMenu from '@/components/SidebarMenu.vue'
  import CardInfoClients from '@/components/CardInfoClients.vue'
  import { ref, onMounted } from 'vue'
  import ListUserOpportunity from '@/components/ListUserOpportunity.vue'
  import ModalItemVue from '@/components/ModalItem.vue'
  import html2pdf from "html2pdf.js";
  const userStore = userDefineStore()
const client = ref(false)
const closeDescription = ref(true)
const description = ref('')
const descriptionProduct = ref('')
const imageSelect = ref(true)
const loadClient = ref(false)
const opportunity =ref(false)
const closeDescriptionProduct =ref(true)
const logo =ref(false)
const openModalOpportunity =ref(false)
const openOpportunity = ref(false)
const listUrl = ref([])
const keystamp = ref(0)
const infoCompany = ref(0)

onMounted(()=>{
    getInfoCompany();
 
})
const refresh = ()=>{
    keystamp.value = new Date().getTime();
}
const receivedClient = (item)=>{
    client.value = item
    keystamp.value = new Date().getTime();
    loadClient.value = true
    openModalOpportunity.value = true
}
const getInfoCompany = () => {
   
   const url = process.env.VUE_APP_API_URL + `company/${userStore._company_id }`
     const config = {
         headers: { Authorization: `Bearer ${userStore._api_token}` }
     };
     
   axios.get(url, config).then((response) => {
     
     infoCompany.value = response.data
     logo.value = `http://localhost:8000${infoCompany.value.logo}`;
   });
}
const onFileChange = (e)=> {
      const file = e.target.files[0];
      listUrl.value.push(URL.createObjectURL(file));
    }
const opportunitySelected = (oportunidade)=>{

    opportunity.value = oportunidade
    openOpportunity.value = true
    openModalOpportunity.value = false
}

const exportToPDF = ()=> {
    var opt = {
        margin:0,       
        filename:opportunity.value.number + '.pdf',
        image:{ type: 'jpeg', quality: 1 },
        html2canvas:  { scale: 2 },
        jsPDF:{ unit: 'in', format: 'a4', orientation: 'portrait' }
};

html2pdf().set(opt).from(document.getElementById("element-to-convert")).save()
    }
    const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
</script>

<style scoped></style>
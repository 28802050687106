<template>
    <div class="p-2 mb-3 w-72 mr-4 flex flex-col border-solid border-2 border-sky-600
                                     bg-sky-300 rounded-lg hover:shadow-lg " :class="color">
        <div class="flex flex-col justify-between  ">

            <div class="flex items-center">
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
                    element.name
                }}</p>
            </div>

            <div class="flex">

                <button @click="openQualify(element)"
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600">


                    <font-awesome-icon icon="fa-solid fa-edit" />
                </button>
                <button @click="updateProspect(element)"
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600">


                    <font-awesome-icon icon="fa-solid fa-lock" />
                </button>
                <button aria-label="Delete user"
                    class="p-1 focus:outline-none focus:shadow-outline text-red-500 hover:text-red-600"
                    @click="onDelete(element)">
                    <font-awesome-icon icon="fa-solid fa-trash" />
                </button>
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{ formatDate(element.created_at) }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { inject } from 'vue'
  import { useRouter } from 'vue-router'
  const user = userDefineStore()
const router = useRouter();
const $swal = inject('$swal');
// eslint-disable-next-line
const props = defineProps({ element: Object, color: String })


// eslint-disable-next-line
const emit = defineEmits(['sendQualify']);
/*const sendQualify = (item) => {
    emit('sendQualify', item);
}
*/
const onDelete = () => {
    $swal.fire(
        'Pense bem',
        'Ao deletar esta tarefa você perderá totalmente o histórico atrelado, você pode também editar esta tarefa',
        'warning'
    )
}
const openQualify = (element) => {
    const url = process.env.VUE_APP_API_URL + "prospect/" + element.id;
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).then(() => {
        router.push({ name: 'qualificating', params: { prospect_id: element.id } });
    })
}
const updateProspect = (element) => {
    const url = process.env.VUE_APP_API_URL + "prospect/" + element.id
    element.status = 'criado'
    element.user_id = 'null'
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.put(url, element, config).catch(function () {
    }).then(() => {
    })
}
function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}
</script>

<style scoped></style>
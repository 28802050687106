<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>
            <SidebarMenu></SidebarMenu>
        </div>
        <div class="flex flex-col w-full">
            <NavBar :section="'Usuários'"></NavBar>

            <div class="flex flex-row items-center ml-8 mt-8 mr-8">
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Nome</label>
                    <input type="text" v-model="name"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        email</label>
                    <input type="text" v-model="email"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Tipo
                    </label>
                    <select v-model="type"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                        <option value="Vendedor">Vendedor</option>
                        <option value="Gerente">Gerente</option>
                        <option value="SDR">SDR</option>
                    </select>
                </div>

                <div v-if="!update"
                    class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="saveList">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
                <div v-if="update"
                    class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="updateUser">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
            </div>


            <div class="flex flex-col ml-4 mt-4 mr-4">
                <div class="grid grid-cols-10 bg-white justify-items-center w-full">
                    <div class="bg-white col-span-3 text-gray-400 font-sm rounded-tl px-4 py-2">Nome</div>
                    <div class="bg-white col-span-3 text-gray-400 font-sm  px-4 py-2">email</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm  px-4 py-2">tipo</div>

                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tr px-4 py-2">Ação</div>
                </div>
                <div v-for="(line, index) in listUser" :key="index"
                    class="grid grid-cols-10 bg-white py-3 px-3 hover:bg-neutral-50 justify-items-center">
                    <div class="col-span-3">{{ line.name }}</div>
                    <div class="col-span-3">{{ line.email }}</div>
                    <div v-if="line.type == 'manager'" class="col-span-2">Gerente</div>
                    <div v-if="line.type == 'seller'" class="col-span-2">Vendedor</div>
                    <div class="col-span-2">
                        <button class="text-indigo-500" @click="editUser(line)"> <font-awesome-icon
                                icon="fa-solid fa-edit" /></button>
                        <button class="text-red-400 ml-4" @click="deleteUser(line.id)"> <font-awesome-icon
                                icon="fa-solid fa-trash" /></button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { onMounted, ref } from 'vue'
const userStore = userDefineStore()
//import { useRouter } from 'vue-router';
//const router = useRouter()
const listUser = ref([])
const email = ref()
const type = ref()
const name = ref()
const update = ref(false)
const idUpdate = ref()

onMounted(() => {
    getUsers();

})
const editUser = (item) => {
    update.value = true
    name.value = item.name
    email.value = item.email
    type.value = item.type

    idUpdate.value = item.id
}
const updateUser = () => {
    const url = process.env.VUE_APP_API_URL + "user/" + idUpdate.value
    let payload = { 'name': name.value, 'email': email.value, 'type': type.value, company_id: userStore._company_id }
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
        name.value = ''
        email.value = ''
        type.value = ''
        update.value = false
        idUpdate.value = ''
        getUsers()
    })
}
const deleteUser = (user) => {
    const url = process.env.VUE_APP_API_URL + "user/" + user
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.delete(url, config).then(() => {
        getUsers()
    })
}
const getUsers = () => {
    const url = process.env.VUE_APP_API_URL + "users/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listUser.value = response.data
    })
}
const saveList = () => {

    let payload = {
        'name': name.value,
        'email': email.value,
        'password': email.value,
        'type': type.value,
        'status': true,
        company_id: userStore._company_id
    }
    const url = process.env.VUE_APP_API_URL + "register"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        name.value = ''
        email.value = ''
        type.value = ''
        getUsers();
    })
}
</script>

<style scoped></style>
<template>
    <div >
        <div class="flex flex-col   m-2 mr-4">
            <div class="flex flex-col items-start ">
                <label class="block mb-1 ml-2 text-sm font-medium text-gray-900 ">
                    Produto
                </label>
                <textarea type="text" v-model="product"
                    class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                </textarea>
            </div>
             <div class="flex flex-col items-start mt-4">
                <label class="block mb-1 ml-2 text-sm font-medium text-gray-900 ">
                    Descrição
                </label>
                <textarea type="text" v-model="description"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                </textarea>
            </div>
            
            <div class="flex flex-col items-start mt-4">
                <label class="block mb-1 ml-2 text-sm font-medium text-gray-900 ">
                    Valor R$</label>
                <input type="text" v-model="value" @input="maskReal"
                class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
            </div>
            <button v-if="!update" type="button" @click="saveProduct"
                class="w-24 h-10 mt-4 ml-4 mr-4  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 
                  focus:outline-none focus:ring-blue-300 
                  font-medium rounded-lg text-sm text-center ">
                  Salvar
            </button>
            <button v-else type="button" @click="updateProduct"
                class="w-24 h-10 mt-4 ml-4 mr-4  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 
                  focus:outline-none focus:ring-blue-300 
                  font-medium rounded-lg text-sm text-center ">
                  Salvar
            </button>
        </div>

</div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { inject, onMounted, ref } from 'vue'
  const user = userDefineStore()

const $swal = inject('$swal');
// eslint-disable-next-line
const props = defineProps(['product','update'])
// eslint-disable-next-line
const emit = defineEmits(['close','update']);
const product = ref('')
const value = ref('')
const description = ref('')
const idProduct= ref('')

onMounted(() => {
 
    if (props.update){
        product.value = props.product.product
        value.value = props.product.value
        description.value = props.product.description
        idProduct.value = props.product.id
    }
})

const saveProduct = () => {
    if((value.value !='') || (product.value !='') || (description.value !='') ){
value.value = value.value.replace('.','')
value.value = value.value.replace(',','.')
let payload = { 'product': product.value, 'description': description.value,'value': value.value,user_id: user._user_id ,  company_id: user._company_id }
const url = process.env.VUE_APP_API_URL + "product"
const config = {
headers: { Authorization: `Bearer ${user._api_token}` }
};
axios.post(url, payload, config).then(() => {
    product.value = ''
    description.value = ''
    value.value = ''
    $swal.fire(
            '',
            'Novo produto cadastrado com sucesso!',
            'success'
        )
    emit('close')
   
})
}
else{
    $swal.fire(
            'Ops!',
            'Você precisa cadastrar todos os itens de novo produto',
            'warning'
        )
}
}

const updateProduct = ()=>{
    value.value = value.value.replace('.','')
    value.value = value.value.replace(',','.')
    let payload = {
        id: idProduct.value,
        product: product.value,
        value: value.value,
        user_id: user._user_id ,
        description: description.value,
    }
    emit('update',payload)
    $swal.fire(
            'Ok!',
            'Produto editado com sucesso!',
            'success'
        )
    emit('close')
}

const maskReal = () => {
    value.value = value.value.replace(/\D/g, ""); // Remove todos os não dígitos
    value.value = value.value.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
    value.value = value.value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
	
}
</script>
import {defineStore} from 'pinia';
import { userDefineStore } from './UserStore';

export const chatDefineStore = defineStore('chat',{
    //state
    state(){
        return{
            notifications: 0,
            chat_id: 0,
            message:0
        }
    },
    actions:{
        receivedNotification( payload) {
            const user = userDefineStore()                    
            this.message = payload.message.msg.id
            this.chat_id = payload.message.msg.chat_id
       
            if (user._user_email == payload.message.user.email) {
               this.notifications++
            }
        },
        resetNotification() {
                this.notifications =0
        },
    },
    getters:{
        getNotification(){
            return this.notifications
        },
        getMessage(){
            return this.message
        },
        getChatId(){
            return this.chat_id
        }

    }

    
})
<template>
    <div>
        <div class="grid grid-cols-2 gap 2 mb-4 mt-4">
                
            <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                    Nome</label>
                <input type="text" v-model="name"
                class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>
            <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                    e-mail</label>
                <input type="text" v-model="email"
                class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>
            </div>
            <div class="flex flex-row items-center mt-2">
                <div class="flex flex-col items-start ml-2">
                    <label class="text-sm ml-2 font-normal text-gray-900">
                        Tipo
                    </label>
                    <select v-model="type" 
                        class="ml-2 rounded-md border w-60 border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="Vendedor">Vendedor</option>
                        <option value="Gerente">Gerente</option>
                        <option value="SDR">SDR</option>
                    </select>
                </div>
            <div class="flex flex-row items-center ml-2">

                <div v-if="!update"
                    class="flex justify-center item-center pt-2 mt-4  text-base font-sm h-8 ml-2 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="saveUser">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
                <div v-if="update"
                    class="flex justify-center item-center pt-2 mt-4  text-base font-sm h-8 ml-2 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="updateUser">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
                <div v-if="update"
                    class="flex justify-center item-center pt-2 mt-4  text-base font-sm h-8 ml-2 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="closed">

                    <font-awesome-icon icon="fa-solid fa-rectangle-xmark" />
                </div>


            </div>
        </div>
    </div>
</template>

<script setup>
import {  onMounted, ref, watch } from 'vue'
// eslint-disable-next-line
const props = defineProps(['company_id',  'updateUser'])

// eslint-disable-next-line
const emit = defineEmits(['saved', 'updated','close']);
const name = ref('')
const email = ref('')
const type = ref('')
const update = ref(false)

onMounted(() => {
    if (props.updateUser) {

        update.value = true
        email.value = props.updateUser.email
        type.value = props.updateUser.type
        name.value = props.updateUser.name      
    }

})

watch(
    () => props.updateUser,
    () => {

        email.value = props.updateUser.email
        
        type.value = props.updateUser.type
        
        name.value = props.updateUser.name
       
    }
)


const updateUser = () => {
    const payload = { 
        id: props.updateUser.id, 
        name: name.value, 
       
        type: type.value,
        email: email.value,  
       
        company_id: props.company_id, }
   
    emit('updated', payload);
    name.value = '';
    email.value = '';
    type.value = '';

    
}
const closed = ()=>{
    emit('close');
}
const saveUser = () => {
    const payload = { 
        name: name.value,  
        email: email.value,
        type: type.value,
        password: email.value,
        company_id: props.company_id, }
    emit('saved', payload);
    name.value = '';
    email.value = '';
    type.value = ''
}
</script>

<style scoped></style>
<template>
    <div>

        <VueApexCharts type="line" height="180" :options="options" :series="series" ></VueApexCharts>
    </div>
</template>

<script setup>
import VueApexCharts from "vue3-apexcharts";
import {  ref  } from 'vue'
// eslint-disable-next-line
const props = defineProps(['title','labels','series','real'])
const options = ref({
    chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            tooltip: {
              y: {
                formatter: function (val) {
                    if(props.real){
                        return formatarReal(val)
                    }
                    else{
                        return val
                    }
                }
              }
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: props.title,
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: props.labels,
            }
          
          },)
          const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}      
const series = ref(props.series)
/*const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}*/
</script>
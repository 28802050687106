<template>
    <div>
       
            <NavBar  :section="'Dashboard'" :menuItem="menuItem" :head="['Meu Dashboard','Relatórios']" @navItem="navItem"></NavBar>
           
        
                <div v-if="report" class="grid grid-cols-4 grid-rows-2 gap-4 p-8">
                    <CardSetings @navItem="navItem" :title="'Vendas em Andamento'" :color="'bg-lify_blue  hover:bg-lify_blue_hover'" :icon="'fa-dollar-sign'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Vendas Encerradas'" :color="'bg-lify_blue  hover:bg-lify_blue_hover'" :icon="'fa-dollar-sign'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Vendedores'" :color="'bg-lify_blue  hover:bg-lify_blue_hover'" :icon="'fa-user-tie'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Vendas por Produtos'" :color="'bg-lify_blue  hover:bg-lify_blue_hover'" :icon="'fa-box'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Qualificações Clientes'" :color="'bg-lify_blue  hover:bg-lify_blue_hover'" :icon="'fa-star'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Qualificações Leads'" :color="'bg-lify_blue hover:bg-lify_blue_hover'" :icon="'fa-star'"></CardSetings>
                     
                </div>
             
                <div v-if="salesInProgress" class="mr-4">
                    <ReportOnAllSales></ReportOnAllSales>
                </div>
                <div v-if="salesClosed" class="mr-4">
                  <ReportSales></ReportSales>
                </div>
                <div v-if="sellers" class="mr-4">
                    <SellerDashboard></SellerDashboard>
                </div>
                <div v-if="salesByProducts" class="mr-4">
                    <ReportSalesProduct></ReportSalesProduct>
                </div>
                <div v-if="clientQualifications" class="mr-4">
                 <ReportReviewClients></ReportReviewClients>
                </div>
                <div v-if="leadQualification" class="mr-4">
                   <ReportReviewLeads></ReportReviewLeads>
                </div>
                <div v-if="clientReport" class="mr-4">
                   <ReportClient></ReportClient>
                </div>
              
            
            </div>

</template>

<script setup>

import SellerDashboard from "@/components/dashboard/SellerDashboard.vue"
import { ref } from 'vue'
import CardSetings from '../CardSetings.vue'
import NavBar from '../NavBar.vue'
import ReportReviewClients from './ReportReviewClients.vue'
import ReportReviewLeads from './ReportReviewLeads.vue'
import ReportSalesProduct from './ReportSalesProduct.vue'
import ReportSales from './ReportSales.vue'
import ReportOnAllSales from './ReportOnAllSales.vue'
import ReportClient from '@/components/dashboard/ReportClient.vue'
// eslint-disable-next-line
const emit = defineEmits(['sendNav']);

const report = ref(true)
const salesInProgress = ref(false)
const salesClosed = ref(false)
const clientReport = ref(false)
const sellers = ref(false)
const salesByProducts = ref(false)
const clientQualifications = ref(false)
const leadQualification = ref(false)
const menuItem = 'Relatórios'

const navItem = (item) => {
    if (item == 'Relatórios') {

        report.value = true
        salesInProgress.value = false
        salesClosed.value = false
        sellers.value = false
        salesByProducts.value = false
        clientQualifications.value = false
        leadQualification.value = false
        clientReport.value = false
       
    }
    if (item == 'Vendas em Andamento') {
        report.value = false
        salesInProgress.value = true
        salesClosed.value = false
        sellers.value = false
        salesByProducts.value = false
        clientQualifications.value = false
        leadQualification.value = false
        clientReport.value = false
       
    }
    if (item == 'Meu Dashboard') {

        emit('sendNav','Meu Dashboard')
       
    }
    else if (item == 'Vendas Encerradas') {
        report.value = false
        salesInProgress.value = false
        salesClosed.value = true
        sellers.value = false
        salesByProducts.value = false
        clientQualifications.value = false
        leadQualification.value = false
        clientReport.value = false
    }
    else if (item == 'Vendedores') {
        report.value = false
        salesInProgress.value = false
        salesClosed.value = false
        sellers.value = true
        salesByProducts.value = false
        clientQualifications.value = false
        leadQualification.value = false
        clientReport.value = false
    }
    else if (item == 'Vendas por Produtos') {
        report.value = false
        salesInProgress.value = false
        salesClosed.value = false
        sellers.value = false
        salesByProducts.value = true
        clientQualifications.value = false
        leadQualification.value = false
        clientReport.value = false
    }
    else if (item == 'Qualificações Clientes') {
        report.value = false
        salesInProgress.value = false
        salesClosed.value = false
        sellers.value = false
        salesByProducts.value = false
        clientQualifications.value = true
        leadQualification.value = false
        clientReport.value = false
    }
    else if (item == 'Qualificações Leads') {
        report.value = false
        salesInProgress.value = false
        salesClosed.value = false
        sellers.value = false
        salesByProducts.value = false
        clientQualifications.value = false
        leadQualification.value = true
        clientReport.value = false
    }
    else if (item == 'Clientes') {
        clientReport.value = true
        report.value = false
        salesInProgress.value = false
        salesClosed.value = false
        sellers.value = false
        salesByProducts.value = false
        clientQualifications.value = false
        leadQualification.value = false
    }
   
    
}
</script>
<template>
    <div class="flex flex-row">
        <div class="flex flex-col">
            <div class="flex flex-col items-center ml-2 mt-4">
                <label class="block mb-2 text-base font-medium ">
                    Nome empresa</label>
                <input type="text" v-model="empresa"
                    class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 ">
            </div>
            <div class="flex flex-row">
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">
                        CNPJ</label>
                    <input type="text" v-model="cnpj"   @input="maskCnpj"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                </div>
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">
                        CEP (somente números)
                    </label>
                    <input type="text" v-model="cep" @keyup="searchCep"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                </div>                    
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">
                        Plano
                    </label>
                    <select v-model="plan_id"
                    class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                   <option v-for="(item, index) in plans" :value="item.id" :key="index">
                                {{ item.name }}</option>
                    </select>
             </div>                    
            </div>
            <div class="flex flex-row">
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">
                        Logradouro
                    </label>
                    <div class="mt-2">
                      <input type="text" v-model="logradouro"
                      class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    </div>
                </div>
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">Número</label>
                <div class="mt-2">
                  <input type="text" v-model="numero"   
                  class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                   </div>
                </div>
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">
                        Complemento
                    </label>
                    <div class="mt-2">
                        <input type="text" v-model="complemento"  
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">
                        Bairro
                    </label>
                    <div class="mt-2">
                      <input type="text" v-model="bairro"
                      class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    </div>
                </div>
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">Localidade</label>
                <div class="mt-2">
                  <input type="text" v-model="localidade"  
                  class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                   </div>
                </div>
                <div class="flex flex-col items-center ml-2 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 ">
                        Estado
                    </label>
                    <div class="mt-2">
                        <input type="text" v-model="uf"  
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    </div>
                </div>
            </div>
            <div class="flex flex-row items-center ml-4 mt-4">
                        <div class="flex justify-center item-center pt-2  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                            @click="saveCompany">

                            <font-awesome-icon icon="fa-solid fa-save" />
                        </div>
                    </div>
                
                
        </div>
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios';
  import { inject, onMounted, ref } from 'vue';
  const userStore = userDefineStore()

// eslint-disable-next-line 
const emit = defineEmits(['refresh']);
const $swal = inject('$swal');
const empresa = ref('')
const cnpj = ref(null)
const cep = ref('')
const logradouro = ref('')
const numero = ref('')
const complemento = ref('')
const bairro = ref('')
const localidade = ref('')
const uf = ref('')
const plans =ref('')
const plan_id = ref('')
onMounted(() => {

    getPlans();
    
})



const maskCnpj = () => {
    var y = cnpj.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    cnpj.value = !y[2] ? y[1] : '' + y[1] + '.' + y[2] + (y[3] ? '.' + y[3] : '') + (y[4] ? '/' + y[4] : '') + (y[5] ? '-' + y[5] : '')
}


const searchCep = ()=> {
			if(cep.value.length == 8) {
				axios.get(`https://viacep.com.br/ws/${ cep.value }/json/`)
				.then((response)=>{
                    if(response.data.erro){
                        $swal.fire(
                            'Não encontrado',
                            'Verifique o cep novamente, não encontramos por este número',
                            'warning'
                        )                   
                    }
                    bairro.value = response.data.bairro 
                    cep.value = response.data.cep 
                    logradouro.value = response.data.logradouro 
                    localidade.value = response.data.localidade 
                    uf.value = response.data.uf 
                })
			
			}
		}
const getPlans = ()=>{
    const url = process.env.VUE_APP_API_URL + "plans"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {

        //   router.push('/');
    }).then((response) => {
  
        plans.value = response.data
    })
}
const saveCompany = () => {
    const url = process.env.VUE_APP_API_URL + "company"
    const payload = { name: empresa.value, 
        cnpj: cnpj.value, 
        cep: cep.value,
        logradouro: logradouro.value,
        numero: numero.value,
        complemento: complemento.value,
        Bairro: bairro.value,
        localidade: localidade.value,
        uf: uf.value,
        plan_id: plan_id.value
    }
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        emit('refresh');
        $swal.fire(
            'Nova empresa criada com sucesso',
            '',
            'success'
        )
    })
}

</script>

<style scoped></style>
<template>
    <div class="flex flex-col ml-2 mt-4">
        <div class="grid bg-lify_blue justify-items-center w-full" :class="{
                          ' grid-cols-12': buttonsCrud,
                          ' grid-cols-10': !buttonsCrud,
                      }">
            <div class="bg-lify_blue col-span-2 text-white font-sm  px-4 py-2">Nome</div>
            <div class="bg-lify_blue col-span-2 text-white font-sm  px-4 py-2">Data</div>
            <div class="bg-lify_blue col-span-3 text-white font-sm  px-4 py-2">Descrição</div>
            <div class="bg-lify_blue col-span-2 text-white font-sm  px-4 py-2">Tipo</div>
            <div class="bg-lify_blue col-span-2 text-white font-sm  px-4 py-2">Vendedor</div>
            <div v-if="buttonsCrud" class="bg-lify_blue col-span-1 text-white font-sm rounded-tr px-4 py-2">Ação</div>
        </div>
        <div v-for="(line, index) in listReport" :key="index"
            class="grid bg-white py-3 px-3 hover:bg-neutral-50 justify-items-center"
            :class="{
                          'grid-cols-12': buttonsCrud,
                          'grid-cols-10': !buttonsCrud,
                      }">
            <div class="col-span-2" v-if="line.client_name">{{ line.client_name }}</div>
            <div class="col-span-2" v-if="line.lead_name">{{ line.lead_name }}</div>
            <div class="col-span-2" v-if="line.prospect_name">{{ line.prospect_name }}</div>    
            <div class="col-span-2">{{ formateDate(line.date) }}</div>
            <div class="col-span-3">{{ line.description }}</div>
            <div class="col-span-2" v-if="line.client_id">Cliente</div>
            <div class="col-span-2" v-if="line.lead_id">Lead</div>
            <div class="col-span-2" v-if="line.opportunity_id">Oportunidade</div>
            <div class="col-span-2" v-if="line.prospect_id">Prospect</div>
            <div class="col-span-2" >{{ line.name }}</div>
            <div v-if="buttonsCrud" class="col-span-1">
                <button class="text-lify_blue" @click="editReports(line)"> <font-awesome-icon
                        icon="fa-solid fa-edit" /></button>
                <button class="text-red-400 ml-4" @click="deleteReports(line.id)"> <font-awesome-icon
                        icon="fa-solid fa-trash" /></button>
            </div>
        </div>
              </div>
</template>
<script setup>
// eslint-disable-next-line
const props = defineProps(['listReport','buttonsCrud'])
// eslint-disable-next-line
const emit = defineEmits(['editReports','deleteReports'])
console.log(props.listReport)
const editReports = (item)=>{
    emit('editReports',item)
}
const deleteReports = (item)=>{
    emit('deleteReports',item)
}
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
</script>
<template>
    <div class="flex flex-col">
        <div class="grid grid-cols-3 p-4">
            <BuscaClientes class="grid col-span-1" v-if="showClient" @sendClient="receveidClient"></BuscaClientes>
            <BuscaProdutos class="grid col-span-1" @sendData="receivedProduto"  :title="true"></BuscaProdutos>
            <div v-if="showUser" >
                <BuscaUser v-if="!seller" class="grid col-span-1" @sendUser="receivedUser"  :title="'Busca Vendedor'"></BuscaUser> 
                <div v-else  class="flex flex-col">
                    <div class="flex justify-between border border-lify_blue rounded-md p-2">
                        <div>
                            <span class="flex text-start">Executivo responsável</span>
                            <CardUserAvatar :user="seller"></CardUserAvatar>
                        </div>
                        <div class="w-4 h-4 text-xs cursor-pointer" @click="closeSeller">
                            <font-awesome-icon icon="fa-solid fa-x" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ButtonLify class="ml-4" @action-click="createOpportunity" :title="'Criar Oportunidade'"/>
          
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import BuscaClientes from './BuscaClientes.vue'
  import BuscaProdutos from './BuscaProdutos.vue'
  import BuscaUser from './BuscaUser.vue'
  import axios from 'axios'
  import {  ref, inject, onMounted } from 'vue'
  import ButtonLify from '@/components/default/ButtonLify.vue'
  import CardUserAvatar from './CardUserAvatar.vue'
  const user = userDefineStore()
// eslint-disable-next-line
const props = defineProps(['client'])
  // eslint-disable-next-line
  const emit = defineEmits(['close']);
const client = ref('')
const product = ref();
const seller = ref();
const showClient = ref(true)
const showUser = ref(true)

onMounted(()=>{
    if(props.client){
        client.value = props.client
        showClient.value = false
        showUser.value = false
        seller.value = {id: props.client.owner, name:props.client.owner_name,avatar:props.client.avatar}
    }
    
})
const closeSeller = ()=>{
    seller.value = ''
    
}
const $swal = inject('$swal');
const receivedUser = (userSelected)=>{
 
    seller.value = userSelected
    
}
const receveidClient = (clientSelected) => {

    client.value = clientSelected
    seller.value = {id: clientSelected.owner, name:clientSelected.owner_name, avatar:clientSelected.avatar}

}
const receivedProduto = (productSelected)=>{

    product.value = productSelected
  
}
const createOpportunity = async()=>{
    if((client.value == '')||(product.value == '')||(seller.value == '')){
        $swal.fire(
        'Ops!',
        'Você precisa declarar todos os campos!',
        'warning'
    )
    }
    else{
    const msgChat = `Novo chat para a oportunidade do cliente ${client.value.name}.`
   
    const payload = {
        user_id: user._user_id,
        seller_id: seller.value.id,
        client_id: client.value.id,
        value_opportunity: product.value.value,
        product_id: product.value.id,
        company_id: user._company_id
    }
    const url = process.env.VUE_APP_API_URL + "opportunity"
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    await axios.post(url, payload, config).then((response) => {

        createChat(response.data.opportunity.id, msgChat)
    })
}
}
const createChat = async(opportunity_id,msgChat)=>{
    const payload = {
        description: msgChat,
       
        user_id: user._user_id,
        opportunity_id: opportunity_id,
        company_id: user._company_id,
    }
    const url = process.env.VUE_APP_API_URL + "chat"

    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    await axios.post(url, payload, config).then(() => {

        $swal.fire(
        'Oportunidade Criada! 🖖',
        'Não esqueça de qualificar sua oportunidade e boas Vendas!!',
        'success'
    )
    emit('close');
        
    })
}

</script>

<style scoped></style>
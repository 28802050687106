<template>
  <div>
    <div class="grid col-span-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
      <span class=" ml-4">Informações da Oportunidade</span>
    </div>
    <div class="grid grid-cols-3 ">
      <span class="grid justify-items-start  col-span-1 ml-2 text-sm">Nome:</span>
      <span class="grid justify-items-start  col-span-2 mr-2 text-md font-semibold">{{ opportunity.name_opportunity  }}</span>
      <span v-if="opportunity.cnpj != null" class="grid justify-items-start  col-span-1 ml-2 text-sm">CNPJ</span>
      <span v-if="opportunity.cnpj != null" class="grid justify-items-start  col-span-2 mr-2 text-sm ">{{ opportunity.cnpj }}</span>
      <span v-if="opportunity.cpf != null" class="grid justify-items-start  col-span-1 ml-2 text-sm">CPF</span>
      <span v-if="opportunity.cpf != null" class="grid justify-items-start  col-span-2 mr-2 text-sm ">{{ opportunity.cpf }}</span>
      <span class="grid justify-items-start  col-span-1 ml-2 text-sm">Número Oportunidade</span>
      <span class="grid justify-items-start  col-span-2 mr-2 text-sm ">{{ opportunity.number }}</span>
      <span class="grid justify-items-start  col-span-1 ml-2 text-sm">R$</span>
      <span class="grid justify-items-start  col-span-2 mr-2 text-md font-semibold ">{{ formatarReal(opportunity.value_opportunity) }}</span>
      <span class="grid justify-items-start  col-span-1 ml-2 text-sm">Vendedor</span>
      <span class="grid justify-items-start  col-span-2 mr-2 text-sm ">{{ opportunity.name_seller }}</span>
      <span class="grid justify-items-start  col-span-1 ml-2 text-sm">Data Encerramento</span>
      <span class="grid justify-items-start  col-span-2 mr-2 text-sm ">{{ formateDate(opportunity.updated_at) }}</span>
      <span class="grid justify-items-start  col-span-1 ml-2 text-sm">Status</span>
      <span v-if="opportunity.status == 'success'" class="grid justify-items-start  col-span-2 mr-2 text-sm ">Encerrada com sucesso</span>
    </div>
    <div class="grid col-span-4 mt-4 mb-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
      <span class=" ml-4">Chat da Oportunidade</span>
    </div>
    <div class="flex flex-col overflow-y-auto overflow-x-hidden mb-32" >
      <div v-if="totalChat == 0">
       <span class="text-lify_blue ml-4">Não existem comentários neste chat.</span>
      </div>
      <div v-else>

        <div v-for="(coment, index) in listComments" :key="index">
          <ChatMessageLeft v-if="index % 2 == 0" class="flex justify-start" :coment="coment"></ChatMessageLeft>
          <ChatMessageRight v-else class="flex justify-end" :coment="coment"></ChatMessageRight>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { userDefineStore } from '@/store/UserStore'
import ChatMessageLeft from '@/components/ChatMessageLeft.vue'
import ChatMessageRight from '@/components/ChatMessageRight.vue'
import axios from 'axios'
const userStore = userDefineStore()
//eslint-disable-next-line
const props = defineProps(['opportunity'])
const chat = ref()
const listComments = ref()
const totalChat = ref(0)
onMounted(() => {
  console.log(props.opportunity)
    getChat()
    
 })
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}

  
const getChat = ()=>{
    
const url = process.env.VUE_APP_API_URL + "chatOpportunity/" + props.opportunity.opportunity_id
const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
axios.get(url, config).catch(function () {
}).then((response) => {
    chat.value = response.data[0]
    getComents()
}); 

}
const getComents = ()=>{
const url = process.env.VUE_APP_API_URL + "comments/" + chat.value.id
const config = {
        headers: {
            Authorization: `Bearer ${userStore._api_token}`,
        }
    }
axios.get(url, config).catch(function () {
}).then((response) => {
    totalChat.value = Object.keys(response.data).length
    listComments.value = response.data
});

}
</script>

<template>
    <div class="space-y-4 w-96">
        <h5 class="text-xl font-medium text-gray-900 dark:text-white">Cadastro de Prospect</h5>
        <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Nome</label>
            <input type="text" v-model="name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
        </div>
        <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                e-mail</label>
            <input type="email" v-model="email" class="bg-gray-50 border border-gray-300 text-gray-900 
                                                    text-sm rounded-lg focus:ring-blue-500 
                                                    focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 
                                                    dark:placeholder-gray-400 dark:text-white">
        </div>
        <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Telefone</label>
            <input type="text" @input="maskPhone" v-model="phone" class="bg-gray-50 border border-gray-300 text-gray-900 
                                                 text-sm rounded-lg focus:ring-blue-500 
                                                focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 
                                    dark:placeholder-gray-400 dark:text-white">
        </div>
        <div class="flex justify-between">
            <div class="flex flex-row items-baseline  mb-2 content-end ">
                <p class=" text-gray-700 font-semibold font-sans  mr-1">Fonte do Lead: </p>
                <select v-model="fonte"
                    class="mt-1 rounded-md border border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option v-for="(item, index) in listData" :value="item.id" :key="index">
                                {{ item.description }}</option>
                </select>
            </div>
        </div>
        <button type="submit" class="w-auto ml-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm 
                                px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 
                                dark:focus:ring-blue-800" @click="saveProspect">
        Cadastrar
    </button>
</div>
</template>
<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { inject, onMounted, ref } from 'vue'
  const user = userDefineStore()
const $swal = inject('$swal');
// eslint-disable-next-line
const emit = defineEmits(['close']);
const email = ref('')
const phone = ref('')
const fonte = ref('')
const name = ref('')
const listData = ref()

onMounted(()=>{
    getDatas()
})
const maskPhone = () => {
    var x = phone.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    phone.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}
const saveProspect = () => {
    if((email.value == '')||(phone.value == '')||(fonte.value == '')||(name.value  == '')){
    $swal.fire(
        'Ops',
        'Você precisa inserir todas as informações do prospect para cdastro.',
        'warning'
    )
    }
    else{
    let payload = {
        'name': name.value,
        'email': email.value,
        'fonte': fonte.value,
        'phone': phone.value,
        'user_id': user._user_id,
        company_id: user._company_id
    }
    const url = process.env.VUE_APP_API_URL + "prospect"
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.post(url, payload, config)
    name.value = ''
    email.value = ''
    phone.value = ''
    fonte.value = ''
    $swal.fire(
        'Prospect criado! 🤝',
        'Vamos a venda!!',
        'success'
    )
    emit('close');
}
}
const getDatas = () => {
    const url = process.env.VUE_APP_API_URL + "dataProspects/" + user._company_id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
        listData.value = response.data
    })
}

</script>
<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>
            <SidebarAdmMenu></SidebarAdmMenu>
        </div>
        <div class="flex flex-col w-full">
            <NavBarAdmin :section="'Empresas'"
             :company="true" 
             :head="[ ]" 
              @sendCompany="receivedCompany" @refresh="refresh" :key="keystamp"></NavBarAdmin>


     
                <HomeCompanies :company="company" :key="keystamp" @refresh="refresh"/>
   
           
            
        </div>
    </div>
</template>

<script setup>


import NavBarAdmin from '@/components/admin/NavBarAdmin.vue';
import SidebarAdmMenu from '@/components/admin/SidebarAdmMenu.vue';

import HomeCompanies from './HomeCompanies.vue';
import { ref } from 'vue'




const company = ref(false)

const keystamp = ref(0)

const refresh = ()=>{
    keystamp.value = new Date().getTime();
}

const receivedCompany = (item)=>{
    company.value = item
    keystamp.value = new Date().getTime();
   
}



</script>

<style scoped></style>
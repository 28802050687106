<template>
  <div>
    <div v-if="load" class="flex flex-col ">
      <div class="flex flex-row justify-between">

        <div class="flex flex-col items-start justify-start p-2  mb-2  rounded-md">
          <span class="text-xl  font-sm  text-indigo-500">

            Avaliação de Produtos
          </span>
         
          <span class=" text-blue-700 font-semibold font-sans ml-2 mt-2 italic text-sm">
              *Se esta avaliação for para a venda de produtos de terceiros, indique o cliente vendedor.
            </span>
        </div>
        <div v-if="openReview" @click="openReviews" class="cursor-pointer mt-4 mr-4 ">          
          <span class="flex items-end bg-white col-span-1 text-blue-700 font-sm  px-4 py-2">
           Avaliações anteriores
          </span>
          <!-- 
            
          -->
        </div>
      </div>
        <div class="flex flex-row items-center justify-center mb-4">
       
          <BuscaProdutos @sendData="receivedProduto" :title="true" class="flex items-center min-w-96 mr-4"></BuscaProdutos>
          <BuscaClientes @sendClient="receivedClient" class="flex items-center mr-4"></BuscaClientes>
          <BuscaQuestionarie @sendData="receivedQuestionarie" :type="'Produto'" class="flex items-center mr-4"></BuscaQuestionarie>
         
        </div>
        
        <div v-if="openQuestionary">
          <div v-for="(item, index) in listQuestions" :key="index" class="flex flex-col text-lg  items-start ">
          <div v-if="index == control">
              <FormResponse @sendQuestion="saveResponse" @back="back" :index="index"
                  :qualify="responseQualified[control]" :question="item">
              </FormResponse>
          </div>
        </div>
        <div v-if="calculate" class="flex flex-col  items-start justify-start">
          <span class="flex justify-center text-lg text-indigo-700 font-sans">{{ menssage }}</span>
          <div class="flex justify-center item-center pt-2 mt-2  text-base font-sm h-8 ml-8 w-48 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
            @click="saveReview">
            <span class="flex justify-center item-center">Encerrar Avaliação</span>
            <span class="flex justify-center item-center ml-1">
                <font-awesome-icon icon="fa-solid fa-save" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <ModalItem v-if="openReviewModal" @refresh="closeReview" :title="'Avaliações Anteriores'">   
      <div class="flex flex-col ml-4 mr-4 mt-4">
                <div class="grid grid-cols-12 bg-white justify-items-center w-full">
                    <div class="bg-white col-span-3 text-gray-400 font-sm rounded-tl px-4 py-2">Cliente</div>
                    <div class="bg-white col-span-3 text-gray-400 font-sm  px-4 py-2">Produto</div>
                    <div class="bg-white col-span-3 text-gray-400 font-sm  px-4 py-2">Avaliado por</div>
                    <div class="bg-white col-span-1 text-gray-400 font-sm  px-4 py-2">Data da avaliação</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tr px-4 py-2">Ação</div>
                </div>
                <div v-for="(line, index) in listReviews" :key="index"
                    class="grid grid-cols-12 bg-white py-3 px-3 hover:bg-neutral-50 justify-items-center">
                    <div v-if="line.client_name" class="col-span-3">{{ line.client_name}}</div>
                    <div v-else class="col-span-3">Somente avaliação de produto</div>
                    <div class="col-span-3">{{ line.product }}</div>
                    <div class="col-span-3">{{ line.user_name}}</div>
                    <div class="col-span-1">{{ formateDate(line.created_at) }}</div>
                    <div class="col-span-2">
                        <button class="text-indigo-500" @click="openQuestions(line)"> <font-awesome-icon
                                icon="fa-solid fa-folder-open" /></button>
                        
                    </div>
                </div>
            </div>
    </ModalItem>
    <div v-if="openQuestionsModal" > 
        <ModalItem @refresh="closeModalQuestions">             
            <div v-for="(element, index) in questions" class="mt-2  mr-4" :key="index">
                <LineAnswers  :element="element"></LineAnswers>
            </div> 
        </ModalItem>          
    </div>
    <LoadingSpinner class="flex justify-center items-center h-screen" v-if="wait"></LoadingSpinner>
  </div>
</template>

<script setup>
import FormResponse from '@/components/FormResponse.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { inject, onMounted, ref, watch } from 'vue'
import BuscaClientes from './BuscaClientes.vue'
import BuscaProdutos from './BuscaProdutos.vue'
import BuscaQuestionarie from './BuscaQuestionarie.vue'
import ModalItem from './ModalItem.vue'

import LineAnswers from './LineAnswers.vue';
const userStore = userDefineStore()
// eslint-disable-next-line
const emit = defineEmits(['navItem']);


const listProducts = ref([])
const totalQuestions = ref()
const questionary = ref()
const listQuestions = ref([])
const calculate = ref(false)
const client_id = ref(null)
const wait = ref(false)
const openQuestionary = ref(false)
const openReviewModal = ref(false)
const questions = ref([])
const openQuestionsModal = ref(false)
const load = ref(false)
const  responseQualified = ref([]); 
const productSelected = ref(false)
const openReview = ref(false)
const menssage = ref();
const payloadSave = ref([])
const control = ref(0)
const listReviews = ref([])
const $swal = inject('$swal');
onMounted(()=>{
  getProducts()
})
const openReviews = ()=>{
  openReviewModal.value = true
}
const closeReview = ()=>{
  openReviewModal.value = false
}
const closeModalQuestions = ()=>{
  openQuestionsModal.value = false
}
const receivedProduto = (product)=>{
  productSelected.value = product
 
}
const openQuestions = (item)=>{

    questions.value = JSON.parse(item.answers)
    openQuestionsModal.value = true
emit('openReview',item)
}
const receivedQuestionarie = (item)=>{

  questionary.value = item.id
    onChange()
  }
watch(
  
() => productSelected.value,
  () => {
    if (productSelected.value) {
      checkReview()
  }
},
)

const checkReview = ()=>{
  const url = process.env.VUE_APP_API_URL + "checkReviewProduct/" + productSelected.value.id 
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
      if(response.data[0]){
        listReviews.value = response.data
        openReview.value = true
      }
     
    })
}
/* eslint-disable */
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const getProducts = () => {
    const url = process.env.VUE_APP_API_URL + "products/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
        listProducts.value = response.data
        load.value= true;
    })
}
const receivedClient = (item)=>{
  client_id.value = item.id
}

const onChange = () => {
    if( questionary.value){
        wait.value =true

        const url1 = process.env.VUE_APP_API_URL + "questionary/" + questionary.value
        const config1 = {
            headers: { Authorization: `Bearer ${userStore._api_token}` }
        };
        axios.get(url1, config1).then((response) => {
            if(response.data.type == 'Produto'){
              const url = process.env.VUE_APP_API_URL + "questionsQuestionary/" + userStore._company_id + '/' + response.data.id
                const config = {
                    headers: { Authorization: `Bearer ${userStore._api_token}` }
                    };
                axios.get(url, config).then((response) => {
                totalQuestions.value = Object.keys(response.data).length
              
                listQuestions.value = response.data
                wait.value =false
                if(totalQuestions.value == 0){
                  $swal.fire(
            'Ops!',
            'Não encontramos perguntas para este questionário, insira as perguntas no menu Questionário/Perguntas.',
            'warning'
        )
                }
                else{
                  openQuestionary.value =true
                }
               
    })
            }
         

         else{
            $swal.fire(
                'Este questionário não é para qualificação de Produto!',
                'Selecione um questionário para esta finalidde, caso ainda não possua cadastre no item questionários',
                'warning'
            )
            wait.value =false
        }
        })
    
    
}
    else{
        wait.value =false
        $swal.fire(
            'Ops!',
            'Não encontramos um questionário para qualificação de Produto neste produto',
            'warning'
        )
    }
}

const back = () => {
    control.value--;
}
const saveResponse = (event) => {
    responseQualified.value[control.value] = event
    control.value++;
    if (control.value == totalQuestions.value) {
        let totalPoints = responseQualified.value.reduce((total, num) => total + num.points, 0);
        let totalDefault = responseQualified.value.reduce((total, num) => total + parseInt(num.value), 0);
        let totalPercente = (totalPoints / totalDefault) * 100
        let temperature = 0
        if (totalPercente >= 75) {
            temperature = 3
            menssage.value = "Pontuação: " + totalPercente + "%."
        }
        else if ((totalPercente < 75) && (totalPercente >= 50)) {
            menssage.value = "Pontuação: " + totalPercente + "%."
            temperature = 2
        }
        else {
            menssage.value = "Pontuação: " + totalPercente + "%."
            temperature = 1
        }
        calculate.value = true
        payloadSave.value = {
            status: 'qualificated',
            points: totalPoints,
            type:'product',
            temperature: temperature,
            answers: responseQualified.value,
            company_id: userStore._company_id ,
            product_id: productSelected.value.id,
            client_id: client_id.value,
            questionary_id: questionary.value,
            user_id: userStore._user_id
        }

    }
}
const saveReview = ()=>{
  if(!productSelected.value.id){
    $swal.fire(
            'Ops',
            'Para salvar a avaliação você precisa indicar o produto',
            'sucess'
        )
  }
  else{
  payloadSave.value.msg = `Nova avaliação para o produto ${productSelected.value.product} efetuada.`
  const url = process.env.VUE_APP_API_URL + "review"

const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
};
 axios.post(url, payloadSave.value, config).then(() => {
    emit('navItem', 'Avaliações');
    $swal.fire(
            'Perfeito',
            'Produto Avaliado com sucesso',
            'sucess'
        )
})
}
}
</script>

<style scoped>
    
</style>
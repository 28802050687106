<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>
            <SidebarMenu></SidebarMenu>
        </div>
        <div class="flex flex-col w-full">
            <NavBar :section="'Qualificação de Leads'" :head="[]"></NavBar>
        <div v-if="!wait" class="flex flex-col items-start ml-4">
            <div class="flex flex-row items-center">
                
                <div v-if="loading"
                class="flex flex-col justify-start mt-4 items-start bg-white  text-indigo-700 border-solid
                                                                                                                                                                                border-2  border-neutral-50 bg-white rounded-lg shadow-lg p-4 ">
                    <span class="ml-4 text-md  ">Nome: {{ prospectQualify.name }} </span>
                    <span class="ml-4 text-md  ">Data entrada: {{ formatDate(prospectQualify.created_at) }}</span>
                    <span class="ml-4 text-md  ">e-mail: {{ prospectQualify.email }}</span>
                    <span class="ml-4 text-md  ">Fone: {{ prospectQualify.phone }}</span>
                    <a href="https://api.whatsapp.com/send?phone=seunumerodetelefone&text=sua%20mensagem"
                        class=" text-green-600" target="_blank">
                        <span class=" text-xl cursor-pointer">
                            <font-awesome-icon icon="fa-brands fa-whatsapp" />

                        </span>
                    </a>
                </div>
                <div v-if="loading">
                    <div class="flex flex-row items-center  mb-2 content-end">
                        <p class=" text-gray-700 font-semibold font-sans w-72 mr-1">Selecione o Produto: </p>
                        <select v-model="product"
                            class="mt-1  w-full rounded-md border border-gray-300 bg-white text-black
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">

                            <option v-for="(item, index) in listProducts" :value="item" :key="index">
                                {{ item.product }}</option>
                        </select>
                    </div>
                    <div class="flex flex-row items-center  mb-2 content-end">
                        <p class=" text-gray-700 font-semibold font-sans w-72 mr-1">Selecione o questionário: </p>
                        <select v-model="questionary" @change="onChange"
                            class="mt-1  w-full rounded-md border border-gray-300 bg-white text-black
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">

                            <option v-for="(item, index) in listQuestionaries" :value="item" :key="index">
                                {{ item.title}}</option>
                        </select>
                    </div>
                    
                    <span class="text-sm font-sans ml-4 text-black italic">*Para iniciarmos o questionário é necessário a
                        escolha de um produto para o seu prospect</span>
                </div>
            </div>
            <div v-if="loading" class="flex flex-col justify-start items-start mt-4">
                <div v-for="(item, index) in listQuestions" :key="index" class="flex flex-col text-lg  items-start ">

                    <div v-if="index == control">
                        <FormResponse @sendQuestion="saveResponse" @back="back" :index="index"
                            :qualify="responseQualified[control]" :question="item">
                        </FormResponse>
                    </div>

                </div>

                <div v-if="calculate" class="flex flex-col  items-start justify-start">
                    <span class="flex justify-center text-lg text-lify_blue font-sans">{{ menssage }}</span>
                    <div class="flex flex-col   mb-2 ">
                        <p class=" text-gray-700 font-sans mr-1">Escolha o vendedor para encaminar este Lead: </p>
                        <select v-model="vendor"
                            class="mt-1  w-full rounded-md border border-gray-300 bg-white text-black
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">

                            <option v-for="(item, index) in listUsers" :value="item" :key="index">
                                {{ item.name }}</option>
                        </select>
                    </div>
                    <ButtonLify @actionClick="saveLead" :title="'Encerrar qualificação'"></ButtonLify>
                    
                </div>
            </div>
        </div>
        <LoadingSpinner class="flex justify-center items-center h-screen" v-if="wait"></LoadingSpinner>
        </div>
    </div>
</template>
  
<script setup>

import FormResponse from '@/components/FormResponse.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import ButtonLify from '@/components/default/ButtonLify.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { inject, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const userStore = userDefineStore()
const route = useRoute()
const router = useRouter()
const $swal = inject('$swal');
const totalQuestions = ref(0);
const listQuestions = ref([]);
const responseQualified = ref([]);
const listProducts = ref([])
const listUsers = ref([])
const payloadSave = ref([])
const listQuestionaries = ref([])
const vendor = ref()
const leadCreated = ref()
const control = ref(0)
const qualification_id = ref(false)
const loading = ref(false)
const wait = ref(false)
const questionary = ref(false)
const calculate = ref(false)
const menssage = ref();
const product = ref();
const prospectQualify = ref()
//const router = useRouter()
onMounted(() => {
    getProspect()
    getProducts();
    getQuestionaries();
    getUsers();
})
const getProspect = () => {

    const url = process.env.VUE_APP_API_URL + "prospect/" + route.params.prospect_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
        prospectQualify.value = response.data
        loading.value = true
    })
}
const getProducts = () => {
    const url = process.env.VUE_APP_API_URL + "products/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
        listProducts.value = response.data
    })
}
const getUsers = () => {
    const url = process.env.VUE_APP_API_URL + "users/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listUsers.value = response.data
    })
}
const getQuestionaries = () => {
    const url = process.env.VUE_APP_API_URL + "questionaries/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listQuestionaries.value = response.data
    })
}

const back = () => {
    control.value--;
}
const saveResponse = (event) => {
    responseQualified.value[control.value] = event
    control.value++;
    if (control.value == totalQuestions.value) {
        let totalPoints = responseQualified.value.reduce((total, num) => total + num.points, 0);
        let totalDefault = responseQualified.value.reduce((total, num) => total + parseInt(num.value), 0);
        let totalPercente = (totalPoints / totalDefault) * 100
        let temperature = 0
        if (totalPercente >= 75) {
            temperature = 3
            menssage.value = "Pontuação: " + totalPercente + "%."
        }
        else if ((totalPercente < 75) && (totalPercente >= 50)) {
            menssage.value = "Pontuação: " + totalPercente + "%."
            temperature = 2
        }
        else {
            menssage.value = "Pontuação: " + totalPercente + "%."
            temperature = 1
        }
        calculate.value = true
        payloadSave.value = {
            status: 'qualificated',
            temperature: temperature,
            points: totalPoints,
            answers: responseQualified.value,
            company_id: prospectQualify.value.company_id,
            lead_id: null,
            product_id: product.value.id,
            prospect_id: prospectQualify.value.id,
            user_id: userStore._user_id
        }

    }
}

const onChange = () => {
    if( questionary.value){
        wait.value =true

        const url1 = process.env.VUE_APP_API_URL + "questionary/" + questionary.value.id
        const config1 = {
            headers: { Authorization: `Bearer ${userStore._api_token}` }
        };
        axios.get(url1, config1).then((response) => {
            if(response.data.type == 'Lead'){
              const url = process.env.VUE_APP_API_URL + "questionsQuestionary/" + userStore._company_id + '/' + response.data.id
                const config = {
                    headers: { Authorization: `Bearer ${userStore._api_token}` }
                    };
                axios.get(url, config).then((response) => {
                totalQuestions.value = Object.keys(response.data).length
                listQuestions.value = response.data
                wait.value =false
                if(totalQuestions.value == 0){
                    $swal.fire(
                '😖',
                'Precisamos que você insira perguntas a este questionário para prosseguir com a qualificação, na opção Questionários/Perguntas.',
                'warning'
            )
                }
               
    })
            }
         

         else{
            $swal.fire(
                'Este questionário não é do tipo qualificação de Leads!',
                'Selecione um questionário para esta finalidde, caso ainda não possua cadastre no item questionários',
                'warning'
            )
            wait.value =false
        }
        })
    
    
}
    else{
        wait.value =false
        $swal.fire(
            'Ops!',
            'Não encontramos um questionário para qualificação de Lead, verfique seus questionários no menu questionário.',
            'warning'
        )
    }
}
const saveQualification = (lead) => {
    payloadSave.value.lead_id = lead.id
    payloadSave.value.msg = 'Nova Qualificação efetuada para o Lead '+lead.name
    payloadSave.value.typeNotification = 'Lead'

    const url = process.env.VUE_APP_API_URL + "qualification"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payloadSave.value, config).then((response) => {
        
        qualification_id.value = response.data.id
        updateProspect()
    })
}
const saveLead = async ()=>{
    wait.value = true
    const payload = {
        status: 'active',
        name: prospectQualify.value.name,
        email: prospectQualify.value.email,
        phone: prospectQualify.value.phone,
        user_id: userStore._user_id,
        company_id: userStore._company_id
    }
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    const url2 = process.env.VUE_APP_API_URL + "showLead/"+userStore._company_id+"/"+prospectQualify.value.email
    await axios.get(url2,  config).then((response) => {
      
        if(response.data[0] != undefined){
            leadCreated.value = response.data[0]
            saveQualification(response.data[0])
        }
        else{
            const url = process.env.VUE_APP_API_URL + "lead"
            axios.post(url, payload, config).then((response) => {
            
                leadCreated.value = response.data.lead
                saveQualification(response.data.lead)
    })
        }
    })

    
}
const updateProspect = async ()=>{
    const payload = {
        name: prospectQualify.value.name,
        email: prospectQualify.value.email,
        phone: prospectQualify.value.phone,
        fonte: prospectQualify.value.fonte,
        status: 'lead',
        qualificated: true,
        user_id: userStore._user_id,
    }
    const url = process.env.VUE_APP_API_URL + "prospect/"+prospectQualify.value.id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
   await axios.put(url, payload, config).then(() => {
        createOpportunity()
    })
}
const createOpportunity = async()=>{
    const msgComent = 'Oportunidade criada por '+userStore._user_name+ ' e encaminhada para '+vendor.value.name
    const msgChat = `Novo chat para oportunidade do Lead ${prospectQualify.value.name}.`
    const payload = {
        user_id: userStore._user_id,
        seller_id: vendor.value.id,
        lead_id: leadCreated.value.id,
        value_opportunity: product.value.value,
        product_id: product.value.id,
        msgComent: 'Oportunidade criada por '+userStore._user_name+ ' e encaminhada para '+vendor.value.name,
        msgChat:`Novo chat para oportunidade do Lead ${prospectQualify.value.name}.`,
        qualification_id: qualification_id.value,
        company_id: userStore._company_id
    }
    const url = process.env.VUE_APP_API_URL + "opportunity"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    await axios.post(url, payload, config).then((response) => {
        createChat(response.data.opportunity.id,msgChat,msgComent)
    })
}
const createChat = async(opportunity_id,msgChat,msgComent)=>{
    const payload = {
        description: msgChat,
       
        user_id: userStore._user_id,
        opportunity_id: opportunity_id,
        company_id: userStore._company_id,
    }
    const url = process.env.VUE_APP_API_URL + "chat"

    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    await axios.post(url, payload, config).then((response) => {

        createComment(response.data.id,msgComent)
        
    })
}
const createComment = (chat_id, comment) => {
    let payload = {
        description: comment,
        status: 'active' ,
        chat_id: chat_id,
        user_id: userStore._user_id,
        company_id:   userStore._company_id,
    }
    const url = process.env.VUE_APP_API_URL + "comment"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        $swal.fire(
        'Oportunidade criada com sucesso! 🖖',
        'Não esqueça de qualificar sua oportunidade e boas Vendas!!',
        'success'
    )
    router.push('/sdr');
    })
}
 
function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}
</script>
  
<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>
            <SidebarMenu></SidebarMenu>
        </div>
        <div class="flex flex-col w-full">
            <NavBar :section="'Clientes'"
             :client="true" 
             :head="['Novo']" 
             @navItem="navItem"
              @sendClient="receivedClient" @refresh="refresh" :key="keystamp"></NavBar>     
                <HomeClient :client="client" :key="keystamp" @refresh="refresh"/>   
        </div>

        <ModalItem v-if="modalNewClient" @refresh="closeNewClient">
            <FormClient @close="closeNewClient"></FormClient>
        </ModalItem>
        <ModalItemSm v-if="modal" @refresh="close">
            <div class="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
                <span class="ml-4">Faça o upload de sua planilha CSV</span>
            </div>
            <span class="text-start">Para o upload ser efetuado com sucesso, sua planilha precisa estar no formato CSV separado com vírgula</span>
            <div class="flex flex-col">
                <div class="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
                    <span class="ml-4">Atende para o cabeçalho de sua planilha</span>
                </div>
                <span class="border-black">Nome</span>
                <span class="border-black">CNPJ</span>
                <span class="border-black">Executivo Responsável</span>
                <span class="border-black">Segmento</span>
                <span class="border-black">CEP (formato 99.999.-999)</span>
                <span class="border-black">Logradouro</span>
                <span class="border-black">Número</span>
                <span class="border-black">Complemento</span>
                <span class="border-black">Bairro</span>
                <span class="border-black">Localidade</span>
                <span class="border-black">Estado</span>
                </div>
                <input type="file" ref="fileInput" class="form-control" @change="loadCSV">
        </ModalItemSm>
    </div>
</template>

<script setup>


import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import ModalItemSm from '@/components/ModalItemSm.vue'
import HomeClient from '@/components/HomeClient.vue'
import ModalItem from '@/components/ModalItem.vue';
import FormClient from '@/components/FormClient.vue';
import { ref } from 'vue'
const modal = ref(false)

const modalNewClient= ref(false)
const navItem = (item) => {
  if(item == 'Novo'){
    modalNewClient.value = true
   
  }
  
}
const closeNewClient = () => {
  
  modalNewClient.value = false
  keystamp.value = new Date().getTime();
 
}
const close = ()=>{
    modal.value = false
}

const client = ref(false)

const keystamp = ref(0)

const refresh = ()=>{
    keystamp.value = new Date().getTime();
}
const loadCSV = (ev)=> {
       const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) =>{
        
        var lines=e.target.result.split("\n");
        var result = [];
        var headers=lines[0].split(";");

  for(var i=1;i<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(";");

      for(var j=0;j<headers.length;j++){
        if(j == headers.length -1){
            obj[headers[j]] = currentline[j].replace(/(\r\n|\n|\r)/gm, "");
        }
        else{
            obj[headers[j]] = currentline[j];
        }
      }

      result.push(obj);

  }
  console.log(result)
      //var dados = JSON.stringify(result)
      } 

      reader.readAsText(file);
        }
const receivedClient = (item)=>{
    client.value = item
    keystamp.value = new Date().getTime();
   
}



</script>

<style scoped></style>
<template>
  <div class="flex flex-row bg-neutral-100">
    <div class="w-1/6">
      <SidebarMenu></SidebarMenu>
    </div>
    <div class="flex flex-col w-full" v-if="settings">
      <NavBar :section="'Funil'" :head="[]"></NavBar>
      <div class="flex flex-row">
      <div class="grid grid-cols-4 gap-2 ml-2 mr-2 w-full" >
      <div v-for="(item, index) in listOpportunities" :key="index">
        
        <div class="grid col-span-1 w-full content-start" v-if="item.phase < 5">
          <div class="w-full">
            <CardFunil class="mb-2" :etapa="item.namePhase" :qtd="item.qtd" :value="item.totalR"
            :color="colors[item.phase - 1]">
          </CardFunil>
        </div>
          <draggable :list="item.opportunities" :item-key="'lista'+item.phase" class="list-group" @change="onDrop($event, item.phase)" ghost-class="ghost"
              group="funil" @start="dragging = true" @end="drag=false">
              <template #item="{ element }" >
                <card v-if="item.phase != '4' " :element="element" @openComment="openComment" @openModalvalue="openModalvalue" @openModal="openModalContact" @openModalSchedule="openModalSchedule"></card>          
                <CardClosed v-else :element="element" @encerrar="encerrar" @openModal="openModalContact" @openModalSchedule="openModalSchedule"></CardClosed>
          
     
              </template>
            </draggable>
        </div>                
      </div>                
      </div>                
     
      </div>      
    </div>
    <ModalItem v-if="showModal" @refresh="closeModal">
      <ShowContacts :client_id="client_id"></ShowContacts>
    </ModalItem>
    <ModalItemSm v-if="showModalSchedule" @refresh="closeModal" :title="'Agendamento'">
      <FormSchedule :opportunity_id="opportunity_id" @close="closeModal"></FormSchedule>
    </ModalItemSm>
    <SmartAlert v-if="showModalValue" @close="closeModal" @save="saveValue" @sendCancel="closeModal" 
    :btn-cancel="true"
    :btn-save="true"
    :title="'Deseja alterar o valor de sua proposta?'">
      <span>O valor atual da proposta é: R$ {{ opportunity.value_opportunity }}</span>
      <input @input="maskReal" type="text" class="w-full rounded-mdborder-gray-300 shadow-sm px-6 border 
      border-gray-300 border-indigo-500 ring-indigo-500 h-8 sm:text-sm"
       v-model="new_value_opportunity">
    </SmartAlert>
    <ModalItem v-if="modalNewClient" @refresh="closeClient">
            <FormClient @close="closeClient"></FormClient>
        </ModalItem>
  </div>
</template>

<script setup>
import CardClosed from '@/components/CardClosed.vue'
import CardFunil from '@/components/CardFunil.vue'
import card from '@/components/CardLead.vue'
import FormSchedule from '@/components/FormSchedule.vue'
import ModalItem from '@/components/ModalItem.vue'
import ModalItemSm from '@/components/ModalItemSm.vue'
import NavBar from '@/components/NavBar.vue'
import ShowContacts from '@/components/ShowContacts.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import { userDefineStore } from '@/store/UserStore'
import FormClient from '@/components/FormClient.vue'
import axios from 'axios'
import { inject,  onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import draggable from 'vuedraggable'
import SmartAlert from '@/components/SmartAlert.vue'
const userStore = userDefineStore()
// eslint-disable-next-line
const router = useRouter()
const $swal = inject('$swal');
const colors = ['from-sky-300 to-blue-500','from-blue-500 to-fuchsia-500','from-fuchsia-500 to-yellow-500','from-yellow-500 to-red-500','from-green-50 to-green-500']
// eslint-disable-next-line
onMounted(() => {
  getPhases()
  getOpportunties();

});

const client_id = ref(true)
const new_value_opportunity = ref()
// eslint-disable-next-line
const opportunity = ref(false)
const opportunity_id = ref(false)
const modalNewClient = ref(false)
const dragging = ref(false);
const showModal = ref(false);
const waitClientId = ref(false);
const showModalSchedule = ref(false);
const showModalValue = ref(false);
// eslint-disable-next-line
const settings = ref(false)
const state = ref(false)
const listOpportunities = ref([])
const listPhases = ref([])

const openModalContact = (id) => {

  client_id.value = id;
  showModal.value = true;
}
const closeClient = (client)=>{
  waitClientId.value.element.client_id = client
  console.log(waitClientId.value)
  updatePhase( waitClientId.value.element, waitClientId.value.list, waitClientId.value.chat, waitClientId.value.comment)
  modalNewClient.value = false
}
const closeModal = () => {
  showModal.value = false;
  showModalSchedule.value = false;
  showModalValue.value = false;
}
const encerrar = (dados)=>{
 dados.element.status=dados.status
 dados.element.coment2 = 'Venda encerrada como '+dados.status
console.log(dados)
 
 if((dados.element.lead_id)&&(!dados.element.client_id)){
    $swal.fire({
  title: 'Oportunidade encerrada.',
  text: "Deseja cadastrar este lead como cliente?",
  inputAttributes: {
    autocapitalize: 'off'
  },
  showCancelButton: true,
  confirmButtonText: 'Sim',
  cancelButtonText: 'Não',
  showLoaderOnConfirm: true,
  
}).then((result) => {
          if (result.isConfirmed) {
            waitClientId.value = {
              element:dados.element,
              list:dados.list,
              coment1:dados.comment,
              coment2:dados.coment2,
            }
            modalNewClient.value = true
   }
   else{
  updatePhase(dados.element,dados.list,dados.comment,dados.comment2)
  $swal.fire(
            'Ok!',
            'Oportunidade encerrada.',
            'success'
        )
 }
  
})
 }
 else{
  updatePhase(dados.element,dados.list,dados.comment,dados.comment2)
  $swal.fire(
            'Ok!',
            'Oportunidade encerrada.',
            'success'
        )
 }
    

}
const checkPhase = (order)=>{
  listPhases.value.map((element) => {
    if(element.order == order){
      state.value = element.phase
    }
  });
  return state.value
}
const getPhases = () => {
  let url = process.env.VUE_APP_API_URL + "phasesFunnels/" + userStore._company_id
  
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };

  axios.get(url, config).then((response) => {

    listPhases.value = response.data
    
  });
}
const saveValue = ()=>{
  if(new_value_opportunity.value != opportunity.value.value_opportunity){
    new_value_opportunity.value = new_value_opportunity.value.replace('.','')
    new_value_opportunity.value = new_value_opportunity.value.replace(',','.')

    const url = process.env.VUE_APP_API_URL + "opportunity/" +  opportunity.value.opportunity_id
    opportunity.value.value_opportunity = new_value_opportunity.value
    const config = {
      headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, opportunity.value, config).catch(function () {
    }).then(() => {
      getPhases()
      getOpportunties();
      $swal.fire(
        'Ok!',
        'Atualizamos o valor de sua proposta',
        'success'
    )
    })
    showModalValue.value = false
    new_value_opportunity.value = 0
  }
  
}
const openModalvalue = (item)=>{
  opportunity.value = item
  showModalValue.value = true
  
}
const getOpportunties = () => {
  let url = process.env.VUE_APP_API_URL;
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  if (userStore._user_type == 'Gerente') {

    url += "funil/" + userStore._company_id
    axios.get(url, config).then((response) => {

listOpportunities.value   = response.data;
settings.value = true

});
  }
  else {
    url += "funilUser" 
       const payload={
            company_id : userStore._company_id,
            user_id: userStore._user_id
        }
        axios.post(url,payload, config).catch(function () {
        }).then((response) => {
            listOpportunities.value   = response.data;
            settings.value = true
        })
  }
  

 
}
const openComment = (opportunity)=>{

  $swal.fire({
  title: 'Qual o seu comentário?',
  input: 'text',
  inputAttributes: {
    autocapitalize: 'off'
  },
  showCancelButton: true,
  confirmButtonText: 'Salvar',
  cancelButtonText: 'Não',
  showLoaderOnConfirm: true,
  preConfirm: (chat) => {
    return chat
  },
  allowOutsideClick: () =>  getOpportunties(),
}).then((result) => {
  let url = process.env.VUE_APP_API_URL + "chatOpportunity/" + opportunity.opportunity_id 
  
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  axios.get(url, config).then((response) => {
    saveComment(response.data[0].id,result.value,)
    getOpportunties();
  });
})
}
const onDrop = (evt, list) => {
  if(evt.added){
  if( list < evt.added.element.phase){
    $swal.fire({
  title: 'Para retornar a uma fase anterior você precisa inserir um comentário com o motivo',
  input: 'text',
  inputAttributes: {
    autocapitalize: 'off'
  },
  showCancelButton: true,
  confirmButtonText: 'Salvar',
  cancelButtonText: 'Não',
  showLoaderOnConfirm: true,
  preConfirm: (chat) => {
    return chat
  },
  allowOutsideClick: () =>  getOpportunties(),
}).then((result) => {
          if (result.isConfirmed) {
      
    let comment = `${userStore._user_name} retornou a oportunidade(${evt.added.element.name_opportunity})  para ${checkPhase(list)}, com o comentário: ${result.value}`
    updatePhase(evt.added.element, list,false, comment, state.value)
  }
  else{
    $swal.fire(
            'Movimentação não realizada!',
            'Você precisa inserir um comentário para movimentar uma oportunidade para uma fase anterior do funil.',
            'warning'
        )
  }
});
  }
  else{
  if (evt.added) {
    console.log(opportunity.value)
  $swal.fire({
  title: 'Deseja inserir um comentário a movimentação da oportunidade?',
  input: 'text',
  inputAttributes: {
    autocapitalize: 'off'
  },
  showCancelButton: true,
  confirmButtonText: 'Salvar',
  cancelButtonText: 'Não',
  showLoaderOnConfirm: true,
  preConfirm: (chat) => {
    return chat
  },
  allowOutsideClick: () =>  getOpportunties(),
}).then((result) => {


  let comment = `${userStore._user_name} movimentou a oportunidade(${evt.added.element.name_opportunity}) para ${checkPhase(list)}.`
  if (result.isConfirmed) {
    console.log(evt.added.element)
    updatePhase(evt.added.element, list,comment, result.value)
    
  }
  else{
    
    updatePhase(evt.added.element, list, comment,false)
  }
})
 
  }
}
  }
}
const updatePhase = (element,list,comment1, comment2)=>{

  const url = process.env.VUE_APP_API_URL + "opportunity/" + element.opportunity_id
    const payload = {
      status: element.status,
      action: 'movimentou',
      phase: list,
      user_id: element.user_id,
      seller_id: element.seller_id,
      value_opportunity: element.value_opportunity,
      product_id: element.product_id,
      qualification_id:element.qualification_id
    }
    const config = {
      headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, payload, config).catch(function () {
    }).then(() => {
      const url2 = process.env.VUE_APP_API_URL + "chatOpportunity/" + element.opportunity_id 
          axios.get(url2, config).then(function (result) {
            saveComment(result.data[0].id,comment1)
           // let msg = userStore._user_name + ' movimentou a oportunidade para '+checkPhase(list)+'.'
           if(comment2){
             saveComment(result.data[0].id, comment2 );
           } 
            getOpportunties();
          })
        
      
    })
}

const saveComment = (chat_id, comment) => {
    let payload = {
        description: comment,
        status: 'active' ,
        chat_id: chat_id,
        user_id: userStore._user_id,
        company_id:   userStore._company_id,
    }
    const url = process.env.VUE_APP_API_URL + "comment"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        
    })
}

const openModalSchedule = (item)=>{

  opportunity_id.value = item.opportunity_id
  showModalSchedule.value = true
}

const maskReal = () => {
  //new_value_opportunity.value = new_value_opportunity.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
   // phone.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
   new_value_opportunity.value = new_value_opportunity.value.replace(/\D/g, ""); // Remove todos os não dígitos
   new_value_opportunity.value = new_value_opportunity.value.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
   new_value_opportunity.value = new_value_opportunity.value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
	//valorAlterado = "R$" + valorAlterado;

   //new_value_opportunity.value =  parseFloat(new_value_opportunity.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
 
}
</script>


<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1d1d1d;
}
</style>
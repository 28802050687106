<template>
  
    <div class="grid grid-cols-1">
      <div class="gcol-span-full bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
          <span class="ml-4">Cadastro da Empresa</span>
        </div>
        <div class="border-b border-gray-900/10 ml-4">
      <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
        <div class="sm:col-span-3">
        </div>
        
        <div class="col-span-full" v-if="showCpf">
          <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Nome</label>
          <div class="mt-2">
            <input type="text" v-model="name" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="col-span-full" v-if="!showCpf">
          <div class="block text-sm font-medium leading-6">
            <span class="text-red-500">*</span>
            <label for="street-address" class="text-gray-900">Nome da Empresa</label>
          </div>
          <div class="mt-2">
            <input type="text" v-model="name" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
       

       

        <div class="sm:col-span-3 sm:col-start-1" v-if="showCpf">
          <div class="block text-sm font-medium leading-6">
            <span class="text-red-500">*</span>
            <label for="street-address" class="text-gray-900">CPF</label>
          </div>
          <div class="mt-2 text-center">
            <input type="text" v-model="cpf"  @input="maskCpf"  
            class=" p-2 w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="sm:col-span-3 sm:col-start-1" v-if="!showCpf">
          <div class="block text-sm font-medium leading-6">
            <span class="text-red-500">*</span>
            <label for="street-address" class="text-gray-900">CNPJ</label>
          </div>
          <div class="mt-2 text-center">
            <input type="text" v-model="cnpj"  @input="maskCnpj"  
            class=" p-2 w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="block text-sm font-medium leading-6">
            <span class="text-red-500">*</span>
            <label for="street-address" class="text-gray-900">Executivo Responsável</label>
          </div>
         
          <div class="mt-2 mr-6">
            
          <BuscaUser @sendUser="receivedUser" :title="''"></BuscaUser> 
       
        
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="block text-sm font-medium leading-6">
            <span class="text-red-500">*</span>
            <label for="street-address" class="text-gray-900">Segmento</label>
          </div>
          <div class="mt-2">
            <div>
              {{ segment }}
              <select v-model="segment" 
                    class="mt-1 rounded-md border w-60 border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                <option v-for="(item, index) in listSegments" :value="item.id" :key="index">
                                    {{ item.segment }}
                                </option>
            </select>
        </div>

          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="city" class="block text-sm font-medium leading-6 text-gray-900">CEP (somente números)</label>
          <div class="mt-2">
            <input type="text" v-model="cep" @keyup="searchCep" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div class="col-span-full">
          <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Logradouro</label>
          <div class="mt-2">
            <input type="text" v-model="logradouro"
            class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset
             ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        


        

        <div class="sm:col-span-2 sm:col-start-1">
          <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Número</label>
          <div class="mt-2">
            <input type="text" v-model="numero" @keyup="searchCep" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Complemento</label>
          <div class="mt-2">
            <input type="text" v-model="complemento" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Bairro</label>
          <div class="mt-2">
            <input type="text" v-model="bairro" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Localidade</label>
          <div class="mt-2">
            <input type="text" v-model="localidade" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">Estado</label>
          <div class="mt-2">
            <input type="text" v-model="uf"  class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
      </div>
    </div>
    <div class="gcol-span-full bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
      <span class="ml-4">Cadastro do contato principal</span>
    </div>
    <div class="border-b border-gray-900/10 pb-12 ml-4 mr-4">
      <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
        <div class="sm:col-span-3">
        </div>
        <div class="col-span-full" v-if="!showCpf">
          <div class="block text-sm font-medium leading-6">
            <span class="text-red-500">*</span>
            <label for="street-address" class="text-gray-900">Nome do Contato Principal</label>
          </div>
         <div class="mt-2">
            <input type="text" v-model="name_contact" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="col-span-full" >
          <div class="block text-sm font-medium leading-6">
            <span class="text-red-500">*</span>
            <label for="street-address" class="text-gray-900">e-mail</label>
          </div>
          <div class="mt-2">
            <input type="text" v-model="email" class="block w-full rounded-md border-0
             p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
             focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        

        <div class="sm:col-span-3 sm:col-start-1">
          <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Fone</label>
          <div class="mt-2">
            <input type="text" v-model="phone" @input="maskPhone" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Cargo</label>
          <div class="mt-2">
            <input type="text" v-model="office" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="sm:col-span-3 sm:col-start-1" v-if="showCpf">
          <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Data Nascimento</label>
          <div class="mt-2">
            <input type="date" v-model="birth" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="sm:col-span-3" v-if="!showCpf">
          <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Data Nascimento</label>
          <div class="mt-2">
            <input type="date" v-model="birth" class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div>
            <div v-if="!update"
                    class="flex justify-center item-center pt-2 mt-4  text-base font-sm h-8 ml-2 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="saveContact">
                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
                <div v-if="update"
                    class="flex justify-center item-center pt-2 mt-4  text-base font-sm h-8 ml-2 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="updateContact">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
                <div v-if="update"
                    class="flex justify-center item-center pt-2 mt-4  text-base font-sm h-8 ml-2 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="closed">

                    <font-awesome-icon icon="fa-solid fa-rectangle-xmark" />
                </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { inject, onMounted, ref, watch } from 'vue'
import BuscaUser from './BuscaUser.vue'
  const user = userDefineStore()
// eslint-disable-next-line
const props = defineProps(['client_id', 'showCpf', 'updateClient'])
const $swal = inject('$swal');
// eslint-disable-next-line
const emit = defineEmits(['saved', 'updated','close']);


const email = ref('')
const phone = ref('')
const office = ref('')
const segment = ref('')
const cep = ref('')
const logradouro = ref('')
const numero = ref('')
const complemento = ref('')
const bairro = ref('')
const localidade = ref('')
const uf = ref('')
const owner = ref('')
const cpf = ref(null)
const cnpj = ref(null)
const update = ref(false)
const birth = ref('')
const name = ref('')
const listSegments = ref([])
const name_contact = ref('')
onMounted(() => {
  getSegments();
    if (props.updateClient) {
        update.value = true
        email.value = props.updateClient.email
        phone.value = props.updateClient.phone
        office.value = props.updateClient.office
        cpf.value = props.updateClient.cpf
        birth.value = props.updateClient.birth
        name.value = props.updateClient.name
        bairro.value = props.updateClient.bairro 
        cep.value = props.updateClient.cep 
        logradouro.value = props.updateClient.logradouro 
        localidade.value = props.updateClient.localidade 
        uf.value = props.updateClient.uf 
        numero.value = props.updateClient.numero
        complemento.value = props.updateClient.complemento 
    }

})
const receivedUser = (userReceived)=>{
owner.value = userReceived.id

}
const getSegments = () => {
    const url = process.env.VUE_APP_API_URL + "clientSegments/" + user._company_id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listSegments.value = response.data
    })
}
const maskCnpj = () => {
  var y = cnpj.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  cnpj.value = !y[2] ? y[1] : '' + y[1] + '.' + y[2] + (y[3] ? '.' + y[3] : '') + (y[4] ? '/' + y[4] : '') + (y[5] ? '-' + y[5] : '')
}
const searchCep = ()=> {
			if(cep.value.length == 8) {
				axios.get(`https://viacep.com.br/ws/${ cep.value }/json/`)
				.then((response)=>{
                    if(response.data.erro){
                        $swal.fire(
                            'Não encontrado',
                            'Verifique o cep novamente, não encontramos por este número',
                            'warning'
                        )                   
                    }
                    bairro.value = response.data.bairro 
                    cep.value = response.data.cep 
                    logradouro.value = response.data.logradouro 
                    localidade.value = response.data.localidade 
                    uf.value = response.data.uf 
                })
			
			}
		}
watch(
    () => props.updateClient,
    () => {
        email.value = props.updateClient.email
        phone.value = props.updateClient.phone
        office.value = props.updateClient.office
        cpf.value = props.updateClient.cpf
        birth.value = props.updateClient.birth
        owner.value = props.updateClient.owner
        name.value = props.updateClient.name
        bairro.value = props.updateClient.bairro 
        segment.value = props.client_segmentation_id
        cep.value = props.updateClient.cep 
        logradouro.value = props.updateClient.logradouro 
        localidade.value = props.updateClient.localidade 
        uf.value = props.updateClient.uf 
        numero.value = props.updateClient.numero
        complemento.value = props.updateClient.complemento 
    }
)

const maskCpf = () => {
    var x = cpf.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    cpf.value = !x[2] ? x[1] : '' + x[1] + '.' + x[2] + (x[3] ? '.' + x[3] : '') + (x[4] ? '-' + x[4] : '')

}
const maskPhone = () => {
    var x = phone.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    phone.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}
const updateContact = () => {
    const payload = { id: props.updateClient.id, name: name.value,owner: owner.value, birth: birth.value, 
      cpf: cpf.value, cnpj: cnpj.value,
        email: email.value,  bairro: bairro.value, cep: cep.value, logradouro:logradouro.value,
        localidade: localidade.value, uf: uf.value, numero:numero.value,
        complemento:complemento.value, office: office.value, phone: phone.value, 
        client_id: props.client_id, company_id: user._company_id, user_id: user._user_id,client_segmentation_id : segment.value  }
    emit('updated', payload);
    name.value = '';
    email.value = '';
    office.value = '';
    phone.value = '';
    owner.value = '';
    segment.value =''
    birth.value = '';
    cnpj.value= ''
    cpf.value = '';
    bairro.value = ''
    cep.value = ''
    logradouro.value = ''
    localidade.value = ''
    uf.value = '' 
    complemento.value = '' 
    numero.value = '' 
}
const closed = ()=>{
    emit('close');
}
const saveContact = () => {
  console.log(segment.value)
  let erro = ""
  if((segment.value == '')){
    erro+= 'segmento'
                        
  }
  if((name.value == '')){
    erro+= ', nome'
                        
  }
  if((email.value == '')){
    erro+= ', e-mail'
                        
  }
  if((owner.value == '')){
    erro+= ', executivo'
                        
  }
  console.log(props.showCpf)
  console.log(cpf.value)
  if((props.showCpf)&&(cpf.value == null)){
    erro+= ', cpf'
  }
  if((!props.showCpf)&&(cnpj.value == null)){
    erro+= ', cnpj'
  }
  
  if(erro == ''){
    
  
    const payload = { name: name.value, cpf: cpf.value,cnpj: cnpj.value, email: email.value, owner: owner.value,
        cep: cep.value, logradouro:logradouro.value,
        localidade: localidade.value, uf: uf.value, numero:numero.value,
        complemento:complemento.value, bairro: bairro.value, name_contact: name_contact.value,
        office: office.value, phone: phone.value, client_id: props.client_id, 
        company_id: user._company_id, user_id: user._user_id,client_segmentation_id:segment.value }
    emit('saved', payload);
    name.value = '';
    email.value = '';
    owner.value = ''
    office.value = '';
    phone.value = '';
    birth.value = '';
    cpf.value = '';
    bairro.value = ''
    cep.value = ''
    segment.value = ''
    logradouro.value = ''
    localidade.value = ''
    uf.value = '' 
    complemento.value = '' 
    numero.value = '' 
}
else{
  $swal.fire(
      'Ops',
      'Você precisa cadastrar os campos '+erro,
      'warning'
                        ) 
}
}
</script>

<style scoped></style>
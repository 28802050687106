<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>
            <SidebarAdmMenu></SidebarAdmMenu>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-row ml-2 ">
                <div class="flex flex-row">
                    <div class=" p-2 ml-auto text-xl font-sm h-8 text-indigo-500 rounded-md">
                        Cadastro de Planos
                    </div>
                </div>
            </div>

            <div v-if="!update" class="flex flex-row items-center ml-8 mt-8">
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Descrição</label>
                    <input type="text" v-model="description"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Valor</label>
                    <input type="text" v-model="value"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="saveList">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
            </div>
            <div v-if="update" class="flex flex-row items-center ml-8 mt-8">
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Valor</label>
                    <input type="text" v-model="value"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Descrição</label>
                    <input type="text" v-model="description"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                <div class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="updatePlans">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
            </div>

            <div class="flex flex-col ml-4 mt-4">
                <div class="grid grid-cols-10 bg-white justify-items-center w-full">
                    <div class="bg-white col-span-6 text-gray-400 font-sm  px-4 py-2">Descrição</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tl px-4 py-2">Valor</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tr px-4 py-2">Ação</div>
                </div>
                <div v-for="(line, index) in listPlans" :key="index"
                    class="grid grid-cols-10 bg-white py-3 px-3 hover:bg-neutral-50 justify-items-center">
                    <div class="col-span-6">{{ line.description }}</div>
                    <div class="col-span-2">{{ line.value }}</div>
                    <div class="col-span-2">
                        <button class="text-indigo-500" @click="editPlans(line)"> <font-awesome-icon
                                icon="fa-solid fa-edit" /></button>
                        <button class="text-red-400 ml-4" @click="deletePlans(line.id)"> <font-awesome-icon
                                icon="fa-solid fa-trash" /></button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import SidebarAdmMenu from '@/components/admin/SidebarAdmMenu.vue';
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios';
import { onMounted, ref } from 'vue';
const userStore = userDefineStore()
//import { useRouter } from 'vue-router';
//const router = useRouter()
const listPlans = ref([])
const value = ref()
const description = ref()
const update = ref(false)
const idUpdate = ref()

onMounted(() => {
    getPlans();

})
const editPlans = (item) => {
    update.value = true
    value.value = item.value
    description.value = item.description
    idUpdate.value = item.id
}
const updatePlans = () => {
    const url = process.env.VUE_APP_API_URL + "plan/" + idUpdate.value
    let payload = { 'value': value.value, 'description': description.value, }
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, payload, config).catch(function () {

        //router.push('/');
    }).then(() => {
        value.value = ''
        description.value = ''
        idUpdate.value = ''
        getPlans()
    
    })
}
const deletePlans = (plan) => {
    const url = process.env.VUE_APP_API_URL + "plan/" + plan
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.delete(url, config).catch(function () {

        //router.push('/');
    }).then(() => {
        getPlans()
  
    })
}
const getPlans = () => {
    const url = process.env.VUE_APP_API_URL + "plans"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {

        //   router.push('/');
    }).then((response) => {
        listPlans.value = response.data
    
    })
}
const saveList = () => {

    // listPlans.value.push({ 'categorie': categorie.value, 'description': description.value })
    let payload = { 'value': value.value, 'description': description.value }
    const url = process.env.VUE_APP_API_URL + "plan"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config)
    value.value = ''
    description.value = ''
    getPlans();
}
</script>

<style scoped></style>
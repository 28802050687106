<template>
  <div>
    <span class="flex text-lg text-start text-lify_blue">{{ title }}</span>
      <div class="grid grid-cols-6 mt-2 ml-4 mr-4">
          <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Título</span>
          <span class="grid col-span-2 text-lg text-white border-2 border-white bg-lify_blue">Descrição</span>
          <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">tipo</span>
          <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Perguntas</span>
          <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Ação$</span>
      </div>
    
    <div class="grid grid-rows-1 mt-2  border-lify_blue hover:bg-slate-200" v-for="(line, index) in listQuestionaries" :key="index">
        <div class="grid grid-cols-6 mt-2 border-indigo-300">
            <span class="grid col-span-1 text-base   text-black" >{{line.title}}</span>
            <span class="grid col-span-2 text-base   text-black" >{{line.description}}</span>
            <span class="grid col-span-1 text-base   text-black" >{{line.type}}</span>
            <span class="grid col-span-1 text-base   text-black" >{{line.questions_count}}</span>
            <button class="col-span- text-red-400 ml-4" @click="deleteQuestionarie(line.id)"> 
                    <font-awesome-icon icon="fa-solid fa-trash" />
            </button>
        </div>
    </div>
  </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { inject, onMounted, ref } from 'vue'
  const $swal = inject('$swal');
  // eslint-disable-next-line 
  const props = defineProps(['title'])
  const userStore = userDefineStore()
const listQuestionaries = ref(false)
onMounted(()=>{
    getQuestionaries()
})

const getQuestionaries = ()=>{
    const url = process.env.VUE_APP_API_URL + "listQuestionaries/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
   
        listQuestionaries.value = response.data
    })
}
const deleteQuestionarie = (id)=>{

    $swal.fire({
  title: 'atenção'+id,
  text: 'Ao deletar este questionário você irá deletar todas as perguntas a ele atreladas',
  showCancelButton: true,
  confirmButtonText: 'Deletar',
  cancelButtonText: 'Cancelar',
  icon:'warning',
}).then((result) => {
    if (result.isConfirmed) {
        $swal.fire(
            'Questionário deletado.',
            '',
            'success'
        )
  }
    })
}
</script>

<style scoped>
    
</style>
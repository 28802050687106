<template>
  <div>
    <div class="flex flex-row"  v-if="userStore._user_type == 'Gerente'">
      <BuscaUser class="ml-4 " @sendUser="receivedUser" :title="'Busca Vendedor'"></BuscaUser>       
      <CardDates  @sendDates="receivedDates"></CardDates>
    </div>
    <CardDates v-else @sendDates="receivedDates"></CardDates>
    <div v-if="load" class="flex flex-col ml-4 mt-4 mr-4">
      <TableReportVisits :list-report="list" :buttons-crud="true" @editReports="editReports" @deleteReports="deleteReport"></TableReportVisits>                            
      </div>
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { inject,  ref } from 'vue'
  import CardDates from './default/CardDates.vue';
  import BuscaUser from './BuscaUser.vue';
  import TableReportVisits from './TableReportVisits.vue';
  const userStore = userDefineStore()
  // eslint-disable-next-line
const emit = defineEmits(['onUpdate'])
const $swal = inject('$swal');
const list = ref([])
const dateStart = ref('')
const dateEnd = ref('')
const user = ref('')
const load = ref(false)

const receivedUser = (userReceived)=>{
  user.value = userReceived
}
const receivedDates = (date)=>{
  dateStart.value = date.dateStart
  dateEnd.value = date.dateEnd
  if(userStore._user_type == 'Gerente'){
    getReports()
  }
  else{
    getReportsUser()
  }
}
const getReports = ()=>{
    const url = process.env.VUE_APP_API_URL + "reportsUser/" + userStore._company_id + '/' +  dateStart.value + '/' + dateEnd.value + '/' + user.value.id
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  axios.get(url, config).then((response) => {
    console.log(response.data)
    list.value = response.data
    load.value = true
  })
}
const getReportsUser = ()=>{
    const url = process.env.VUE_APP_API_URL + "reportsUser/" + userStore._company_id + '/' +  dateStart.value + '/' + dateEnd.value + '/' + userStore._user_id
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  axios.get(url, config).then((response) => {

    list.value = response.data
    load.value = true
  })
}

const deleteReport = (item)=>{

    const url = process.env.VUE_APP_API_URL + "reportVisit/" + item
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.delete(url,  config)
    .then(() => {
        getReports()
        $swal.fire(
        '🤝',
        'Relatório deletado com sucesso.',
        'success'
    )
    });

}
const editReports = (item) => {

      emit('onUpdate',item)
  }

</script>
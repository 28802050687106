<template>
    <div v-if="onLoad" class="">
        <div class=" mt-2 mr-2 ml-2 flex  content-start  ">
            <div
                class="flex flex-col  
                bg-white hover:shadow-lg
                border-l-4 border-transparent  bg-gradient-to-r from-gray-50 to-gray-200 border border-indigo-400
                 rounded-lg shadow-md overflow-hidden ">
                <div class="flex flex-col mt-2">
                    <div class="flex flex-row justify-between">
                        <span class="text-slate-700 text-lg flex items-start ml-4">{{ opportunity.name_opportunity }}</span>
                        <div class="flex flex-row">
                            <button 
                            class="flex mr-2 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600 " 
                            @click="openChat">
                                <font-awesome-icon icon="fa-solid fa-comment" class="text-indigo-300 text-lg" />
                            </button>
                            

                        </div>
                    </div>
                    <hr class="flex w-128 ml-4 mt-2 mb-4 mr-2  items-center">
                    <div class="flex flex-row  justify-between">

                        <span class="flex ml-6 justify-start italic text-sm ">
                                Produto: {{ opportunity.product }}
                        </span>
                        <span class="flex mr-2 justify-start italic text-sm ">
                                {{ formatarReal(opportunity.product_value) }}
                        </span>
                       
                    </div>
                    <div class="flex flex-row mr-2 ml-6  justify-between">

                        
                        <span class="flex justify-start italic text-sm">
                            Vendedor: {{ opportunity.seller_name }}
                        </span>
                        <span class="flex  justify-start italic text-sm">
                            Nº {{ opportunity.number }}
                        </span>
                    </div>
                    <div class="flex flex-row w-full  justify-between">
                        <span class="flex justify-start italic text-sm ml-6">Data entrada: {{ formateDate(opportunity.created_at) }}</span>                   
                        <span class="flex justify-start italic text-sm mr-2">Fase funil: {{ opportunity.phase_name }}</span>                   
                    </div>
                </div>
             </div>
        </div>
      
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  import { opportunityDefineStore } from '@/store/OpportunityStore';
  const opportunityStore = opportunityDefineStore()
  const userStore = userDefineStore()
  import {  useRouter } from 'vue-router'
// eslint-disable-next-line
const props = defineProps(['opportunity'])

const listTasks = ref([])
const onLoad = ref(false)

const router = useRouter()

onMounted(() => {

getTasks()
})
// eslint-disable-next-line
const returnAvatar = (avatar)=>{
    return `${process.env.VUE_APP_BASE_URL}${avatar}`
}
const openChat = ()=>{
    opportunityStore.setId(props.opportunity.opportunity_id)
    console.log(opportunityStore.opportunity_id)
    router.push('/chat')
}
// eslint-disable-next-line
const getTasks = () => {

const url = process.env.VUE_APP_API_URL + "tasksOpportunities/" + props.opportunity.opportunity_id
const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
};
axios.get(url, config).catch(function () {
}).then((response) => {
    listTasks.value = response.data
    onLoad.value = true
  
   
});
}

const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const formatarReal = (valor)=>{
return parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
</script>
<template>
    <div v-if="loadTask">
     <div class="bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
        <span class="ml-4">Informações da Tarefa</span>
    </div>
    <div class="grid grid-cols-2">
        
       
        <div class="grid grid-cols-3 ">
            <div class="flex flex-row">
               

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Descrição da tarefa</label>
                </div>
                <span class=" grid col-span-2 ml-2 justify-items-start  h-8 p-2 text-gray-900 ">
                    {{ task.description }}
                </span>

                <div class="flex flex-row">
                <span class="text-red-500 items-start mt-4">*</span>
                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900 mt-4">
                Data de encerramento</label>
                </div>
                <span class=" grid col-span-2 ml-2 justify-items-start  h-8 p-2 text-gray-900 ">
                    {{ formateDate(task.data) }}
                </span>
            <label class="grid col-span-1 mt-4 justify-items-start text-sm font-normal text-gray-900">
                Resultado da tarefa</label>
            <input type="text" v-model="result"
                class=" grid col-span-2 justify-items-start ml-2 h-8 mt-4
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">                        
       
        </div>
        <div class="grid grid-cols-3 ml-2 mr-2">
            <div class="flex flex-row">
                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Tipo
                </label>
            </div>
            <span class=" grid col-span-2 ml-2 justify-items-start  h-8 p-2 text-gray-900 ">
                    {{ task.descriptionTask }}
            </span>
          
            <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                Envolvidos
            </label>
            <div v-for="(item, index) in involved" :key="index" class="flex flex-row">

                <span  class=" grid col-span-2 ml-2 justify-items-start  h-8 p-2 text-gray-900 " >
                    {{ item.name }}
                </span>
            </div>
                
        </div>
    </div>
    <div class="bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
        <span class="ml-4">Relacionamentos da Tarefa</span>
    </div>
    <div class="grid grid-cols-4">
        <div class="grid col-span-2 grid-cols-3" v-if="task.client_name">
            <span class="text-sm ml-2 col-span-1" >Cliente: </span>
            <span class="text-sm ml-2 col-span-2" >{{ task.client_name }}</span>
        </div>
        <div class="grid col-span-2 grid-cols-3" v-if="task.product">
            <span class="text-sm ml-2 col-span-1" >Produto: </span>
            <span class="text-sm ml-2 col-span-2" >{{ task.product }}</span>
        </div>
        <div class="grid col-span-2 grid-cols-3" v-if="task.opportunity_name">
            <span class="text-sm ml-2 col-span-1" >Oportunidade: </span>
            <span class="text-sm ml-2 col-span-2" >{{ task.opportunity_name }}</span>
        </div>
      
    </div>
    <div class="bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
        <span class="ml-4">Comentários da Tarefa</span>
    </div>
    <div >
    
        <div v-for="(commentario, index) in listComent" :key="index" class="flex items-start justify-start">
            <span  class="flex ml-6 italic items-start text-sm text-neutral-400">
                {{ commentario.name }} : {{ commentario.description }}
            </span>
        </div>
        <div  class="flex flex-col items-start mr-4">
            <label class=" mb-1 ml-2 text-sm font-medium text-gray-900 ">
                Novo comentário
            </label>
            <textarea type="text" v-model="comment"
                class="ml-4 mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
            </textarea>
        </div>
        <div  class="flex justify-end mt-2">
            <button @click="saveComment(task.id)" class="flex items-center justify-center w-6 h-6
              bg-indigo-500 text-indigo-50 rounded-md cursor-pointer">
                <font-awesome-icon icon="fa-solid fa-save" />
            </button>
        </div>
    </div>
</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
const user = userDefineStore()
//const $swal = inject('$swal');
// eslint-disable-next-line
const props = defineProps(['task'])
const listComent = ref()
const comment = ref()
const result = ref()
const loadTask = ref(false)
const involved = ref()


onMounted(()=>{

    getInvolveds()
    getComments()

})
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleDateString('pt-BR', options)
}

const getComments = () => {

    const url = process.env.VUE_APP_API_URL + "tasksComments/" + props.task.id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {

        listComent.value = response.data
        loadTask.value = true
    });

}
const getInvolveds = () => {

    const url = process.env.VUE_APP_API_URL + "tasksInvolveds/" + props.task.id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
  
        involved.value = response.data
        
    });

}

const saveComment = (tasks_id) => {
    let payload = {
        description: comment.value,
        status: 'active' ,
        chat_id: null,
        tasks_id: tasks_id,
        user_id: user._user_id,
        company_id:   user._company_id,
    }
    const url = process.env.VUE_APP_API_URL + "comment"
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        comment.value = "";
        getComments()
    })
}
</script>

<style scoped>

</style>
<template>
    <div>
        <div class="flex flex-col">
            <div  class="flex flex-row items-center ml-8 mt-8 mr-4">
                <div class="flex flex-col items-start ml-2">
                    <label class="text-sm ml-2 font-normal text-gray-900">
                        Nome da Fase
                    </label>
                    <input type="text" v-model="phase"
                        class=" ml-2 bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-sm focus:ring-blue-500
                          focus:border-blue-500 block w-full p-1 h-8">
                </div>
               
                <div class="flex flex-col items-start ml-2">
                    <label class="text-sm ml-2 font-normal text-gray-900">
                        Descrição
                    </label>
                    <input type="text" v-model="description"
                        class=" ml-2 bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-sm focus:ring-blue-500
                          focus:border-blue-500 block w-full p-1 h-8">
                </div>
                
                <div v-if="!update"
                class="flex justify-center item-center pt-2  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="saveList">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
                <div v-if="update" class="flex justify-center item-center pt-2  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="updatePhases">
                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
            </div>

            <div class="flex flex-col ml-4 mt-4">
                <div class="grid grid-cols-10 bg-white justify-items-center w-full">
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tl px-4 py-2">Fase</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tl px-4 py-2">Ordem</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tl px-4 py-2">Descrição</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tl px-4 py-2">Cor</div>
                    <div  v-if="userStore._user_type == 'Gerente'"  class="bg-white col-span-2 text-gray-400 font-sm rounded-tr px-4 py-2">Editar</div>
                </div>
                <div v-for="(line, index) in listPhases" :key="index"
                    class="grid grid-cols-10 bg-white py-3 px-3 hover:bg-neutral-50 justify-items-center">
                    <div class="col-span-2">{{ line.phase }}</div>
                    <div class="col-span-2">{{ line.order }}</div>
                    <div class="col-span-2">{{ line.description }}</div>                    
                    <div class="col-span-2  w-32 bg-gradient-to-r" :class="line.color"></div>                    
                    <div class="col-span-2">
                        <button v-if="userStore._user_type == 'Gerente'" class="text-indigo-500" @click="editPhases(line)"> <font-awesome-icon
                                icon="fa-solid fa-edit" /></button>
                        
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  const userStore = userDefineStore()

const listPhases = ref([])
const description = ref()
const phase = ref()
const order = ref()
const color = ref()
const update = ref(false)
const idUpdate = ref()

onMounted(() => {
    getPhases();

})
const editPhases = (item) => {
    update.value = true
    description.value = item.description
    order.value = item.order
    phase.value = item.phase
    color.value = item.color
    idUpdate.value = item.id
}
const updatePhases = () => {
    const url = process.env.VUE_APP_API_URL + "phasesFunnel/" + idUpdate.value
    let payload = { 'description': description.value,'order': order.value,'color': color.value,'phase': phase.value, company_id: userStore._company_id }
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
        description.value = ''
        idUpdate.value = ''
        getPhases()
    })
}
// eslint-disable-next-line
const deletePhases = (data) => {
    const url = process.env.VUE_APP_API_URL + "phasesFunnel/" + data
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.delete(url, config).then(() => {
        getPhases()
    })
}
const getPhases = () => {
    const url = process.env.VUE_APP_API_URL + "phasesFunnels/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listPhases.value = response.data
    })
}
const saveList = () => {
    let payload = { 'description': description.value,'order': order.value,'phase': phase.value, company_id: userStore._company_id }
    const url = process.env.VUE_APP_API_URL + "phasesFunnel"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config)
    description.value = ''
    order.value = ''
    phase.value = ''
    getPhases();
}
</script>
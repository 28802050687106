<template>
    <div class="flex flex-col overflow-auto">
        
        <div class="flex flex-col ml-4 mt-4 mr-4">
            <div class="grid grid-cols-10 bg-white justify-items-center w-auto mr-4">
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Data Abertura</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Cliente</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Produto</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Valor</div>
                <div class="bg-white col-span-2 text-gray-900 font-light  text-sm  px-2 py-2">Situação</div>
            </div>
            <div class="grid grid-cols-10 bg-white justify-items-center w-auto mr-4 hover:bg-slate-500 hover:text-white" 
             v-for="(line, index) in opportunities" :key="index">
                <div class=" col-span-2  font-light  text-xs  px-2 py-2  ">{{ formateDate(line.created_at) }}</div>
                <div class=" col-span-2  font-light  text-xs  px-2 py-2 ">{{ line.name_opportunity }}</div>
                <div class=" col-span-2  font-light  text-xs  px-2 py-2 ">{{ line.product }}</div>
                <div class=" col-span-2  font-light  text-xs  px-2 py-2 ">{{ formatarReal(line.value) }}</div>
                <div class=" col-span-2  font-light  text-xs  px-2 py-2 ">{{ line.status }}</div>
            </div>
        </div>

    </div>
</template>
<script setup>

import { ref, onMounted } from 'vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
const userStore = userDefineStore()

// eslint-disable-next-line
const props = defineProps(['user','dateStart','dateEnd'])

const opportunities = ref([])

onMounted(()=>{
    getOpportunity()
})
// eslint-disable-next-line
const emit = defineEmits(['sendQualify', 'openModal']);
const getOpportunity = ()=>{
    
    const url = process.env.VUE_APP_API_URL + "userOpportunitySearch/"  + props.user.id + '/' + props.dateStart + '/' + props.dateEnd
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {

        opportunities.value = response.data
    });
    
}

const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
</script>
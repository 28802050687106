<template>
    <div >
        <div class="flex flex-col">
            
            <div v-if="!update &&  userStore._user_type == 'Gerente'" class="flex flex-row items-center ml-8 mt-8 mr-4">
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Fonte</label>
                    <input type="text" v-model="description"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
                
                <div class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="saveList">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
            </div>
            <div v-if="update" class="flex flex-row items-center ml-8 mt-8">
                <div class="flex flex-col items-center ml-8 mt-4">
                    <label class="block mb-2 text-base font-medium text-gray-900 dark:text-white">
                        Fonte</label>
                    <input type="text" v-model="description"
                        class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                </div>
               
                <div class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="updateDatas">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
            </div>

            <div class="flex flex-col ml-4 mt-4">
                <div class="grid grid-cols-10 bg-white justify-items-center w-full">
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tl px-4 py-2">Fonte</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tr px-4 py-2">Ação</div>
                </div>
                <div v-for="(line, index) in listData" :key="index"
                    class="grid grid-cols-10 bg-white py-3 px-3 hover:bg-neutral-50 justify-items-center">
                    <div class="col-span-2">{{ line.description }}</div>
                    <div class="col-span-2"  v-if="userStore._user_type == 'Gerente'" >
                        <button class="text-indigo-500" @click="editDatas(line)"> <font-awesome-icon
                                icon="fa-solid fa-edit" /></button>
                        <button class="text-red-400 ml-4" @click="deleteDatas(line.id)"> <font-awesome-icon
                                icon="fa-solid fa-trash" /></button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  const userStore = userDefineStore()

const listData = ref([])
const description = ref()
const update = ref(false)
const idUpdate = ref()

onMounted(() => {
    getDatas();

})
const editDatas = (item) => {
    update.value = true
    description.value = item.description
    idUpdate.value = item.id
}
const updateDatas = () => {
    const url = process.env.VUE_APP_API_URL + "dataProspect/" + idUpdate.value
    let payload = { 'description': description.value,'status': true, company_id: userStore._company_id }
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, payload, config).then(() => {
        description.value = ''
        idUpdate.value = ''
        getDatas()
    })
}
const deleteDatas = (data) => {
    const url = process.env.VUE_APP_API_URL + "dataProspect/" + data
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.delete(url, config).then(() => {
        getDatas()
    })
}
const getDatas = () => {
    const url = process.env.VUE_APP_API_URL + "dataProspects/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listData.value = response.data
    })
}
const saveList = () => {
    let payload = { 'description': description.value,  company_id: userStore._company_id }
    const url = process.env.VUE_APP_API_URL + "dataProspect"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config)
    description.value = ''
    getDatas();
}
</script>
<template>
    <div class="" v-if="loadInfo">
        <div  class="flex flex-col justify-center  " v-for="(agenda, index) in schedule" :key="index">
            <span @click="sendComand(agenda)" class="flex text-xs text-center items-center mb-1 justify-center text-indigo-500 bg-indigo-300 rounded-md cursor-pointer" >
                {{formateTitle(agenda)}}
            </span>
           
        </div>
    </div>
    <div class="flex flex-col  h-12" v-if="!loadInfo" ></div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
const loadInfo= ref(false)
// eslint-disable-next-line
const props = defineProps(['schedule'])
// eslint-disable-next-line
const emit = defineEmits(['openCalendar']);
onMounted(()=>{
    console.log(props.schedule)
    loadInfo.value = false

    if(props.schedule[0]){
        loadInfo.value = true
    }
    
})
// eslint-disable-next-line
const sendComand = (agenda)=>{
    emit('openCalendar', agenda)
}
const formateTitle = (title)=>{

    if(title.title == undefined){
        return ""
    }
    else
    console.log(title.title)
    return title.title.substr(0,10)
}
</script>
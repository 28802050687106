
import {userDefineStore} from '../store/UserStore'
export const ifAuthenticated = (to, from, next) => {
    const userStore = userDefineStore()
    //store.dispatch('users/useSubscribe');
    // const userStore = userDefineStore()
    userStore.useMe().then(() => {
        next()
    }).catch(() => {
        next({ name: 'login' });
    })
    
}
export const ifAdminAuthenticated = (to, from, next) => {
    const userStore = userDefineStore()
   
    userStore.isAdmin().then(() => {
        next()
    }).catch(() => {
        next({ name: 'AdmLogin' });
    })

}
<template>
    <div>

        <span class="italic mt-4">Você pode escolher somente uma das opções para seu relatório</span>
        <div class="flex flex-row mt-4 justify-center">
            <div class="">
                <input class="mr-1" type="radio"  name="type" value="client" v-model="type">
                <label for="type">Cliente</label>
            </div>
            <div class="ml-4">
                <input class="mr-1" type="radio"  name="type" value="lead" v-model="type">
                <label for="type">Lead</label>
            </div>
            <div class="ml-4">
                <input class="mr-1" type="radio"  name="type" value="opportunity" v-model="type">
                <label for="type">Oportunidade</label>
            </div>
            <div class="ml-4">
                <input class="mr-1" type="radio" name="type" value="prospect" v-model="type">
                <label for="type">Prospect</label>
            </div>
        </div>
        <div class="flex flex-row justify-center items-center">
            <BuscaClientes v-if="type=='client'" @sendClient="receivedClient"></BuscaClientes>
            <BuscaLeads v-if="type=='lead'" @sendLead="receivedLead"></BuscaLeads>
            <BuscaProspects v-if="type=='prospect'" @sendProspect="receivedProspect"></BuscaProspects>
            <SearchOpportunity v-if="type=='opportunity'" @sendOpportunity="receivedOpportunity"></SearchOpportunity>   
        </div>
        <div v-if="check=='opportunity'" :key="opportunity.id">
            <span class=" p-2 ml-auto text-xl font-sm h-8 text-indigo-500 rounded-md">Oportunidade: {{ opportunity.number }}</span>
            <ReportVisit  @saved="emitSave" :title="'Relatório de visitas de oportunidade'" class="mr-4" :opportunity="opportunity" :lead="false" :client="false" :prospect="false"/>
        </div>
        <div v-if="check=='lead'" :key="lead.id" >
            <span class=" p-2 ml-auto text-xl font-sm h-8 text-indigo-500 rounded-md">Lead: {{ lead.name }}</span>
            <ReportVisit @saved="emitSave" :title="'Relatório de visitas de lead'" class="mr-4" :lead="lead" :opportunity="false" :client="false" :prospect="false"/>
        </div>
        <div v-if="check=='client'" :key="client.id">
            <span class=" p-2 ml-auto text-xl font-sm h-8 text-indigo-500 rounded-md">Cliente: {{ client.name }}</span>
            <ReportVisit @saved="emitSave" :title="'Relatório de visitas de cliente'" class="mr-4" :client="client" :opportunity="false" :lead="false" :prospect="false"/>
        </div>
        <div v-if="check=='prospect'" :key="prospect.id" >
            <span class=" p-2 ml-auto text-xl font-sm h-8 text-indigo-500 rounded-md">Prospect: {{ prospect.name }}</span>
            <ReportVisit @saved="emitSave" :title="'Relatório de visitas de prospect'" class="mr-4" :prospect="prospect" :client="false" :opportunity="false" :lead="false"/>
        </div>
    </div>
</template>
<script setup>
import BuscaClientes from '@/components/BuscaClientes.vue'
import BuscaLeads from '@/components/BuscaLeads.vue'
import BuscaProspects from '@/components/BuscaProspects.vue'
import ReportVisit from '@/components/ReportVisit.vue'
import SearchOpportunity from '@/components/SearchOpportunity.vue'
import { ref, watch } from 'vue'
const opportunity = ref(false)
  const lead = ref(false)
  const type = ref(false)
  const prospect = ref(false)
  const client = ref(false)
  const check = ref(false)
  // eslint-disable-next-line
  const emit = defineEmits(['saved'])
  watch(
  () => type.value,
  () => {
    check.value =false
  })
  const receivedOpportunity = (opportunityReceived)=>{
  opportunity.value = opportunityReceived
  check.value = 'opportunity'
  }
  const emitSave = ()=>{
    emit('saved')
  }
  const receivedLead = (leadReceived)=>{

    lead.value = leadReceived
    check.value = 'lead'
  }
  const receivedClient = (clientReceived)=>{
    client.value = clientReceived
    check.value = 'client'
  }
  const receivedProspect = (clientProspect)=>{
    prospect.value = clientProspect
    check.value = 'prospect'
  }

</script>
<template>
    <div v-if="onLoad" class="flex flex-col justify-start">
        <div class=" pl-6 pr-6 pt-2 pb-3  flex  content-start space-x-4">
            <div
                class="flex flex-col  ml-4 mt-4  
                bg-white hover:shadow-lg
                border-l-4 border-transparent border-indigo-500
                 rounded-sm shadow-md overflow-hidden ">
                <div class="flex flex-col   mt-2 ">
                    <div class="flex flex-row w-full justify-between">
                        <span class="text-slate-700 text-lg flex items-start ml-4">{{ opportunity.name_opportunity }}</span>
                    </div>
                    <hr class="flex w-128 ml-4 items-center">
                    <span class="flex ml-6 justify-start italic text-sm text-neutral-400">
                            Produto: {{ opportunity.product }}
                        </span>
                    <span class="flex ml-6 justify-start italic text-sm text-neutral-400">
                            {{ formatarReal(opportunity.product_value) }}
                        </span>
                </div>
                <div class="flex flex-row justify-between ml-2  ml-4">
                    <div class="flex flex-row ml-4 italic items-start text-sm text-neutral-400">
                        
                        
                        <span class="flex text-slate-700 text-sm">Nº {{ opportunity.number }}</span>
                    </div>
                    <div class="flex items-end mr-4 mb-2">
                        <button v-if="!openDetail" 
                        class="flex focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                        @click="openDetail = !openDetail">
                            <font-awesome-icon icon="fa-solid fa-angle-left" />
                        </button>
                        <button v-if="openDetail" 
                        class="focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                        @click="openDetail = !openDetail">
                        <font-awesome-icon icon="fa-solid fa-angle-down" />
                        </button>
                    </div>
                </div>
                <div v-if="openDetail" class="flex flex-col ml-4 border-l-2  pr-4 border-transparent border-gray-500">
                    <div class="mb-4" v-for="(post, index) in listPosts" :key="index">
                        <div class="flex items-start">
                            <span v-if="post.phase == opportunity.phase" class="italic items-start text-sm text-neutral-400">
                                <font-awesome-icon icon="fa-solid fa-bell" class="text-green-400" />
                            </span>
                            <span v-else class="italic items-start text-sm text-neutral-400">
                                <font-awesome-icon icon="fa-solid fa-bell" class="text-gray-400" />
                            </span>
                            <span class="italic items-start text-sm ml-2 mr-2 text-neutral-400" v-if="post.phase == 1">Etapa: Lead</span> 
                            <span class="italic items-start text-sm ml-2 mr-2 text-neutral-400" v-if="post.phase == 2">Etapa: Visita</span> 
                            <span class="italic items-start text-sm ml-2 mr-2 text-neutral-400" v-if="post.phase == 3">Etapa: Proposta</span> 
                            <span class="italic items-start text-sm ml-2 mr-2 text-neutral-400" v-if="post.phase == 4">Etapa: Fechamento</span> 
                            <span class="italic items-start text-sm ml-2 mr-2 text-neutral-400">
                                {{ formateDate(post.created_at) }}
                            </span>
                        </div>
                        <span class="flex mt-1 ml-1 italic items-start text-sm text-neutral-800">
                        {{ post.description }}
                        </span>
                        <div v-for="(commentario, index) in post.comments" :key="index" class="flex items-start justify-start">
                            <span  class="flex ml-6 italic items-start text-sm text-blue-500">
                                {{formateDate(commentario.created_at)}}
                                {{ commentario.name }} : {{ commentario.description }}
                            </span>
                        </div>
                        <div v-if="post.phase == opportunity.phase" class="flex flex-col items-start mr-4">
                        <label class="block mb-1 ml-2 text-sm font-medium text-gray-900 ">
                            Comentário da oportunidade</label>
                            <textarea type="text" v-model="comment"
                                class="ml-4 mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                            </textarea>
                        </div>
                        <div v-if="post.phase == opportunity.phase" class="flex justify-end mt-2">
                            <button @click="saveComment(post.id)" class="flex items-center justify-center w-6 h-6
                             bg-indigo-500 text-indigo-50 rounded-md cursor-pointer">
                             <font-awesome-icon icon="fa-solid fa-save" />
                            </button>
                        </div>
                    </div>
                    <div class="flex flex-col" >
                        <span class="text-slate-700 text-lg flex items-start ml-4"> Tarefas da Oportunidade</span>
                        <div v-for="(task, index) in listTasks" :key="index" class="flex items-start justify-start">
                            <CardTask v-if="task.status == 'active'" :task="task" @sendRefresh="getTasks()"></CardTask>
                            <CardTaskClosed v-if="task.status == 'closed'" :task="task" @sendRefresh="getTasks()"></CardTaskClosed>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  import CardTask from './CardTask.vue';
  import CardTaskClosed from './CardTaskClosed.vue';
  const userStore = userDefineStore()
// eslint-disable-next-line
const props = defineProps(['opportunity'])
// eslint-disable-next-line
const listPosts = ref([])
const listTasks = ref([])
const openDetail = ref(true)
const onLoad = ref(false)
const comment = ref('')
onMounted(() => {
getPosts()
getTasks()
})
// eslint-disable-next-line
const getPosts = () => {

const url = process.env.VUE_APP_API_URL + "postOpportunity/" + props.opportunity.opportunity_id
const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
};
axios.get(url, config).catch(function () {
}).then((response) => {
    listPosts.value = response.data
    getComments();

});
}
// eslint-disable-next-line
const getTasks = () => {

const url = process.env.VUE_APP_API_URL + "tasksOpportunities/" + props.opportunity.opportunity_id
const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
};
axios.get(url, config).catch(function () {
}).then((response) => {
    listTasks.value = response.data
    onLoad.value = true
  
   
});
}
const getComments = () => {
    let aux = 0
listPosts.value.map((post)=>{
    const url = process.env.VUE_APP_API_URL + "comments/" + post.id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {
    }).then((response) => {
    
        listPosts.value[aux].comments = response.data
        aux++;
    });

})
}
const saveComment = (post_id) => {
    let payload = {
        description: comment.value,
        status: 'active' ,
        post_id: post_id,
        user_id: userStore._user_id,
        company_id:   userStore._company_id,
    }
    const url = process.env.VUE_APP_API_URL + "comment"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        comment.value = "";
        getPosts()
    })
}
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const formatarReal = (valor)=>{
return parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
</script>
<template>
    <div class="p-2 mb-3 w-262  flex flex-col border-solid border-2 cursor-move border-neutral-50
                                                                                                                             bg-white rounded-lg hover:shadow-lg "
        :class="color">
        <div class="flex flex-col justify-between  ">

            <div class="flex items-center">

                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
                    element.question
                }}</p>
            </div>

            <div class="flex flex-row">
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">Valor: {{ element.value }}</p>
                <button aria-label="Edit user"
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                    @click="onEdit(element)">
                    <font-awesome-icon icon="fa-solid fa-edit" />
                </button>
                <button aria-label="Delete user"
                    class="p-1 focus:outline-none focus:shadow-outline text-red-500 hover:text-red-600"
                    @click="onDeleteQuestion(element)">
                    <font-awesome-icon icon="fa-solid fa-trash" />
                </button>

            </div>
        </div>
    </div>
</template>

<script setup>
//const $swal = inject('$swal');
// eslint-disable-next-line
const props = defineProps({ element: Object, color: String })

// eslint-disable-next-line
const emit = defineEmits(['deleteQuestion', 'editQuestion']);
const onDeleteQuestion = (item) => {
    emit('deleteQuestion', item);
}

const onEdit = (item) => {
    emit('editQuestion', item);
}

</script>

<style scoped></style>
<template>
  <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div >
        
            <SidebarMenu></SidebarMenu>
        </div>
        <div  class="flex flex-col w-full">
            <div v-if="user">
            <NavBar v-if="userStore._user_type == 'Gerente'" :menuItem="menuItem" :section="'Dashboard'" :head="['Meu Dashboard','Relatórios']" @navItem="navItem"></NavBar>
            <NavBar v-else :section="'Dashboard'" :menuItem="menuItem" :head="['Meu Dashboard']" @navItem="navItem"></NavBar>
            </div>
        <div v-if="user">
       
            <UserDashboard></UserDashboard>
        </div>
        <div v-if="report">
            <ReportDashboard @sendNav="navItem"></ReportDashboard>
        </div>
        
        
        </div>
    
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue"
import SidebarMenu from '@/components/SidebarMenu.vue'
import ReportDashboard from "@/components/dashboard/ReportDashboard.vue"
import UserDashboard from '@/components/dashboard/UserDashboard.vue'
import { userDefineStore } from '@/store/UserStore'
import { ref } from 'vue'
  const userStore = userDefineStore()
const report = ref(false)

const menuItem = ref('Meu Dashboard')
const user = ref(true)

const navItem = (item) => {

    if (item == 'Relatórios') {
        report.value = true
       
        user.value = false
        menuItem.value = 'Relatórios'
    }    
    else if (item == 'Meu Dashboard') {
        report.value = false
        user.value = true
         menuItem.value = 'Meu Dashboard'
    }
}
</script>

<style scoped>
    
</style>
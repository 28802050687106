<template>
    <div @click="senditem(title)"  class="flex flex-col justify-center 
        items-center rounded-md 
        w-48 h-48 cursor-pointer  hover:shadow-lg " :class="color">
        <span class="text-white text-3xl"><font-awesome-icon :icon="`fa-solid ${icon}`" /></span>
        <span class="text-white text-2xl">{{title}}</span>
    </div>
</template>
<script setup>
// eslint-disable-next-line
const props = defineProps(['icon', 'title', 'color'])
// eslint-disable-next-line
const emit = defineEmits(['navItem']);

const senditem = (item) => {
    emit('navItem', item);
}
</script>
<template>   
    <div class="flex flex-col items-start">
        <div class="flex flex-row h-10 mt-1 items-center">
            <span class="w-full">Selecione o cliente</span>
             <BuscaClientes  @sendClient="receveidClient" class="w-full" :title="true" :titleValue="''"></BuscaClientes>                
        </div>
        <div class="flex flex-col" v-if="clientView">
            <div class="grid grid-cols-10">
                <div class="grid col-span-6">
                <div class="grid col-span-6 w-full h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                    <span class=" ml-4">Informações do cliente</span>
                </div>
                <CardInfoClients class="grid col-span-6" :client="client" ></CardInfoClients>
                </div>
                <div class="grid col-span-4">
                    <CardManageClient class="" :clientInfo="client"  ></CardManageClient>
                </div>
            </div>
          
        </div>
    </div>
</template>
<script setup>
    import BuscaClientes from '../BuscaClientes.vue';
    import CardInfoClients from '../CardInfoClients.vue';
    import {ref} from 'vue'
    import CardManageClient from '../CardManageClient.vue';
    const client =ref('')
    const clientView =ref(false)

    const receveidClient = (clientSelected) => {
        client.value = clientSelected
        clientView.value =true
    }
</script>

<template>
  <div>
    <div class="flex flex-col">
            <div class="flex flex-row ml-2 ">
                <div class="flex flex-row">
                    <div class=" p-2 ml-auto text-xl font-sm h-8 text-indigo-500 rounded-md">
                        Cadastro de Despesas
                    </div>
                </div>
            </div>

            <div class="flex flex-row items-center ml-8 mt-8">
                <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                        Valor R$</label>
                    <input type="text" v-model="value"
                    class=" ml-2 bg-gray-50 border border-gray-300
                     text-gray-900 text-sm rounded-sm focus:ring-blue-500
                      focus:border-blue-500 block w-full p-1 h-8
                      "></div>
                <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                        Descrição</label>
                    <input type="text" v-model="description"
                    class=" ml-2 bg-gray-50 border border-gray-300
                     text-gray-900 text-sm rounded-sm focus:ring-blue-500
                      focus:border-blue-500 block w-full p-1 h-8
                      "> </div>
                <div class="flex flex-col items-start ml-2">
                    <label class="text-sm ml-2 font-normal text-gray-900">
                        Data da despesa</label>
                    <input type="date" v-model="date"
                    class=" ml-2 bg-gray-50 border border-gray-300
                     text-gray-900 text-sm rounded-sm focus:ring-blue-500
                      focus:border-blue-500 block w-full p-1 h-8
                      "> 
                </div>
                      <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                        Tipo da despesa</label>
                      <select v-model="type" @change="onChange"
                      class=" ml-2 bg-gray-50 border border-gray-300
                     text-gray-900 text-sm rounded-sm focus:ring-blue-500
                      focus:border-blue-500 block w-full p-1 h-8
                      ">                    
                                <option value="Combustível">Combustível</option>
                                <option value="Alimentação">Alimentação</option>
                                <option value="Pedágio">Pedágio</option>
                                <option value="Outros">Outros</option>
            </select>
                      </div>
                <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                        Comprovante</label>
                    <button @click="$refs.image.click()"
                        class="p-1 focus:outline-none focus:shadow-outline text-neutral-500  hover:text-neutral-700">
                        <font-awesome-icon icon="fa-solid fa-paperclip" />
                    </button>
                    <input type="file" @change="onFileChange" ref="image" style="display: none"
                        class="p-1 focus:outline-none focus:shadow-outline  text-neutral-500 hover:text-neutral-700">
                </div>
                <div  v-if="!update" class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="saveExpense">

                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
                <div  v-if="update" class="flex justify-center item-center pt-2 mt-12  text-base font-sm h-8 ml-8 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="updateExpense">
                    update
                    <font-awesome-icon icon="fa-solid fa-save" />
                </div>
            </div>
        
            <div class="flex flex-col ml-4 mt-4">
                <div class="flex flex-row mb-2">
                    <div class="flex flex-col items-start ml-2">
                        <label class="text-sm ml-2 font-normal text-gray-900">
                        Data de início</label>
                        <input type="date" v-model="dateStart"
                        class=" ml-2 bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-sm focus:ring-blue-500
                          focus:border-blue-500 block w-full p-1 h-8
                          "> 
                    </div>
                <div class="flex flex-col items-start ml-2">
                    <label class="text-sm ml-2 font-normal text-gray-900">
                        Data de fim</label>
                    <input type="date" v-model="dateEnd"
                    class=" ml-2 bg-gray-50 border border-gray-300
                     text-gray-900 text-sm rounded-sm focus:ring-blue-500
                      focus:border-blue-500 block w-full p-1 h-8
                      "> 
                </div>
                <div class="flex justify-center item-center pt-2 mt-4  text-base font-sm h-8 ml-4 w-8 bg-indigo-500 text-indigo-50 rounded-md cursor-pointer"
                    @click="getExpenses">
                    
                    <font-awesome-icon icon="fa-solid fa-search" />
                </div>
                </div>
                <div class="grid grid-cols-12 bg-white justify-items-center w-full">
                    <div class="bg-white col-span-3 text-gray-400 font-sm  px-4 py-2">Data</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tl px-4 py-2">Valor</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm  px-4 py-2">Tipo</div>
                    <div class="bg-white col-span-3 text-gray-400 font-sm  px-4 py-2">Descrição</div>
                    <div class="bg-white col-span-2 text-gray-400 font-sm rounded-tr px-4 py-2">Ação</div>
                </div>
                <div v-for="(line, index) in listExpenses" :key="index"
                    class="grid grid-cols-12 bg-white py-3 px-3 hover:bg-neutral-50 justify-items-center">
                    <div class="col-span-3">{{formateDateBr(line.date) }}</div>
                    <div class="col-span-2">{{ formatarReal(line.value)}}</div>
                    <div class="col-span-2">{{ line.type}}</div>
                    <div class="col-span-3">{{ line.description }}</div>
                    <div class="col-span-2">
                        <button class="text-indigo-500" @click="editExpense(line)"> <font-awesome-icon
                                icon="fa-solid fa-edit" /></button>
                        <button class="text-red-400 ml-4" @click="deleteExpense(line.id)"> <font-awesome-icon
                                icon="fa-solid fa-trash" /></button>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  const user = userDefineStore()
// eslint-disable-next-line

const listExpenses = ref([])
const description = ref([])
const update = ref(false)
const value = ref()
const date = ref()
const dateStart = ref()
const dateEnd = ref()
const type = ref()
const file = ref()
const idUpdate = ref()
const formData = ref(null);
onMounted(() => {   
  
    date.value = new Date()
    dateStart.value = new Date()
    dateEnd.value = new Date()
    getExpenses();
    
})

const getExpenses = ()=>{

    let date1  = formatDate(dateStart.value)
    let date2  = formatDate(dateEnd.value)
    const url = process.env.VUE_APP_API_URL + "expenseMonth/"+ user._company_id +'/'+date1+'/'+date2
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).then((response) => {
   
        listExpenses.value = response.data
    })
}
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
const saveExpense = ()=>{
    formData.value = new FormData();
    formData.value.append('file', file.value);
    const url = process.env.VUE_APP_API_URL + "expense"
    const config = {
        headers: {
            Authorization: `Bearer ${user._api_token}`,
            'Content-Type': 'multipart/form-data'
        }
    };
    value.value = value.value.replace('.','')
    value.value = value.value.replace(',','.')
    let dateSave = formatDate(date.value)
    const payload = {
        value: value.value,
        description: description.value,
        document: file.value,
        date: dateSave,
        type: type.value,
        user_id: user._user_id,
        company_id: user._company_id,
    }

    axios.post(url, payload, config).then(() => {
        date.value = ''
        description.value = ''
        value.value = ''
        idUpdate.value = ''
        getExpenses()
    });
}
const updateExpense = ()=>{
    const url = process.env.VUE_APP_API_URL + "expense/" + idUpdate.value
    let payload = { 'document': null, 'description': description.value,'date': date.value,'value': value.value, company_id: user._company_id }
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.put(url, payload, config).catch(function () {

        //router.push('/');
    }).then(() => {
        date.value = ''
        description.value = ''
        value.value = ''
        idUpdate.value = ''
        update.value = false
        getExpenses()
    })
}
const deleteExpense = (id)=>{
    const url = process.env.VUE_APP_API_URL + "expense/" + id
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.delete(url,  config)
    .then(() => {getExpenses()});
}
const editExpense = (item) => {
    update.value = true
    date.value = item.date
    value.value = item.value
    description.value = item.description
    document.value = item.document
    idUpdate.value = item.id
}
const onFileChange = (event) => {
    file.value = event.target.files[0];
};
const formatarReal = (valor)=>{
  return parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
const formateDateBr = (data) => {
    let datas = data.split('-')
    return [datas[2], datas[1], datas[0]].join('/');
}
</script>

<style scoped>
    
</style>
<template>
    <div v-if="loadTasks && loadQualifications" class="flex flex-col border border-blue-500 text-start rounded-md p-2">
        <div class="grid col-span-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                <span class="ml-4 ">Gerenciamento</span>
        </div>
        
          <div class="text-lify_blue ml-1 hover:text-lify_blue_hover">
            <div @click="modalOpportunities = !modalOpportunities"
              class="relative flex flex-row cursor-pointer items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-lify_blue pr-6">
              <span class="inline-flex justify-center items-center ml-4">
                <font-awesome-icon  icon="fa-solid fa-dollar-sign" class="text-yellow-500 w-6 h-6"></font-awesome-icon>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">{{opportunities_lenght}} Oportunidades</span>
            </div>
        </div>
          <div class="text-lify_blue ml-1 hover:text-lify_blue_hover" @click="modalQualifications = !modalQualifications">
            <div @click="openQualifications"
              class="relative flex flex-row cursor-pointer items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-lify_blue pr-6">
              <span class="inline-flex justify-center items-center ml-4">
                <font-awesome-icon  icon="fa-solid fa-star" class="text-yellow-500 w-6 h-6"></font-awesome-icon>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">{{qualifications_lenght}} Qualificações</span>
            </div>
        </div>
          <div class="text-lify_blue ml-1 hover:text-lify_blue_hover" @click="modalVisits = !modalVisits">
            <div @click="openVisits"
              class="relative flex flex-row cursor-pointer items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-lify_blue pr-6">
              <span class="inline-flex justify-center items-center ml-4">
                <font-awesome-icon  icon="fa-solid fa-handshake" class="text-yellow-500 w-6 h-6"></font-awesome-icon>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">{{visits_lenght}} Visitas</span>
            </div>
        </div>
    
    <ModalItem v-if="modalQualifications" @refresh="close">  
        <div class=" h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
        <div class="flex flex-row justify-between">
          <span class=" ml-4">Qualificações do cliente</span>
          <span @click="newQualification">
            <font-awesome-icon class="text-white mr-4 cursor-pointer"
               icon="fa-solid fa-plus" />
          </span> 
        </div>

      </div>   
      <TableReviews :reviews="listQualifications"/>
    </ModalItem>
    <ModalItem v-if="modalOpportunities" @refresh="close">  
      
        <ListOpportunitiesClient :opportunities="listOpportunities" @new="newOpportunity"></ListOpportunitiesClient>
 
    </ModalItem>
    <ModalItem v-if="modalVisits" @refresh="close">  
      <TableReportVisits :buttonsCrud="false" :listReport="listVisits"/>
    </ModalItem>
    <ModalItem v-if="openReviewClient" @refresh="close">   
        <ReviewClientes :clientInfo="clientInfo" @close="close"></ReviewClientes>  
    </ModalItem>
    <ModalItem v-if="openNewOpportunity" @refresh="close">
        <div class=" h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
        <div class="flex flex-row justify-between">
          <span class=" ml-4">Nova oportunidade </span>
          <span @click="newQualification">
            <font-awesome-icon class="text-white mr-4 cursor-pointer"
            icon="fa-solid fa-plus" />
        </span> 
    </div>
    
</div>   
<CardInfoClients :client="clientInfo"/>
        <FormNewOportunityClient :client="clientInfo" @close="close"></FormNewOportunityClient>
    </ModalItem>
</div>
</template>
<script setup>
import {ref, onMounted} from 'vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import ModalItem from './ModalItem.vue';
import ReviewClientes from './ReviewClientes.vue';
import TableReviews from './TableReviews.vue';
//import TableOpportunities from './dashboard/TableOpportunities.vue';
import ListOpportunitiesClient from './ListOpportunitiesClient.vue';
import FormNewOportunityClient from './FormNewOportunityClient.vue';
import TableReportVisits from '@/components/TableReportVisits.vue'
const user = userDefineStore()
import CardInfoClients from './CardInfoClients.vue';
// eslint-disable-next-line
const props = defineProps(['clientInfo'])
//const $swal = inject('$swal');

const listOpportunities = ref()
const opportunities_lenght=ref(0)
const loadTasks = ref()
const listQualifications = ref()
const qualifications_lenght=ref(0)
const visits_lenght=ref(0)
const loadQualifications = ref()
const modalVisits = ref(false)
const openVisits = ref(false)
const modalQualifications = ref(false)
const modalOpportunities = ref(false)
const openReviewClient = ref(false)
const openNewOpportunity = ref(false)
const listVisits = ref([])
const loadVisits = ref(false)
onMounted(()=>{
    getOpportunties()
    getQualifications()
    getVisits()
})
const getOpportunties = ()=>{
  const url = process.env.VUE_APP_API_URL + "clientOpportunities/" + props.clientInfo.id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listOpportunities.value = response.data

    opportunities_lenght.value = Object.keys(response.data).length
    loadTasks.value = true
  })
}
const getQualifications = ()=>{
  const url = process.env.VUE_APP_API_URL + "checkReviewClient/" + props.clientInfo.id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listQualifications.value = response.data
    qualifications_lenght.value = Object.keys(response.data).length
    loadQualifications.value = true
  })
}
const getVisits = ()=>{
  const url = process.env.VUE_APP_API_URL + "reportsClient/" + props.clientInfo.id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    visits_lenght.value = Object.keys(response.data).length
    listVisits.value = response.data
    loadVisits.value = true
  })
}
const close = ()=>{
    modalOpportunities.value =false
    modalQualifications.value =false
    openReviewClient.value =false
    openNewOpportunity.value = false
    modalVisits.value = false
    getOpportunties()
    getQualifications()
}
const newQualification = ()=>{
    openReviewClient.value = true
}
const newOpportunity = ()=>{
    openNewOpportunity.value = true
}
</script>
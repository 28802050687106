<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>
            <SidebarAdmMenu></SidebarAdmMenu>
        </div>
        <div class="flex flex-col w-full">
            <NavBar :section="'Dashboard'" :head="['Analítico', 'Financeiro']" @navItem="navItem"></NavBar>
            <div class="flex flex-row ">

            </div>
        </div>

    </div>
</template>

<script setup>
import SidebarAdmMenu from '@/components/admin/SidebarAdmMenu.vue';
import NavBar from '@/components/NavBar.vue';
import { ref } from 'vue'
const analitico = ref(false)
const financeiro = ref(false)

const navItem = (item) => {
    if (item == 'Analítico') {
        analitico.value = true
        financeiro.value = false
       
    }
    else if (item == 'Financeiro') {
        analitico.value = false
        financeiro.value = true
       
    }
    
}
</script>

<style scoped></style>
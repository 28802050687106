<template>
    <button type="button" @click="actionSend"
                  class="flex items-center p-3 text-white text-lg font-semibold bg-lify_blue   
                  hover:bg-lify_blue_hover hover:text-white focus:ring-4 
                  focus:outline-none focus:ring-blue-300  
                  font-medium rounded-lg text-sm text-center "
                  :class="{ 'w-10 h-10': icon,  'w-auto h-auto max-w-fit max-h-fit':!icon }">
        <slot>{{title}}</slot>          
    </button>
</template>
<script setup>
// eslint-disable-next-line
const props = defineProps(['title','icon'])
// eslint-disable-next-line
const emit =defineEmits(['actionClick'])
const actionSend = ()=>{
    emit('actionClick')
}
</script>
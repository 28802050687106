<template>
    <div class="grid grid-cols-5 bg-white justify-items-center w-auto ">
        <div class="bg-white col-span-1 text-gray-400 font-sm  px-4 py-2">Data</div>
        <div class="bg-white col-span-1 text-gray-400 font-sm  px-4 py-2">Questionário</div>
        <div class="bg-white col-span-1 text-gray-400 font-sm  px-4 py-2">Pontos</div>    
        <div class="bg-white col-span-1 text-gray-400 font-sm  px-4 py-2">Avaliador</div>    
        <div class="bg-white col-span-1 text-gray-400 font-sm  px-4 py-2">Ação</div>
    </div>
    <div class="grid grid-rows-2">
        <div class="grid grid-cols-5 row-span-1 bg-white justify-items-center py-1 px-1 w-auto hover:bg-slate-200"
            v-for="(line, index) in reviews" :key="index">         
        <div class=" col-span-1 text-gray-400 font-sm  ">            
            <span class=" text-base ml-2">{{ formateDate(line.created_at)   }}</span>
        </div>
        <div class=" col-span-1 text-gray-400 font-sm  ">            
            <span class=" text-base ml-2">{{ line.title   }}</span>
        </div>
        <div class=" col-span-1 text-gray-400 font-sm  ">
            <span class=" text-base ml-2">{{ line.points   }}</span>
        </div>
        <div class=" col-span-1 text-gray-400 font-sm  ">
            <span class=" text-base ml-2">{{ line.avaliator   }}</span>
        </div>
        <div v-if="!openQuestions" class="col-span-1 font-sm ">
            <button class="text-indigo-500 ml-1 hover:text-indigo-200" @click="openReview(line.answers)"> 
                <font-awesome-icon icon="fa-solid fa-folder-open" />
            </button>
        </div>
        <div v-if="openQuestions" class="col-span-1 font-sm ">
            <button class="text-indigo-500 ml-1 hover:text-indigo-200" @click="closeReview()"> 
                <font-awesome-icon icon="fa-solid fa-folder-closed" />
            </button>
        </div>
    </div>
    <div v-if="openQuestions" > 
        <ModalItem @refresh="closeReview">             
            <div v-for="(element, index) in questions" class="mt-2  mr-4" :key="index">
                <LineAnswers  :element="element"></LineAnswers>
            </div> 
        </ModalItem>          
    </div>
    </div>   
 </template>
                
<script setup>
import { ref } from 'vue'
import LineAnswers from './LineAnswers.vue'
import ModalItem from './ModalItem.vue'
// eslint-disable-next-line
const props = defineProps(['reviews'])
const questions = ref([])
const openQuestions = ref(false)

const openReview = (item)=>{

    questions.value = JSON.parse(item)
    openQuestions.value =true
}


const closeReview = ()=>{
    
    questions.value = ''
    openQuestions.value =false
}

const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}

</script>
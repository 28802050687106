<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>

            <SidebarMenu></SidebarMenu>
        </div>
        <div v-if="load" class="flex flex-col items-start ml-4">

            <div class="flex flex-row items-center">
                <div
                    class="flex flex-col justify-start mt-4 items-start bg-white  text-indigo-700 border-solid
                                                                                                                                                                                                                                                                                                                                                            border-2  border-neutral-50
                                                                                                                                                                                                                                                                                                                                                           bg-white rounded-lg shadow-lg p-4 ">
                    <span class="ml-4 text-md  ">Nome: {{ qualifited.name }} </span>
                    <span class="ml-4 text-md  ">Data entrada: {{ formatDate(qualifited.created_at) }} </span>
                    <span class="ml-4 text-md  ">e-mail: {{ qualifited.email }}</span>
                    <span class="ml-4 text-md  ">Fone: {{ qualifited.phone }}</span>

                </div>
            </div>
            <div class="flex flex-col justify-start p-4 mt-4 items-start bg-white  text-indigo-700 border-solid">
                <span class="ml-4 text-md  ">Qualificado por: {{ qualifited.user_name }}</span>
            </div>
            <div class="flex flex-col justify-start p-4 mt-4 items-start bg-white  text-indigo-700 border-solid">
                <span class="ml-4 text-md  ">Produto de interesse: {{ qualifited.product }}</span>
            </div>
        </div>
        <div v-if="load"
            class="flex flex-col items-start mt-4 ml-4 border-solid 
                                                                                                                                                            border-2 cursor-move border-neutral-50
                                                                                                                                                            h-10  bg-white rounded-lg shadow-lg ">
            <div class="flex flex-row  w-128 items-center justify-between">

                <span class="flex justify-center items-center ml-4 mt-2">Qualificação</span>
                <button v-if="!openQuestion" aria-label="Edit user"
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                    @click="openQuestion = !openQuestion">
                    <font-awesome-icon icon="fa-solid fa-angle-left" />
                </button>
                <button v-if="openQuestion" aria-label="Edit user"
                    class="p-1 focus:outline-none focus:shadow-outline text-slate-500 hover:text-slate-600"
                    @click="openQuestion = !openQuestion">
                    <font-awesome-icon icon="fa-solid fa-angle-down" />
                </button>
            </div>

            <div v-for="(element, index) in questions" class="mt-2" :key="index">

                <LineAnswers v-if="openQuestion" :element="element"></LineAnswers>
            </div>
        </div>
    </div>
</template>

<script setup>
import LineAnswers from '@/components/LineAnswers.vue';
import SidebarMenu from '@/components/SidebarMenu.vue';
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios';
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
const userStore = userDefineStore()
const route = useRoute()
const qualifited = ref({});
const questions = ref({});

const load = ref(false);
const openQuestion = ref(true);

onBeforeMount(() => {

    getQualifited();
})
const getQualifited = () => {
    const url = process.env.VUE_APP_API_URL + "findQualification/" + route.params.qualifited_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {

    }).then((response) => {

        qualifited.value = response.data
        questions.value = JSON.parse(qualifited.value.answers)
        load.value = true
    })
}
function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}
</script>

<style scoped></style>
<template>
    <div>
        <div class="flex flex-row  h-screen w-screen bg-neutral-100">
            <div>
                <SidebarMenu></SidebarMenu>
            </div>
            <div class="flex flex-col w-full h-screen">
                <NavBar :section="'Chat'" :head="['Detalhes oportunidade']" @navItem="detalhes" ></NavBar>
                <div class="flex flex-col overflow-y-auto overflow-x-hidden h-screen mb-4" v-if="load">
                <span class="text-slate-800 text-lg font-semibold">
                    Oportunidade: {{ opportunity.name_opportunity }}
                </span>
                    <div v-for="(coment, index) in listComments" :key="index">
                        <ChatMessageLeft v-if="index % 2 == 0" class="flex justify-start" :coment="coment"></ChatMessageLeft>
                        <ChatMessageRight v-else class="flex justify-end" :coment="coment"></ChatMessageRight>
                    </div>
                </div>
                <div class="h-20 flex bg-neutral-100  justify-center ml-52 mb-4   inset-x-0 bottom-0 max-w-200 ">
                    <div class="flex flex-row justify-between ">
                        <span class="flex h-10 justify-center text-blue-900 mt-2 mr-2">Digite seu comentário</span>
                        <input type="text" v-model="comment" class="h-10 p-4 rounded-lg w-200 bg-blue-100 border-1 border-blue-500">
                        <span class="h-10 w-10  content-center "><font-awesome-icon @click="saveComment" class="text-blue-500 cursor-pointer " icon="fa-solid fa-paper-plane" /></span>
                    </div>
                </div>
            </div>
        </div>
        <ModalItemSm @refresh="detalhes" v-if="openModal" :title="'Detalhes da Oportunidade'">

            <span class="text-slate-800 text-base ">Cliente: {{ opportunity.name_opportunity }}</span>
            <span class="text-slate-800 text-base ">Fase do Funil: {{opportunity.phase_name}}</span>
            <span class="text-slate-800 text-base ">Produto: {{opportunity.product}}</span>
            <span class="text-slate-800 text-base ">{{formatarReal(opportunity.value_opportunity)}}</span>
            <span class="text-slate-800 text-base "> Vendedor: {{opportunity.seller_name}}</span>
        </ModalItemSm>
    </div>
</template>
<script setup>
import {  ref, inject, watch,  onMounted } from 'vue';
import { userDefineStore } from '@/store/UserStore'
  import { storeToRefs } from 'pinia'
import { chatDefineStore } from '@/store/ChatStore';
import { opportunityDefineStore } from '@/store/OpportunityStore';
import NavBar from '@/components/NavBar.vue';
import ModalItemSm from '@/components/ModalItemSm.vue';
import SidebarMenu from '@/components/SidebarMenu.vue'
import ChatMessageLeft from '@/components/ChatMessageLeft.vue'
import ChatMessageRight from '@/components/ChatMessageRight.vue'
 // import {  useRoute } from 'vue-router'
  import axios from 'axios'
  const userStore = userDefineStore()
  const $swal = inject('$swal');
  const chatStore = chatDefineStore()
  const opportunityStore = opportunityDefineStore()
  const  {message}  = storeToRefs(chatStore)
  //const route = useRoute()
  const chat = ref()
  const listComments = ref()
  const load = ref(false)
  const openModal = ref(false)
  const comment = ref()
  const opportunity = ref()
  onMounted(() => {

    getChat()
    
 })
 
const detalhes = ()=>{
openModal.value = !openModal.value
}  
const getChat = ()=>{
   opportunity.value = opportunityStore.getOpportunityInfo
const url = process.env.VUE_APP_API_URL + "chatOpportunity/" + opportunity.value.opportunity_id
const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
axios.get(url, config).catch(function () {
}).then((response) => {
    console.log(response.data)
    chat.value = response.data[0]
    getComents()
    load.value = true
}); 

}
const getComents = ()=>{
const url = process.env.VUE_APP_API_URL + "comments/" + chat.value.id
const config = {
        headers: {
            Authorization: `Bearer ${userStore._api_token}`,
        }
    }
axios.get(url, config).catch(function () {
}).then((response) => {
    listComments.value = response.data
});

}
const saveComment = ( ) => {
    let payload = {
        description: comment.value,
        status: 'active' ,
        chat_id: chat.value.id,
        user_id: userStore._user_id,
        company_id:   userStore._company_id,
    }
    const url = process.env.VUE_APP_API_URL + "comment"
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config).then(() => {
        comment.value =""
        getComents()
    })
}
watch(message,  () => {
    if (chatStore.chat_id == chat.value.id) {

    $swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 4000,
         icon: 'warning',
         title: 'Nova mensagem',
         text: 'você recebeu uma nova mensagem para a oportunidade.',
         showCancelButton: 'true'
       });
    
       getComents()
  }
})
const formatarReal = (valor)=>{
return parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
</script>
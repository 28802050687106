<template>
    <div class="flex flex-col " id="element-to-convert">
        <BuscaDataMultipleUser @sendData="receivedUser" class="z-50" :title="'Busca Vendedores'"></BuscaDataMultipleUser>
        <div class="grid grid-cols-4 ">
                <div class="grid col-span-1 gap-2 ml-4" v-for="(users,index) in user" :key="index">
                  <CardUserAvatar :user="users"></CardUserAvatar>
                </div>
            </div>
        <div class="flex p-2 mt-4 border border-indigo-400 rounded-md flex-col ">
        <span class="text-start">Resumo geral por vendedores</span>
         <div class="grid grid-cols-5 gap-2 mb-4 mr-2">
           <CardDashboardResume  :real="true" :title="'Vendas com sucesso'" :value="cardSalesSuccess" :event="'salesSuccess'" @sendEvent="receivedEvent"></CardDashboardResume>
           <CardDashboardResume  :real="true" :title="'Vendas não concretizadas'" :value="cardSalesFailure" :event="'salesSuccess'" @sendEvent="receivedEvent"></CardDashboardResume>
           <CardDashboardResume  :real="false" :title="'Oportunidades'" :value="cardOpportunity" :event="'salesSuccess'" @sendEvent="receivedEvent"></CardDashboardResume>
           <CardDashboardResume  :real="false" :title="'Leads'" :value="cardLeads" :event="'salesSuccess'" @sendEvent="receivedEvent"></CardDashboardResume>
           <CardDashboardResume  :real="false" :title="'Clientes'" :value="cardClients" :event="'salesSuccess'" @sendEvent="receivedEvent"></CardDashboardResume>
          </div>
          <div v-if="loadDataVendas" class="flex flex-row ml-2 mr-2 ">
            <DonutChartComponent :data="donutSuccessData"  :label="donutLabels" :key="keystamp" :title="'Vendas concretizadas'"></DonutChartComponent>
            <DonutChartComponent :data="donutFailureData"  :label="donutLabels" :key="keystamp" :title="'Vendas não concretizadas'" ></DonutChartComponent>      
          </div>
        </div>
          <div :key="keyStampSuccess">
              <div v-for="(opportunity,index) in listOpportunities" :key="index" class="m-4">
                <CardUserAvatar :user="opportunity.user"></CardUserAvatar>
                <TableOpportunities v-if="loadTableSuccess" :title="'Vendas concretizas'" :listOpportunities="opportunity.opportunities" :key="index"></TableOpportunities>
              </div>
          </div>
              <div :key="keyStampFailure">
                <div v-for="(opportunity,index) in listFailure" :key="index" class="m-4">
                  <CardUserAvatar :user="opportunity.user"></CardUserAvatar>
                  <TableOpportunities v-if="loadTableSuccess" :title="'Vendas não concretizas'" :listOpportunities="opportunity.opportunities" :key="index"></TableOpportunities>
                </div>
              </div>
          
        
            <div v-if="relOpp" class="overflow-auto">
              <UserOpportunity class="overflow-auto" :dateStart="dateStart" :dateEnd="dateEnd" :user="user" :key="user.id"></UserOpportunity>
        </div>
            
    </div>
</template>
<script setup>
  import { ref } from 'vue'
  import BuscaDataMultipleUser from '../BuscaDataMultipleUser.vue'
  import DonutChartComponent from './DonutChartComponent.vue';
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import html2pdf from "html2pdf.js"
  import UserOpportunity from '../UserOpportunity.vue'
  //import BarChartComponent from './BarChartComponent.vue'
  import TableOpportunities from './TableOpportunities.vue';
  import CardDashboardResume from './CardDashboardResume.vue'
  import CardUserAvatar from '@/components/CardUserAvatar.vue'
  const userStore = userDefineStore()

  const loadDataOportunidades = ref(false)
  const loadDataVendas = ref(false)
  const dateStart = ref(false)
  const dateEnd = ref(false)
  const user = ref(false)
  const relOpp = ref(false)
  const serieVendas = ref(false)
  const serieOportunidades = ref(false)
  const loadTableSuccess = ref(false)
  const loadTableFailure = ref(false)
  const cardSalesSuccess = ref(0)
  const cardSalesFailure = ref(0)
  const cardOpportunity = ref(0)
  const cardClients = ref(0)
  const cardLeads = ref(0)
  const listOpportunities = ref([])
  const keystamp = ref(0)
  const keyStampUsers = ref(0)
  const keyStampFailure = ref(0)
  const keyStampSuccess = ref(0)
  const listFailure = ref([])
  const donutLabels = ref([])
  const donutSuccessData = ref([])
  const donutFailureData = ref([])

  const receivedUser = (userReceived)=>{
      user.value = userReceived.user
      keyStampUsers.value =  new Date().getTime();
      dateStart.value = userReceived.dateStart
      dateEnd.value = userReceived.dateEnd
      donutFailureData.value = []
      donutSuccessData.value = []
      donutLabels.value = []
      graficoVendas()
      graficoOportunidades()
      salesFailureMultiSeller()
      salesSuccessMultiSeller()
  }

  // eslint-disable-next-line
  const exportToPDF = ()=> {
      var opt = {
          margin:0,
          filename:user.value.name + '.pdf',
          image:{ type: 'jpeg', quality: 0.98 },
          html2canvas:  { scale: 2 },
          jsPDF:{ unit: 'in', format: 'letter', orientation: 'landscape' }
  };

  html2pdf().set(opt).from(document.getElementById("element-to-convert")).save()
      }

  const graficoVendas = ()=>{
        let userArray = user.value.map((element)=>{
        
          return element.id
        })

          const url = process.env.VUE_APP_API_URL + "graficoVendas/" + userStore._company_id + '/' +  dateStart.value + '/' + dateEnd.value 
        const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
        };
        axios.post(url,{user: userArray}, config).then((response) => {
        
          serieVendas.value = response.data
          serieVendas.value.map((element)=>{
            donutLabels.value.push( element.name)
            //Posição 0 vendas concretizadas
            donutSuccessData.value.push(element.data[0])
            //Posição 1 vendas Não concretizadas
            donutFailureData.value.push(element.data[1])
          })
          loadDataVendas.value =true
          somaTotais()

        keystamp.value = new Date().getTime();
      })
    }
  const salesFailureMultiSeller = ()=>{
      

          const url = process.env.VUE_APP_API_URL + "reportSalesFailureMultiUser/" + userStore._company_id + '/' +  dateStart.value + '/' + dateEnd.value 
        const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
        };
        axios.post(url,{users:user.value}, config).then((response) => {
        
          listFailure.value = response.data
          loadTableFailure.value =true
          keyStampFailure.value = new Date().getTime();
      })
    }
  const salesSuccessMultiSeller = ()=>{
    
    const config = {
      headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
      const url = process.env.VUE_APP_API_URL + "reportSalesSuccessMultiUser/" + userStore._company_id + '/' +  dateStart.value + '/' + dateEnd.value
      axios.post(url,{users:user.value}, config).then((response) => {
        
          listOpportunities.value = response.data
          loadTableSuccess.value = true
          keyStampSuccess.value = new Date().getTime();
        })
        
      


       
    }
  const graficoOportunidades = ()=>{
        let userArray = user.value.map((element)=>{
        
          return element.id
        })

          const url = process.env.VUE_APP_API_URL + "graficoOportunidades/" + userStore._company_id + '/' +  dateStart.value + '/' + dateEnd.value 
        const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
        };
        axios.post(url,{user: userArray}, config).then((response) => {
        
          serieOportunidades.value = response.data
          loadDataOportunidades.value =true
          somaTotaisOp()
      })
    }

  const somaTotais = ()=>{
    cardSalesSuccess.value = 0
    cardSalesSuccess.value = 0

    for (let index = 0; index < serieVendas.value.length; index++) {
      cardSalesSuccess.value += parseFloat(serieVendas.value[index].data[0])
      cardSalesFailure.value += parseFloat(serieVendas.value[index].data[1])

    }
  }
  const somaTotaisOp = ()=>{
    cardOpportunity.value = 0
    cardLeads.value = 0
    cardClients.value = 0

    for (let index = 0; index < serieOportunidades.value.length; index++) {
      cardOpportunity.value += parseFloat(serieOportunidades.value[index].data[0])
      cardClients.value += parseFloat(serieOportunidades.value[index].data[1])
      cardLeads.value += parseFloat(serieOportunidades.value[index].data[2])

    }
  }
const receivedEvent = (item)=>{
  console.log(item)
}

</script>
<template>
    <div class="flex flex-col w-full">
        <span class="flex items-start">Questionários</span>
        <div class="flex flex-col ">
            <div class="flex flex-row ">
                <Combobox v-model="selected">
                    <div class="relative">
                        <div
                            class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                            <ComboboxInput
                                class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                :displayValue="(person) => person.title" @change="query = $event.target.value" />
                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </ComboboxButton>
                        </div>
                        <TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0"
                            @after-leave="query = ''">
                            <ComboboxOptions
                                class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <div v-if="filteredPeople.length === 0 && query !== ''"
                                    class="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>

                                <ComboboxOption v-for="person in filteredPeople" as="template" :key="person.id"
                                    :value="person" v-slot="{ selected, active }">
                                    <li class="relative cursor-pointer select-none text-start py-2 pl-2 pr-4" :class="{
                                        'bg-indigo-600 text-white': active,
                                        'text-gray-900': !active,
                                    }">
                                        <span class="block truncate"
                                            :class="{ 'font-medium': selected, 'font-normal': !selected }">
                                            {{ person.title }}
                                        </span>
                                        
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </TransitionRoot>
                    </div>
                </Combobox>
               
            </div>
        </div>


    </div>
</template>
  
<script setup>
  import { userDefineStore } from '@/store/UserStore'
import {
Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, TransitionRoot
} from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import axios from 'axios'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
    const user = userDefineStore()
  // eslint-disable-next-line
  const props = defineProps(['type'])
// eslint-disable-next-line
const emit = defineEmits(['sendData']);

const listData = ref([])
let selected = ref('')
let query = ref('')
const $swal = inject('$swal');
onBeforeMount(() => {
    getData();
})

const getData = () => {
    const url = process.env.VUE_APP_API_URL + "typeQuestionaries/" + user._company_id  + '/' + props.type
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).then((response) => {
  
        if(response.data[0]){

            listData.value = response.data
        }
        else{
            $swal.fire(
            'Ops!',
            'Você precisa cadastrar um questionário para qualificação de clientes antes de iniciar a qualificação',
            'warning'
        )
        }
    })
}

let filteredPeople = computed(() =>
    query.value === ''
        ? listData.value
        : listData.value.filter((person) =>
            person.title
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(query.value.toLowerCase().replace(/\s+/g, ''))
        )
)
watch(
    () => selected.value,
    () => {
        emit('sendData', selected.value);
    }
)
</script>
  
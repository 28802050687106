<template>
    <div class="flex flex-row items-center w-full">
            <div class="flex flex-col w-44 ml-4">
                <label class="flex text-base font-medium text-gray-900 ">Data início</label>
                <input type="date" v-model="dateStart"
                class="flex  bg-gray-50 border h-9 border-gray-300 text-gray-900
                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">

            </div>
            <div class="flex flex-col w-44 ml-4">
                <label class="flex text-base font-medium text-gray-900">Data fim</label>
                <input type="date" v-model="dateEnd"
                class="flex  bg-gray-50 border h-9 border-gray-300 text-gray-900
                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
            </div>
        <div  class="flex ml-3   mt-6">
            <ButtonLify @action-click="sendData">
                <font-awesome-icon icon="fa-solid fa-search" />
            </ButtonLify>
          
        </div>
    </div>
</template>
<script setup>
    import {ref, onMounted} from 'vue'
    import ButtonLify from '@/components/default/ButtonLify.vue'
    // eslint-disable-next-line
    const emit =defineEmits(['sendDates'])
    const dateStart = ref()
    const dateEnd = ref()
    onMounted(()=>{
        iniDates()
        sendData()
    })
    const iniDates = ()=>{
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    dateStart.value = now.getFullYear()+"-"+(month)+"-01";
    dateEnd.value = now.getFullYear()+"-"+(month)+"-"+(day) ;
   }
   const sendData = ()=>{
    emit('sendDates',{dateStart:dateStart.value, dateEnd:dateEnd.value})
   }
</script>
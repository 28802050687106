<template>
    <teleport to="body">
        <transition leave-active-class="duration-200">
            <div class="fixed inset-0 overflow-y-auto px-4 py-6 sm:px-0 z-50" scroll-region>
                <transition enter-active-class="ease-out duration-300" enter-from-class="opacity-0"
                    enter-to-class="opacity-100" leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <div class="fixed inset-0 transform transition-all">
                        <div class="absolute inset-0 bg-gray-500 opacity-75">


                        </div>
                    </div>
                </transition>
                <transition enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="
                            bg-white
                            rounded-lg
                            overflow-y-auto
                            overflow-x-hidden
                            overscroll-none
                            shadow-xl
                            transform
                            transition-all
                            sm:m-auto
                            w-2/4
                            h-auto
                            p-2
                        ">
                        <div @click="closeModal" class="flex justify-end items-end mb-3">
                            <span class=" w-4 h-4 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="border border-spacing-1 rounded-md w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                        <slot></slot>
                    </div>
                </transition>
            </div>
        </transition>
    </teleport>

</template>
<script setup>
// eslint-disable-next-line
const emit = defineEmits(['close']);
const closeModal = () => {
    emit('close')
}
</script>


<style scoped>

</style>
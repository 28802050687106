<template>
    <div class="flex flex-col">
  
        <div class="bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
        <span class="ml-4">Definição da Autmoação de Tarefa</span>
    </div>
    <div class="flex flex-row">
        <div class="flex">
            <div class="flex flex-row">
                <span class="text-red-500 items-start">*</span>

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Tipo de Automação</label>
            </div>
            <select v-model="typeAutomation" 
                class="grid col-span-2 ml-2  justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="Funil">Funil</option>
                <option value="SDR">SDR</option>
                <option value="Cliente">Cliente</option>
                <option value="Lead">Lead</option>
            </select>   
        </div>
        <div v-if="typeAutomation == 'Funil'" class="flex flex-row ml-4">
                <span class="text-red-500 items-start">*</span>

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Entrando na fase</label>
            </div>
            <select v-if="typeAutomation == 'Funil'" v-model="pointAutomation" 
                class="grid col-span-2 ml-2  justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option v-for="(item, index) in listPhases" :value="item.id" :key="index">{{item.phase}}</option>
            </select> 
            <div v-if="typeAutomation == 'SDR'" class="flex flex-row ml-4">
                <span class="text-red-500 items-start">*</span>

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Ponto Automação</label>
            </div>
            <select v-if="typeAutomation == 'SDR'" v-model="pointAutomation" 
                class="grid col-span-2 ml-2 justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option  value="Prospect Criado" >Prospect Criado</option>
                <option  value="Lead Qualificado" >Lead Qualificado</option>
            </select> 
    </div>
        <div class="bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
        <span class="ml-4">Descrição da Tarefa</span>
    </div>
    <div class="grid grid-cols-2">
        
       
        <div class="grid grid-cols-3 ">
              
            <div class="flex flex-row mt-4">
                <span class="text-red-500 items-start">*</span>

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Tipo de Tarefa</label>
            </div>
            <select v-model="typeTask" 
                class="grid col-span-2 ml-2 mt-4 justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option v-for="(item, index) in listTypeTask" :value="item.id" :key="index">{{item.type}}</option>
            </select>                     
            <div class="flex flex-row mt-4">
                <span class="text-red-500 items-start">*</span>

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Título da tarefa</label>
            </div>
            
            <input type="text" v-model="title"
                class=" grid col-span-2 ml-2 mt-4 justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">                        
                
            <div class="flex flex-row mt-4">
                <span class="text-red-500 items-start">*</span>

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Descrição da tarefa</label>
            </div>
            
            <input type="text" v-model="description"
                class=" grid col-span-2 ml-2 mt-4 justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">                        
                
            <div class="flex flex-row">
                <span class="text-red-500 items-start mt-4">*</span>
                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900 mt-4">
                Data de encerramento</label>
                </div>
            <div class=" grid col-span-2">
                <div class="flex flex-row">

                    <input type="number" v-model="days"
                    class=" justify-items-center  w-24  ml-2  h-8 mt-4 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                    placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span class=" h-8 mt-4 ml-2"> dias após a abertura da tarefa</span>
                </div>
            </div>   
        </div>
        <div class="grid grid-cols-3 ml-8">
            <div class="flex flex-row">
                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Encolvidos por Grupo</label>
            </div>
            <BuscaMultipleUsersGroup @sendUser="receivedGroup" :title="''"  
                class="z-50 grid col-span-2 justify-items-start w-72 h-8">
            </BuscaMultipleUsersGroup>  
            <div class="flex flex-row" >                
                <label class="z-10 grid col-span-1 justify-items-start 
                    text-sm font-normal text-gray-900">
                    Encolvidos por Usuário</label>
            </div>
            <BuscaMultipleUsers @sendUser="receivedUser" :title="''"  class="z-10 grid col-span-2 justify-items-start w-72 h-8"></BuscaMultipleUsers>  
         
            
           
            </div>
            <div class="flex justify-center item-center pt-2 mt-4 
                text-base font-sm h-8 ml-2 w-8 bg-indigo-500 text-indigo-50 
                rounded-md cursor-pointer"
                @click="saveAutomation">
                <font-awesome-icon icon="fa-solid fa-save" />
            </div>
        </div>
        <div class="flex flex-row  ml-4 mt-4">
            <div class="ml-4 mt-4" v-for="(item,index) in group" :key="index">
                <span> <font-awesome-icon icon="fa-solid fa-users" class="w-10 h-10 rounded-full" /></span>
                
                <span class="">{{ item.group }}</span>
            </div>
        </div>
        <div class="flex flex-row">

            <div class="flex flex-row ml-4 mt-4" v-for="(user,index) in involved" :key="index">
                <img v-if="user.avatar" class="w-10 h-10 rounded-full" 
                :src="returnAvatar(user.avatar)" >
                <span v-else> <font-awesome-icon icon="fa-solid fa-user" class="w-8 h-8 rounded-full" /></span>
                <span class="text-lg ml-4">{{user.name}}</span>
            </div>
        </div>
    </div>

</template>
<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref, inject } from 'vue'
  import BuscaMultipleUsers from '@/components/BuscaMultipleUsers.vue';
  import BuscaMultipleUsersGroup from '@/components/BuscaMultipleUsersGroup.vue'
  const userStore = userDefineStore()
  const $swal = inject('$swal');
  const typeAutomation = ref('')
  const description = ref('')
  const group = ref(false)
  const days = ref('')
  const title = ref('')
  const typeTask = ref('')
  const involved = ref(false)
  const pointAutomation = ref('')
  const listTypeTask = ref([])
  const listPhases = ref([])
  onMounted(()=>{
    getTypeTasks()
    getPhases()
  })
const receivedGroup = (item)=>{
    group.value = item.map((element) => {
      return {
        group: element.group,
      }})
}

  const getTypeTasks = ()=>{
    const url = process.env.VUE_APP_API_URL + "typeTasksCompany/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listTypeTask.value = response.data
    })
  }
  const getPhases = () => {
    const url = process.env.VUE_APP_API_URL + "phasesFunnels/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listPhases.value = response.data
    })
}
const returnAvatar = (avatar)=>{
    return `${process.env.VUE_APP_BASE_URL}${avatar}`
}
const saveAutomation = ()=>{
    const url = process.env.VUE_APP_API_URL + `taskAutomation`
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };

    const payload = {
        type: typeAutomation.value,
        point_automation:pointAutomation.value,
        title:title.value,
        involved_group: group.value,
        involved_users: involved.value,
        description:description.value,
        data:days.value,
        client:null,//client.value,
        user:null,//user.value,
        opportunity:true,//opportunity.value,
        lead:null,//lead.value,
        qualification:null,//qualification.value,
        type_tasks_id:typeTask.value ,          
        company_id:userStore._company_id,
    }
    axios.post(url,payload, config).then(function () {

        typeAutomation.value= ''
        pointAutomation.value= ''
        title.value= ''
        group.value= ''
        involved.value= ''
        description.value= ''
        days.value= ''
        typeTask.value = ''
        $swal.fire(
            '👍!',
            'Automação cadastrada!',
            'success'
        )
    })
}
const receivedUser= (item)=>{  
    involved.value =  item.map((element) => {
      return {
        id: element.id,    
        name:element.name,
        avatar: element.avatar
      }})
}
</script>
<template>
    <div class="bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
        <span class="ml-4">Informações da Tarefa</span>
    </div>
    <div class="grid grid-cols-2">
        
       
        <div class="grid grid-cols-3 ">
            <div class="flex flex-row">
                <span class="text-red-500 items-start">*</span>

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Titulo da tarefa</label>
            </div>
            <input type="text" v-model="title"
                class=" grid col-span-2 ml-2 justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">                        
            <div class="flex flex-row mt-4">
                <span class="text-red-500 items-start">*</span>

                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Descrição da tarefa</label>
            </div>
            <input type="text" v-model="description"
                class=" grid col-span-2 ml-2 justify-items-start mt-4 h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">                        
                <div class="flex flex-row items-start justify-items-start ">
                <span class="text-red-500  mt-4">*</span>
                <span class="grid col-span-1 text-sm font-normal text-gray-900 mt-4">
                Data de encerramento</span>
                </div>
            <input type="date" v-model="data"
            class=" grid col-span-2 justify-items-center    ml-2  h-8 mt-4 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <label class="grid col-span-1 mt-4 justify-items-start text-sm font-normal text-gray-900">
                Resultado da tarefa</label>
            <input type="text" v-model="result"
                class=" grid col-span-2 justify-items-start ml-2 h-8 mt-4
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">                        
                <label class="grid col-span-1 mt-4 justify-items-start text-sm font-normal text-gray-900">
                    Definir lembrete no calendário? 
                </label>
          
            <div class="flex flex-row mt-4">
                <select   v-model="show" 
            class=" grid col-span-2 justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="sim">Sim</option>
                <option value="não">Não</option>
            </select>
                
                <input v-if="show"  type="datetime" v-model="hour"  class=" grid col-span-2 ml-2 justify-items-start  h-8
                rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"> 
            </div>
        </div>
        <div class="grid grid-cols-3 ml-4 mr-2 ">
            <div class="flex flex-row">
                <span class="text-red-500 items-start">*</span>
                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Tipo
                </label>
            </div>
            <select v-if="!client"  v-model="type" @change="openSearch" 
            class=" grid col-span-2 justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option v-for="(item, index) in listTypes" :value="item" :key="index">{{item.type}}</option>
            </select>
            <span v-else class=" grid col-span-2 justify-items-start  h-8 font-semibold">Cliente</span>
            <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
        Envolvidos</label>

            <BuscaMultipleUsers @sendUser="receivedUser" :title="''"  class=" grid col-span-2 justify-items-start w-72 h-8"></BuscaMultipleUsers>  
           <div class="grid col-span-3 ">
           <div class="grid grid-cols-3">
            <div class="flex flex-col" v-for="(item,index) in involved" :key="index">
                <CardUserAvatar :user="item" ></CardUserAvatar>
            </div>
           </div>
           </div>
               
        </div>
    </div>
    <div class="bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-10 mb-4">
        <span class="ml-4">Conexões da Tarefa</span>
    </div>
        <div class="flex flex-row mt-4 justify-between" v-if="openBusca">
          <SearchOpportunity  @sendOpportunity="receivedOpportunity" :key="keystamp"></SearchOpportunity>
          <BuscaClientes v-if="openAllSearch" @sendClient="receivedClient"></BuscaClientes>
          <BuscaProdutos v-if="openAllSearch" @sendData="receivedProduto" :title="true" ></BuscaProdutos>
          <BuscaLeads    v-if="openAllSearch" @sendLead="receivedLead"></BuscaLeads>
        </div>
        <div v-else>
            <CardInfoClients :client="client"></CardInfoClients>
        </div>
    <div v-if="opportunity">
        
        <CardOpportunity :opportunity="opportunity" @closeOpportunity="closeOpportunity"/>
        
    </div>
        <div class="flex mt-20 justify-end">
            <button class="bg-blue-400 text-white font-semibold rounded-md p-2" @click="saveTask">Cadastrar</button></div>
        
</template>

<script setup>
  import { userDefineStore } from '@/store/UserStore'
  const user = userDefineStore()
import axios from 'axios'
import { ref, onMounted, inject } from 'vue';
import BuscaClientes from './BuscaClientes.vue';
import BuscaLeads from './BuscaLeads.vue';
import BuscaProdutos from './BuscaProdutos.vue';
import SearchOpportunity from './SearchOpportunity.vue';
import BuscaMultipleUsers from './BuscaMultipleUsers.vue';
import CardOpportunity from './CardOpportunity.vue';
import CardUserAvatar from './CardUserAvatar.vue';
import CardInfoClients from './CardInfoClients.vue';
// eslint-disable-next-line
const props = defineProps(['client'])
// eslint-disable-next-line
const emit = defineEmits(['sendClose']);
const $swal = inject('$swal');
const description = ref()
const show = ref(false)
const openBusca = ref(true)
const openAllSearch = ref(true)
const type = ref()
const title = ref()
const data = ref()
const result = ref()
const client = ref(null)
const calendar_id = ref(null)
const lead = ref(null)
const opportunity = ref(null)
const product = ref(null)
const client_id = ref(null)
const lead_id = ref(null)
const opportunity_id = ref(null)
const product_id = ref(null)
const involved = ref(null)
const hour = ref()
const listTypes = ref([])
const keystamp = ref(0)
onMounted(()=>{
    if(props.client){
        console.log(props.client)
        client.value = props.client
        type.value = client.value
        openBusca.value =false
    }
    getTypeTasks()
    iniDates()
})
const closeOpportunity = ()=>{
    keystamp.value = new Date().getTime();
    opportunity.value = null
    openAllSearch.value = true
}
const receivedClient = (item)=>{

    client.value = item
    client_id.value = item.id
}
const receivedUser= (item)=>{
    involved.value = item
  
}
const receivedOpportunity= (item)=>{
console.log(item)
    opportunity.value = item
    opportunity_id.value = item.opportunity_id

    openAllSearch.value = false
}
const receivedProduto = (item)=>{
   
    product.value = item
    product_id.value = item.id
}
const receivedLead = (item)=>{
  
    lead.value = item
    lead_id.value = item.id
}
const getTypeTasks = ()=>{
    const url = process.env.VUE_APP_API_URL + `typeTasksCompany/${user._company_id}`
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    axios.get(url, config).catch(function () { }).then(function (result) {
        listTypes.value = result.data
    })
}

const iniDates = ()=>{
  
  var now = new Date();
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() + 1)).slice(-2);

data.value = now.getFullYear()+"-"+(month)+"-"+(day) ;
hour.value = now.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute:'2-digit'
  });
  keystamp.value = new Date().getTime();
  }


  const saveTask = ()=>{
    const config = {
        headers: { Authorization: `Bearer ${user._api_token}` }
    };
    if(show.value){
    let payloadCalendar = {
       
        'description': description.value,
        'type': 'Lembrete',
        'data': data.value,
        'hora': hour.value,
        'client_id': client_id.value,
        'opportunity_id': opportunity_id.value,
        'title': title.value,
        'company_id': user._company_id,
        'user_id': user._user_id
    }
    const urlCalendar = process.env.VUE_APP_API_URL + "calendar"
   
    axios.post(urlCalendar, payloadCalendar, config).then((result) => {
        calendar_id.value=result.id
        
    })    
}
    let payload = {
        'title': title.value,
        'status': 'active',
        'result': null,
        'data': data.value,
        'involved': involved.value,
        'description': description.value,
        'type_tasks_id': type.value.id,
        'user_id': user._user_id,
        'lead_id': lead_id.value,
        'client_id': client_id.value,
        'opportunity_id': opportunity_id.value,
        'product_id': product_id.value,
        'qualification_id': null,
        'calendar_id': calendar_id.value,
        'company_id': user._company_id,    
    }
    const url = process.env.VUE_APP_API_URL + `tasks`
    
    axios.post(url,payload, config).then(function (result) {
    
        if(involved.value != null)
        {

            saveIvolved(result.data.id,)
        }
        $swal.fire(
            '👍!',
            'Tarefa cadastrada!',
            'success'
        )
        emit('sendClose');
    })

  }
  const saveIvolved = (task_id) => {
  
        const url = process.env.VUE_APP_API_URL + "involved/"
        const config = {
            headers: {
                Authorization: `Bearer ${user._api_token}`,
                'Content-Type': 'multipart/form-data'
            }
        };

        const payload = {
            'involved': involved.value,
            'tasks_id': task_id,
            'company_id': user._company_id,   
        }
        axios.post(url, payload, config)
            
    
}
</script>
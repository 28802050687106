<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>
            <SidebarMenu></SidebarMenu>
        </div>
        <div class="flex flex-col w-full mr-2" >
            <NavBar :section="`Produtos`" :menuItem="menuItem" :head="['Lista de Produtos','Cadastrar Produto','Avaliar Produto','Avaliações']" @navItem="navItem"></NavBar>
            <div v-if="formProduct" >
                <ModalItem @refresh="closeModal"  :title="'Novo Produto'"> 
                    <FormProducts @close="closeModal"/>
                </ModalItem>
            </div>
            <div v-if="listProduct" :key="keystamp" @navItem="navItem">
                    <ListProducts/>
               
            </div>
            <div v-if="reviewProduct">
                <ReviewProduct @navItem="navItem"/>
            </div>
            <div v-if="listReview">
                <ListReviews @openReview="openReview"/>
            </div>
            
        </div>
    </div>
</template>

<script setup>
import FormProducts from '@/components/FormProducts.vue'
import ListReviews from '@/components/ListReviews.vue'
import NavBar from '@/components/NavBar.vue'
import ReviewProduct from '@/components/ReviewProduct.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import ListProducts from '@/components/ListProducts.vue'
import ModalItem from '@/components/ModalItem.vue'
import { ref, onMounted } from 'vue'
const formProduct = ref(false)
const reviewProduct = ref(false)
const listReview = ref(false)
const listProduct = ref(true)
const keystamp = ref(0)
const idReview = ref(false)
const menuItem = ref('Cadastro')
const openReview = (id)=>{
    idReview.value = id
    navItem('Detalhe')
}
onMounted(() => {
    navItem('Lista de Produtos')
})
const closeModal = ()=>{
    formProduct.value = false
    keystamp.value = new Date().getTime();
    navItem('Lista de Produtos')
}
const navItem = (item) => {
    if (item == 'Cadastrar Produto') {
        formProduct.value = true
        reviewProduct.value = false
        listReview.value = false
        listProduct.value = false
        menuItem.value = 'Cadastro'
    }
    else if (item == 'Avaliar Produto') {
        formProduct.value = false
        listReview.value = false
        reviewProduct.value = true
        listProduct.value = false
         menuItem.value = 'Avaliar Produto'
    }
    else if (item == 'Avaliações') {
        formProduct.value = false
        reviewProduct.value = false
        listReview.value = true
        listProduct.value = false
         menuItem.value = 'Avaliações'
    }
    
    else if (item == 'Lista de Produtos') {
        formProduct.value = false
        reviewProduct.value = false
        listReview.value = false
        listProduct.value = true
         menuItem.value = 'Lista de Produtos'
    }
}
</script>

<style scoped></style>
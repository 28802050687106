<template>
    <div>
        <div class="flex flex-row">
                <span class="text-red-500 items-start">*</span>
                <label class="grid col-span-1 justify-items-start text-sm font-normal text-gray-900">
                    Processo de Automação</label>
            </div>

            <select v-model="automationSelected" @change="formatTask"
                class="grid col-span-2 ml-2  justify-items-start  h-8
                 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option v-for="(item, index) in listAutomations" :value="item" :key="index">{{item.title}}</option>
               
            </select>
            <div v-if="openFlow" class="flex flex-row mt-8 justify-center items-center">
                <div class="flex justify-center items-center text-white text-lg rounded-md w-32  max-h-24 cursor-pointer bg-blue-500 hover:bg-blue-600 hover:shadow-lg">
                    <span>Automação para {{ automation.type }}</span>
                </div>
                <div class="flex flex-col  justify-center items-center text-base font-semibold ml-4 mr-4">
                    <span class=" text-xl"> ➙ </span>
                </div>
                <div class="flex flex-col justify-center items-center text-white text-lg rounded-md w-48   cursor-pointer bg-blue-500 hover:bg-blue-600 hover:shadow-lg">
                    <span>Entrando na fase</span>
                    <span> {{ automation.point_automation }}</span>
                </div>
                <div class="flex flex-col  justify-center items-center text-base font-semibold ml-4 mr-4">
                    <span class=" text-xl"> ➙ </span>
                </div>
                <div class="flex flex-col justify-center items-center text-white rounded-md w-44 h-full cursor-pointer
                bg-blue-500 hover:bg-blue-600 hover:shadow-lg">
                    <span> Cria a tarefa:</span>
                    <div class="flex flex-row">
                        <span class="text-sm "> {{ automation.title }}</span>
                    </div>
                </div>
                <span class="ml-4 mr-4 text-xl"> ➙ </span>
                <div class="flex flex-col justify-center items-center text-white rounded-md w-44 h-full cursor-pointer
                bg-blue-500 hover:bg-blue-600 hover:shadow-lg">
                
                <span class="text-sm italic mt-4">Ação: {{ automation.description }}</span>
                <span class="text-sm italic mt-4">Com prazo de: {{ automation.data }} dias</span>
            </div>
           
        </div>
        <div v-if="openFlow" class="bg-gradient-to-r from-cyan-500 to-blue-500 text-center text-white rounded-md m-4">
            <span class="">Participantes da tarefa</span>
        </div>
            <div v-if="openFlow" class="flex flex-row m-4">
                <div class="flex flex-row m-4">
                    <div class="" v-for="(item,index) in group" :key="index">
                        <span> 
                            <font-awesome-icon icon="fa-solid fa-users" class="w-10 h-10 rounded-full" />
                        </span>                
                        <span class="">
                            {{ item.group }}
                        </span>
                    </div>
                </div>
                <div class="flex flex-row m-4">
                    <div class="flex flex-row" v-for="(user,index) in users" :key="index">
                        <img v-if="user.avatar" class="w-10 h-10 rounded-full" 
                            :src="returnAvatar(user.avatar)" >
                        <span v-else> 
                            <font-awesome-icon icon="fa-solid fa-user" class="w-8 h-8 rounded-full" />
                        </span>
                        <span class="text-lg ">{{user.name}}</span>
                    </div>
                </div>
            </div>
    </div>
</template>
<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import { onMounted, ref } from 'vue'
  
  const userStore = userDefineStore()
  const listAutomations = ref([])
  const users = ref([])
  const group = ref([])
  const automation = ref(false)
  const automationSelected = ref(false)
  const openFlow = ref(false)
  
  onMounted(() => {
    getAutomations();

})
const getAutomations = () => {
    const url = process.env.VUE_APP_API_URL + "taskAutomations/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).then((response) => {
        listAutomations.value = response.data
  
    })
}
const formatTask = ()=>{
    openFlow.value = false
     users.value = JSON.parse(automationSelected.value.involved_users)
     group.value = JSON.parse(automationSelected.value.involved_group)
    automation.value = automationSelected.value
    automation.value.involved_users = users.value
    automation.value.involved_group = group.value
    openFlow.value = true
}
const returnAvatar = (avatar)=>{
    return `${process.env.VUE_APP_BASE_URL}${avatar}`
}
</script>
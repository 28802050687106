<template>
    <div v-if="load" class="flex flex-col mr-6 ml-4 mt-4 ">
                <div class="grid grid-cols-12 gap-1 bg-white">
                    <div class=" col-span-3 bg-lify_blue  text-white font-sm  px-4 py-2">Produto</div>
                    <div class=" col-span-5 bg-lify_blue  text-white font-sm  px-4 py-2  ">Descrição</div>
                    <div class=" col-span-2 bg-lify_blue  text-white font-sm  px-4 py-2  ">Valor</div>
                    <div class=" col-span-2 bg-lify_blue  text-white font-sm  px-4 py-2">Ação</div>
                </div>
            
        </div>
    <div  v-for="(line, index) in ListProducts" :key="index"
            class="grid grid-cols-12 gap-2 bg-white py-3 mr-6 ml-4  hover:bg-neutral-100">
                <div class="col-span-3 text-sm">{{ line.product }}</div>
                <div class="col-span-5 text-sm ">{{ line.description }}</div>
                <div class="col-span-2 text-sm">{{ formatarReal(line.value) }}</div>
                <div class="col-span-2 font-sm">
                    <button class="text-indigo-500 ml-2" @click="editProducts(line)"> <font-awesome-icon
                            icon="fa-solid fa-edit" /></button>
                    <button class="text-red-400 ml-2" @click="deleteProducts(line.id)"> <font-awesome-icon
                            icon="fa-solid fa-trash" /></button>
                </div>
            </div>
           <div v-if="update">
            <ModalItem @refresh="closeModal"  :title="'Edição de Produto'"> 
                <FormProducts :product="product" :update="true" @update="updateProducts"></FormProducts>
            </ModalItem>
           </div>
</template>
<script setup>
  import { userDefineStore } from '@/store/UserStore'
  import axios from 'axios'
  import {  onMounted, ref } from 'vue'
  import ModalItem from './ModalItem.vue';
  import FormProducts from './FormProducts.vue';
  const user = userDefineStore()


const ListProducts = ref([])

const product = ref('')
const update = ref(false)
const load = ref(false)

onMounted(() => {
getProducts();
})
const closeModal = () => {
    update.value = false
    getProducts();
}


const editProducts = (item) => {

    product.value = item
    update.value =true
}
/* eslint-disable */
const updateProducts = (payload) => {
const url = process.env.VUE_APP_API_URL + "product/" + payload.id
let product = { 'product': payload.product, 'description': payload.description,'value': payload.value, company_id: user._company_id }
const config = {
headers: { Authorization: `Bearer ${user._api_token}` }
};
axios.put(url, product, config).then(() => {

getProducts()
})
}
const deleteProducts = (product) => {
const url = process.env.VUE_APP_API_URL + "product/" + product
const config = {
headers: { Authorization: `Bearer ${user._api_token}` }
};
axios.delete(url, config).then(() => {
getProducts()
})
}
const getProducts = () => {
const url = process.env.VUE_APP_API_URL + "products/" + user._company_id
const config = {
headers: { Authorization: `Bearer ${user._api_token}` }
};
axios.get(url, config).then((response) => {

ListProducts.value = response.data
load.value= true
})
}


const formatarReal = (valor)=>{
return parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}




</script>
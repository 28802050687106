<template>
    <div class="p-2 mb-3 w-full  flex flex-col border-solid border-2 border-re-600
                                                                                                                                                                                                                         bg-white rounded-lg hover:shadow-lg "
        :class="color">
        <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
            element.type
        }}</p>
        <div class="flex flex-col justify-between  ">

            <div class="flex justify-between">
                <p class="ml-2 text-gray-700 font-semibold font-sans tracking-wide">{{
                    element.name_opportunity
                }}</p>
                <font-awesome-icon v-if="element.qualification_temperature == 1" icon="fa-solid fa-icicles" class="text-blue-300" />
                <font-awesome-icon v-if="element.qualification_temperature == 2" icon="fa-solid fa-mug-hot" class="text-yellow-300" />
                <font-awesome-icon v-if="element.qualification_temperature == 3" icon="fa-solid fa-fire" class="text-red-500" />
            </div>


            <div class="flex">

                <button v-if="element.type == 'Cliente'"
                    class="p-1 focus:outline-none focus:shadow-outline text-lify_blue hover:text-lify_blue_hover"
                    @click="openContact(element)">
                    <font-awesome-icon icon="fa-solid fa-address-book" />
                </button>

                <button v-if="element.type == 'Lead'"
                    class="p-1 focus:outline-none focus:shadow-outline text-lify_blue hover:text-lify_blue_hover"
                    @click="sendWhatsapp(element)">
                    <font-awesome-icon icon="fa-brands fa-whatsapp" />
                </button>
                <a v-if="element.type == 'Lead'"
                    class="p-1 focus:outline-none focus:shadow-outline text-lify_blue hover:text-lify_blue_hover"
                    :href="`mailto:${element.email_opportunity}`">
                    <font-awesome-icon icon="fa-solid fa-envelope" />
                </a>
                <a v-if="element.type == 'Lead'" :href="`tel:${element.phone_opportunity}`"
                    class="p-1 focus:outline-none focus:shadow-outline text-lify_blue hover:text-lify_blue_hover">
                    <font-awesome-icon icon="fa-solid fa-phone" />
                </a>

                <button class="p-1 focus:outline-none focus:shadow-outline text-lify_blue hover:text-lify_blue_hover"
                    @click="openModalSchedule(element)">
                    <font-awesome-icon icon="fa-solid fa-calendar-days" />
                </button>
                <button class="p-1 focus:outline-none focus:shadow-outline text-lify_blue hover:text-lify_blue_hover"
                    @click="openModalvalue(element)">
                    <font-awesome-icon icon="fa-solid fa-dollar-sign" />
                </button>
                <button class="p-1 focus:outline-none focus:shadow-outline text-lify_blue hover:text-lify_blue_hover"
                @click="openComment(element)">


                    <font-awesome-icon icon="fa-solid fa-comment" />
                </button>
                <button aria-label="Delete user"
                    class="p-1 focus:outline-none focus:shadow-outline text-red-500 hover:text-red-600"
                    @click="onDelete(element)">
                    <font-awesome-icon icon="fa-solid fa-trash" />
                </button>
            </div>
        </div>
        <p class="flex text-start items-start text-gray-700  italic text-sm ">  <font-awesome-icon icon="fa-solid fa-user-tie" class="mr-2"/>{{ element.seller_name }}</p>
        <p class="flex text-start items-start text-gray-700 italic text-sm  ">Criada em: {{ formatDate(element.created_at) }}</p>
        <p class="flex text-start items-start text-gray-700 italic text-sm  ">Movimentada: {{ formatDate(element.updated_at) }}</p>
        <p class="flex text-start items-start text-gray-700 italic text-sm  "><font-awesome-icon icon="fa-solid fa-clock" /> {{ inDays(element.created_at) }}</p>

    </div>
</template>

<script setup>
import { inject } from 'vue'
const $swal = inject('$swal');
// eslint-disable-next-line
const props = defineProps(['element', 'color'])

// eslint-disable-next-line
const emit = defineEmits(['sendQualify', 'openModal', 'openModalSchedule','openComment','openModalvalue']);
// eslint-disable-next-line
const sendQualify = (item) => {
    emit('sendQualify', item);
}
const openComment = (item) => {
    emit('openComment', item);
}
const openModalSchedule=  (item)=>{
    emit('openModalSchedule', item);
}
const openModalvalue=  (item)=>{
    emit('openModalvalue', item);
}
const onDelete = () => {
    $swal.fire(
        'Pense bem',
        'Ao deletar esta tarefa você perderá totalmente o histórico atrelado, você pode também editar esta tarefa',
        'warning'
    )
}
function formatDate(date) {
    if (date) {
        let input = new Date(date);
        return input.toLocaleString();
    } else {
        return '';
    }
}
const sendWhatsapp = () => {
    const text = 'Este é um teste de mensagem custonizada'
    const phone = '55' + props.element.phone_opportunity.replace(/[^0-9]/g, '')
    const url = 'https://wa.me/' + phone + '?text=' + text;
    window.open(url)
}
const openContact = (item) => {
   
    emit('openModal', item.client_id);
}
const inDays=(dateStart)=>{
    var date1 = new Date(dateStart);
var date2 = new Date();
var timeDiff = Math.abs(date2.getTime() - date1.getTime());
var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
return diffDays;
}
</script>

<style scoped></style>
<template>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100  overflow-x-hidden">
        <div>
            <SidebarMenu></SidebarMenu>
        </div>
        <div class="flex flex-col w-full">
            <NavBar :section="`Questionário`" :menuItem="menuItem" :head="[ 'Lista Questionários','Perguntas','Novo']" @navItem="navItem"></NavBar>
            <div v-if="navList" class="flex flex-row ml-2 mt-4">
        <ListQuestionaries></ListQuestionaries>
        </div>
            <div class="mt-4" >
                <ModalItemSm @refresh="closeModal" v-show="newQuestionarie" :title="'Novo Questionário'">

                    <FormQuestionary  @saved="questionaryReceived" />
                </ModalItemSm>
            </div>
            <div v-if="navQuestion" class="flex flex-col">
                
            
            <div  class="flex flex-row ml-2  items-center">
                
                    <div class="flex flex-col p-4">
                        <p class=" text-gray-700 font-semibold font-sans" :class="classTxtError">Selecione o questionário</p>
                        <select v-model="questionary_id" :class="classError" @change="detailQuestionary"
                            class=" rounded-md border border-gray-300 bg-white text-black py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <option v-for="(item, index) in questionaries" :value="item.id" :key="index">
                                {{ item.title }}</option>
                        </select>
                    </div>
                    <ButtonLify :title="'Nova Pergunta'" @action-click="openQuestions"></ButtonLify>
                    
                
            </div>
        </div>
            <div class="flex h-screen flex-col items-center">
                <div>


                </div>
                <div v-if="questionary_id" class="w-full  h-5/6 overflow-y-auto  overflow-x-hidden  text-center">
                    <draggable :list="questions" :disabled="false" item-key="name" class="list-group" ghost-class="ghost"
                        group="people" @start="dragging = true" @end="checkMove">
                        <template #item="{ element }">
                            <card :element="element" @deleteQuestion="deleteQuestion" @editQuestion="editQuestion"
                                v-if="questionary_id == element.questionary_id "></card>
                        </template>
                    </draggable>
                </div>
            </div>
        
        
    </div>
        <FormModal v-if="modal" @close="close">
            <FormQuestions @sendQuestion="receivedQuestion" :itemEdit="itemEdit" @updateQuestion="updateQuestion"
                :questionary_id="questionary_id"></FormQuestions>
        </FormModal>
    </div>
  
</template>

<script setup>

import card from '@/components/CardQuestion.vue'
import FormModal from '@/components/FormModal.vue'
import FormQuestionary from '@/components/FormQuestionary.vue'
import FormQuestions from '@/components/FormQuestions.vue'
import ListQuestionaries from '@/components/ListQuestionaries.vue'
import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { inject, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import ModalItemSm from '@/components/ModalItemSm.vue'
import draggable from 'vuedraggable'
import ButtonLify from '@/components/default/ButtonLify.vue'
const userStore = userDefineStore()
const $swal = inject('$swal');
const questions = ref('');
const itemEdit = ref('');
const modal = ref(false);
const questionaries = ref();
const questionary_id = ref(false);
const questionaryItem = ref(false);
const questionarySelected = ref(false);
const newQuestionarie = ref(false);
const navQuestion = ref(false);
const navList = ref(true);
const classError = ref('');
const classTxtError = ref('');
const menuItem = ref('Lista Questionários')
const router = useRouter()
const close = () => {
    itemEdit.value = ''
    modal.value = false
}
onMounted(() => {
    getQuestions();
    getQuestionary();
})
const closeModal = ()=>{
    newQuestionarie.value = false
    navItem('Lista Questionários')
}
const navItem = (item) => {
    if (item == 'Lista Questionários') {
        navQuestion.value = false
        navList.value = true
        newQuestionarie.value = false
        menuItem.value = 'Lista Questionários'
    }
    else if (item == 'Perguntas') {
        navQuestion.value = true
        navList.value = false
        newQuestionarie.value = false
         menuItem.value = 'Perguntas'
    }
    else if (item == 'Novo') {
        newQuestionarie.value = true
        navQuestion.value = false
        navList.value = false
         menuItem.value = 'Novo'
    }
}

const detailQuestionary = (element)=>{
    questionaries.value.map((questionary)=>{
        if(questionary.id == element.target.value){
            questionarySelected.value = questionary;
        }
    })

}
watch(
  () => questionarySelected.value,
  () => {
    questionaryItem.value = questionarySelected.value
})
const openQuestions = ()=>{
    if(questionary_id.value){
        modal.value = true
    }
    else{
        $swal.fire(
            'Ops!',
            'Você precisa escolher um questionário para criar uma pergunta.',
            'error'
        )
    }
}
const questionaryReceived = ()=>{
    getQuestionary();
}

const editQuestion = (item) => {
    itemEdit.value = item
    modal.value = true
}
const deleteQuestion = (item) => {
    const url = process.env.VUE_APP_API_URL + "question/" + item.id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.delete(url, config).catch(function () {
        router.push('/');
    }).then(() => {
        getQuestions()

    })
}
const getQuestions = () => {
    const url = process.env.VUE_APP_API_URL + "questions/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {

        //  router.push('/');
    }).then((response) => {
        questions.value = response.data
    })
}
const getQuestionary = () => {
    const url = process.env.VUE_APP_API_URL + "questionaries/" + userStore._company_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {

        router.push('/');
    }).then((response) => {

        questionaries.value = response.data
    })
}
const updateQuestion = (payload) => {
    const url = process.env.VUE_APP_API_URL + "question/" + payload.id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, payload, config).catch(function () {
    }).then(() => {
        modal.value = false
        itemEdit.value = ''
        getQuestions()
    })
}
const saveOrder = () => {
    if (questionary_id.value) {
        $swal.fire(
            'Ops!',
            'Você precisa escolher uma categoria para reordenar as perguntas',
            'error'
        )
        classError.value = 'border-2 border-rose-600 '
        classTxtError.value = 'text-lg text-rose-600 '
    }
    else {

        var newOrder = questions.value.map((element) => {
            if (element.questionary_id == questionary_id.value) {
                return element;
            }
        }
        ).filter(function (element) {
            return element !== undefined;
        });
        newOrder.map(
            (element, index) => {
                element.order = index;
                updateQuestion(element)
                return element
            }
        )
    }
}
const receivedQuestion = (payload) => {
    const url = process.env.VUE_APP_API_URL + "question"
    payload.order = questions.value.length
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.post(url, payload, config).catch(function () {
        //   router.push('/');

    }).then(() => {

        getQuestions()
        modal.value = false
    })
}
const checkMove = () => {

    saveOrder();
}
</script>

<style scoped></style>
<template>
  <div>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
        <div>
            <SidebarMenu></SidebarMenu>
        </div>
        <div class="flex flex-col w-full" >
            <NavBar :section="`Oportunidades`" ></NavBar>
            <div v-if="load">
                <DetailOpportunity  :opportunity="opportunity"/>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import DetailOpportunity from '@/components/DetailOpportunity.vue'
import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
const userStore = userDefineStore()
const route = useRoute()
const load = ref(false)


const opportunity = ref()
onMounted(() => {
    opportunity.value = route.params.opportunity_id
    getOpportunty()
   
    
})
const getOpportunty = () => {
const url = process.env.VUE_APP_API_URL + "opportunity/" + route.params.opportunity_id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.get(url, config).catch(function () {
        //   router.push('/');
    }).then((response) => {
        opportunity.value = response.data
        load.value = true
    })
}

</script>

<style scoped>
    
</style>
<template>
    <div>
        <span class="text-xl text-lify_blue">Relatório de qualificação de Leads</span>
    <div class="flex flex-row h-10 p-2 mt-4">
        <label class="block mb-1 text-base font-medium text-gray-900 ">Data início</label>
        <input type="date" v-model="dateStart"
        class="ml-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
        <label class="block ml-4 mb-1 text-base font-medium text-gray-900">Data fim</label>
        <input type="date" v-model="dateEnd"
        class="ml-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500">
        <div  class="flex justify-center items-center ml-2">
            <ButtonLify @action-click="getReviews">
                <font-awesome-icon icon="fa-solid fa-search" />
            </ButtonLify>
          
        </div>
    </div>
    <div>
        <div class="grid grid-cols-5 ml-4 mr-4 mt-4">
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Lead</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Data</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Responsável</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Pontos</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Abrir</span>
        </div>
        <div class="grid grid-rows-1 mt-2  border-indigo-300 hover:bg-slate-200 mr-4 ml-4" v-for="(review, index) in listReviews" :key="index">
            <div class="grid grid-cols-5 mt-2 border-indigo-300">
            <span class="grid col-span-1 text-base  text-black">{{ review.lead_name }}</span>
            <span class="grid col-span-1 text-base  text-black">{{ formateDate(review.created_at) }}</span>
            <span class="grid col-span-1 text-base  text-black">{{ review.user_name }}</span>
            <span class="grid col-span-1 text-base  text-black">{{ review.points }}</span>
            <span class="grid col-span-1 text-base  text-black">
                    <ButtonLify v-show="!openQuestions" @action-click="openReview(review.answers)" class="grid col-span-1 justify-items-center justify-self-center" >
                        <font-awesome-icon icon="fa-solid fa-folder-open" class="flex items-center text-center" />
                    </ButtonLify>
                    <ButtonLify v-show="openQuestions" @action-click="closeReview()" class="grid col-span-1 justify-items-center justify-self-center" >
                        <font-awesome-icon icon="fa-solid fa-folder-closed" class="flex items-center text-center" />
                    </ButtonLify>
                </span>
            
        </div>
        </div>
        <div v-if="openQuestions" > 
        <ModalItem @refresh="closeReview">             
            <div class="grid col-span-4 mt-4 mb-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                <span class=" ml-4">Questionário</span>
             </div>
        <div v-for="(element, index) in questions" class="mt-2  mr-4" :key="index">
            <LineAnswers  :element="element"></LineAnswers>
        </div> 
    </ModalItem>          
    </div>
    </div>
</div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
  import axios from 'axios'
  import { userDefineStore } from '@/store/UserStore'
  import ModalItem from '../ModalItem.vue';
  import LineAnswers from '../LineAnswers.vue';
  import ButtonLify from '../default/ButtonLify.vue';
  const userStore = userDefineStore()
const dateStart = ref()
const dateEnd = ref()
const listReviews = ref()
const questions = ref([])
const openQuestions = ref(false)
onMounted(() => {
    iniDates()
    getReviews()
})
const getReviews = ()=>{
    const url = process.env.VUE_APP_API_URL + "reportReviewLeads/" + userStore._company_id + '/' + dateStart.value + '/' + dateEnd.value
      const config = {
          headers: { Authorization: `Bearer ${userStore._api_token}` }
      };
      
    axios.get(url, config).then((response) => {
     
      listReviews.value = response.data
})
}
const iniDates = ()=>{
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    dateStart.value = now.getFullYear()+"-"+(month)+"-01";
    dateEnd.value = now.getFullYear()+"-"+(month)+"-"+(day) ;
   }
   const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const openReview = (item)=>{

questions.value = JSON.parse(item)
openQuestions.value =true
}
const closeReview = ()=>{
    
    questions.value = ''
    openQuestions.value =false
}

</script>
<template>
    <div >
        <div>
            <span class="flex text-lg text-start text-lify_blue">{{ title }}</span>
        <div class="grid grid-cols-6 mt-2 ml-4 mr-4">
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Cliente</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Produto</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Data</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Vendedor</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">R$</span>
            <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Abrir</span>
        </div>
        <div   class="bg-white ml-4 mr-4">
           
            <div class="grid grid-rows-1 mt-2  border-lify_blue hover:bg-slate-200" v-for="(opportunity, index) in listOpportunities" :key="index">
                <div class="grid grid-cols-6 mt-2 border-indigo-300">

                    <span class="grid col-span-1 text-base   text-black">{{ opportunity.name_opportunity }}</span>
                    <span class="grid col-span-1 text-base   text-black">{{ opportunity.product }}</span>
                    <span class="grid col-span-1 text-base   text-black">{{ formateDate(opportunity.updated_at) }}</span>
                    <span class="grid col-span-1 text-base   text-black">{{ opportunity.name_seller }}</span>
                    <span class="grid col-span-1 text-base   text-black">{{ formatarReal(opportunity.value_opportunity) }}</span>
                    <span class="grid col-span-1 text-base  text-black">
                    <ButtonLify :icon="true" v-show="!openOpportunity" @action-click="openOpportunities(opportunity)" class="grid col-span-1 justify-items-center justify-self-center" >
                        <font-awesome-icon icon="fa-solid fa-folder-open" class="flex items-center text-center" />
                    </ButtonLify>
                    <ButtonLify :icon="true" v-show="openOpportunity" @action-click="closeOpportunities()" class="grid col-span-1 justify-items-center justify-self-center" >
                        <font-awesome-icon icon="fa-solid fa-folder-closed" class="flex items-center text-center" />
                    </ButtonLify>
                </span>
                    
                </div>
            </div>
        </div>
        <div v-if="openOpportunity" > 
        <ModalItem @refresh="closeOpportunities">             
       <CardOpportunity :opportunity="opportunity"></CardOpportunity>
    </ModalItem>          
    </div>
    </div>
    </div>
</template>
<script setup>
import {ref} from 'vue'
import ModalItem from '../ModalItem.vue';
import CardOpportunity from '../CardOpportunity.vue';
import ButtonLify from '../default/ButtonLify.vue';
// eslint-disable-next-line
const props = defineProps(['listOpportunities','title'])
// eslint-disable-next-line
const emit = defineEmits(['sendData']);
const openOpportunity = ref(false)
const opportunity = ref('')

const openOpportunities = (item)=>{

opportunity.value = item
openOpportunity.value =true
}
const closeOpportunities = ()=>{

opportunity.value = ''
openOpportunity.value =false
}
const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const formatarReal = (valor)=>{

return  parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
</script>
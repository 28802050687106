<template>
    <div v-if="loadTasks" class="flex flex-col border border-blue-500 text-start rounded-md p-2">
        <div class="grid col-span-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
          <div class="flex flex-row justify-between">

            <span class="ml-4 ">Tarefas do cliente</span>
            <span @click="openTaskForm = !openTaskForm" class="cursor-pointer">
              <font-awesome-icon  class=" text-white mr-4" 
              icon="fa-solid fa-plus" />
            </span>
          </div>      
        </div>
        <div class="flex flex-row p-2 justify-between">
            <button @click="controlButton('Hoje')"      :class="{ 'bg-blue-500': btnHoje, 'bg-cyan-500': !btnHoje }" class="text-sm cursor-pointer  text-white p-2 rounded-md">Hoje</button>
            <button @click="controlButton('Vencidas')"  :class="{ 'bg-blue-500': btnVencida, 'bg-cyan-500': !btnVencida }" class="text-sm cursor-pointer  text-white p-2 rounded-md">Vencidas</button>
            <button @click="controlButton('Futuras')"   :class="{ 'bg-blue-500': btnfuturo, 'bg-cyan-500': !btnfuturo }" class="text-sm cursor-pointer  text-white p-2 rounded-md">Futuras</button>

        </div>
        <div v-for="(task, index) in listtasks" :key="index" class="overflow-y-auto overflow-x-hidden ">
            <CardTask :task="task" :color="'bg-blue-500'" @sendOpen="sendOpen" @sendRefresh="sendRefresh"></CardTask>
        </div>
        <ModalItem v-if="openTaskForm" @refresh="closeModal" :title="'Nova Tarefa'">
            <FormTask @sendClose="closeModal" :client="clientInfo"></FormTask>
        </ModalItem>
    </div>
</template>
<script setup>
import {ref, onMounted} from 'vue'
import { userDefineStore } from '@/store/UserStore'
import CardTask from './CardTask.vue';
import axios from 'axios'
import ModalItem from './ModalItem.vue';
import FormTask from './FormTask.vue';
const user = userDefineStore()
// eslint-disable-next-line
const props = defineProps(['clientInfo'])
//const $swal = inject('$swal');
// eslint-disable-next-line
const emit = defineEmits(['sendOpen']);
const listtasks = ref()
const loadTasks = ref()
const btnHoje = ref(true)
const btnfuturo = ref(false)
const openTaskForm = ref(false)
const btnVencida = ref(false)

onMounted(()=>{
  console.log(props.clientInfo)
  clientTasksNow()
})

const sendOpen = (task)=>{
    emit('sendOpen', task);
}
const sendRefresh = ()=>{
  if(btnHoje.value){
    clientTasksNow()
  }
  // eslint-disable-next-line
  else if(btnfuturo.value ){
    clientFutureTasks()
  }
  else if(btnVencida.value ){
    clientDueTasks()
  }
}
const controlButton = (btn)=>{
  if(btn == 'Hoje'){
    btnHoje.value = true
    btnfuturo.value = false
    btnVencida.value = false
    clientTasksNow()
  }
  else if(btn == 'Vencidas'){
    btnHoje.value = false
    btnfuturo.value = false
    btnVencida.value = true
    clientDueTasks()
  }
  else{
    btnHoje.value = false
    btnfuturo.value = true
    btnVencida.value = false
    clientFutureTasks()
  }
}
const closeModal = ()=>{
     openTaskForm.value = false;
     emit('refresh');
}

const clientTasksNow = ()=>{
  const url = process.env.VUE_APP_API_URL + "clientTasksNow/" + props.clientInfo.id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listtasks.value = response.data
    loadTasks.value = true
  })
}
const clientDueTasks = ()=>{
    const url = process.env.VUE_APP_API_URL + "clientDueTasks/" + props.clientInfo.id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listtasks.value = response.data
    loadTasks.value = true
  })
}
const clientFutureTasks = ()=>{
    const url = process.env.VUE_APP_API_URL + "clientFutureTasks/" + props.clientInfo.id
  const config = {
    headers: { Authorization: `Bearer ${user._api_token}` }
  };
  axios.get(url, config).then((response) => {
    listtasks.value = response.data
    loadTasks.value = true
  })
}

</script>
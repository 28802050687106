<template>
    <div>
        <div class="flex flex-row  h-screen w-screen bg-neutral-100">
            <div>
                <SidebarMenu :key="keystamp"></SidebarMenu>
            </div>
            <div class="flex flex-col w-full">
                <NavBar :section="'Configurações'" :head="['Home']" @navItem="navItem"></NavBar>
                <div v-if="home" class="grid grid-cols-4 grid-rows-2 gap-4 p-8">
                    <CardSetings v-if="user._user_type == 'Gerente'" @navItem="navItem" :title="'Automação Tarefas'" :color="'bg-blue-500  hover:bg-blue-600'" :icon="'fa-screwdriver-wrench'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Fluxo de Automações'" :color="'bg-blue-500  hover:bg-blue-600'" :icon="'fa-diagram-project'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Perfil'" :color="'bg-blue-500  hover:bg-blue-600'" :icon="'fa-building-user'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Fonte Prospect'" :color="'bg-blue-500  hover:bg-blue-600'" :icon="'fa-sort'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Categorias'" :color="'bg-blue-500  hover:bg-blue-600'" :icon="'fa-sort'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Segmentos'" :color="'bg-blue-500  hover:bg-blue-600'" :icon="'fa-sort'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Fases do Funil'" :color="'bg-blue-500  hover:bg-blue-600'" :icon="'fa-filter'"></CardSetings>
                    <CardSetings @navItem="navItem" :title="'Tipos de Tarefas'" :color="'bg-blue-500  hover:bg-blue-600'" :icon="'fa-list-check'"></CardSetings>
                    
                   
                </div>
             
                <div v-if="category" class="mr-4">
                    <CategoriesView/>
                </div>
                <div v-if="dataProspect" class="mr-4">
                    <DataProspectView/>
                </div>
                <div v-if="clientSegment" class="mr-4">
                    <ClientSegmentationView/>
                </div>
                <div v-if="phasesFunnel" class="mr-4">
                    <PhasesFunnelView/>
                </div>
                <div v-if="typeTasks" class="mr-4">
                    <TypeTask/>
                </div>
                <div v-if="perfil" class="mr-4">
                    <PerfilView @reload-avatar="onReloadAvatar"/>
                </div>
                <div v-if="automation" class="mr-4">
                    <TaskAutomationView/>
                </div>
                <div v-if="flowAutomation" class="mr-4">
                    <FlowAutomationView/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import { ref } from 'vue'
import CategoriesView from './CategoriesView.vue'
import PhasesFunnelView from './PhasesFunnelView.vue'
import DataProspectView from './DataProspectView.vue'
import TaskAutomationView from './TaskAutomationView.vue'
import ClientSegmentationView from'./ClientSegmentationView.vue'
import TypeTask from './TypeTask.vue'
import PerfilView from './PerfilView.vue'
import CardSetings from '@/components/CardSetings.vue'
import FlowAutomationView from './FlowAutomationView.vue'
import { userDefineStore } from '@/store/UserStore'
  const user = userDefineStore()
const dataProspect = ref(false)
const category = ref(false)
const phasesFunnel = ref(false)
const typeTasks = ref(false)
const automation = ref(false)
const flowAutomation = ref(false)
const perfil = ref(false)
const home = ref(true)
const clientSegment = ref(false)
const keystamp = ref(0)

const onReloadAvatar = ()=>{
    keystamp.value = new Date().getTime();
}
const navItem = (item) => {
    if (item == 'Fonte Prospect') {
        home.value = false
        dataProspect.value = true
        category.value = false
        phasesFunnel.value = false
        typeTasks.value = false
        automation.value = false
        perfil.value = false
        flowAutomation.value = false
        clientSegment.value = false
    }
    else if (item == 'Categorias') {
        home.value = false
        dataProspect.value = false
        category.value = true
        phasesFunnel.value = false
        typeTasks.value = false
        perfil.value = false
        automation.value = false
        flowAutomation.value = false
        clientSegment.value = false
    }
    else if (item == 'Fases do Funil') {
        home.value = false
        dataProspect.value = false
        category.value = false
        phasesFunnel.value = true
        typeTasks.value = false
        perfil.value = false
        automation.value = false
        flowAutomation.value = false
        clientSegment.value = false
    }
    else if (item == 'Tipos de Tarefas') {
        home.value = false
        dataProspect.value = false
        category.value = false
        phasesFunnel.value = false
        typeTasks.value = true 
        perfil.value = false
        automation.value = false
        flowAutomation.value = false
        clientSegment.value = false
    }
    else if (item == 'Perfil') {
        home.value = false
        dataProspect.value = false
        category.value = false
        phasesFunnel.value = false
        typeTasks.value = false 
        perfil.value = true
        automation.value = false
        flowAutomation.value = false
        clientSegment.value = false
    }
    else if (item == 'Automação Tarefas') {
        home.value = false
        dataProspect.value = false
        category.value = false
        phasesFunnel.value = false
        typeTasks.value = false 
        perfil.value = false
        automation.value = true
        flowAutomation.value = false
        clientSegment.value = false
    }
    else if (item == 'Fluxo de Automações') {
        home.value = false
        dataProspect.value = false
        category.value = false
        phasesFunnel.value = false
        typeTasks.value = false 
        perfil.value = false
        automation.value = false
        flowAutomation.value = true
        clientSegment.value = false
    }
    else if (item == 'Home') {
        home.value = true
        dataProspect.value = false
        category.value = false
        phasesFunnel.value = false
        typeTasks.value = false 
        perfil.value = false
        automation.value = false
        flowAutomation.value = false
        clientSegment.value = false
    }
    else if (item == 'Segmentos') {
        clientSegment.value = true
        home.value = false
        dataProspect.value = false
        category.value = false
        phasesFunnel.value = false
        typeTasks.value = false 
        perfil.value = false
        automation.value = false
        flowAutomation.value = false
    }
    
}
</script>
<template>
    <div class="flex flex-row bg-neutral-100">
      <div class="w-1/6">
        <SidebarMenu></SidebarMenu>
      </div>
      <div class="flex flex-col w-full" >
        <NavBar :section="'Kanban'" :head="[]"></NavBar>
        <div class="flex flex-row">
        <div class="grid  gap-2 ml-2 mr-2 w-full" :class="`grid-cols-${numberPhases}` ">
        <div v-for="(item, index) in listTasks" :key="index">
          
          <div class="grid col-span-1 w-full content-start" >
            <div class="w-full mb-4">
            <CardTopKanban :etapa="item.phase" :color="item.color"/>
          </div>
          <draggable :list="item.tasks" :item-key="'lista'+item.order" class="list-group" @change="onDrop($event, item.order)" ghost-class="ghost"
              group="funil" @start="dragging = true" @end="drag=false">
              <template #item="{ element }" >
                <CardTask :task="element" :color="'bg-blue-500'" @sendOpen="sendOpen"></CardTask>
              </template>
            </draggable>
          </div>                
        </div>                
        </div>                
       
        </div>      
      </div>
      
    </div>
  </template>
  <script setup>
   import NavBar from '@/components/NavBar.vue'
  import SidebarMenu from '@/components/SidebarMenu.vue'
  import { userDefineStore } from '@/store/UserStore'
  import CardTask from '@/components/CardTask.vue'
  import CardTopKanban from '@/components/CardTopKanban.vue'
  import axios from 'axios'
  import {   onMounted, ref } from 'vue'
 // import { useRouter } from 'vue-router'
  import draggable from 'vuedraggable'
//  import SmartAlert from '@/components/SmartAlert.vue'
  const userStore = userDefineStore()
const numberPhases= ref(0)
  const listTasks = ref([])
  onMounted(()=>{
    getTasks()
  })
  const getTasks=()=>{
    const url = process.env.VUE_APP_API_URL + "kanban/" + userStore._company_id
  const config = {
    headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
  axios.get(url, config).then((response) => {
    numberPhases.value = Object.keys(response.data).length
    listTasks.value = response.data
   // listInvolvedsTasks.value = response.data.involvedsTasks
    //loadTasks.value = true
  })
  }

  const sendOpen = ()=>{
    console.log('open')
  }
  const onDrop = (evt, list) => {
  if(evt.added){
    let task = evt.added.element
    task.status = list
    const url = process.env.VUE_APP_API_URL + "tasks/"+task.id
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
    };
    axios.put(url, task, config)
  }
  }
  </script>
import { ifAdminAuthenticated, ifAuthenticated } from '@/router/guard'
import FunilView from '@/views/FunilView.vue'
import QualificatingView from '@/views/QualificatingView'
import { createRouter, createWebHistory } from 'vue-router'
import CalendarView from '../views/NewCalendarView.vue'
import ClientOportunity from '../views/ClientOportunity.vue'
import ClientView from '../views/ClientView.vue'
import ConfigureView from '../views/Configure/ConfigureView.vue'
import DashboardView from '../views/DashboardView.vue'
import ExpenseView from '../views/ExpenseView.vue'
import LeadView from '../views/LeadView.vue'
import LoginView from '../views/LoginView.vue'
import NotificationView from '../views/NotificationView'
import OportunityView from '../views/OportunityView.vue'
import OpportunityDetailView from '../views/OpportunityDetailView.vue'
import ProductsView from '../views/ProductsView.vue'
import QuestionsView from '../views/QuestionsView.vue'
import ReportVisitView from '../views/ReportVisitView.vue'
import SdrView from '../views/SdrView.vue'
import UserView from '../views/UserView.vue'
import CompanyView from '../views/admin/CompanyView.vue'
import DashboardAdm from '../views/admin/DashboardAdm.vue'
import AdmLogin from '../views/admin/LoginView.vue'
import PlanView from '../views/admin/PlanView.vue'
import CommercialProposalView from '../views/CommercialProposalView.vue'
import KanbanView from '@/views/KanbanView.vue'
import ChatView from '@/views/ChatView.vue'
const routes = [{
    path: '/',
    name: 'login',

    component: LoginView
},
{
    path: '/adm',
    name: 'AdmLogin',

    component: AdmLogin
},
{
    path: '/chat',
    beforeEnter: ifAuthenticated,
    name: 'Chat',
    component: ChatView
},
{
    path: '/sdr',
    beforeEnter: ifAuthenticated,
    name: 'sdr',
    component: SdrView
},
{
    path: '/expense',
    beforeEnter: ifAuthenticated,
    name: 'expense',
    component: ExpenseView
},
{
    path: '/dashboard',
    beforeEnter: ifAuthenticated,
    name: 'dashboard',
    component: DashboardView
},
{
    path: '/kanban',
    beforeEnter: ifAuthenticated,
    name: 'kanban',
    component: KanbanView
},
{
    path: '/commercialProposal',
    beforeEnter: ifAuthenticated,
    name: 'commercialProposal',
    component: CommercialProposalView
},
{
    path: '/report',
    beforeEnter: ifAuthenticated,
    name: 'report',
    component: ReportVisitView
},
{
    path: '/dashboardAdm',
    beforeEnter: ifAdminAuthenticated,
    name: 'dashboardAdm',
    component: DashboardAdm
},
{
    path: '/plan',
    beforeEnter: ifAdminAuthenticated,
    name: 'Plan',
    component: PlanView
},
{
    path: '/company',
    beforeEnter: ifAdminAuthenticated,
    name: 'company',
    component: CompanyView
},
{
    path: '/clientOportunity',
    beforeEnter: ifAuthenticated,
    name: 'clientOportunity',
    component: ClientOportunity
},
{
    path: '/configure',
    beforeEnter: ifAuthenticated,
    name: 'configure',
    component: ConfigureView
},
{
    path: '/calendar',
    beforeEnter: ifAuthenticated,
    name: 'calendarView',
    component: CalendarView
},
{
    path: '/client',
    beforeEnter: ifAuthenticated,
    name: 'client',
    component: ClientView
},
{
    path: '/notification',
    beforeEnter: ifAuthenticated,
    name: 'notification',
    component: NotificationView
},
{
    path: '/noticifation',
    name: 'noticifation',
    beforeEnter: ifAuthenticated,
    component: NotificationView
},
{
    path: '/user',
    name: 'user',
    beforeEnter: ifAuthenticated,
    component: UserView
},
{
    path: '/produtos',
    name: 'produtos',
    beforeEnter: ifAuthenticated,
    component: ProductsView
},
{
    path: '/lead:qualifited_id',
    name: 'lead',
    beforeEnter: ifAuthenticated,
    component: LeadView
},
{
    path: '/oportunity:opportunity_id',
    name: 'oportunity',
    beforeEnter: ifAuthenticated,
    component: OportunityView
},
{
    path: '/opportunityDetail:opportunity_id',
    name: 'opportunityDetail',
    beforeEnter: ifAuthenticated,
    component: OpportunityDetailView
},
{
    path: '/qualificating:prospect_id',
    name: 'qualificating',
    beforeEnter: ifAuthenticated,
    component: QualificatingView
},
{
    path: '/questions',
    name: 'questions',
    beforeEnter: ifAuthenticated,
    component: QuestionsView
},

{
    path: '/funil',
    name: 'funil',
    beforeEnter: ifAuthenticated,
    component: FunilView
},

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
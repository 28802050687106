
<template>
    <div class="fixed w-full inset-0 z-100 overflow-hidden flex justify-center items-center animated fadeIn faster"
        style="background: rgba(0,0,0,.7);">
        <div class="border border-indigo-500 shadow-lg  modal-container bg-white w-2/3 h-128
             rounded shadow-lg  overflow-y-auto">
            <div class="modal-content py-4 text-center px-4 z-49 ">
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t h-20">
                  <h3 class="text-xl font-semibold text-gray-900 ">
                      {{ title }}
                  </h3>
                  <button @click="sendClose"  type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal">
                      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                      </svg>
                      <span class="sr-only">Close modal</span>
                  </button>
              </div>

                <!--Body-->
                <div class="flex flex-col h-80 bg-white z-60 ">
                    <slot>

                    </slot>
                </div>
            </div>

            <!--Footer-->
            <div class="flex flex-row items-center justify-end  border-t border-gray-200 rounded-b " >
                  <button type="button" @click="saveAction"
                  class="w-24 h-10 mt-4 ml-4 mr-4  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 
                  focus:outline-none focus:ring-blue-300 
                  font-medium rounded-lg text-sm text-center ">Salvar</button>
                  <button  type="button" @click="cancelAction" 
                  class="w-24 h-10 mt-4 ml-4 mr-4  text-sm font-medium text-gray-900 focus:outline-none 
                  bg-white rounded-lg border border-gray-200 hover:bg-gray-100 
                  hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 ">Cancelar</button>
              </div>
        </div>
    </div>
</template>
<script setup>
// eslint-disable-next-line 
const emit = defineEmits(['refresh', 'save']);
// eslint-disable-next-line
const props = defineProps(['title'])
const sendClose = () => {
    emit('refresh');
}
const cancelAction = () => {
    emit('refresh');
}
const saveAction = () => {
    emit('save');
}

</script>
<style scoped>
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1.5;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1.5;
    }

    to {
        opacity: 0;
    }
}
</style>

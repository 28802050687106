<template>
    <div>
      <div  class="flex flex-col ml-2 items-start">
        <div class="w-full h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
                <span class="ml-4 ">Avaliação do cliente {{ clientInfo.name }}</span>
        </div>
            
            <p class=" text-gray-700 mt-2 font-semibold font-sans ml-2 italic text-sm">*selecione o questionário para iniciar a avaliação</p>
          
         
          <div class="flex flex-row mb-4">
        
            <BuscaQuestionarie @sendData="receivedQuestionarie" :type="'Cliente'" class="flex items-start ml-4"></BuscaQuestionarie>
            
          </div>
          <div v-if="oldAvaliation" class="col-span-4 items-end h-8">        
           <span class="text-blue-500">
             {{ oldAvaliation.length}} avaliações efetuadas para este cliente.
           </span>
            <button v-if="!show_reviews" 
            class="p-1 focus:outline-none focus:shadow-outline h-8 w-8 bg-blue-500 rounded-md text-white hover:bg-blue-600"
            @click="show_reviews = !show_reviews">
            <font-awesome-icon icon="fa-solid fa-angle-left" />
          </button>
          <button v-if="show_reviews" aria-label="Edit user"
          class="p-1 focus:outline-none focus:shadow-outline  bg-blue-500 h-8 w-8 rounded-md  text-white hover:bg-blue-600"
          @click="show_reviews = !show_reviews">
          <font-awesome-icon icon="fa-solid fa-angle-down" />
        </button>
      </div>
          <div v-if="openQuestionary">
           
            <div v-for="(item, index) in listQuestions" :key="index" class="flex flex-col text-lg  items-start ">
            <div v-if="index == control" class="p-2">
                <FormResponse @sendQuestion="saveResponse" @back="back" :index="index"
                    :qualify="responseQualified[control]" :question="item">
                </FormResponse>
                
            </div>
          </div>
          <div v-if="calculate" class="flex flex-col  items-start justify-start">
            <span class="flex justify-center text-lg text-lify_blue font-sans">{{ menssage }}</span>
            <ButtonLify @action-click="saveReview" :title="'Encerrar Avaliação'"></ButtonLify>
            
          </div>
        </div>
      </div>
     
    </div>
  </template>
  
  <script setup>
  import FormResponse from '@/components/FormResponse.vue'
import { userDefineStore } from '@/store/UserStore'
import axios from 'axios'
import { inject, ref, watch, } from 'vue'
import BuscaQuestionarie from './BuscaQuestionarie.vue'
import ButtonLify from './default/ButtonLify.vue'
  const userStore = userDefineStore()
 
  // eslint-disable-next-line
  const emit = defineEmits(['close']);
  // eslint-disable-next-line
const props = defineProps(['clientInfo'])
  
  const totalQuestions = ref()
  const listQuestions = ref([])
  const calculate = ref(false)
  const openQuestionary = ref(false)
  const oldAvaliation = ref(false)
  const  responseQualified = ref([]); 
  const questionariSelected = ref(false)
  const menssage = ref();
  const payloadSave = ref([])
  const show_reviews = ref(false)
  const control = ref(0)
  const $swal = inject('$swal');

  watch(
    () => questionariSelected.value,
  () => {
   
    if(questionariSelected.value) {
      onChange()
  }
}
)
 

  const receivedQuestionarie = (item)=>{
    questionariSelected.value = item.id
    
  }
  const onChange = () => {

    const url = process.env.VUE_APP_API_URL + "questionsQuestionary/" + userStore._company_id + '/' + questionariSelected.value
    const config = {
        headers: { Authorization: `Bearer ${userStore._api_token}` }
        };
    axios.get(url, config).then((response) => {
    totalQuestions.value = Object.keys(response.data).length
    listQuestions.value = response.data
    if(listQuestions.value.length == 0){
      $swal.fire(
              'Questionário sem perguntas!',
              'Verifique se existem perguntas para este questionário no menu Questionários.',
              'warning'
          )
    }
    openQuestionary.value = true
      })
              }
  
  const back = () => {
      control.value--;
  }
  const saveResponse = (event) => {
      responseQualified.value[control.value] = event
      control.value++;
      if (control.value == totalQuestions.value) {
          let totalPoints = responseQualified.value.reduce((total, num) => total + num.points, 0);
          let totalDefault = responseQualified.value.reduce((total, num) => total + parseInt(num.value), 0);
          let totalPercente = (totalPoints / totalDefault) * 100
          let temperature = 0
          if (totalPercente >= 75) {
              temperature = 3
              menssage.value = "Pontuação: " + totalPercente + "%."
          }
          else if ((totalPercente < 75) && (totalPercente >= 50)) {
              menssage.value = "Pontuação: " + totalPercente + "%."
              temperature = 2
          }
          else {
              menssage.value = "Pontuação: " + totalPercente + "%."
              temperature = 1
          }
          calculate.value = true
          payloadSave.value = {
              status: 'qualificated',
              temperature: temperature,
              type:'client',
              points: totalPoints,
              answers: responseQualified.value,
              company_id: userStore._company_id ,
              product_id: null,
              questionary_id: questionariSelected.value,
              client_id: props.clientInfo.id,
              user_id: userStore._user_id
          }
  
      }
  }
  const saveReview = ()=>{
    payloadSave.value.msg = 'Nova avaliação de cliente efetuada.'
    const url = process.env.VUE_APP_API_URL + "review"
  
  const config = {
      headers: { Authorization: `Bearer ${userStore._api_token}` }
  };
   axios.post(url, payloadSave.value, config).then(() => {
      emit('close');
      $swal.fire(
              'Perfeito',
              'Cliente Avaliado com sucesso',
              'sucess'
          )
    
      questionariSelected.value =''
      openQuestionary.value = false
  })
  }

  </script>
  
  <style scoped>
      
  </style>
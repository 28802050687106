<template>
<div v-if="contain">
    <div class="grid grid-cols-5">
        <span v-if="type == 'lead'" class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Lead</span>
        <span v-if="type == 'client'" class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Cliente</span>
        <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Data</span>
        <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Responsável</span>
        <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Pontos</span>
        <span class="grid col-span-1 text-lg text-white border-2 border-white bg-lify_blue">Abrir</span>
    </div>
    <div class="grid grid-cols-5" v-for="(review, index) in listReviews" :key="index">
        <span class="grid col-span-1 text-base  text-black">{{ review.lead_name }}</span>
        <span class="grid col-span-1 text-base  text-black">{{ formateDate(review.created_at) }}</span>
        <span class="grid col-span-1 text-base  text-black">{{ review.user_name }}</span>
        <span class="grid col-span-1 text-base  text-black">{{ review.points }}</span>
        <span class="grid col-span-1 text-base  text-black">
                <ButtonLify :icon="true" v-if="!openQuestions"  @action-click="openReview(review.answers)" class="grid col-span-1 justify-items-center justify-self-center">
                    <font-awesome-icon icon="fa-solid fa-folder-open"  class="flex items-center text-center"/>
                </ButtonLify>
                <ButtonLify :icon="true"  v-if="openQuestions" @action-click="closeReview">
                    <font-awesome-icon icon="fa-solid fa-folder-closed"  class="flex items-center text-center"/>
                </ButtonLify>
            </span>
        
    </div>
    <div v-if="openQuestions" > 
        <ModalItem @refresh="closeReview">             
        <div class="grid col-span-4 mt-4 mb-4 h-6 bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md ">
            <span class=" ml-4">Questionário</span>
        </div>
        <div v-for="(element, index) in questions" class="mt-2  mr-4" :key="index">
            <LineAnswers  :element="element"></LineAnswers>
        </div> 
        </ModalItem>          
    </div>
</div>
<div v-else>Não foi encontrado nenhum review para os dados informados.</div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
  import ModalItem from '@/components/ModalItem.vue';
  import LineAnswers from '@/components/LineAnswers.vue';
  import ButtonLify from './default/ButtonLify.vue';
  // eslint-disable-next-line
const props = defineProps(['listReviews','type'])
const questions = ref([])
const openQuestions = ref(false)
const contain = ref(false)
onMounted(()=>{
    if(props.listReviews[0]){
        contain.value = true
    }
})
   const formateDate = (data) => {
    let dataLocal = new Date(data)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit" };
    return dataLocal.toLocaleTimeString('pt-BR', options)
}
const openReview = (item)=>{

questions.value = JSON.parse(item)
openQuestions.value =true
}
const closeReview = ()=>{
    
    questions.value = ''
    openQuestions.value =false
}

</script>
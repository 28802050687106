<template>
  <div>
    <div class="flex flex-row  h-screen w-screen bg-neutral-100">
      <SidebarMenu></SidebarMenu>
      <div class="flex flex-col w-full">
        <NavBar :section="'Relatório de Visita'" :head="['Novo', 'Listagem']" @navItem="navItem"></NavBar>
        <div v-if="newReport">
          <ModalItem  @refresh="closeModal">
            <FormReportVisit @saved="closeModal"></FormReportVisit>
          </ModalItem>
        </div>

        <ModalItemSm v-if="updateReport" :title="'Edição de relatório'" @refresh="closeModal">
          <ReportVisit :title="'Update de relatório de visitas'" 
          @saved="closeModal"
           class="mr-4" 
          :update="true"
          :report="report"
          :opportunity="report.opportunity_id" 
          :lead="report.lead_id" 
          :client="report.client_id" 
          :prospect="report.prospect_id"/>
        </ModalItemSm>
          <div v-if="listReport">
            <ListReportComponent @onUpdate="onUpdate" :key="keystamp"></ListReportComponent>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script setup>
import FormReportVisit from '@/components/FormReportVisit.vue'
import ModalItemSm from '@/components/ModalItemSm.vue'
import ReportVisit from '@/components/ReportVisit.vue'
import ListReportComponent from '@/components/ListReportComponent.vue'
import NavBar from '@/components/NavBar.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import ModalItem from '@/components/ModalItem.vue'
import { ref } from 'vue'

  const newReport= ref(false)
  const updateReport= ref(false)
  const listReport =ref(true)
  const report = ref([])
  
  const keystamp = ref(0)
  
  const navItem = (item) => {
    if (item == 'Novo') {
        newReport.value = true
        listReport.value = false
       
      }
      else if(item=='Listagem'){
        keystamp.value = new Date().getTime();
        newReport.value = false
        listReport.value = true
       
      }
      
      
    }
    const closeModal = ()=>{
      updateReport.value = false
      newReport.value = false
      listReport.value = true
      keystamp.value = new Date().getTime();
    }
    const onUpdate = (reportUpdate)=>{
      report.value = reportUpdate
      updateReport.value = true
  }
  </script>
  
  <style scoped>
  </style>
<template>
    <div class="flex flex-row mt-4">
            <div class="flex flex-col items-start ml-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                Data de início</label>
                <input type="date" v-model="dateStart" 
                class=" ml-2 bg-gray-50 border border-gray-300
                 text-gray-900 text-sm rounded-sm focus:ring-blue-500
                  focus:border-blue-500 block w-full p-1 h-8
                  "> 
            </div>
            <div class="flex flex-col items-start ml-2 mr-2">
                <label class="text-sm ml-2 font-normal text-gray-900">
                    Data de fim</label>
                <input type="date" v-model="dateEnd"
                class=" ml-2 bg-gray-50 border border-gray-300
                 text-gray-900 text-sm rounded-sm focus:ring-blue-500
                  focus:border-blue-500 block w-full p-1 h-8
                  "> 
            </div>
            <div class="flex flex-col items-start ml-2">
               <BuscaMultipleUsers @sendUser="receivedUser" :title="title"></BuscaMultipleUsers> 
            </div>
           
            
        </div>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue'
import BuscaMultipleUsers from './BuscaMultipleUsers.vue';
const dateStart = ref(false)
const dateEnd = ref(false)
const user = ref(false)
// eslint-disable-next-line
const props = defineProps(['title'])
const $swal = inject('$swal');

// eslint-disable-next-line
const emit = defineEmits(['sendData']);
onMounted(()=>{
    iniDates()
})
const receivedUser = (userReceived)=>{
user.value = userReceived
sendData()
}
const sendData = ()=>{
    if((dateStart.value) && (dateEnd.value)&&(user.value)){
        emit('sendData',{user: user.value, dateEnd: dateEnd.value, dateStart:dateStart.value})
    }
    else{
        $swal.fire(
            'Ops!',
            'Você precisa inserir todos os dados da pesquisa.',
            'warning'
        )
    }

}
const iniDates = ()=>{
  
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);

 dateStart.value = now.getFullYear()+"-"+(month)+"-01" ;
 dateEnd.value = now.getFullYear()+"-"+(month)+"-"+(day) ;
    }
</script>

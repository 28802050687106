<template>
    <div>

        <nav class="h-14  bg-white border-gray-200  p-2">
            <div class="max-w-screen-xl flex flex-wrap  justify-between  ">
                <div class="flex flex-row items-center">
                    <span class="self-center ml-4 mr-2 text-xl font-semibold whitespace-nowrap ">{{ section }} </span>
                    <span v-if="client" class="mr-2">Cliente: </span>
                    <BuscaClientes v-if="client" :title="true" :titleValue="' '" @sendClient="receivedClient" :key="keystamp"></BuscaClientes>

                </div>
                <button data-collapse-toggle="navbar-default" type="button"
                    class="inline-flex items-center ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
                    aria-controls="navbar-default" aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
                <div class="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul
                        class="font-medium  flex flex-col  md:p-0  border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
                        <li v-for="(item, index) in head" :key="index">
                            <div v-if="menuItem == item" @click="senditem(item)"
                                class="block py-2 pl-3 cursor-pointer pr-4 text-blue-500 border-blue-500 bg-blue-700  rounded md:bg-transparent md:p-0 "
                                aria-current="page">*{{ item }}</div>
                            <div v-else @click="senditem(item)"
                                class="block py-2 pl-3 cursor-pointer pr-4  bg-blue-700 rounded md:bg-transparent text-blue-700 md:p-0 "
                                aria-current="page">{{ item }}</div>
                        </li>
                        <li class="cursor-pointer" @click="openTaskForm = !openTaskForm">
                            <span class="text-yellow-400">
                                    <font-awesome-icon icon="fa-solid fa-list-check" class="text-blue-400 mr-4" />
                            </span>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'notification' }">
                                <span class="text-yellow-400">
                                    {{ chats.notifications }}
                                    <font-awesome-icon icon="fa-solid fa-bell" class="text-yellow-400 mr-4" />
                                </span>
                            </RouterLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <ModalItem v-if="openTaskForm" @refresh="closeModal" :title="'Nova Tarefa'">
            <FormTask @sendClose="closeModal"></FormTask>
            
        </ModalItem>
    </div>
</template>

<script setup>
import FormTask from './FormTask.vue';
import ModalItem from './ModalItem.vue';
import BuscaClientes from './BuscaClientes.vue';
import { ref,onMounted } from 'vue';
import { chatDefineStore } from '@/store/ChatStore';
  const chats = chatDefineStore()
// eslint-disable-next-line
const props = defineProps(['section', 'head', 'client','menuItem'])
// eslint-disable-next-line
const emit = defineEmits(['navItem','sendClient', 'refresh']);
const openTaskForm = ref(false)
const keystamp = ref(0)
onMounted(()=>{
    keystamp.value = new Date().getTime();
})
const senditem = (item) => {
    emit('navItem', item);
}
const closeModal = ()=>{
     openTaskForm.value = false;
     emit('refresh');
}

const receivedClient = (item)=>{
    emit('sendClient', item);
}
</script>

<style scoped></style>
<template>
    <div class="p-2 mb-3 w-72  flex flex-col border-solid border-2 
     rounded-lg hover:shadow-lg  " :class="{
                                    'border-green-600': colorGreen,
                                    'text-red-600': colorRed,
                                }">
        <div class="flex flex-col justify-between  " >

            <div class="flex items-center">
                <span @click="openTask" class="ml-2 text-gray-700 text-sm font-sans tracking-wide hover:cursor-pointer hover:text-blue-700">{{
                    task.title
                }}</span>
            </div>
            <div class="flex flex-row justify-between">
                <p class="ml-2 text-gray-700 text-sm font-sans tracking-wide">
                   Vencimento {{ formateDate(task.data) }}
                </p>
                <div v-if="openComent"  @click="openComent = !openComent"> <font-awesome-icon class="text-blue-500 cursor-pointer ml-2" icon="fa-solid fa-circle-info " /></div>
                <div v-if="!openComent" @click="openComent = !openComent"> <font-awesome-icon class="text-blue-500 cursor-pointer" icon="fa-solid fa-circle-info" /></div>
            </div>
            

            <ModalItemSm @refresh="close" v-if="openComent"> 
                <div class="flex flex-col justify-start items-start text-start">
                    <div class="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
                        <span class="ml-4">Informações da Tarefa</span>
                    </div>
                    <div class="flex flex-row items-start">
                        <span class="text-base font-normal text-gray-700">                            
                            Tafera: 
                        </span>
                        <span class="text-base font-normal ml-2 text-gray-900">                            
                           {{task.title}}
                        </span>
                    </div>
                   
                    <div class="flex flex-row items-start mt-2">
                        <span class="text-base font-normal text-gray-700">                            
                            Descrição: 
                        </span>
                        <span class="text-base font-normal ml-2 text-gray-900">                            
                           {{task.description}}
                        </span>
                    </div>
                    <div class="flex flex-row items-start mt-2">
                        <span class="text-base font-normal text-gray-700">                            
                            Data Abertura: 
                        </span>
                        <span class="text-base font-normal ml-2 text-gray-900">                            
                            {{formateDate2(task.created_at)}}
                        </span>
                    </div>
                    <div class="flex flex-row items-start mt-2">
                        <span class="text-base font-normal text-gray-700">                            
                            Data Encerramento: 
                        </span>
                        <span class="text-base font-normal ml-2 text-gray-900">                            
                            {{formateDate2(task.updated_at)}}
                        </span>
                    </div>
                   

                    <div class="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
                        <span class="ml-4">Comentários</span>
                    </div>
                    <div v-for="(commentario, index) in task.coments" :key="index" class="flex items-start justify-start">
                        <span  class="flex ml-6 italic items-start text-sm text-blue-600">
                            {{ commentario.name }} : {{ commentario.description }}
                        </span>
                    </div>
                   
                    
                    <div class="content-end  items-end w-full  bg-gradient-to-r from-cyan-500 to-blue-500 text-start text-white rounded-md mt-4 mb-4">
                        <hr>
                    </div>
                    
                </div>
            </ModalItemSm>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref} from 'vue';
import ModalItemSm from './ModalItemSm.vue';

// eslint-disable-next-line
const props = defineProps(['task'])
const colorGreen = ref(false)
const colorRed = ref(false)
onMounted(()=>{
    if(props.task.result == 'success'){
        colorGreen.value = true
    }
    else{
        colorRed.value = true

    }
})
const openComent = ref(false)

// eslint-disable-next-line
const emit = defineEmits(['sendOpen','sendRefresh']);
const close = ()=>{
    openComent.value = !openComent.value 
}
const formateDate = (data) => {
    
    let dataLocal = data.split('-')
    dataLocal = dataLocal[2]+'/'+dataLocal[1]+'/'+dataLocal[0]
   
    return dataLocal
}
const formateDate2 = (data) => {
    
    let dataLocal = data.split('T')
    dataLocal = dataLocal[0].split('-')
    dataLocal = dataLocal[2]+'/'+dataLocal[1]+'/'+dataLocal[0]
    return dataLocal
}
const openTask = ()=>{
    emit('sendOpen', props.task);
}




</script>

<style scoped>

</style>